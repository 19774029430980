<template>
  <div class="row">
    <div class="col">
      <GmvMap
        id="map-location-control"
        mapId="mapKey"
        mapKey="mapKey"
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="roadmap"
        style="width:100%;  height: 72vh;"
        :options="mapOptions"
      >
        <GmvInfoWindow
          mapId="mapKey"
          mapKey="mapKey"
          v-for="(info, index) in infoList"
          :key="index"
          :options="infoOptions"
          :position="info.infoWindowPos"
          :opened="info.infoWinOpen"
        >
          <TooltipComponent :infoContent="info.infoContent"/>
        </GmvInfoWindow>
        <GmvCircle
          v-for="location in circles"
          :key="location.id"
          :center="location.center"
          :draggable="false"
          :editable="false"
          :radius="+location.radius"
        />
        <GmvPolygon
          v-for="polygon in polygons"
          :key="polygon.id"
          :draggable="false"
          :editable="false"
          :paths="polygon"
        />
      </GmvMap>
    </div>
  </div>
</template>

<script>
import { utilities } from '@gmap-vue/v3'
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import TooltipComponent from '@/components/LocationControl/Components/TooltipComponent'
import { toRaw } from 'vue'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'MapComponent',
  components: { TooltipComponent },
  mixins: [momentMixin],
  data () {
    return {
      center: { lat: 0, lng: 0 },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      zoom: 18,
      map: null,
      polylines: [],
      markers: [],
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -5
        }
      },
      infoList: [],
      circles: [],
      polygons: [],
      intervalId: null,
      google: null
    }
  },
  props: { showTooltips: Boolean },
  created () {
    this.initGoogle()
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      // this.locations = this.$store.getters.locations
    }).catch(() => {
      // TODO: missing catch
    })
  },
  mounted () {
    this.handleRef()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      profile: 'Profile'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    },
    displayFilters () {
      return this.$store.getters.displayFilters
    },
    employeesList () {
      return this.$store.getters.employeesGeolocation
    },
    locations () {
      return this.$store.getters.locations.filter(l => !l.hide)
    }
  },
  watch: {
    map () {
      if (this.map) {
        if (this.displayFilters.only_last_point) {
          this.handlePoints()
        } else {
          this.handleRoutes()
        }
      }
    },
    google () {
      if (this.google) {
        this.init()
      }
    },
    locations () {
      const circles = []
      const polygons = []
      this.locations.forEach(location => {
        if (location.use_type === 'circle') {
          circles.push({
            center: {
              lat: +location.latitude,
              lng: +location.longitude
            },
            radius: location.allowed_radius
          })
        }
        if (location.geometry && location.geometry.coordinates && location.use_type === 'polygon') {
          const polygon = []
          location.geometry.coordinates[0].forEach(point => {
            polygon.push({
              lng: +point[0],
              lat: +point[1]
            })
          })
          polygons.push(polygon)
        }
      })
      this.polygons = polygons
      this.circles = circles
    },
    employeesList () {
      if (this.google) {
        this.init()
      }
    },
    displayFilters () {
      if (this.google) {
        this.init()
      }
    },
    showTooltips () {
      if (this.infoList) {
        toRaw(this.infoList).forEach(info => {
          info.infoWinOpen = this.showTooltips
        })
      }
    }
  },
  methods: {
    initGoogle () {
      this.google = getGoogleMapsAPI()
      if (!this.google) {
        setTimeout(() => {
          if (!this.google) {
            this.initGoogle()
          }
        }, 1000)
      }
    },
    handleRef () {
      if (!this.map) {
        if (this.$refs.mapRef) {
          this.$refs.mapRef.mapPromise?.then((map) => {
            this.map = map
            if (this.employeesList.length === 0) {
              navigator.geolocation.getCurrentPosition(position => {
                if (position) {
                  this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  }
                  this.map.panTo(this.center)
                }
                if (this.google) {
                  this.init()
                }
              })
            } else {
              if (this.google) {
                this.init()
              }
            }
          })
        } else {
          setTimeout(() => {
            this.handleRef()
          }, 1000)
        }
      }
    },
    init () {
      if (this.map) {
        if (this.displayFilters.only_last_point) {
          this.handlePoints()
        } else {
          this.handleRoutes()
        }
      }
    },
    handlePoints () {
      const locations = []
      const employeesList = this.displayFilters.employees_filter.length > 0
        ? this.employeesList.filter(employee => this.displayFilters.employees_filter.includes(employee.id))
        : this.employeesList
      employeesList.forEach(employee => {
        const index = employee.locations.length - 1
        locations.push({
          latitude: employee.locations[index].latitude,
          longitude: employee.locations[index].longitude,
          recorded_time: employee.locations[index].recorded_time,
          color: employee.color,
          name: employee.full_name,
          marker_label: (employee.last_name[0] + ' ' + employee.first_name[0]).toUpperCase()
        })
      })
      if (locations.length > 0) {
        this.center = {
          lat: +locations[0].latitude,
          lng: +locations[0].longitude
        }
        this.map.panTo(this.center)
      }
      this.$nextTick(() => {
        this.updateMarkers(locations)
      })
    },
    handleRoutes () {
      const locations = []
      const locationsIndexed = {}
      const employeesList = this.displayFilters.employees_filter.length > 0 ? this.employeesList.filter(employee => this.displayFilters.employees_filter.includes(employee.id)) : this.employeesList
      employeesList.forEach(employee => {
        employee.locations.forEach(location => {
          if (!locationsIndexed[location.shift_id]) {
            locationsIndexed[location.shift_id] = []
          }
          locations.push({
            latitude: location.latitude,
            longitude: location.longitude,
            recorded_time: location.recorded_time,
            name: employee.full_name,
            marker_label: (employee.last_name[0] + ' ' + employee.first_name[0]).toUpperCase(),
            color: employee.color,
            shift_id: location.shift_id
          })
          locationsIndexed[location.shift_id].push({
            lat: +location.latitude,
            lng: +location.longitude,
            color: employee.color
          })
        })
      })
      if (locations.length > 0) {
        this.center = {
          lat: +locations[0].latitude,
          lng: +locations[0].longitude
        }
        this.map.panTo(this.center)
      }
      this.$nextTick(() => {
        this.updateMarkers(locations)
        this.updatePolyline(locationsIndexed)
        // console.log(2, locationsIndexed)
      })
    },
    updatePolyline (locations) {
      toRaw(this.polylines).forEach(line => {
        line.setMap(null)
      })
      this.polylines = []
      const lines = []
      Object.values(locations).forEach(path => {
        // console.log(1, path)
        const lineSymbol = {
          path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        }
        lines.push(new this.google.maps.Polyline({
          path: path,
          geodesic: true,
          strokeColor: path[0].color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
          icons: [{
            icon: lineSymbol,
            repeat: '100px'
          }],
          map: this.map
        }))
      })
      this.polylines = lines
    },
    updateMarkers (locations) {
      if (this.infoList) {
        toRaw(this.infoList).forEach(item => {
          item.infoWinOpen = false
        })
      }
      toRaw(this.markers).forEach(marker => {
        marker.setMap(null)
      })
      toRaw(this.polylines).forEach(line => {
        line.setMap(null)
      })
      this.markers = []
      this.polylines = []
      this.infoList = []
      const marks = []
      const points = []
      const info = []
      const path = 'M24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z'
      for (let i = 0; i <= locations.length - 1; i++) {
        info.push({
          infoWindowPos: {
            lat: +locations[i].latitude,
            lng: +locations[i].longitude
          },
          infoWinOpen: this.displayFilters.only_last_point,
          infoContent: {
            name: locations[i].name,
            time: this.fromZoneToZone(locations[i].recorded_time, 'UTC', this.tz).format(this.profile.date_format + ' ' + this.profile.time_format)
          }
        })
        const svgMarker = {
          path: path,
          fillColor: locations[i].color ? locations[i].color : '#ff2800',
          fillOpacity: 0.9,
          strokeWeight: 0,
          rotation: 0,
          scale: 0.4,
          anchor: new this.google.maps.Point(9, 15)
        }
        // svgMarker.fillColor = locations[i].color ? locations[i].color : '#ff2800'
        points.push({
          lat: +locations[i].latitude,
          lng: +locations[i].longitude
        })
        const marker = new this.google.maps.Marker({
          position: {
            lat: +locations[i].latitude,
            lng: +locations[i].longitude
          },
          icon: svgMarker,
          name: locations[i].name,
          time: this.fromZoneToZone(locations[i].recorded_time, 'UTC', this.tz).format(this.profile.date_format + ' ' + this.profile.time_format),
          map: this.map
        })
        if (this.displayFilters.only_last_point) {
          marker.addListener('click', () => {
            this.toggleInfoWindow(marker, i)
          })
        } else {
          marker.addListener('mouseover', () => {
            this.toggleInfoWindow(marker, i)
          })
          marker.addListener('mouseout', () => {
            this.toggleInfoWindow(marker, i)
          })
        }
        marks.push(marker)
      }
      this.infoList = info
      // console.log(2, marks)
      this.markers = marks
      this.$nextTick(() => {
        this.fitToMap(points)
      })
    },
    fitToMap (points) {
      this.map.setZoom(this.zoom)
      if (points.length > 0) {
        const bounds = this.map.getBounds()
        if (bounds) {
          for (let n = 0; n < points.length; n++) {
            bounds.extend(points[n])
          }
          this.map.fitBounds(bounds)
        }
      }
    },
    toggleInfoWindow (marker, i) {
      this.infoList[i].infoWindowPos = marker.position
      this.infoList[i].infoContent = marker
      this.infoList[i].infoWinOpen = !this.infoList[i].infoWinOpen
    }
  }
}
</script>

<style scoped>

</style>
