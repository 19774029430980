import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const state = {
  tasksToAttach: null,
  taskMarksIndexed: {},
  tasksIndexed: {},
  temporarySubtasks: []
}

const getters = {
  tasksToAttach: state => state.tasksToAttach,
  taskMarksIndexed: state => state.taskMarksIndexed,
  taskMarks: state => Object.values(state.taskMarksIndexed).sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
    return 0
  }),
  tasksIndexed: state => state.tasksIndexed,
  tasks: state => Object.values(state.tasksIndexed),
  temporarySubtasks: state => state.temporarySubtasks,
  taskById: (state) => (id) => {
    return state.tasksIndexed[id]
  }
}

const TASKS_INDEXED = 'TASKS_INDEXED'
const CREATE_TASKS_INDEXED = 'CREATE_TASKS_INDEXED'
const UPDATE_TASKS_INDEXED = 'UPDATE_TASKS_INDEXED'
const DELETE_TASKS_INDEXED = 'DELETE_TASKS_INDEXED'
const TASKS_TO_ATTACH = 'TASKS_TO_ATTACH'
const TASK_MARKS_SET = 'TASK_MARKS_SET'
const TASK_MARKS_UPDATE = 'TASK_MARKS_UPDATE'
const TASK_MARKS_DELETE = 'TASK_MARKS_DELETE'
const TEMPORARY_SUBTASKS = 'TEMPORARY_SUBTASKS'

const mutations = {
  [TEMPORARY_SUBTASKS] (state, payload) {
    state.temporarySubtasks = payload
  },
  [TASKS_INDEXED] (state, payload) {
    const tasks = {}
    payload.forEach(task => {
      tasks[task.id] = task
    })
    state.tasksIndexed = tasks
  },
  [CREATE_TASKS_INDEXED] (state, payload) {
    state.tasksIndexed[payload.id] = payload
  },
  [UPDATE_TASKS_INDEXED] (state, payload) {
    if (state.tasksIndexed[payload.id]) {
      state.tasksIndexed[payload.id] = payload
    } else {
      Object.keys(state.tasksIndexed).forEach(key => {
        state.tasksIndexed[key].children = state.tasksIndexed[key].children.map(child => {
          if (child.id === payload.id) {
            return payload
          }
          return child
        })
      })
    }
  },
  [DELETE_TASKS_INDEXED] (state, payload) {
    if (state.tasksIndexed[payload]) {
      delete state.tasksIndexed[payload]
    } else {
      Object.keys(state.tasksIndexed).forEach(key => {
        state.tasksIndexed[key].children = state.tasksIndexed[key].children.filter(child => child.id !== payload)
      })
    }
  },
  [TASKS_TO_ATTACH] (state, data) {
    state.tasksToAttach = data
  },
  [TASK_MARKS_SET] (state, payload) {
    const marks = {}
    payload.forEach(mark => {
      marks[mark.id] = mark
    })
    state.taskMarksIndexed = marks
  },
  [TASK_MARKS_UPDATE] (state, payload) {
    state.taskMarksIndexed[payload.id] = payload
  },
  [TASK_MARKS_DELETE] (state, payload) {
    delete state.taskMarksIndexed[payload]
  }
}

const actions = {
  clearTasksCache () {
    promises = {}
    lastSuccessful = {}
  },
  setTemporarySubtasks ({ commit }, payload) {
    commit(TEMPORARY_SUBTASKS, payload)
  },
  getTasks ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks` + payload).then(response => {
        commit(TASKS_INDEXED, response.data ? response.data : [])
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksForClient ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/for-client`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksSettings ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/settings`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTask ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/${id}`).then(response => {
        commit(CREATE_TASKS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createTask ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/tasks`, payload).then(response => {
        commit(CREATE_TASKS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateTask ({ commit }, [companyId, taskId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/tasks/${taskId}`, payload).then(response => {
        commit(UPDATE_TASKS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editTask ({ commit }, [companyId, taskId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/tasks/${taskId}/edit`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteTask ({ commit }, [companyId, taskId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/tasks/${taskId}`).then(response => {
        commit(DELETE_TASKS_INDEXED, taskId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksBank ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/user-tasks` + payload).then(response => {
        commit(TASKS_INDEXED, response.data ? response.data : [])
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserTaskShifts ({ commit }, [companyId, task, query, leadTime]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/${task}/shifts?date=${query || ''}&lead_time=${leadTime}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getShiftsToAttach ({ commit }, [companyId, scheduleId, query, clientId, leadTime]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/schedules/${scheduleId}/tasks/shifts?date=${query || ''}&client_id=${clientId || ''}&lead_time=${leadTime}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getShiftsToAttachTaskCalendar ({ commit }, [companyId, scheduleId, query]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/schedules/${scheduleId}/tasks/shifts?${query || ''}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksToAttach ({ commit }, [companyId, shiftId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/shift/${shiftId}/tasks`).then(response => {
        commit(TASKS_TO_ATTACH, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksStatuses ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/statuses`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksStatus ({ commit }, [companyId, taskId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/tasks/statuses/${taskId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateTasksStatus ({ commit }, [companyId, taskId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/tasks/statuses/${taskId}`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createTasksStatus ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/tasks/statuses`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteTasksStatus ({ commit }, [companyId, taskId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/tasks/statuses/${taskId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTaskMarks ({ commit }, companyId) {
    const promiseKey = `/companies/${companyId}/tasks_marks`

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.taskMarksIndexed)
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/task_marks`).then(response => {
        commit(TASK_MARKS_SET, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    }).finally(() => {
      delete promises[promiseKey]
    })

    promises[promiseKey] = promise

    return promise
  },
  createTaskMarks ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/task_marks`, payload).then(response => {
        commit(TASK_MARKS_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateTaskMarks ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/task_marks/${payload.id}`, payload).then(response => {
        commit(TASK_MARKS_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteTaskMark ({ commit }, [companyId, taskId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/task_marks/${taskId}`).then(response => {
        commit(TASK_MARKS_DELETE, taskId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
