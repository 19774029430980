<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage }"
    :rules="validation"
    as="div"
    :label="fieldLabel"
  >
    <ui-input-label
      v-if="label"
      :label="label"
      :hint="hint"
      :required="validation && !!validation.required"
    />
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
      <el-input-number
        :model-value="+value"
        v-bind="field"
        :precision="precision"
        :step="step"
        :placeholder="placeholder"
        :disabled="!!disabled"
        :validate-event="false"
        :min="min"
        :size="size"
        :controls="controls"
        :controls-position="controlsPosition"
      />
<!--      <el-input-->
<!--        type="number"-->
<!--        :model-value="value"-->
<!--        v-bind="field"-->
<!--        :placeholder="placeholder"-->
<!--        :disabled="!!disabled"-->
<!--        :validate-event="false"-->
<!--      >-->
<!--        <template v-if="append" #append>-->
<!--          <ui-input-label v-if="label" :label="label" :hint="hint" />-->
<!--        </template>-->
<!--      </el-input>-->
    </el-form-item>
  </Field>
</template>

<script>
export default {
  name: 'number-input',
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: Object,
    value: [Number, String],
    modelValue: [Number, String],
    placeholder: String,
    disabled: Boolean,
    label: String,
    hint: String,
    controls: {
      type: Boolean, default: true
    },
    controlsPosition: {
      type: String, default: ''
    },
    precision: {
      type: Number, default: 2
    },
    step: {
      type: Number, default: 0.1
    },
    min: {
      type: Number, default: -Infinity
    },
    size: {
      type: String, default: 'default'
    },
    fieldLabel: {
      type: String, default: null
    }
  },

  computed: {
    innerValue: {
      get () {
        return +this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
