<template>
  <el-container style="height: auto; max-height: 600px; overflow-y: auto;">
    <el-timeline>
      <el-timeline-item v-for="(item, index) in logActivities"
                        :key="index"
                        :color="item['action'] === 'created' ? '#0bbd87' : ''"
                        :timestamp="formatStringToDateTime(item['created_at']) + ' (' + $t(item['description']) +  ')'" placement="top">
        <template v-if="item.description === 'created' && item.causer">
          <div v-if="item.log_name === 'shift_logger'">
            {{$t('SHIFT_WAS_CREATED_BY')}} {{ item.causer.full_name }}
          </div>
          <div v-if="item.log_name === 'bonus_penalty_logger' && item.properties.attributes.type === 'penalty'">
            <div>
              {{$t('PENALTY_WAS_CREATED_BY')}} {{ item.causer.full_name }}
            </div>
            <div>
              {{$t('amount')}}: {{item.difference.attributes.amount}}
            </div>
          </div>
          <div v-if="item.log_name === 'bonus_penalty_logger' && item.difference.attributes.type === 'bonus'">
            <div>
              {{$t('BONUS_WAS_CREATED_BY')}} {{ item.causer.full_name }}
            </div>
            <div>
              {{$t('amount')}}: {{item.difference.attributes.amount}}
            </div>
          </div>
        </template>
        <template v-if="item.description === 'updated' && Object.keys(item.difference.attributes).length > 1">
          <div v-if="item.causer">{{$t('SHIFT_WAS_UPDATED_BY')}} {{item.causer.full_name}}</div>
          <div>{{$t('LOG_CHANGES')}}</div>
          <table class="table table-hover">
            <thead>
            <tr class="text-center">
              <th>{{$t('PARAMETER')}}</th>
              <th>{{$t('OLD_VALUE')}}</th>
              <th>{{$t('NEW_VALUE')}}</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(val, key) in item.difference.attributes">
              <tr v-if="key !== 'updated_at'" :key="val+ '__' + key">
                <td>
                  {{$t(key)}}
                </td>
                <td class="text-right">
                  <template v-if="['planned_from', 'planned_to', 'time_from', 'time_to'].includes(key)">
                    {{fromZoneToZone(item.difference.old[key], 'UTC', $store.getters.actualTZ).format(localeDateTimeFormat)}}
                  </template>
                  <template v-else>
                    {{item.difference.old[key]}}
                  </template>
                </td>
                <td class="text-right">
                  <template v-if="['planned_from', 'planned_to', 'time_from', 'time_to'].includes(key)">
                    {{fromZoneToZone(item.difference.attributes[key], 'UTC', $store.getters.actualTZ).format(localeDateTimeFormat)}}
                  </template>
                  <template v-else>
                    {{item.difference.attributes[key]}}
                  </template>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </template>
        <template v-if="item.description === 'deleted'">
          <template v-if="item.log_name === 'shift_logger'">
            <div v-if="item.causer">{{$t('SHIFT_WAS_DELETED_BY')}} {{item.causer.full_name}}</div>
          </template>
          <div v-if="item.log_name === 'bonus_penalty_logger' && item.difference.attributes.type === 'penalty'">
            <div>
              {{$t('PENALTY_WAS_DELETED_BY')}} {{ item.causer.full_name }}
            </div>
            <div>
              {{$t('amount')}}: {{item.difference.attributes.amount}}
            </div>
          </div>
          <div v-if="item.log_name === 'bonus_penalty_logger' && item.difference.attributes.type === 'bonus'">
            <div>
              {{$t('BONUS_WAS_DELETED_BY')}} {{ item.causer.full_name }}
            </div>
            <div>
              {{$t('amount')}}: {{item.difference.attributes.amount}}
            </div>
          </div>
        </template>
        <template v-if="item.description === 'restored'">
          <div v-if="item.causer">{{$t('SHIFT_WAS_RESTORED_BY')}} {{item.causer.full_name}}</div>
        </template>
      </el-timeline-item>
    </el-timeline>
  </el-container>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ShiftLogComponent',
  mixins: [momentMixin],
  data () {
    return {
      logActivities: []
    }
  },
  props: [
    'shift'
  ],
  mounted () {
    this.getShiftLogs()
  },
  methods: {
    getShiftLogs () {
      if (this.isEmployeeHasPermission('update-shifts')) {
        if (!this.$store.getters.shiftLog) {
          this.$store.dispatch('getShiftLogs', this.shift.id).then(logs => {
            this.logActivities = logs.sort((a, b) => {
              if (a.id > b.id) {
                return -1
              }
              if (a.id < b.id) {
                return 1
              }
              return 0
            })
          })
        } else {
          this.logActivities = this.$store.getters.shiftLog
        }
      }
    },
    formatStringToDateTime (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
    }
  }
}
</script>

<style scoped>

</style>
