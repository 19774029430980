<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <tab-navigation-component :tabs="tabs"></tab-navigation-component>
          <div class="row py-2">
            <div class="col-lg-6">
              <div class="text-primary text-uppercase font-weight-bold m-1">
                {{ $t("Statuses") }}
                <ui-hint :content="$t('TASKS_STATUSES_HINTS')" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill mx-3 m-1 col-auto col-auto" type="button" @click="addStatus">
                  {{ $t('Create Status') }}
                </button>
              </div>
            </div>
          </div>
          <div>
              <el-table
                :data="list"
                style="width: 100%">
                <el-table-column
                  prop="title"
                  :label="$t('Title')"
                  width="180">
                </el-table-column>
                <el-table-column
                  :label="$t('Color')"
                  width="180">
                  <template #default="scope">
                    <span class="status-color" v-bind:style="{ backgroundColor: scope.row.color }"></span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Check gps')"
                  width="180">
                  <template #default="scope">
                    <div v-if="scope.row.check_gps">
                      <i class="icon-check text-success"></i>
                    </div>
                    <div v-else>
                      <i class="icon-x text-danger"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('IS_FIRST_STATUS')">
                  <template #default="scope">
                    <div v-if="scope.row.is_first">
                      <i class="icon-check text-success"></i>
                    </div>
                    <div v-else>
                      <i class="icon-x text-danger"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Update start')">
                  <template #default="scope">
                    <div v-if="scope.row.check_in">
                      <i class="icon-check text-success"></i>
                    </div>
                    <div v-else>
                      <i class="icon-x text-danger"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Update end')">
                  <template #default="scope">
                    <div v-if="scope.row.check_out">
                      <i class="icon-check text-success"></i>
                    </div>
                    <div v-else>
                      <i class="icon-x text-danger"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('IS_FINAL_STATUS')">
                  <template #default="scope">
                    <div v-if="scope.row.is_final">
                      <i class="icon-check text-success"></i>
                    </div>
                    <div v-else>
                      <i class="icon-x text-danger"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Actions')">
                  <template #default="scope">
                    <router-link :to="`/c/${companyId}/tasks-statuses/${scope.row.id}`">
                      <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
                    </router-link>
                    <i v-if="!scope.row.is_first" class="icon-trash btn-icon text-danger"
                      @click="deleteStatus(scope.row.id)"
                      role="button">
                    </i>
                  </template>
                </el-table-column>
              </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'TasksStatuses',
  components: { TabNavigationComponent: TabNavigationComponent, DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy, ToastMixin],
  data () {
    return {
      list: [],
      searchQuery: '',
      tabs: [],
      activeTab: 'active',
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    },
    period () {
      this.init()
    }
  },
  methods: {
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: true
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange (val) {
      this.$router.replace({ query: { page: val } }).catch(() => {})
      this.init()
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    init () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then((response) => {
        if (response) {
          this.list = response
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addStatus () {
      this.$router.push(`/c/${this.companyId}/tasks-statuses`)
    },
    deleteStatus (statusId) {
      this.$store.dispatch('deleteTasksStatus', [this.companyId, statusId]).then((response) => {
        this.init()
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        const errorMessage = error.response.data.message
        if (error.response.status === 400 && errorMessage === 'unable to delete system status') {
          this.toastError(this.$i18n?.t(errorMessage))
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>
.status-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
