export default {
  data () {
    return {
      statusColors: {
        new: '#065535',
        late: '#ffa500',
        absent: '#7D7A79',
        started: '#ffff00',
        finished: '#00ff00'
      }
    }
  }
}
