// import Vue from 'vue'
import moment from 'moment'
const FREE_BOARD_RESOURCE_ID = 0
const HOLIDAYS_RESOURCE_ID = 'holidays'
const INFO_EVENT_RESOURCE_ID = 'info_event'
const CUSTOM_RESOURCE_ID = 'custom_events'
const BREAK_RESOURCE_ID = 'break_events'

export default {
  methods: {
    createResourcesByTemplate () {
      return new Promise((resolve, reject) => {
        this.hideCalendar = false
        this.$eventBus.emit('hideCalendar', false)
        // this.$eventBus.emit('selectAllSchedules')
        let resourceFreeBoard, resourceHolidays, resourceCustom, resourceStatistic
        let resources = []
        if (this.isEmployeeHasPermission('get-shifts-admin') ||
          this.rolesByProjects[this.currentProject.id] === 'manager') {
          /** Статистика */
          resourceStatistic = this.resourceInfo()
          resources = resources.concat(resourceStatistic)
        }
        /** Фриборд (для всех пользователей) */
        resourceFreeBoard = this.resourceFreeBoard()
        resources = resources.concat(resourceFreeBoard)

        /** Праздники */
        resourceHolidays = this.resourceHolidays()
        resources = resources.concat(resourceHolidays)

        /** Кастомные темплейты **/
        resourceCustom = this.resourceCustom()
        resources = resources.concat(resourceCustom)

        if (this.currentProject.schedulesByProject.length > 0) {
          // console.log(45, this.templates, this.toHideDeleted)
          const filteredTemplates = this.templates.filter(item => {
            if (item.is_active) {
              return true
            }
            return !this.deletedTemplatesToHide.includes(item.schedule_id + '__sp__' + item.id)
          })
          this.createResourceTemplate(filteredTemplates).then(resourceUsers => {
            resources = resources.concat(resourceUsers)
            this.$store.dispatch('updateResources', resources).catch(() => {})
            this.$store.dispatch('updateFilteredResources', resources).catch(() => {})
            resolve(resources)
          })
        } else {
          this.$store.dispatch('updateResources', []).catch(() => {})
          this.$store.dispatch('updateFilteredResources', []).catch(() => {})
          resolve([])
        }
      })
    },

    /**
     * Создание ресурсов для календаря
     * @returns {Promise<any>}
     */
    createResources () {
      return new Promise((resolve, reject) => {
        this.hideCalendar = false
        this.$eventBus.emit('hideCalendar', false)
        // this.$eventBus.emit('selectAllSchedules')
        let resourceFreeBoard, resourceStatistic, resourceHolidays
        let resources = []
        if (this.isEmployeeHasPermission('get-shifts-admin') ||
          this.rolesByProjects[this.currentProject.id] === 'manager') {
          /** Статистика */
          resourceStatistic = this.resourceInfo()
          resources = resources.concat(resourceStatistic)
        }
        /** Фриборд (для всех пользователей) */
        resourceFreeBoard = this.resourceFreeBoard()
        resources = resources.concat(resourceFreeBoard)

        // /** Перерывы (для всех пользователей) */
        // resourceBreaks = this.resourceBreaks()
        // resources = resources.concat(resourceBreaks)

        /** Праздники */
        resourceHolidays = this.resourceHolidays()
        resources = resources.concat(resourceHolidays)

        if (this.currentProject.schedulesByProject.length > 0) {
          this.createResource(this.schedulesById).then(resourceUsers => {
            resources = resources.concat(resourceUsers)
            this.$store.dispatch('updateResources', resources).catch(() => {})
            resolve(resources)
          })
        } else {
          this.$store.dispatch('updateResources', []).catch(() => {})
          resolve([])
        }
      })
    },

    createResourceTemplate (templates) {
      return this.createResourceManagerTemplate(templates)
    },

    createResource (schedules) {
      const visibleSchedules = {}
      const shiftsVisibleEveryone = this.currentProject.shifts_visible_everyone
      if (this.isEmployeeHasPermission('create-shift-admin') ||
        this.rolesByProjects[this.currentProject.id] === 'manager' ||
        this.rolesByProjects[this.currentProject.id] === 'viewer') {
        return this.createResourceManager(schedules)
      } else {
        Object.keys(schedules).forEach(function (key) {
          if (shiftsVisibleEveryone || schedules[key].visible_everyone) {
            visibleSchedules[key] = schedules[key]
          }
        })
      }

      if (Object.keys(visibleSchedules).length > 0) {
        return this.createResourceManager(visibleSchedules)
      }

      // console.log('createResourceUser')
      return this.createResourceUser(schedules)
    },

    createResourceManagerTemplate (templates) {
      return new Promise((resolve) => {
        const resourcesArray = []
        // console.log(this.currentProject.templatesByProject, templates)
        templates.forEach(template => {
          if (this.currentProject.templatesByProject[template.schedule_id + '__sp__' + template.id]) {
            resourcesArray.push({
              id: template.id,
              title: template.name,
              control: null,
              deleted: false,
              type: 7,
              extendedProps: {
                color: template.color,
                time_from: template.time_from,
                time_to: template.time_to
              }
            })
          }
        })
        resourcesArray.sort(this.sortTemplateResources)
        resourcesArray.forEach((res, index) => {
          res.sortOrder = index
        })
        // console.log(3, resourcesArray)
        resolve(resourcesArray)
      })
    },
    sortTemplateResources (a, b) {
      if (this.templateSortOrder === 'date') {
        const t1 = moment(a.extendedProps.time_from, 'h:mm')
        const t2 = moment(b.extendedProps.time_from, 'h:mm')
        return t1.isAfter(t2) ? 1 : -1
      }
      if (this.templateSortOrder === 'name') {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
    },
    /** Создания ресурсов для менеджеров и админов */
    createResourceManager (schedules) {
      // console.log(123, schedules)
      return new Promise((resolve) => {
        let resource, user, resources, resourcesArray
        this.userSchedule = {}
        resources = {}
        resourcesArray = []
        // добавляем к ресурсам удаленных пользователей у которых есть активные смены на данный период
        Object.keys(this.deletedUsers).forEach(employeeId => {
          user = this.deletedUsers[employeeId]
          const position = []
          const positionsIds = []
          user.positions.sort((a, b) => {
            if (a.priority > b.priority) {
              return 1
            }
            return -1
          })
          user.positions.forEach(item => {
            position.push(item.title)
            positionsIds.push(item.id)
          })
          resource = {
            id: employeeId,
            title: `${user.last_name} ${user.first_name} (${this.$i18n?.t('deleted')})`,
            control: null,
            deleted: true,
            type: 7,
            identifier: user.identifier ? user.identifier : '',
            position: position.join(', '),
            positionsIds: positionsIds,
            priority: user.positions.length > 0 ? user.positions[0].priority : 90000000,
            nameWithIdentifier: user.full_name
          }
          resources[resource.id] = resource
        })
        Object.keys(schedules).forEach(key => {
          const schedule = schedules[key]
          this.usersBySchedule[schedule.id] = []
          // debugger
          schedule.users.forEach((item, index) => {
            this.usersBySchedule[schedule.id].push(item.employee_id)
            user = this.preloadUsers[item.employee_id]
            // console.log(user)
            if (user) {
              const position = []
              const positionsIds = []
              user.positions.sort((a, b) => {
                if (a.priority > b.priority) {
                  return 1
                }
                return -1
              })
              user.positions.forEach(item => {
                position.push(item.title)
                positionsIds.push(item.id)
              })
              this.userSchedules(item.employee_id, schedule)
              resource = {
                id: item.employee_id,
                title: this.nameFormat === 'last_first' ? user.last_name + ' ' + user.first_name : user.first_name + ' ' + user.last_name,
                control: null,
                deleted: false,
                type: 7,
                identifier: user.identifier ? user.identifier : '',
                position: position.join(', '),
                positionsIds: positionsIds,
                priority: user.positions.length > 0 ? user.positions[0].priority : 90000000,
                nameWithIdentifier: user.full_name
              }
              resources[item.employee_id] = resource
            }
          })
        })
        Object.keys(resources).forEach(key => {
          resourcesArray.push(resources[key])
        })
        resourcesArray = resourcesArray.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
        })
        // console.log(resourcesArray)

        this.$eventBus.emit('scheduleUsers', this.usersBySchedule)
        // const primarySortOrder = this.primarySortOrder
        resourcesArray.sort(this.sortEmployeeResources)
        if (this.primarySortOrder === 'identifier') {
          // запихиваем ресурсы без идентификатора вниз списка
          const firstArray = resourcesArray.filter(item => item.identifier !== null &&
            item.identifier !== '' &&
            item.identifier !== 'undefined')
          const secondArray = resourcesArray.filter(item => item.identifier === null ||
            item.identifier === '' ||
            item.identifier === 'undefined')
          resourcesArray = firstArray.concat(secondArray)
        }
        resourcesArray.forEach((res, index) => {
          res.sortOrder = index
        })
        resolve(resourcesArray)
      })
    },

    sortEmployeeResources (a, b) {
      if (this.primarySortOrder === 'name') {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'position') {
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'identifier') {
        if (a.identifier > b.identifier) {
          return 1
        }
        if (a.identifier < b.identifier) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'id_asc') {
        if (a.id > b.id) {
          return 1
        }
        if (a.id < b.id) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'id_desc') {
        if (a.id > b.id) {
          return -1
        }
        if (a.id < b.id) {
          return 1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
    },

    /** Создание ресурса для обычного пользователя */
    createResourceUser (schedules) {
      return new Promise((resolve) => {
        const currentUserId = this.$store.getters.currentCompanyUserId
        let user = null
        Object.keys(schedules).forEach(key => {
          const schedule = schedules[key]
          schedule.users.forEach(item => {
            if (item.employee_id === currentUserId) {
              user = this.preloadUsers[item.employee_id]
              if (user) {
                const position = []
                const positionsIds = []
                user.positions.sort((a, b) => {
                  if (a.priority > b.priority) {
                    return 1
                  }
                  return -1
                })
                user.positions.forEach(item => {
                  position.push(item.title)
                  positionsIds.push(item.id)
                })
                // todo выпилить для обычного юзера проекты, в которых он не состоит
                // this.userSchedules(item.employee_id, schedule)
                user = {
                  id: item.employee_id,
                  title: this.nameFormat === 'last_first' ? user.last_name + ' ' + user.first_name : user.first_name + ' ' + user.last_name,
                  control: null,
                  type: 7,
                  identifier: user.identifier ? user.identifier : '',
                  position: position.join(', '),
                  positionsIds: positionsIds,
                  priority: user.positions.length > 0 ? user.positions[0].priority : 90000000,
                  nameWithIdentifier: user.full_name
                }
              }
            }
          })
        })
        if (user) {
          user = [user]
        } else {
          user = []
          this.hideCalendar = true
          this.$eventBus.emit('hideCalendar', true)
        }
        resolve(user)
      })
    },

    isEditable (shift) {
      const value = this.viewSwitch ? true : shift.employee_id !== FREE_BOARD_RESOURCE_ID
      return (this.isEmployeeHasPermission('update-shift-admin') ||
        this.rolesByProjects[this.currentProject.id] === 'manager') &&
        value
    },

    /** Создания ресурса статистики */
    resourceInfo () {
      return [{
        id: INFO_EVENT_RESOURCE_ID,
        title: null,
        control: null,
        type: 2
      }]
    },

    /** Создания ресурса биржи для всех пользователей */
    resourceFreeBoard () {
      return [{
        id: FREE_BOARD_RESOURCE_ID,
        title: this.$i18n?.t('Free board'),
        control: null,
        type: 3
      }]
    },

    /** Создания ресурса перерывов для всех пользователей */
    resourceBreaks () {
      return [{
        id: BREAK_RESOURCE_ID,
        title: this.$i18n?.t('BREAKS_RESOURCE_TITLE'),
        control: null,
        type: 5
      }]
    },

    /** Создания ресурса праздников */
    resourceHolidays () {
      return [{
        id: HOLIDAYS_RESOURCE_ID,
        title: this.$i18n?.t('Holidays'),
        control: null,
        type: 1
      }]
    },

    /** Создания кастомного ресурса */
    resourceCustom () {
      return [{
        id: CUSTOM_RESOURCE_ID,
        title: this.$i18n?.t('Custom templates'),
        control: null,
        type: 4
      }]
    },

    /** форматирование событий в приемлемый для календаря вид */
    makeShiftsTransformations () {
      const freeBoardEvents = {}
      const events = []
      const filteredShifts = []
      if (this.shifts) {
        const selectedTemplates = {}
        this.selectedTemplates.forEach(template => {
          selectedTemplates[template] = true
        })
        const tmp = {}
        Object.keys(this.schedulesById).forEach(scheduleId => {
          tmp[scheduleId] = 1
        })
        const deletedTemplates = {}
        Object.keys(this.currentProject.templatesByProject).forEach(key => {
          if (this.currentProject.templatesByProject[key][3] === true) {
            deletedTemplates[key] = 1
          }
        })
        this.shifts.forEach(shift => {
          // <-- start собираем данные по кастомным шифтам
          if (shift.template_id < 1) {
            tmp[shift.schedule_id]++
          }
          // <-- end
          // <-- start собираем данные по шифтам с удаленными темплейтами
          if (deletedTemplates[shift.schedule_id + '__sp__' + shift.template_id]) {
            deletedTemplates[shift.schedule_id + '__sp__' + shift.template_id]++
          }
          // <-- end
          if (selectedTemplates[shift.schedule_id + '__sp__' + shift.template_id] || selectedTemplates[shift.schedule_id]) {
            const event = this.setEvent(shift)
            // console.log(123, event)
            if (shift.employee_id !== FREE_BOARD_RESOURCE_ID) {
              if (this.preloadUsers[shift.employee_id] || this.deletedUsers[shift.employee_id]) {
                events.push(event)
                filteredShifts.push(shift)
              }
            } else {
              if (!freeBoardEvents[event.freeBoardKey]) {
                freeBoardEvents[event.freeBoardKey] = []
              }
              freeBoardEvents[event.freeBoardKey].push(event)
            }
          }
        })
        this.$store.dispatch('updateFreeBoardEvents', freeBoardEvents).catch(() => {})
        this.$store.dispatch('updateFilteredShiftsList', filteredShifts).catch(() => {})
        if (this.breaks && this.viewType === 'resourceTimelineDay') {
          this.breaks.forEach(breakSlot => {
            const viewMode = localStorage.getItem('dayDisplayType')
            const userBreaks = this.setBreaks(breakSlot, viewMode)
            // console.log(123, userBreaks)
            if (userBreaks.resourceId === BREAK_RESOURCE_ID) { // групируем перерывы по времени
              // todo ресурс свободных перерывов выпилили, но пусть пока будет
              // if (idMapper[userBreaks.realStart + '__sp__' + userBreaks.realEnd + '__sp__' + userBreaks.scheduleId]) {
              //   idMapper[userBreaks.realStart + '__sp__' + userBreaks.realEnd + '__sp__' + userBreaks.scheduleId]['counter']++
              // } else {
              //   idMapper[userBreaks.realStart + '__sp__' + userBreaks.realEnd + '__sp__' + userBreaks.scheduleId] = userBreaks
              //   idMapper[userBreaks.realStart + '__sp__' + userBreaks.realEnd + '__sp__' + userBreaks.scheduleId]['counter'] = 1
              // }
            } else {
              events.push(userBreaks)
            }
          })
          // console.log(123, idMapper)
          // Object.keys(idMapper).forEach(key => {
          //   const event = Object.assign({}, idMapper[key])
          //   events.push(event)
          // })
        }
        // <-- start собираем айдишники кастомных темплейтов, у которых нет шифтов на календаре и передаем данные в компонент фильтра по расписанию
        const toHide = []
        Object.keys(tmp).forEach(scheduleId => {
          if (tmp[scheduleId] < 2) {
            toHide.push(scheduleId + '__sp__' + 0)
          }
        })
        this.$store.dispatch('updateCustomTemplatesToHide', toHide).catch(() => {})
        // <-- end
        // console.log(1567, deletedTemplates)
        // console.log(shifts)
        const toHideDeleted = []
        Object.keys(deletedTemplates).forEach(templateId => {
          if (deletedTemplates[templateId] < 2) {
            toHideDeleted.push(templateId)
          }
        })
        this.$store.dispatch('updateDeletedTemplatesToHide', toHideDeleted).catch(() => {})
        Object.keys(this.freeBoardEvents).forEach(key => {
          const event = Object.assign({}, this.freeBoardEvents[key][0])
          event.id = key
          event.counter = this.freeBoardEvents[key].length
          events.push(event)
        })
      }
      if (this.holidays && this.holidays.length > 0) {
        this.holidays.forEach(holiday => {
          const companyHolidays = this.setHoliday(holiday)
          events.push(companyHolidays)
          // const userBackgroundHolidays = this.setBackgroundHoliday(holiday)
          // events = events.concat(userBackgroundHolidays)
        })
      }

      if (this.fcRequests) {
        this.fcRequests.forEach(request => {
          const userRequests = this.setUserRequest(request)
          events.push(userRequests)
        })
      }
      if (this.companyAvailability) {
        this.companyAvailability.forEach(availability => {
          const userUnavailability = this.setUnavailability(availability)
          // console.log(123, userUnavailability)
          events.push(userUnavailability)
        })
      }
      // console.log(123, events)

      return events
    },

    /** общая для пользователей и фриборда часть создания события */
    setEvent (shift) {
      // конвертация времени со смещением по таймзоне пользователя (из профиля) относительно UTC (в базе время хранится сконвертированным в UTC)
      const startTime = this.fromZoneToZone(shift.time_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(shift.time_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const plannedStartTime = this.fromZoneToZone(shift.planned_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const plannedEndTime = this.fromZoneToZone(shift.planned_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const id = [shift.schedule_id, shift.template_id].join('__sp__')
      let title = shift.is_double_account ? shift.double_account_data.username : ''
      if (!this.viewSwitcher.showByUsers) {
        // console.log(shift, this.preloadUsers)
        if (shift.employee_id === 0) {
          title = this.$t('FREE_SHIFT')
        } else if (this.preloadUsers[shift.employee_id]) {
          title = this.preloadUsers[shift.employee_id].last_name + ' ' + this.preloadUsers[shift.employee_id].first_name
        }
      }
      const resourceId = this.viewSwitch
        ? shift.employee_id
        : shift.employee_id === FREE_BOARD_RESOURCE_ID
          ? shift.employee_id
          : shift.template_id === 0
            ? CUSTOM_RESOURCE_ID
            : shift.template_id

      const absence = shift.presence_info ? shift.presence_info.absence : false
      const color = this.viewSwitch ? shift.color : (this.schedulesById[shift.schedule_id] && this.schedulesById[shift.schedule_id].color)

      const event = {
        id: shift.id,
        title: title,
        displayEventTime: true,
        start: startTime,
        end: endTime,
        plannedStart: plannedStartTime,
        plannedEnd: plannedEndTime,
        resourceId: resourceId,
        color: color,
        is_double_account: shift.is_double_account,
        marks: shift.marks,
        schedule_id: shift.schedule_id,
        schedule_name: this.schedulesById[shift.schedule_id] && this.schedulesById[shift.schedule_id].name,
        resourceEditable: this.viewSwitch ? true : shift.employee_id !== FREE_BOARD_RESOURCE_ID,
        template: shift.template_id,
        template_name: this.currentProject.templatesByProject[id],
        request_id: shift.request_id,
        break_time: shift.break_time,
        isShift: true,
        absence: absence,
        confirmed_by_employee: shift.confirmed_by_employee,
        location: shift.location,
        note: shift.note,
        hide_note: shift.hide_note,
        breaks: shift.breaks,
        showTime: this.viewSwitcher.showByUsers || (!this.viewSwitcher.showByUsers && (shift.employee_id === 0 || shift.template_id === 0)),
        shiftDuration: shift.duration,
        presence_info: shift.presence_info
      }
      event.editable = this.isEditable(shift)
      if (shift.employee_id !== FREE_BOARD_RESOURCE_ID) {
        if (this.preloadUsers[shift.employee_id]) {
          event.user = this.preloadUsers[shift.employee_id].first_name + ' ' + this.preloadUsers[shift.employee_id].last_name
        } else if (this.deletedUsers[shift.employee_id]) {
          event.user = `${this.deletedUsers[shift.employee_id].first_name} ${this.deletedUsers[shift.employee_id].last_name}`
        }
        event.employee_id = shift.employee_id
        event.freeBoardKey = null
        const groupedBonuses = {}
        if (shift.bonuses) {
          shift.bonuses.forEach(bonus => {
            if (!groupedBonuses[bonus.type]) {
              groupedBonuses[bonus.type] = 0
            }
            groupedBonuses[bonus.type] = groupedBonuses[bonus.type] + bonus.amount
            if (bonus.type === 'bonus') {
              event.isBonus = true
            }
            if (bonus.type === 'penalty') {
              event.isPenalty = true
            }
            if (bonus.type === 'advance') {
              event.isAdvance = true
            }
          })
          event.bonusAmount = groupedBonuses.bonus
          event.penaltyAmount = groupedBonuses.penalty
          event.advanceAmount = groupedBonuses.advance
        }
      } else {
        event.user = this.$i18n?.t('Free board')
        event.employee_id = FREE_BOARD_RESOURCE_ID
        // групировка фриборда по составному ключу
        if (!event.request_id) {
          event.freeBoardKey = shift.schedule_id + '__' + shift.template_id + '__' + shift.time_from + '__' + shift.time_to
          const groupedBonuses = {}
          if (shift.bonuses) {
            shift.bonuses.forEach(bonus => {
              if (!groupedBonuses[bonus.type]) {
                groupedBonuses[bonus.type] = 0
              }
              groupedBonuses[bonus.type] = groupedBonuses[bonus.type] + bonus.amount
            })
            event.bonusAmount = groupedBonuses.bonus
            event.penaltyAmount = groupedBonuses.penalty
            event.advanceAmount = groupedBonuses.advance
            Object.keys(groupedBonuses).forEach(type => {
              if (type === 'bonus') {
                event.isBonus = true
              }
              if (type === 'penalty') {
                event.isPenalty = true
              }
              if (type === 'advance') {
                event.isAdvance = true
              }
              event.freeBoardKey = event.freeBoardKey + '__' + type + '__' + groupedBonuses[type]
            })
          }
          if (shift.location_id) {
            event.freeBoardKey = event.freeBoardKey + '__' + shift.location_id
          }
          if (shift.marks && shift.marks.length > 0) {
            event.freeBoardKey = event.freeBoardKey + '__marks__' + shift.marks.sort((a, b) => {
              if (a > b) {
                return 1
              }
              if (a < b) {
                return -1
              }
              return 0
            }).join('__')
          }
        } else {
          event.freeBoardKey = shift.id
        }
      }

      return event
    },

    setBreaks (breaks, viewMode = 'extended') {
      // console.log(888, breaks)
      const startTime = this.fromZoneToZone(breaks.time_from, 'UTC', this.actualTZ)
      const endTime = this.fromZoneToZone(breaks.time_to, 'UTC', this.actualTZ)
        .format(this.backendDateTimeFormat)
      const plannedStart = this.fromZoneToZone(breaks.planned_from, 'UTC', this.actualTZ)
        .format(this.localeDateTimeFormat)
      const plannedEnd = this.fromZoneToZone(breaks.planned_to, 'UTC', this.actualTZ)
        .format(this.localeDateTimeFormat)
      let isBreak = false
      if (+breaks.employee_id > 0) {
        isBreak = true
      }
      // console.log(123, viewMode)
      const event = {
        id: 'break__sp__' + breaks.id.toString(),
        title: breaks.title,
        start: startTime.format(this.backendDateTimeFormat),
        end: viewMode === 'extended' ? startTime.clone().add(2, 'hour').format(this.backendDateTimeFormat) : endTime,
        realStart: startTime.format(this.backendDateTimeFormat),
        realEnd: endTime,
        plannedStart: plannedStart,
        plannedEnd: plannedEnd,
        displayEventTime: false,
        color: breaks.is_absent ? '#7d7a79' : '#abcdef',
        resourceId: +breaks.employee_id > 0 ? breaks.employee_id.toString() : BREAK_RESOURCE_ID,
        editable: false,
        // rendering: 'background',
        isBreak: isBreak,
        requestId: breaks.request_id,
        overlap: true,
        scheduleId: breaks.schedule_id,
        shiftId: breaks.shift_id,
        employeeId: +breaks.employee_id > 0 ? breaks.employee_id : BREAK_RESOURCE_ID,
        isAbsent: breaks.is_absent
      }
      // console.log(888, event)
      return event
    },

    /** фоновые события (тайм офф реквесты и пр.) */
    setBackgroundEvent (backgroundEvent) {
      const startTime = this.fromZoneToZone(backgroundEvent.date_from, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(backgroundEvent.date_to, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const event = {
        id: backgroundEvent.id,
        start: startTime,
        end: endTime,
        backgroundColor: '#e59282',
        resourceId: backgroundEvent.employee_id,
        rendering: 'background',
        isBackground: true
      }
      return event
    },

    setBackgroundHoliday (holiday) {
      const startTime = this.fromZoneToZone(holiday.start, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(holiday.end, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      let resourcesIds = this.calendarApi.getResources().map(item => item.id)
      resourcesIds = resourcesIds.concat(Object.keys(this.deletedUsers))
      // console.log(holiday)
      const backgroundsEvents = []
      resourcesIds.forEach(id => {
        const event = {
          id: `${id}__sp__${holiday.id}`,
          start: startTime,
          end: endTime,
          backgroundColor: holiday.color,
          resourceId: id,
          display: 'background',
          isBackground: true,
          allowShifts: holiday.allow_shifts,
          overlap: true
        }
        backgroundsEvents.push(event)
      })
      return backgroundsEvents
    },

    setHoliday (holiday) {
      const startTime = this.fromZoneToZone(holiday.start, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(holiday.end, 'UTC', this.actualTZ).format(this.backendDateTimeFormat)
      const event = {
        id: `holiday__sp__${holiday.id}`,
        title: '',
        name: holiday.name,
        description: holiday.description ? holiday.description : '',
        start: startTime,
        end: endTime,
        displayEventTime: false,
        editable: false,
        eventDurationEditable: false,
        eventResourceEditable: false,
        color: holiday.color,
        resourceId: HOLIDAYS_RESOURCE_ID,
        isHoliday: true
      }
      return event
    },

    setUnavailability (item) {
      // console.log(unavailability)
      const event = {
        id: item.id + '__sp__unavailability',
        title: item.note ? item.note : '',
        color: item.color,
        duration: moment.utc(moment.duration(item.duration, 'minutes').asMilliseconds()).format('HH:mm'),
        rrule: {
          dtstart: item.dtstart,
          until: item.until,
          freq: item.freq,
          interval: item.interval,
          count: item.is_recurring ? null : 1,
          byhour: item.byhour,
          byminute: item.byminute,
          bysecond: 0,
          byweekday: item.byweekday
        },
        // exdate: ['2022-08-04 08:00:00']
        exdate: item.exdate,
        editable: false,
        isUnavailability: item.event_type === 'unavailability',
        isAvailability: item.event_type === 'availability',
        isBackground: false,
        overlap: true,
        resourceId: item.employee_id
      }
      return event
    },

    setUserRequest (request) {
      // console.log(444, request)
      const startTime = this.fromZoneToZone(request.date_from, 'UTC', this.actualTZ)
        .format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(request.date_to, 'UTC', this.actualTZ)
        .format(this.backendDateTimeFormat)
      const event = {
        id: `user_request__sp__${request.id}`,
        title: '',
        start: startTime,
        end: endTime,
        displayEventTime: false,
        editable: false,
        eventDurationEditable: false,
        eventResourceEditable: false,
        color: '#7d7a79',
        resourceId: request.created_by,
        isUserRequest: true,
        requestDuration: request.duration,
        from: request.date_from,
        to: request.date_to,
        startTimeRequest: this.fromZoneToZone(request.date_from, 'UTC', this.actualTZ).format(this.localeTimeFormat),
        endTimeRequest: this.fromZoneToZone(request.date_to, 'UTC', this.actualTZ).format(this.localeTimeFormat),
        startDate: this.fromZoneToZone(request.date_from, 'UTC', this.actualTZ).format(this.localeDateFormat),
        endDate: this.fromZoneToZone(request.date_to, 'UTC', this.actualTZ).format(this.localeDateFormat),
        employeeName: request.employee_name,
        isPaid: request.is_paid,
        subtype: request.subtype,
        requestId: request.id,
        employeeId: request.created_by,
        reason: request.type_time_off,
        reasonData: request.reason,
        createdAt: this.fromZoneToZone(request.created_at, 'UTC', this.actualTZ).format(this.localeDateTimeFormat)
      }

      return event
    },

    createBackgroundEvent (color) {
      const resource = this.calendarApi.getResourceById(FREE_BOARD_RESOURCE_ID.toString())
      const events = resource.getEvents()
      if (events.length < 1) {
        const event = {
          id: 'tmp_free_board',
          start: new Date(this.view.currentStart),
          end: new Date(this.view.currentEnd),
          backgroundColor: color,
          rendering: 'background',
          resourceId: FREE_BOARD_RESOURCE_ID
        }
        this.calendarApi.addEvent(event)
        // let calendarEvent = this.calendarApi.getEventById('tmp_free_board')
        // return calendarEvent
      }
      return null
    },

    createBackgroundHolidayEvent (color) {
      const resource = this.calendarApi.getResourceById(HOLIDAYS_RESOURCE_ID)
      const events = resource.getEvents()
      if (events.length < 1) {
        const event = {
          id: 'tmp_holiday',
          start: new Date(this.view.currentStart),
          end: new Date(this.view.currentEnd),
          backgroundColor: color,
          rendering: 'background',
          resourceId: HOLIDAYS_RESOURCE_ID
        }
        this.calendarApi.addEvent(event)
        const calendarEvent = this.calendarApi.getEventById('tmp_holiday')
        return calendarEvent
      }
      return null
    }
  }

}
