import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Login'
import ChangePassword from '@/components/ChangePassword'
import Confirm from '@/components/Confirm'
import Unsubscribe from '@/components/Unsubscribe'
import ConfirmCompanyInvite from '@/components/ConfirmCompanyInvite'
import DashboardUser from '@/components/Dashboard/DashboardUser'
import DashboardCompany from '@/components/Dashboard/DashboardCompany'
// User
import ProfileAvailability from '@/components/CommonComponents/Availability/Availability'
import Files from '@/components/User/Files'
import EmployeeFiles from '@/components/User/EmployeeFiles'
import Profile from '@/components/User/profile/Profile'
import User from '@/components/User/user/User'
// Company
import Company from '@/components/Company/Company'
import CompanyFiles from '@/components/Company/CompanyFiles'
import CompanyRouterWrapper from '@/components/Company/CompanyRouterWrapper'
import Users from '@/components/Company/Users'
import Payments from '@/components/Company/Payments'
import Subscribes from '@/components/Company/Subscribes'
import Invoice from '@/components/Company/CompanySettingsComponents/BalanceComponents/Invoice'
import ModulesPage from '@/components/Company/ModulesPage/ModulesPage'
// Project
import Projects from '@/components/Project/Projects'
import Project from '@/components/Project/Project'
import WorkTemplates from '@/components/Project/Templates'
import WorkTemplate from '@/components/Project/WorkTemplate'
import DoubleAccounts from '@/components/Project/DoubleAccounts'
import Tags from '@/components/Project/Tags'
// Schedule
import Schedules from '@/components/Schedule/Schedules'
import EditSchedule from '@/components/Schedule/EditSchedule'
import CompanySchedule from '@/components/Schedule/CompanySchedule'
import CreateSchedule from '@/components/Schedule/CreateSchedule'
// Requests
import Requests from '@/components/Requests/Requests'
import Request from '@/components/Requests/Request'
import CreateRequest from '@/components/Requests/CreateRequest'
// Registration
import Registration from '@/components/Registration/Registration'
import PasswordRecovery from '@/components/PasswordRecovery'
import PasswordRecoveryConfirm from '@/components/PasswordRecoveryConfirm'
// Statistic
import Statistic from '@/components/Statistic/Statistic'
// Balance
import PayPalSuccessRedirectPage from '@/components/Company/CompanySettingsComponents/PaymentsComponents/PayPalSuccessRedirectPage/'
import Referrals from '@/components/Referrals/Referrals'
import Checklist from '@/components/Tasks/Checklist/Checklist'
import Checklists from '@/components/Tasks/Checklist/Checklists'
import Clients from '@/components/Tasks/Client/Clients'
import Client from '@/components/Tasks/Client/Client'
import ClientFields from '@/components/Tasks/Client/ClientFields'
import Positions from '@/components/Company/Positions'
import Skills from '@/components/Company/Skills'
import Locations from '@/components/Company/Locations'
import Location from '@/components/Company/Location'
// tasks
import Tasks from '@/components/Tasks/Task/Tasks'
import TaskView from '@/components/Tasks/Task/TaskView'
import TaskStatus from '@/components/Tasks/Status/Status'
import TaskStatuses from '@/components/Tasks/Status/Statuses'
import TaskMap from '@/components/Tasks/Map/Map'
import UserTasks from '@/components/Tasks/UserTask/Tasks'
import UserTask from '@/components/Tasks/UserTask/Task'
import UserTasksBank from '@/components/Tasks/UserTask/Bank'
import Salary from '@/components/User/Salary.vue'
import NoteEdit from '@/components/User/user/UserComponents/NoteEdit'
import CompanyLogs from '@/components/Logs/CompanyLogs'
import TaskCalendar from '@/components/Tasks/Calendar/TaskCalendar'
import TaskMarks from '@/components/Tasks/TaskMarks/TaskMarks'
// AppMArketplace
import AppMarketplace from '@/components/AppMarketplace/AppMarketplace'
import DeveloperAppView from '@/components/AppMarketplace/DeveloperAppView'
// QuickBook
import QuickBookRedirectPage from '@/components/Company/CompanySettingsComponents/SchedulesSettingsComponents/QuickBookRedirectPage'
import Developer from '@/components/Developer/Developer'
import EditApplication from '@/components/Developer/EditApplication'
import WebhookLog from '@/components/Developer/Components/WebhookLog'
// location control
import LocationControlContainer from '@/components/LocationControl/LocationControlContainer'
import ShiftActivityMainComponent from '@/components/ShiftActivity/ShiftActivityMainComponent.vue'
import ActivityTemplates from '@/components/ShiftActivity/Views/ActivityTemplates.vue'
import ActivityTemplateFormView from '@/components/ShiftActivity/Views/ActivityTemplateFormView.vue'

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/invite/confirm/:hash/:action',
      name: 'ConfirmCompanyInvite',
      component: ConfirmCompanyInvite,
      meta: {
        resolve: [],
        title: 'Shifton - Confirm company invite'
      }
    },
    // Registration
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: (route) => ({ query: route.query.lang }),
      meta: {
        requiresAuth: false,
        resolve: [],
        title: 'Shifton - Login'
      }
    },
    {
      path: '/registration',
      name: 'Registration',
      component: Registration,
      meta: {
        requiresAuth: false,
        resolve: [],
        title: 'Shifton - Registration'
      }
    },
    {
      path: '/password/recovery',
      name: 'PasswordRecovery',
      component: PasswordRecovery,
      meta: {
        requiresAuth: false,
        resolve: [],
        title: 'Shifton - Password recovery'
      }
    },
    {
      path: '/password/recovery/confirm/:hash',
      name: 'PasswordRecoveryConfirm',
      component: PasswordRecoveryConfirm,
      meta: {
        requiresAuth: false,
        resolve: [],
        title: 'Shifton - Password recovery confirm'
      }
    },
    {
      path: '/change_password',
      name: 'ChangePassword',
      component: ChangePassword,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - Change password'
      }
    },
    {
      path: '/confirm/:hash',
      name: 'Confirm',
      component: Confirm,
      meta: {
        requiresAuth: false,
        dontRedirectIfAuth: true,
        resolve: [],
        title: 'Shifton - Confirm'
      }
    },
    {
      path: '/unsubscribe/:hash',
      name: 'Unsubscribe',
      component: Unsubscribe,
      meta: {
        requiresAuth: false,
        dontRedirectIfAuth: true,
        resolve: [],
        title: 'Shifton - unsubscribe'
      }
    },
    {
      path: '/files',
      name: 'Files',
      component: Files,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - Files'
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - Profile'
      }
    },
    {
      path: '/pay-pal-success',
      name: 'PayPalSuccessRedirectPage',
      component: PayPalSuccessRedirectPage,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - PayPal success redirect page'
      }
    },
    {
      path: '/quick-book-success',
      name: 'QuickBookRedirectPage',
      component: QuickBookRedirectPage,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - PayPal success redirect page'
      }
    },
    {
      path: '/',
      name: 'Index',
      component: DashboardUser,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - User dashboard'
      }
    },
    {
      path: '/dashboard',
      name: 'DashboardUser',
      component: DashboardUser,
      meta: {
        requiresAuth: true,
        resolve: [],
        title: 'Shifton - User dashboard'
      }
    },
    // Company group routes
    {
      path: '/c/:id',
      component: CompanyRouterWrapper,
      meta: {
        companyRoute: true,
        requiresAuth: true,
        resolve: ['company']
      },
      children: [
        {
          path: '',
          name: 'Company',
          component: Company,
          meta: {
            resolve: [],
            title: 'Shifton - Company'
          }
        },
        {
          path: 'modules',
          name: 'Modules',
          component: ModulesPage,
          meta: {
            resolve: [],
            title: 'Shifton - Modules'
          }
        },
        {
          path: 'dashboard',
          name: 'DashboardCompany',
          component: DashboardCompany,
          meta: {
            requiresAuth: true,
            resolve: [],
            title: 'Shifton - Company dashboard'
          }
        },
        {
          path: 'availability',
          name: 'ProfileAvailability',
          component: ProfileAvailability,
          meta: {
            resolve: [],
            title: 'Shifton - Availability'
          }
        },
        {
          path: 'files',
          name: 'CompanyFiles',
          component: CompanyFiles,
          meta: {
            requiresAuth: true,
            resolve: [],
            title: 'Shifton - Company files'
          }
        },
        {
          path: 'files/:userId',
          name: 'EmployeeFiles',
          component: EmployeeFiles,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Employee files'
          }
        },
        {
          path: 'invoice/:invoice_id',
          name: 'Invoice',
          component: Invoice,
          meta: {
            resolve: [],
            title: 'Shifton - Invoice'
          }
        },
        {
          path: 'app-marketplace',
          name: 'AppMarketplace',
          component: AppMarketplace,
          meta: {
            requiresAuth: true,
            resolve: [],
            title: 'Shifton - Apps Marketplaces'
          }
        },
        {
          path: 'salary',
          name: 'Salary',
          component: Salary,
          meta: {
            resolve: [],
            title: 'Shifton - Salary'
          }
        },
        {
          path: 'users',
          name: 'Users',
          component: Users,
          meta: {
            resolve: [],
            title: 'Shifton - Users'
          }
        },
        {
          path: 'users/:userId',
          name: 'User',
          component: User,
          meta: {
            resolve: [],
            title: 'Shifton - User'
          }
        },
        {
          path: 'project/:projectId/users',
          name: 'ProjectUsers',
          component: Users,
          meta: {
            resolve: [],
            title: 'Shifton - Users'
          }
        },
        {
          path: 'project/:projectId/user/:userId',
          name: 'ProjectUser',
          component: User,
          meta: {
            resolve: [],
            title: 'Shifton - User'
          }
        },
        {
          path: 'payments',
          name: 'Payments',
          component: Payments,
          meta: {
            resolve: [],
            title: 'Shifton - Payments'
          }
        },
        {
          path: 'subscribes',
          name: 'Subscribes',
          component: Subscribes,
          meta: {
            resolve: [],
            title: 'Shifton - Subscribes'
          }
        },
        {
          path: 'projects',
          name: 'Projects',
          component: Projects,
          meta: {
            resolve: ['employees', 'projects'],
            title: 'Shifton - Projects'
          }
        },
        {
          path: 'project/:projectId',
          name: 'Project',
          component: Project,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Project'
          }
        },
        {
          path: 'project',
          component: Project,
          meta: {
            resolve: [],
            title: 'Shifton - Project'
          }
        },
        {
          path: 'templates',
          name: 'Templates',
          component: WorkTemplates,
          meta: {
            resolve: [],
            title: 'Shifton - Templates'
          }
        },
        {
          path: 'schedule/:scheduleId/templates/:templateId',
          name: 'Template',
          component: WorkTemplate,
          meta: {
            resolve: [],
            title: 'Shifton - Template'
          }
        },
        {
          path: 'template',
          component: WorkTemplate,
          meta: {
            resolve: [],
            title: 'Shifton - Template'
          }
        },
        {
          path: 'project/:projectId/double_accounts',
          name: 'DoubleAccounts',
          component: DoubleAccounts,
          meta: {
            resolve: [],
            title: 'Shifton - Double accounts'
          }
        },
        {
          path: 'marks/:projectId',
          name: 'Tags',
          component: Tags,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Tags'
          }
        },
        {
          path: 'positions',
          name: 'Positions',
          component: Positions,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Positions'
          }
        },
        {
          path: 'skills',
          name: 'Skills',
          component: Skills,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Skills'
          }
        },
        {
          path: 'locations',
          name: 'Locations',
          component: Locations,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Locations'
          }
        },
        {
          path: 'location/:locationId?',
          name: 'Location',
          component: Location,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Location'
          }
        },
        {
          path: 'schedules',
          name: 'Schedules',
          component: Schedules,
          meta: {
            resolve: [],
            title: 'Shifton - Schedules'
          }
        },
        {
          path: 'schedule/company',
          name: 'CompanySchedule',
          component: CompanySchedule,
          meta: {
            resolve: [],
            title: 'Shifton - Company Schedule'
          }
        },
        {
          path: 'p/:projectId/schedule',
          name: 'ProjectSchedule',
          component: CompanySchedule,
          meta: {
            resolve: [],
            title: 'Shifton - Company Schedule'
          }
        },
        {
          path: 'schedule/master/:scheduleId?',
          name: 'CreateSchedule',
          component: CreateSchedule,
          meta: {
            resolve: [],
            title: 'Shifton - Schedule master'
          }
        },
        {
          path: 'schedule/:scheduleId',
          name: 'Schedule',
          component: EditSchedule,
          meta: {
            resolve: [],
            title: 'Shifton - Edit schedule'
          }
        },
        // Requests
        {
          path: 'requests',
          name: 'Requests',
          component: Requests,
          meta: {
            resolve: [],
            title: 'Shifton - Requests'
          }
        },
        {
          path: 'request',
          component: CreateRequest,
          name: 'CreateRequest',
          meta: {
            resolve: [],
            title: 'Shifton - Create request'
          }
        },
        {
          path: 'request/:requestId',
          name: 'Request',
          component: Request,
          meta: {
            resolve: [],
            title: 'Shifton - Request'
          }
        },
        // Statistic
        {
          path: 'statistic',
          name: 'Statistic',
          component: Statistic,
          meta: {
            resolve: [],
            title: 'Shifton - Statistic'
          }
        },
        // referrals
        {
          path: 'referrals',
          name: 'Referrals',
          component: Referrals,
          meta: {
            resolve: [],
            title: 'Shifton - Referrals'
          }
        },
        // checklists
        {
          path: 'checklist/:checklistId?',
          name: 'Checklist',
          component: Checklist,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Checklist'
          }
        },
        {
          path: 'checklists',
          name: 'Checklists',
          component: Checklists,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Checklists'
          }
        },
        // statuses
        {
          path: 'task-statuses',
          name: 'TaskStatuses',
          component: TaskStatuses,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - TaskStatuses'
          }
        },
        {
          path: 'tasks-map',
          name: 'TaskMap',
          component: TaskMap,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Task Map'
          }
        },
        {
          path: 'task-marks',
          name: 'TaskMarks',
          component: TaskMarks,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Task Marks'
          }
        },
        {
          path: 'tasks-statuses/create',
          name: 'TaskStatusCreate',
          component: TaskStatus,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Task status create'
          }
        },
        {
          path: 'tasks-statuses/:taskStatusId?',
          name: 'TaskStatusEdit',
          component: TaskStatus,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Task status edit'
          }
        },
        // clients
        {
          path: 'clients',
          name: 'Clients',
          component: Clients,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Clients'
          }
        },
        {
          path: 'client-fields',
          name: 'ClientFields',
          component: ClientFields,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Client fields'
          }
        },
        {
          path: 'clients/create',
          name: 'ClientCreate',
          component: Client,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Create client'
          }
        },
        {
          path: 'clients/:clientId',
          name: 'Client',
          component: Client,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Client'
          }
        },
        // Tasks
        {
          path: 'tasks',
          name: 'Tasks',
          component: Tasks,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Tasks'
          }
        },
        {
          path: 'tasks/:taskId/view',
          name: 'TaskView',
          component: TaskView,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - View task'
          }
        },
        {
          path: 'user-tasks',
          name: 'UserTasks',
          component: UserTasks,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - User Tasks'
          }
        },
        {
          path: 'user-tasks/calendar',
          name: 'UserTasksCalendar',
          component: TaskCalendar,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - User Tasks Calendar'
          }
        },
        {
          path: 'user-tasks/bank',
          name: 'UserTasksBank',
          component: UserTasksBank,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - User Tasks bank'
          }
        },
        {
          path: 'user-tasks/:taskId',
          name: 'UserTask',
          component: UserTask,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - User Tasks bank'
          }
        },
        {
          path: 'note/:noteId',
          name: 'NoteEdit',
          component: NoteEdit,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Note edit'
          }
        },
        {
          path: 'logs',
          name: 'companyLogs',
          component: CompanyLogs,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Note edit'
          }
        },
        // For developers
        {
          path: 'developer',
          name: 'Developer',
          component: Developer,
          meta: {
            resolve: [],
            title: 'Shifton - For developers'
          }
        },
        {
          path: 'developer/apps/:appId',
          name: 'Applications',
          component: EditApplication,
          meta: {
            resolve: [],
            title: 'Shifton - Application'
          }
        },
        {
          path: 'developer-app-marketplace/:appId',
          name: 'DeveloperAppView',
          component: DeveloperAppView,
          meta: {
            resolve: [],
            title: 'Shifton - Developer App'
          }
        },
        {
          path: 'webhook-log/:webhookId',
          name: 'WebhookLog',
          component: WebhookLog,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Webhook log'
          }
        },
        {
          path: 'location-control',
          name: 'LocationControl',
          component: LocationControlContainer,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - Location control'
          }
        },
        {
          path: 'shift-activities',
          name: 'ShiftActivities',
          component: ShiftActivityMainComponent,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - shift activities'
          }
        },
        {
          path: 'shifts-activity-templates',
          name: 'ShiftsActivityTemplates',
          component: ActivityTemplates,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - shift activity templates'
          }
        },
        {
          path: 'shift-activity-template/:templateId?',
          name: 'ShiftActivityTemplates',
          component: ActivityTemplateFormView,
          props: true,
          meta: {
            resolve: [],
            title: 'Shifton - shift activity templates'
          }
        }
      ]
    }
  ]
})
