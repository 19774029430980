<template>
  <div>
    <div class="row" v-bind:class="{'pb-4': (progress)}">
      <div class="col-lg-3">
        <h1 class="title-text">{{ $t('Edit company profile') }}</h1>
      </div>
      <div class="col-lg-7 pt-2">
        <div v-if="step && newbie" class="progress" style="height: 10px;">
          <div class="progress-bar bg-info" role="progressbar" :style="{width: progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div v-if="step && newbie" class="justify-content-center d-flex">{{$t("Step")}} {{step}} ({{7 - step + ' ' + $t("left")}})</div>
      </div>
      <div v-if="$route.params.id" class="text-right font-blue-oleo font-xs col-lg-2">
        {{ $t('Company ID:') }} {{ $route.params.id }}
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 px-1" v-if="isEmployeeHasPermission('delete-company')">
            <div class="card card-small">
              <div class="card-body p-1">
                <div id="dropdown-vertical" class="dropdown flex d-flex d-md-none">
                  <button class="btn btn-primary dropdown-toggle w-100" type="button" @click="toggleMenu">
                    {{ $t(buttonTitle) }}
                  </button>
                </div>
                <div class="nav-pills d-md-block"
                     id="tabs-vertical"
                     role="tablist"
                     aria-orientation="vertical"
                     :class="{'d-none': !showTabs}"
                >
                  <a v-for="(title, key) in tabs" :key="key"
                    :href="'#' +  key"
                    class="nav-link pl-3 pr-1 rounded"
                    :class="{active: activeTab === key}"
                    data-toggle="pill" role="tab"
                    :aria-controls="'v-pills-' + key" aria-selected="true"
                    @click="activateTab(key)">
                    {{ $t(title) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="px-1" :class="isEmployeeHasPermission('delete-company')  ? 'col-lg-9' : 'col-lg-12'">
            <div class="tab-content">
              <main-settings
                v-if="activeTab === 'general' && loaded === true"
                id="general"
                :company="company"
                :newbie="step"
                @dispatchCompany="this.dispatchCompany">
              </main-settings>
              <div class="form-group">
                <salary-settings
                  v-if="activeTab === 'salary'  && loaded === true"
                  id="salary"
                  :company="company"
                  :newbie="step"
                  :disabled="disabled"
                  @dispatchCompany="this.dispatchCompany">
                </salary-settings>
                <billing-address
                  v-if="activeTab === 'billing_address' && loaded === true"
                  id="billing_address"
                  :newbie="step">
                </billing-address>

                <payment-methods
                  v-if="activeTab === 'payment' && loaded === true"
                  id="payment"
                  :newbie="step">
                </payment-methods>

                <balance
                  v-if="activeTab === 'balance' && loaded === true"
                  id="balance"
                  :newbie="step">
                </balance>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalarySettings from './CompanySettingsComponents/SalarySettings'
import MainSettings from './CompanySettingsComponents/MainSettings'
import PaymentMethods from './CompanySettingsComponents/PaymentMethods'
import BillingAddress from './CompanySettingsComponents/BillingAddress'
import Balance from './CompanySettingsComponents/Balance'

export default {
  name: 'Company',
  components: {
    SalarySettings: SalarySettings,
    MainSettings: MainSettings,
    BillingAddress: BillingAddress,
    Balance: Balance,
    PaymentMethods: PaymentMethods
  },
  data () {
    return {
      company: { // currCityChoice curr_city companyFields currencyChoices
        name: '',
        city_id: '', // actual_city
        first_name: '',
        last_name: '',
        date_birth: '',
        phone: '',
        email: '',
        address: '', // country
        subdivision_id: '',
        zip_code: '',
        scope_company: '',
        required_working_time_minutes: null,
        block_overlap_level: 'company',
        salary: {
          currency: 'USD',
          basic_rate: 0,
          pay_breaks: false,
          quotas_per_day: 0,
          quotas_per_week: 0,
          allow_overtime: false,
          overtime_rate_1: 0,
          overtime_rate_2: 0,
          overtime_rate_3: 0,
          overtime_rate_4: 0,
          overtime_rate_1_percent: 0,
          overtime_rate_2_percent: 0,
          overtime_rate_3_percent: 0,
          overtime_rate_4_percent: 0,
          work_day_off: 0,
          work_holiday: 0,
          week_start: 0,
          quotas_per_month: 0,
          quotas_per_month_percent: 0,
          quotas_per_week_percent: 0,
          show_salary_to_managers: true
        },
        user_fields: [],
        user_privacy: []
      },
      // companyFields: '',
      disabled: false,
      activeTab: 'general',
      showTabs: true,
      loaded: false,
      buttonTitle: 'Please Select',
      tabsData: {
        general: 'General information',
        salary: 'Salary settings',
        // employee: 'Fields in employees profiles',
        billing_address: 'Billing address',
        payment: 'Payment methods',
        balance: 'Company balance'
      },
      stepsData: [
        'general',
        'salary',
        // 'employee',
        'billing_address',
        'payment',
        'balance'
      ],
      newbie: false,
      step: null,
      checkout: false
    }
  },
  created () {
    this.$eventBus.on('submitForm', this.submitForm)
    // this.$eventBus.on('updateCompany', this.updateCompany)
    this.$eventBus.on('setStep', this.setStep)
    this.$eventBus.on('checkout', this.checkoutTrue)
    this.$eventBus.on('dispatchCompany', this.dispatchCompany)
    this.init()
  },
  beforeUnmount () {
    this.$eventBus.off('submitForm')
    this.$eventBus.off('setStep')
    this.$eventBus.off('checkout')
    this.$eventBus.off('dispatchCompany')
  },
  mounted () {
    if (this.accessDenied) {
      this.accessErrorRedirect('Only company owner can access this page')
    }
    if (this.tabsData[this.$route.hash.substr(1)]) {
      this.activateTab(this.$route.hash.substr(1))
    }
  },
  computed: {
    routeId () {
      return this.$route.params.id
    },
    wizardPassed () {
      return true
    },
    progress () {
      if (this.step) {
        return (100 / 8) * this.step
      }
      return 0
    },
    showNext () {
      return this.step && (this.step <= Object.keys(this.tabs).length)
    },
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('get-company-admin')
    },
    tabs () {
      if (this.wizardPassed) {
        return this.tabsData
      }

      const stepKeys = this.stepsData.slice(0, this.step)
      const filtered = Object.keys(this.tabsData)
        .filter(key => stepKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.tabsData[key]
          return obj
        }, {})

      return filtered
    }
  },
  watch: {
    '$route' () {
      if (this.tabsData[this.$route.hash.substr(1)]) {
        this.activateTab(this.$route.hash.substr(1))
      }
    },
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('Only company owner can access this page')
      }
    },
    routeId () {
      if (!this.step) {
        this.activateTab('general')
      }
      this.init()
    },
    wizardPassed () {
      if (this.wizardPassed) {
        this.newbie = false
        this.loaded = true
        this.step = null
      } else {
        this.newbie = true
        this.loaded = true
        this.step = 1
      }
    },
    step () {
      // console.log(222, this.step)
      if (this.step) {
        this.activateTab(this.stepsData[this.step - 1] || 'general')
      }
    }
  },
  methods: {
    checkoutTrue () {
      this.checkout = true
    },
    dispatchCompany () {
      if (this.$store.getters.companyId) {
        this.getCompany(this.$store.getters.companyId)
      }
      // this.$store.dispatch('getCompanyFields').then(() => {
      //   this.companyFields = this.$store.getters.companyFields
      // })
    },
    init () {
      if (this.$route.params.id) {
        this.getCompany(this.$route.params.id).then(() => {
          if (this.wizardPassed) {
            this.newbie = false
            this.step = null
          } else {
            this.newbie = true
            this.loaded = true
            this.step = 1
          }
        })
      } else {
        this.newbie = true
        this.loaded = true
        this.company.name = ''
        this.step = 1
      }
    },
    setStep (step) {
      this.step = step
      if (parseInt(step) === 8) {
        // this.skipMaster()
        this.activeTab = 'balance'
        this.company.wizard_passed = true
      }
    },
    skipMaster () {
      this.newbie = false
      this.step = null
    },
    toggleMenu () {
      this.showTabs = !this.showTabs
    },
    activateTab (key) {
      // console.log(key)
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false

      if (this.step) {
        this.step = this.stepsData.indexOf(key) + 1
        if (this.step === 0) {
          this.step = null
        }
      }
    },
    getCompany (companyId) {
      return this.$store.dispatch('getCompany', companyId).then(company => {
        const salaryStub = Object.assign({}, company.salary)
        salaryStub.show_salary_to_managers = company.salary.show_salary_to_managers === undefined ? true : company.salary.show_salary_to_managers
        // deep clone object
        this.company = JSON.parse(JSON.stringify(company))

        // fill salary data with basic values
        Object.keys(salaryStub).forEach(key => {
          this.company.salary[key] = salaryStub[key]
        })
        this.loaded = true

        // this.$store.dispatch('getCompanyFields').then(() => {
        //   this.companyFields = this.$store.getters.companyFields
        // })
      })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
