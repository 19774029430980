<template>
  <Form ref="observer" as="div">
    <div v-on:keyup.enter="submitForm" class="p-0">
      <div class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-primary text-uppercase font-weight-bold">
                {{ $t('Salary settings') }}
                <button
                  v-if="salaryTemplate"
                  class="btn rounded-pill shadow-sm btn-success btn-sm mx-2"
                  @click="fillTemplate">{{ $t('FILL_FORM_COUNTRY_TEMPLATE') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <div class="errors" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('PRIMARY_SALARY_SETTINGS') }}
              </span>
              <!--            <div class="font-weight-bold mt-2">{{ $t('Set up employee pay rate with or without overtime work settings.') }}</div>-->
            </div>
          </div>
        </div>
        <div class="card-body">
          <el-alert
            :title="$t('SALARY_SETTINGS_WARNING')"
            type="warning"
            show-icon>
          </el-alert>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-select
                name="currency"
                :label="$t('Currency')"
                v-model="salary.currency"
                class="w-100"
                :key-name="'key'"
                :label-name="'name'"
                :value-name="'code'"
                :options="currencyChoices"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 mt-2">
              <div>
                <ui-radio-group v-model="salary.is_shift_rate_enable">
                  <ui-radio
                    name="IS_RATE_PER_HOUR_ENABLE_false"
                    :setValue="false">{{ $t('IS_RATE_PER_HOUR_ENABLE') }}
                    <ui-hint :content="$t('IS_RATE_PER_HOUR_ENABLE_HINT')" />
                  </ui-radio>
                </ui-radio-group>
              </div>
              <div>
                <ui-radio-group v-model="salary.is_shift_rate_enable">
                  <ui-radio
                    name="IS_RATE_PER_HOUR_ENABLE_true"
                    :setValue="true">{{ $t('IS_RATE_PER_SHIFT_ENABLE') }}
                    <ui-hint :content="$t('IS_RATE_PER_SHIFT_ENABLE_HINT')" />
                  </ui-radio>
                </ui-radio-group>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3 mt-2">
              <ui-number-input
                  :disabled="salary.is_shift_rate_enable"
                  name="basic_rate"
                  :label="$t('Basic rate/hour')"
                  v-model="salary.basic_rate"
                  :validation="{required: false, decimal: 2, greaterOrEqualThanZero: true}"
              />
            </div>
            <div class="col-md-3 mt-2">
              <ui-number-input
                  name="basic_shift_rate"
                  :disabled="!salary.is_shift_rate_enable"
                  :label="$t('BASIC_SHIFT_RATE')"
                  v-model="salary.basic_shift_rate"
                  :validation="{required: false, decimal: 2, greaterOrEqualThanZero: true}"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-select
                name="rate_priority"
                :label="$t('SHIFT_RATE_PRIORITY')"
                :hint="$t('SHIFT_RATE_PRIORITY_HINT')"
                v-model="salary.shift_rate_priority"
                class="w-100"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :options="shiftRatePriorityOptions"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.pay_breaks"
                v-model="salary.pay_breaks"
              >
                <div class="d-flex align-items-end">
                  {{ $t('Pay employees for breaks') }}
                  <ui-hint :content="$t('PAY_FOR_BREAKS_HINT')" />
                </div>
              </ui-checkbox>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-select
                name="week_start"
                :label="$t('Working week starts on')"
                v-model="salary.week_start"
                :filterable="true"
                class="w-100"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :options="weekDays"
              />
            </div>
          </div>
          <div class="mt-3">
            <label class="font-weight-bold">
              {{ $t('WORK_DAYS') }}
              <ui-hint :content="$t('WORK_DAYS_HINT')" />
            </label>
          </div>
          <div class="row">
            <div
              v-for="(item, index) in workDays"
              :key="index"
              class="col-auto"
            >
              <ui-checkbox
                :name="index + '__is_work_day'"
                v-model="item.is_work_day"
                :label="$t(item.name)"
              />
            </div>
          </div>
          <div class="row align-items-center mt-2">
            <div class="col-md-6">
              <ui-number-input
                name="required_working_time"
                :label="$t('COMPANY_REQUIRED_WORKING_TIME')"
                :hint="$t('COMPANY_REQUIRED_WORKING_TIME_TOOLTIP')"
                v-model="required_working_time"
                :precision="2"
                :step="0.1"
                :min="0"
                :validation="{required: false, decimal: 2}"
              />
            </div>
            <div class="col-md-6 mt-auto">
              <ui-checkbox
                name="dropWT"
                class="text-left"
                v-model="dropWT"
              >
                {{$t('DROP_INDIVIDUAL_WORKING_TIME_SETTINGS') }}
                <ui-hint :content="$t('DROP_INDIVIDUAL_WORKING_TIME_SETTINGS_HINT')" :danger="true" />
              </ui-checkbox>
            </div>
          </div>
        </div>
      </div>
      <el-divider v-if="!salary.is_shift_rate_enable" />
      <div v-if="!salary.is_shift_rate_enable" class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('Work quotas and overtime hours settings') }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row my-2">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.allow_overtime"
                v-model="salary.allow_overtime"
              >
                {{$t('Allow overtime work')}}
              </ui-checkbox>
            </div>
          </div>
          <div>
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('Daily work hours quota and overtime work settings') }}</span>
            </el-divider>
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group form-inline text-center">
                  <label class="mx-1">{{ $t('Daily work quota') }}</label>
                  <ui-number-input
                    :min="0"
                    name="quotas_per_day"
                    v-model="salary.quotas_per_day"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t('hours') }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="form-group form-inline text-center">
                  <ui-checkbox
                    name="salary.calculate_overtime_hours"
                    v-model="salary.calculate_overtime_hours"
                    :disabled="!salary.allow_overtime"
                  >
                    <div class="d-flex align-items-end">
                      {{$t('CALCULATE_OVERTIME_HOURS') }}
                      <ui-hint :content="$t('CALCULATE_OVERTIME_HOURS_HINT')" />
                    </div>
                  </ui-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt-2">
                <div class="form-group form-inline">
                  <label class="col-form-label mx-1">{{ $t(salary.calculate_overtime_hours ? 'From' : 'After') }}</label>
                  <ui-number-input
                    name="overtime_rate_1"
                    v-model="salary.overtime_rate_1"
                    @input="clearOvertime(1)"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :validation="{required: false, decimal: 2}"
                    :disabled="!salary.allow_overtime"
                  />
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'hour of overtime, the base rate is' : 'hours worked a day. Basic rate') }}</label>
                  <ui-number-input
                    name="overtime_rate_1_percent"
                    v-model="salary.overtime_rate_1_percent"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    @input="clearOvertime(1)"
                    :validation="{required: false, decimal: 2}"
                    :disabled="!salary.allow_overtime"
                  />
                  <label class="mx-1">{{ $t('% per hour') }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="form-group form-inline">
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'From' : 'After') }}</label>
                  <ui-number-input
                    name="overtime_rate_2"
                    v-model="salary.overtime_rate_2"
                    :disabled="!isOvertimeRate1Valid || !salary.allow_overtime"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    @input="clearOvertime(2)"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'hour of overtime, the base rate is' : 'hours worked a day. Basic rate') }}</label>
                  <ui-number-input
                    name="overtime_rate_2_percent"
                    v-model="salary.overtime_rate_2_percent"
                    :disabled="!isOvertimeRate1Valid || !salary.allow_overtime"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    @input="clearOvertime(2)"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t('% per hour') }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="form-group form-inline">
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'From' : 'After') }}</label>
                  <ui-number-input
                    name="overtime_rate_3"
                    v-model="salary.overtime_rate_3"
                    :disabled="!isOvertimeRate2Valid || !salary.allow_overtime"
                    @input="clearOvertime(3)"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'hour of overtime, the base rate is' : 'hours worked a day. Basic rate') }}</label>
                  <ui-number-input
                    name="overtime_rate_3_percent"
                    v-model="salary.overtime_rate_3_percent"
                    :disabled="!isOvertimeRate2Valid || !salary.allow_overtime"
                    @input="clearOvertime(3)"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t('% per hour') }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="form-group form-inline">
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'From' : 'After') }}</label>
                  <ui-number-input
                    name="overtime_rate_4"
                    v-model="salary.overtime_rate_4"
                    :disabled="!isOvertimeRate3Valid || !salary.allow_overtime"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t(salary.calculate_overtime_hours ? 'hour of overtime, the base rate is' : 'hours worked a day. Basic rate') }}</label>
                  <ui-number-input
                    name="overtime_rate_4_percent"
                    v-model="salary.overtime_rate_4_percent"
                    :disabled="!isOvertimeRate3Valid || !salary.allow_overtime"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                    :validation="{required: false, decimal: 2}"
                  />
                  <label class="mx-1">{{ $t('% per hour') }}</label>
                </div>
              </div>
            </div>
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('Weekly work hours quota and overtime work settings') }}</span>
            </el-divider>
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="form-group form-inline">
                  <label class="mx-1">{{ $t('Weekly work quota') }}</label>
                  <ui-number-input
                    name="quotas_per_week"
                    v-model="salary.quotas_per_week"
                    :validation="{required: false, decimal: 2}"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                  />
                  <label class="mx-1">{{ $t('hours.') }}</label><label class="mx-1">{{ $t('Payment over the weekly rate: basic rate') }}</label>
                  <ui-number-input
                    name="quotas_per_week_percent"
                    v-model="salary.quotas_per_week_percent"
                    :validation="{required: false, decimal: 2}"
                    :precision="2"
                    :step="0.1"
                    :min="0"
                  />
                  <label class="mx-1">{{ $t('% per hour') }}</label>
                </div>
              </div>
            </div>
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('Monthly work hours quota and overtime work settings') }}</span>
            </el-divider>
            <div class="row">
              <div class="col-md-6 mt-2">
                <ui-number-input
                  name="quotas_per_month"
                  :label="$t('Monthly work quota')"
                  v-model="salary.quotas_per_month"
                  :validation="{required: false, decimal: 2}"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :hint="$t('MONTHLY_WORK_QUOTA_HINT')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <label class="font-weight-bold mx-1 ">{{ $t('MONTHLY_WORK_QUOTA_BY_MONTHS') }}<ui-hint :content="$t('MONTHLY_WORK_QUOTA_BY_MONTHS_HINT')" /></label>
                <table class="table table-hover table-sortable">
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("Month") }}</th>
                      <th class="text-left">{{ $t("Quota") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(quota, index) in salary.monthly_work_quotas" :key="index">
                      <td class="align-middle">{{ $t(listOfMonths[quota.month - 1]) }}</td>
                      <td class="align-middle w-50" >
                        <ui-number-input
                          :name="'quotas_per_week_percent__' + index"
                          v-model="salary.monthly_work_quotas[index]['quota']"
                          :validation="{required: false, decimal: 2}"
                          :precision="2"
                          :step="0.1"
                          :min="0"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mt-2">
                <ui-checkbox
                  :name="'quotas_per_month_from_position'"
                  v-model="salary.quotas_per_month_from_position"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('TAKE_FROM_POSITION') }}
                    <ui-hint :content="$t('TAKE_FROM_POSITION_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider v-if="!salary.is_shift_rate_enable" />
      <div v-if="!salary.is_shift_rate_enable" class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
            <span class="text-primary text-uppercase font-weight-bold">
              {{ $t('Pay rate for holidays and days off') }}
            </span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="form-group form-inline">
                <label class="mx-1">{{ $t('Work on day off') }}</label>
                <ui-number-input
                  name="work_day_off"
                  v-model="salary.work_day_off"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :validation="{required: false, decimal: 2}"
                />
                <label class="mx-1">{{ $t('% to the basic rate') }}</label>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="form-group form-inline">
                <label class="mx-1">{{ $t('Work on holiday') }}</label>
                <ui-number-input
                  name="work_holiday"
                  v-model="salary.work_holiday"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :validation="{required: false, decimal: 2}"
                />
                <label class="mx-1">{{ $t('% to the basic rate') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('SETTINGS_NIGHT_PROCESSING') }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-time-picker
                  :label="$t('NIGHT_START')"
                  name="night_start"
                  :placeholder="$t('From')"
                  :validation="{required: !!salary.night_end}"
                  :clearable="true"
                  :value-format="'HH:mm'"
                  v-model="salary.night_start"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-time-picker
                  :label="$t('NIGHT_END')"
                  name="night_end"
                  :placeholder="$t('To')"
                  :validation="{required: !!salary.night_start}"
                  :clearable="true"
                  :value-format="'HH:mm'"
                  v-model="salary.night_end"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <ui-select
                name="night_timezone"
                :label="$t('NIGHT_TIMEZONE')"
                :hint="$t('NIGHT_TIMEZONE_HINT')"
                v-model="salary.night_timezone"
                class="w-100"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :options="nightTimeZoneOptions"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <label class="control-label font-weight-bold">
                {{ $t('NIGHT_SHIFT_DEFINITION_TYPE') }}
                <ui-hint :content="$t('NIGHT_SHIFT_DEFINITION_TYPE_HINT')" />
              </label>
              <div class="col-lg-12">
                <ui-radio-group v-model="salary.night_shift_definition_type" class="d-inline-block">
                  <div>
                    <ui-radio
                      name="full_entry"
                      :setValue="'full_entry'">
                      {{ $t('NIGHT_SHIFT_DEFINITION_FULL_ENTRY') }}
                      <ui-hint :content="$t('NIGHT_SHIFT_DEFINITION_FULL_ENTRY_HINT')" />
                    </ui-radio>
                  </div>
                  <div>
                    <ui-radio
                      name="intersects_beginning_shift"
                      :setValue="'intersects_beginning_shift'">
                      {{ $t('NIGHT_SHIFT_DEFINITION_INTERSECTS_BEGINNING_SHIFT') }}
                      <ui-hint :content="$t('NIGHT_SHIFT_DEFINITION_INTERSECTS_BEGINNING_SHIFT_HINT')" />
                    </ui-radio>
                  </div>
                  <div>
                    <ui-radio
                      name="overlap"
                      :setValue="'overlap'">
                      {{ $t('NIGHT_SHIFT_DEFINITION_OVERLAP') }}
                      <ui-hint :content="$t('NIGHT_SHIFT_DEFINITION_OVERLAP_HINT')" />
                    </ui-radio>
                  </div>
                </ui-radio-group>
              </div>
              <div class="col-md-12">
                <label class="control-label font-weight-bold">
                  {{ $t('NIGHT_SHIFT_DEFINITION_TIME') }} <ui-hint :content="$t('NIGHT_SHIFT_DEFINITION_TIME_HINT')" />
                </label>
                <ui-number-input
                  name="night_shift_definition_time"
                  v-model="salary.night_shift_definition_time"
                  :validation="{required: false, decimal: 4}"
                  :disabled="salary.night_shift_definition_type !== 'overlap'"
                  :precision="4"
                  :step="0.1"
                  :min="0"
                />
              </div>
            </div>
          </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="control-label font-weight-bold">
            {{ $t('NIGHT_TIME_TRACKING_TYPE') }}
            <ui-hint :content="$t('NIGHT_TIME_TRACKING_TYPE_HINT')" />
            </label>
            <div class="col-lg-12">
              <ui-radio-group
                class="d-inline-block"
                v-model="salary.night_time_tracking_type"
                :disabled="salary.night_shift_definition_type !== 'overlap'">
                <div>
                  <ui-radio
                    name="all_time"
                    :setValue="'all_time'">
                    {{ $t('NIGHT_TIME_TRACKING_ALL_TIME_TYPE') }} <ui-hint :content="$t('NIGHT_TIME_TRACKING_ALL_TIME_TYPE_HINT')" />
                  </ui-radio>
                </div>
                <div>
                  <ui-radio
                    name="overlap"
                    :setValue="'overlap'">{{ $t('NIGHT_TIME_TRACKING_OVERLAP_TYPE') }}
                    <ui-hint :content="$t('NIGHT_TIME_TRACKING_OVERLAP_TYPE_HINT')" />
                  </ui-radio>
                </div>
              </ui-radio-group>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="control-label font-weight-bold">
              {{ $t('COEFFICIENT_TYPE') }}
              <ui-hint :content="$t('COEFFICIENT_TYPE_HINT')" />
            </label>
            <div class="col-lg-12">
              <ui-radio-group
                class="d-inline-block"
                v-model="salary.night_coefficient_type">
                <div>
                  <ui-radio
                    name="time_recalculation"
                    :setValue="'time_recalculation'">
                    {{ $t('TIME_RECALCULATION') }} <ui-hint :content="$t('TIME_RECALCULATION_HINT')" />
                  </ui-radio>
                </div>
                <div>
                  <ui-radio
                    name="rate_increase"
                    :setValue="'rate_increase'">
                    {{ $t('RATE_INCREASE') }} <ui-hint :content="$t('RATE_INCREASE_HINT')" />
                  </ui-radio>
                </div>
              </ui-radio-group>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="control-label font-weight-bold">
              {{ $t('NIGHT_COEFFICIENT') }}
            </label>
            <ui-number-input
              name="night_coefficient"
              v-model="salary.night_coefficient"
              :validation="{required: false, decimal: 4}"
              :precision="4"
              :step="0.1"
              :min="0"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="control-label font-weight-bold">
              {{ $t('MAXIMUM_NUMBER_OF_NIGHT_SHIFTS_PER_WEEK') }} <ui-hint :content="$t('MAXIMUM_NUMBER_OF_NIGHT_SHIFTS_PER_WEEK_HINT')" />
            </label>
            <ui-number-input
              name="night_max_shifts_per_week"
              v-model="salary.night_max_shifts_per_week"
              :validation="{required: false, integer: true}"
              :precision="0"
              :step="1"
              :min="0"
            />
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('SETTINGS_NOT_ONLY_FOR_ISRAEL') }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <ui-number-input
                :label="$t('MINIMUM_BREAK_BETWEEN_SHIFTS')"
                name="minimum_break_between_shifts"
                v-model="salary.minimum_break_between_shifts"
                :validation="{required: false, decimal: 2}"
                :precision="2"
                :step="0.1"
                :min="0"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ui-number-input
                :label="$t('MINIMUM_BREAK_BETWEEN_SHIFTS_FOR_WOMEN')"
                name="minimum_break_between_shifts_for_women"
                v-model="salary.minimum_break_between_shifts_for_women"
                :validation="{required: false, decimal: 2}"
                :precision="2"
                :step="0.1"
                :min="0"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ui-number-input
                :label="$t('MINIMUM_BREAK_IN_ROW_PER_WEEK')"
                name="minimum_break_in_row_per_week"
                v-model="salary.minimum_break_in_row_per_week"
                :validation="{required: false, decimal: 2}"
                :precision="2"
                :step="0.1"
                :min="0"
              />
            </div>
          </div>
        </div>
      </div>
      <el-divider />
      <div class="card card-small">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-12">
              <span class="text-primary text-uppercase font-weight-bold">{{ $t('ADDITIONAL_SALARY_SETTINGS') }}</span>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.show_salary_to_users"
                v-model="salary.show_salary_to_users"
              >
                <div class="d-flex align-items-end">
                  {{ $t('SHOW_SALARY_TO_EMPLOYEE') }}
                  <ui-hint :content="$t('SHOW_SALARY_TO_EMPLOYEE_HINT')" />
                </div>
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.show_salary_to_managers"
                v-model="salary.show_salary_to_managers"
              >
                <div class="d-flex align-items-end">
                  {{ $t('SHOW_SALARY_TO_MANAGERS') }}
                  <ui-hint :content="$t('SHOW_SALARY_TO_MANAGERS_HINT')" />
                </div>
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.take_rate_from_position"
                v-model="salary.take_rate_from_position"
                  :disabled="salary.is_shift_rate_enable"
              >
                <div class="d-flex align-items-end">
                  {{ $t('TAKE_RATE_FROM_POSITION') }}
                  <ui-hint :content="$t('TAKE_RATE_FROM_POSITION_HINT')" />
                </div>
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ui-checkbox
                name="salary.split_hour_count"
                v-model="salary.split_hour_count"
              >
                <div class="d-flex align-items-end">
                  {{ $t('SPLIT_HOUR_COUNT') }}
                  <ui-hint :content="$t('SPLIT_HOUR_COUNT_HINT')" />
                </div>
              </ui-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 text-center py-2">
          <button
            type="submit"
            name="submit"
            :disabled="disabled"
            class="btn rounded-pill shadow-sm"
            v-bind:class="[newbie ? 'btn-primary' : 'btn-success']"
            @click="submitForm">{{ newbie ? $t('Next') : $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>

import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'SalarySettings',
  props: ['newbie', 'company'],
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      currencyChoices: [],
      disabled: false,
      salary: {},
      salaryTemplate: {},
      required_working_time: null,
      dropWT: false,
      pickerOptions: {
        start: '00:00',
        step: '00:05',
        end: '24:00'
      },
      shiftRatePriorityOptions: [
        { id: 'position', name: this.$i18n?.t('By positions') },
        { id: 'location', name: this.$i18n?.t('By location') }
      ],
      nightTimeZoneOptions: [
        { id: 'project', name: this.$i18n?.t('By project timezone') },
        { id: 'user', name: this.$i18n?.t('By user timezone') }
      ],
      weekDays: [
        { id: 0, name: this.$i18n?.t('Monday') },
        // { 'id': 1, 'name': this.$i18n?.t('Tuesday') },
        // { 'id': 2, 'name': this.$i18n?.t('Wednesday') },
        // { 'id': 3, 'name': this.$i18n?.t('Thursday') },
        // { 'id': 4, 'name': this.$i18n?.t('Friday') },
        // { 'id': 5, 'name': this.$i18n?.t('Saturday') },
        { id: 6, name: this.$i18n?.t('Sunday') }
      ],
      workDays: [],
      listOfMonths: moment.months()
    }
  },
  computed: {
    country () {
      return this.$store.getters.company.address
    },
    calculateOvertimeHours () {
      return this.salary.calculate_overtime_hours
    },
    isOvertimeRate1Valid () {
      if (this.calculateOvertimeHours) {
        return this.salary.overtime_rate_1_percent && !this.$refs.observer?.errors.overtime_rate_1 && !this.$refs.observer?.errors.overtime_rate_1_percent
      }
      return !!(this.salary.overtime_rate_1 &&
        this.salary.overtime_rate_1_percent &&
        !this.$refs.observer?.errors.overtime_rate_1 &&
        !this.$refs.observer?.errors.overtime_rate_1_percent)
    },
    isOvertimeRate2Valid () {
      return !!(this.isOvertimeRate1Valid &&
        this.salary.overtime_rate_2 &&
        this.salary.overtime_rate_2_percent &&
        !this.$refs.observer?.errors.overtime_rate_2 &&
        !this.$refs.observer?.errors.overtime_rate_2_percent)
    },
    isOvertimeRate3Valid () {
      return !!(this.isOvertimeRate2Valid &&
        this.salary.overtime_rate_3 &&
        this.salary.overtime_rate_3_percent &&
        !this.$refs.observer?.errors.overtime_rate_3 &&
        !this.$refs.observer?.errors.overtime_rate_3_percent)
    }
  },
  created () {
    this.$store.dispatch('getSalaryTemplate', this.$route.params.id).then((response) => {
      this.salaryTemplate = response.settings
    })

    const salary = Object.assign({}, this.company.salary)
    salary.week_start = +salary.week_start
    this.required_working_time = this.company.required_working_time_minutes
      ? Math.round((this.company.required_working_time_minutes / 60 + Number.EPSILON) * 100) / 100
      : null

    if (!salary.basic_rate) {
      salary.basic_rate = 0
    }

    salary.night_coefficient_type = salary.night_coefficient_type ? salary.night_coefficient_type : 'rate_increase'
    salary.night_coefficient = salary.night_coefficient ? salary.night_coefficient : 0
    salary.night_shift_definition_type = salary.night_shift_definition_type ? salary.night_shift_definition_type : 'full_entry'
    salary.night_shift_definition_time = salary.night_shift_definition_time ? salary.night_shift_definition_time : 0
    salary.night_time_tracking_type = salary.night_time_tracking_type ? salary.night_time_tracking_type : 'all_time'
    salary.night_max_shifts_per_week = salary.night_max_shifts_per_week ? salary.night_max_shifts_per_week : 0
    salary.quotas_per_week = salary.quotas_per_week ? salary.quotas_per_week : 0
    salary.quotas_per_week_percent = salary.quotas_per_week_percent ? salary.quotas_per_week_percent : 0
    salary.quotas_per_month = salary.quotas_per_month ? salary.quotas_per_month : 0
    salary.quotas_per_month_percent = salary.quotas_per_month_percent ? salary.quotas_per_month_percent : 0
    salary.quotas_per_month_from_position = salary.quotas_per_month_from_position ? salary.quotas_per_month_from_position : false
    salary.calculate_overtime_hours = salary.calculate_overtime_hours ? salary.calculate_overtime_hours : false
    salary.basic_shift_rate = salary.basic_shift_rate ? salary.basic_shift_rate : 0
    salary.is_shift_rate_enable = salary.is_shift_rate_enable ? salary.is_shift_rate_enable : false
    salary.monthly_work_quotas = salary.monthly_work_quotas ? salary.monthly_work_quotas : [...Array(12).keys()].map(i => { return { month: i + 1, quota: salary.quotas_per_month } })
    salary.night_timezone = salary.night_timezone ? salary.night_timezone : 'project'
    salary.allow_overtime = !!this.company.salary.allow_overtime
    salary.split_hour_count = salary.split_hour_count ? salary.split_hour_count : false

    if (!salary.currency) {
      salary.currency = 'USD'
    }

    this.salary = salary
    this.$store.dispatch('getCurrencies', this.$store.getters.locale).then((currency) => {
      this.currencyChoices = currency
    })
  },
  watch: {
    'salary.week_start' () {
      this.handleWorkDays()
    }
  },
  methods: {
    handleWorkDays () {
      this.workDays = Object.assign([], this.company.work_days)
      this.workDays.sort((a, b) => {
        if (a.index > b.index) {
          return 1
        }
        if (a.index < b.index) {
          return -1
        }
        return 0
      })
      let firstDay = +this.salary.week_start + 1
      if (firstDay === 7) {
        firstDay = 0
      }
      const startArray = this.workDays.splice(firstDay)
      this.workDays = startArray.concat(this.workDays)
    },
    handleDaysChange (val, index) {
      this.workDays[index].is_work_day = val
    },
    fillTemplate () {
      if (this.salaryTemplate) {
        this.salary = Object.assign({}, this.salaryTemplate)
        this.salary.allow_overtime = this.salaryTemplate.allow_overtime === 1
        this.salary.pay_breaks = this.salaryTemplate.pay_breaks === 1
        this.salary.week_start = +this.salary.week_start
      }
    },
    clearOvertime (value, calculateOvertimeHours = false) {
      switch (value) {
        case 1:
          if (calculateOvertimeHours) {
            this.salary.overtime_rate_1 = 0
            this.salary.overtime_rate_1_percent = 100
          }
          this.salary.overtime_rate_2 = 0
          this.salary.overtime_rate_2_percent = 0
          this.salary.overtime_rate_3 = 0
          this.salary.overtime_rate_3_percent = 0
          this.salary.overtime_rate_4 = 0
          this.salary.overtime_rate_4_percent = 0
          break
        case 2:
          this.salary.overtime_rate_3 = 0
          this.salary.overtime_rate_3_percent = 0
          this.salary.overtime_rate_4 = 0
          this.salary.overtime_rate_4_percent = 0
          break
        case 3:
          this.salary.overtime_rate_4 = 0
          this.salary.overtime_rate_4_percent = 0
          break
        default:
          break
      }
    },
    submitForm () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          if (this.dropWT) {
            this.$confirm(this.$i18n?.t('DROP_WORKING_TIME_ALERT'), this.$i18n?.t('Are you sure?'), {
              confirmButtonText: this.$i18n?.t('Yes'),
              cancelButtonText: this.$i18n?.t('No'),
              type: 'warning',
              center: true
            }).then(() => {
              this.$store.dispatch('dropWorkingTime', this.$route.params.id).then(response => {
                this.dropWT = false
              }).catch(() => {})
              this.handleSave()
            }).catch(() => {})
          } else {
            this.handleSave()
          }
        }
      })
    },
    handleSave () {
      this.disabled = true
      this.salary.minimum_break_in_row_per_week = this.salary.minimum_break_in_row_per_week ? this.salary.minimum_break_in_row_per_week : 0
      this.salary.minimum_break_between_shifts_for_women = this.salary.minimum_break_between_shifts_for_women ? this.salary.minimum_break_between_shifts_for_women : 0
      this.salary.minimum_break_between_shifts = this.salary.minimum_break_between_shifts ? this.salary.minimum_break_between_shifts : 0
      const companyData = this.salary
      this.$store.dispatch('editCompany', [this.$route.params.id, {
        salary: companyData,
        work_days: this.workDays,
        required_working_time_minutes: this.required_working_time
          ? Math.round(this.required_working_time * 60 + Number.EPSILON)
          : this.required_working_time
      }]).then(() => {
        this.disabled = false
        if (this.newbie) this.$eventBus.emit('setStep', 3)
        this.toastSuccess(this.$t('Company successfully edited'))
      }).catch(() => {
        this.disabled = false
      })
    }
  }
}
</script>

<style scoped>
</style>
