<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errors }"
    :rules="validation"
    as="div">
    <ui-input-label v-if="label" :label="label" :hint="hint"/>
    <el-autocomplete
      :model-value="value"
      v-bind="field"
      :disabled="disabled"
      :class="containerClass"
      :fetch-suggestions="querySearchAsync"
      :placeholder="placeholder"
      @select="(item) => $emit('select', item)"
      :teleported="false"
    />
    <span
        v-for="(error, index) in errors" v-bind:key="name + index"
        class="invalid-feedback"
        :data-cy="dataCy + '-alert'"
    >
      {{ $t(error) }}
    </span>
    <span
        v-for="(error, index) in apiErrors" v-bind:key="'apiError' + name + index"
        class="invalid-feedback"
        :data-cy="dataCy + '-alert'"
    >
      {{ $t(error) }}
    </span>
  </Field>
</template>

<script>
export default {
  name: 'ui-autocomplete',
  emits: ['update:modelValue'],

  props: {
    value: String,
    modelValue: String,
    name: String,
    validation: [String, Object],
    disabled: Boolean,
    querySearchAsync: Function,
    handleSelect: Function,
    placeholder: String,
    label: String,
    hint: String,
    dataCy: String,
    apiErrors: Array,
    containerClass: String
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
