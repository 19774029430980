<template>
  <div v-if="settings">
    <div>
      <ui-checkbox
        @change="change"
        v-model="settings.shifts"
        name="SHOW_SHIFTS"
      >
        {{$t('SHOW_SHIFTS')}}
      </ui-checkbox>
    </div>
    <div>
      <ui-checkbox
        name="SHOW_TIME_OFF"
        @change="change"
        v-model="settings.timeOff"
      >
        {{$t('SHOW_TIME_OFF')}}
      </ui-checkbox>
    </div>
    <div>
      <ui-checkbox
        name="SHOW_UNAVAILABILITY"
        @change="change"
        v-model="settings.unavailability"
      >
        {{$t('SHOW_UNAVAILABILITY')}}
      </ui-checkbox>
    </div>
    <div>
      <ui-checkbox
        name="SHOW_AVAILABILITY"
        @change="change"
        v-model="settings.availability"
      >
        {{$t('SHOW_AVAILABILITY')}}
      </ui-checkbox>
    </div>
    <div v-if="breakModuleIsActive">
      <ui-checkbox
        name="SHOW_BREAKS"
        @change="change"
        v-model="settings.breaks"
      >
        {{$t('SHOW_BREAKS')}}
      </ui-checkbox>
    </div>
  </div>
</template>

<script>
import { MODULE_BREAKS } from '@/modules/setup'

export default {
  name: 'DisplaySettings',
  data () {
    return {
      settings: null,
      breakModuleIsActive: true
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  watch: {
    companyId () {
      this.loadData()
    }
  },
  created () {
    // если есть companyId грузим данные, в ином случаи через watch
    if (this.companyId) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('isModuleActive', [this.companyId, MODULE_BREAKS]).then(response => {
        // console.log(response)
        this.breakModuleIsActive = true
        const stateSettings = this.$store.getters.displayEventsSettings
        const storageSettings = JSON.parse(localStorage.getItem('displayEventsSettings'))
        if (stateSettings) {
          this.settings = stateSettings
          return
        }
        if (storageSettings) {
          this.$store.dispatch('updateDisplaySettings', storageSettings)
          this.settings = storageSettings
          return
        }
        this.settings = {
          shifts: true,
          timeOff: true,
          unavailability: true,
          availability: true,
          breaks: true,
          holidays: true,
          deletedUsers: true,
          notes: true
        }
        this.$store.dispatch('updateDisplaySettings', this.settings)
        localStorage.setItem('displayEventsSettings', JSON.stringify(this.settings))
      }).catch(() => {
        this.breakModuleIsActive = false
        const stateSettings = this.$store.getters.displayEventsSettings
        const storageSettings = JSON.parse(localStorage.getItem('displayEventsSettings'))
        if (stateSettings) {
          this.settings = stateSettings
          if (this.settings.breaks) {
            this.settings.breaks = false
            this.$store.dispatch('updateDisplaySettings', this.settings)
          }
          return
        }
        if (storageSettings) {
          this.settings = storageSettings
          this.settings.breaks = false
          this.$store.dispatch('updateDisplaySettings', this.settings)
          return
        }
        this.settings = {
          shifts: true,
          timeOff: true,
          unavailability: true,
          availability: true,
          breaks: false,
          holidays: true,
          deletedUsers: true,
          notes: true
        }
        this.$store.dispatch('updateDisplaySettings', this.settings)
        localStorage.setItem('displayEventsSettings', JSON.stringify(this.settings))
      })
    },
    change () {
      localStorage.setItem('displayEventsSettings', JSON.stringify(this.settings))
      this.$store.dispatch('updateDisplaySettings', this.settings)
    }
  }
}
</script>

<style scoped>

</style>
