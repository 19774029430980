<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
        @activate="getTableDataActivated()"
        :dummyInfo="dummyInfo"
      >
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div v-if="list.length > 0 || useSearch" class="card card-small">
        <div class="card-body table-responsive">
          <tab-navigation-component :tabs="tabs" />
          <div class="row py-2">
            <div class="col-lg-6">
              <div class="text-primary text-uppercase font-weight-bold">
                {{ $t("Clients") }}
                <ui-hint :content="$t('CLIENTS_HINTS')" />
              </div>
              <div class="form-inline mt-2">
                <ui-text-input
                    name="search-client"
                    class='w-100'
                    v-model='searchQuery'
                    :placeholder="$t('SEARCH_CLIENTS_PLACEHOLDER')"
                    v-on:keyup.enter="searchClients"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="text-right">
                <button class="btn btn-primary btn-sm rounded-pill ml-4 mr-2" type="button" @click="addClient">
                  {{ $t('Create Client') }}
                </button>
              </div>
            </div>
          </div>
          <el-table
            class="table"
            header-row-class-name="thead-light"
            :data="list"
            style="width: 100%">
            <el-table-column
              :label="$t('Name')"
              >
              <template #default="scope">
                <div class="text-break">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Address')"
              prop="address">
              <template #default="scope">
                <div class="text-break">
                  {{ scope.row.address }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Phone')"
              prop="phone">
            </el-table-column>
            <el-table-column
              :label="$t('Email')"
              prop="email">
            </el-table-column>
            <template v-for="(item) in clientFields">
              <el-table-column
                v-if="item.show_in_grid"
                :key="item.id"
                :label="item.title"
                >
                <template #default="scope">
                  <div v-if="scope.row.fields[item.id]" class="text-break">
                    <template v-if="scope.row.fields[item.id]['field_type'] === 'checkbox'">
                      {{+scope.row.fields[item.id].pivot.value ? $t('YES') : $t('NO')}}
                    </template>
                    <template v-else-if="scope.row.fields[item.id]['field_type'] === 'date_range_picker'">
                      {{ formatDate(scope.row.fields[item.id].pivot.options[0]) }} - {{ formatDate(scope.row.fields[item.id].pivot.options[1]) }}
                    </template>
                    <template v-else-if="scope.row.fields[item.id]['field_type'] === 'date_picker'">
                      {{ formatDate(scope.row.fields[item.id].pivot.value) }}
                    </template>
                    <template v-else>
                      {{ scope.row.fields[item.id]['field_type'] !== 'multi_select' ? scope.row.fields[item.id].pivot.value : scope.row.fields[item.id].pivot.options.join(', ')  }}
                    </template>
                  </div>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              align="right">
              <template #default="scope">
                <i
                  class="icon-pencil btn-icon text-primary mx-1"
                  @click="handleEdit(scope.$index, scope.row)">
                </i>
                <i
                  class="icon-trash btn-icon text-danger mx-1"
                  @click="handleDelete(scope.$index, scope.row)">
                </i>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
            >
          </el-pagination>
        </div>
      </div>
      <div v-else class="card">
        <div class="card-body text-center">
          <tab-navigation-component :tabs="tabs" />
          <img class="img-fluid" style="max-height: 350px" src="../../../../public/static/images/App/Splash_1_01.png"
               alt=""/>
          <h5 class="m-3">
            {{$t('NO_CLIENTS')}}
          </h5>
          <div>
            <button class="btn btn-primary btn-md rounded-pill shadow-sm" type="button" @click="addClient">
              {{ $t('Create Client') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import mixinMoment from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Clients',
  components: { TabNavigationComponent: TabNavigationComponent, DummyPaymentRequired },
  mixins: [TasksAccessMixin, mixinDummy, mixinMoment, ToastMixin],
  data () {
    return {
      list: [],
      search: '',
      tabs: [],
      searchQuery: '',
      useSearch: false,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      clientFields: [],
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      timeoutId: null
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    },
    searchQuery () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
      this.timeoutId = setTimeout(() => {
        this.searchClients()
      }, 1000)
    }
  },
  methods: {
    formatDate (val) {
      return moment(val).format(this.localeDateFormat)
    },
    updateList () {
      this.list.forEach(client => {
        client.fields = client.fields.reduce((obj, item) => {
          obj[item.id] = item
          return obj
        }, {})
        // tmp.forEach(val => {
        //   client.fields[val.id] = val
        // })
      })
    },
    searchClients () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
      this.pagination.currentPage = 1
      this.useSearch = true
      let payload = '?page=' + this.pagination.currentPage
      payload += '&search=' + encodeURIComponent(this.searchQuery)
      this.$store.dispatch('getClients', [this.companyId, payload]).then((response) => {
        this.handleClientListUpdate(response)
      }).catch(() => {})
    },
    handleClientListUpdate (response) {
      const responseData = response.data.sort((a, b) => {
        const aName = `${a.first_name} ${a.last_name}`
        const bName = `${b.first_name} ${b.last_name}`
        if (aName.toLowerCase() > bName.toLowerCase()) {
          return 1
        }
        return -1
      })
      this.pagination.currentPage = response.current_page
      this.pagination.total = response.total
      if (response.current_page > response.last_page) {
        this.$router.replace({ query: { page: response.last_page } }).catch(() => {})
      }
      this.list = responseData
      this.updateList()
    },
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: '#',
          title: 'Clients',
          isActive: true
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.$store.dispatch('getCompanyClientFields').then((response) => {
        this.clientFields = response
      })

      let payload = '?page=' + this.pagination.currentPage
      if (this.searchQuery !== '') {
        payload += '&search=' + encodeURIComponent(this.searchQuery)
      }
      this.$store.dispatch('getClients', [this.companyId, payload]).then((response) => {
        this.handleClientListUpdate(response)
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    // querySearchClientAsync () {
    //   this.$store.dispatch('getClients', [this.companyId, '?search=' + this.searchQuery]).then((response) => {
    //     this.list = response
    //   }).catch({})
    // },
    handleEdit (index, row) {
      this.$router.push(`/c/${this.companyId}/clients/${row.id}`)
    },
    handleDelete (index, row) {
      this.$confirm(this.$t('This will permanently delete the client. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteClient', [this.companyId, row.id]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          this.init()
        }).catch((err) => {
          let message = this.$t(err.response.data.message)
          if (err.response.data.errors.related_task) {
            message = this.$t('CLIENT_HAS_RELATED_TASK')
          }
          this.toastError(message)
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    addClient () {
      this.$router.push(`/c/${this.companyId}/clients/create`)
    },
    handleCurrentChange (val) {
      // this.$router.replace({ query: { page: val } }).catch(() => {})
      let payload = '?page=' + this.pagination.currentPage
      payload += '&search=' + encodeURIComponent(this.searchQuery)
      this.$store.dispatch('getClients', [this.companyId, payload]).then((response) => {
        this.handleClientListUpdate(response)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
