<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
          <span class="title-text">
            {{ $t("TASKS_MAIN_TITLE") }}
            <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
          </span>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <tab-navigation-component
            :tabs="tabs"
          />
          <mark-create-form
            :titleName="'TASK_MARK_NAME'"
            :colorPickerName="'Choose a color:'"
          />
          <template v-if="taskMarks.length > 0">
            <div class="text-uppercase font-weight-bold text-primary">
              {{$t('TASK_MARKS_LIST')}}
            </div>
            <el-divider />
            <template v-for="(mark, index) in taskMarks" :key="mark.id">
              <MarkItemComponent
                :index="index"
                :mark="mark"
                @onSave="saveTaskMark"
                @onDelete="deleteTaskMark"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import MarkCreateForm from '@/components/CommonComponents/Marks/MarkCreateForm'
import MarkItemComponent from '@/components/CommonComponents/Marks/MarkItemComponent'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'TaskMarks',
  components: { MarkItemComponent, MarkCreateForm, TabNavigationComponent, DummyPaymentRequired },
  mixins: [mixinDummy, TasksAccessMixin, ToastMixin],
  data () {
    return {
      tabs: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.setTubs()
      this.init()
    }
  },
  computed: {
    taskMarks () {
      return this.$store.getters.taskMarks
    },
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.setTubs()
      this.init()
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getTaskMarks', this.companyId).then((response) => {
        if (response) {
          this.list = response
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: true
        })
      }
    },
    deleteTaskMark (id) {
      this.$store.dispatch('deleteTaskMark', [this.companyId, id]).then(() => {
        this.toastSuccess(this.$i18n?.t('TASK_MARK_DELETED'))
      }).catch(error => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    saveTaskMark (payload) {
      this.$store.dispatch('updateTaskMarks', [this.companyId, payload]).then(() => {
        this.toastSuccess(this.$i18n?.t('TASK_MARK_UPDATED'))
      }).catch(error => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
