<template>
  <Form ref="observer" as="div">
    <div v-if="weekOvertime.is_overtime || weekOvertimeEmployeeToExchange.is_overtime" class="row mb-2">
      <el-alert
        v-if="weekOvertime.is_overtime"
        :title="overtimeTitle"
        effect="dark"
        :closable="false"
        center
        type="warning">
      </el-alert>
      <el-alert
        v-if="weekOvertimeEmployeeToExchange.is_overtime"
        :title="overtimeExchangeTitle"
        effect="dark"
        :closable="false"
        center
        type="warning">
      </el-alert>
    </div>
    <div v-if="blockChangeUser">
      <el-alert
        :title="$t('SHIFT_WITH_BONUS_WARNING')"
        effect="dark"
        :closable="false"
        center
        type="warning">
      </el-alert>
    </div>
    <div class="row mb-2">
      <div class="col-md-6 py-1">
        <ui-select
          :label="$t('EMPLOYEE_TITLE_EVENT_MODAL')"
          name="employee"
          v-model="shiftInfoState.employee_id"
          :clearable="false"
          :placeholder="$t('Select')"
          :options="usersBySchedule"
          :validation="{required: true}"
          key-name="id"
          label-name="full_name"
          value-name="id"
        />
      </div>
      <div class="col-md-6 py-1">
        <ui-color-picker
          name="shift_color"
          v-model="shiftInfoState.color"
          :colors="colorsChoices"
          :label="$t('Shift color')"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Продолжительность смены-->
    <div class="row mt-2">
      <div class="col-lg-12 text-left">
        <label class="control-label font-weight-bold">{{ $t("Shift period") }}: {{ periodDuration }} {{ periodDurationHrsHuman }} {{ projectTimeZone }} <ui-hint :content="$t('SHIFT_PERIOD_DURATION_HINT')" /></label>
        <label class="control-label font-weight-bold">{{ $t("Shift duration hrs") }}: {{ duration }} {{ durationHrsHuman }} <ui-hint :content="$t('SHIFT_DURATION_HINT')" /></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 py-1">
        <ui-datetime-picker
          :label="$t('SHIFT_START')"
          name="from"
          :placeholder="$t('Select date and time')"
          v-model="startDateTime"
          @change="$emit('updateOvertimeCheck')"
          :validation="{required: true}"
          :clearable="false"
          :disabled="!showButtons"
        />
      </div>
      <div class="col-md-6 py-1">
        <ui-datetime-picker
          :label="$t('SHIFT_END')"
          name="to"
          :placeholder="$t('Select date and time')"
          v-model="endDateTime"
          @change="$emit('updateOvertimeCheck')"
          :validation="{required: true}"
          :clearable="false"
          :disabled="!showButtons"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-6 py-1">
        <span class="text-secondary small">{{ $t('PLANNED_SHIFT_START') }} {{plannedStartDateTime}}</span>
      </div>
      <div class="col-md-6 py-1">
        <span class="text-secondary small">{{ $t('PLANNED_SHIFT_END') }} {{plannedEndDateTime}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 py-1" v-show="!hideBreak">
        <ui-number-input
          name="break_time"
          :label="$t('BREAK_MIN')"
          v-model="breakTime"
          :precision="0"
          :step="1"
          :min="0"
          :validation="{integer: true, min_value: 0, max_value: durationMinutes}"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Опоздания-->
    <div class="row my-2">
      <div class="col">
        <ui-select
          :label="$t('LATENESS_TYPE_TITLE')"
          :hint="$t('LATENESS_TYPE_HINT')"
          :name="'late'"
          v-model="latenessStatus"
          :clearable="true"
          :placeholder="$t('Select')"
          :options="latenessStatusesList"
          :key-name="'value'"
          :label-name="'label'"
          :value-name="'value'"
        />
      </div>
      <div class="col">
        <ui-period-picker
            :label="$t('Late for')"
            :name="'lateness'"
            :disabled="latenessStatus !== 'late'"
            :validation="{required: true}"
            :clearable="false"
            v-model="latenessValue"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Ранний уход-->
    <div class="row my-2">
      <div class="col">
        <ui-select
          :label="$t('EARLY_LEAVING')"
          :hint="$t('EARLY_LEAVING_HINT')"
          :name="'early_leaving_status'"
          v-model="earlyLeavingStatus"
          :clearable="true"
          :placeholder="$t('Select')"
          :options="earlyLeavingStatusesList"
          :key-name="'value'"
          :label-name="'label'"
          :value-name="'value'"
        />
      </div>
      <div class="col">
        <ui-period-picker
            :label="$t('Left earlier')"
            :name="'early_leaving'"
            :disabled="earlyLeavingStatus !== 'early'"
            :validation="{required: true}"
            :clearable="false"
            v-model="earlyValue"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <!--Пэйрэйт и позиции-->
    <div class="row my-2 align-items-end">
      <div v-if="salaryForManager" class="col-md-6 py-1">
        <ui-number-input
          name="rate"
          :label="companySalary.is_shift_rate_enable ? $t('PAY_FOR_SHIFT') : $t('SHIFT_PAY_RATE')"
          :hint="companySalary.is_shift_rate_enable ? $t('PAY_FOR_SHIFT_HINT') : $t('SHIFT_PAY_RATE_HINT')"
          v-model="shiftInfoState.rate"
          :validation="{decimal: 2, min_value: 0}"
          :placeholder="$t('BASIC_PAY_RATE')"
          :min="0"
        />
      </div>
      <div class="col-md-6 py-1">
        <ui-tags-select
          :label="$t('Shift positions')"
          :hint="$t('POSITION_HINT')"
          :name="'positions'"
          v-model="shiftMarks"
          :validation="{required: false}"
          :clearable="true"
          :filterable="true"
          :placeholder="$t('Select')"
          :options="projectMarks"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
          :allow-create="isEmployeeHasPermission('create-shift-admin') ||
           rolesByProjects[currentProjectId] === 'manager'"
          :disabled="!(isEmployeeHasPermission('create-shift-admin') ||
           rolesByProjects[currentProjectId] === 'manager')"
        >
          <template v-slot:default="slotProps">
            <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
      </div>
    </div>
    <el-divider class="my-2" />
    <div class="row">
      <div class="col-12 col-md-6">
        <ui-select
          :label="$t('SHIFT_EDIT_LOCATION_SELECT')"
          :hint="$t('SHIFT_EDIT_LOCATION_SELECT_HINT')"
          :name="'location'"
          v-model="shiftInfoState.location_id"
          :clearable="true"
          :placeholder="$t('Select')"
          :options="locations"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
        />
      </div>
    </div>
    <el-divider class="my-2" />
    <div class="row">
      <div class="col-12">
        <ui-textarea-input
          name="note"
          :label="$t('SHIFT_NOTE_LABEL')"
          :hint="$t('SHIFT_NOTE_LABEL_HINT')"
          v-model="shiftInfoState.note"
          :validation="{required: false, max: 256}"
          :rows="2"
          :placeholder="$t('SHIFT_NOTE')"
        />
      </div>
      <div class="col-auto d-flex">
        <ui-checkbox
          class="mt-auto"
          name="hide_note"
          :disabled="!shiftInfoState.note"
          v-model="shiftInfoState.hide_note"
        >
          {{ $t('SHIFT_NOTE_HIDE_LABEL') }}
          <ui-hint :content="$t('SHIFT_NOTE_HIDE_LABEL_HINT')" />
        </ui-checkbox>
      </div>
    </div>
    <el-divider class="my-2" />
    <template  v-if="showButtons">
      <div class="row mt-2">
        <div class="col-12 col-md-6 text-center">
          <span class="mx-1">{{$t('SHIFT_TO_FREEBOARD_TITLE')}}</span>
          <ui-hint :content="$t('SHIFT_TO_FREEBOARD_HINTS')" />
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
      <div v-if="showButtons" class="row">
        <div class="col-md-6 p-1">
          <div class="text-center">
            <button
                class="btn btn-primary btn-sm rounded-pill shadow-sm"
                data-dismiss="modal"
                :disabled="blockChangeUser"
                aria-label="Close"
                @click="putOnFreeBoard">
              {{ $t('SHIFT_TO_FREEBOARD_BUTTON') }}
            </button>
          </div>
        </div>
        <div class="col-md-6 p-1">
          <div class="d-flex">
            <button
                class="btn btn-danger btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                :disabled="blockChangeUser"
                @click="deleteShift">
              {{ $t('Delete') }}
            </button>
            <button
                class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                aria-label="Close"
                @click="save">
              {{ $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </Form>
</template>

<script>
import moment from 'moment-timezone'
import ToastMixin from '@/mixins/ToastMixin'
import momentMixin from '@/mixins/mixinMoment'
import mixinColors from '@/mixins/mixinColors'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import errorMixin from '@/mixins/mixinApiErrors'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftEditComponent',
  mixins: [
    ToastMixin,
    momentMixin,
    mixinColors,
    errorMixin,
    mixinColorsMarks
  ],
  data () {
    return {
      breakTime: null,
      breakTimeChoices: [
        { id: 0, name: this.$t('None') },
        { id: 10, name: this.$t('10 min') },
        { id: 15, name: this.$t('15 min') },
        { id: 20, name: this.$t('20 min') },
        { id: 30, name: this.$t('30 min') },
        { id: 45, name: this.$t('45 min') },
        { id: 60, name: this.$t('60 min') },
        { id: 90, name: this.$t('90 min') }
      ],
      latenessStatus: 'in_time',
      latenessValue: moment().startOf('day').toDate(),
      latenessStatusesList: [
        {
          value: 'in_time',
          label: this.$i18n?.t('In time')
        },
        {
          value: 'late',
          label: this.$i18n?.t('Late')
        },
        {
          value: 'absence',
          label: this.$i18n?.t('Absence')
        }
      ],
      earlyLeavingStatus: 'in_time',
      earlyValue: moment().startOf('day').toDate(),
      earlyLeavingStatusesList: [
        {
          value: 'in_time',
          label: this.$i18n?.t('In time')
        },
        {
          value: 'early',
          label: this.$i18n?.t('Early')
        }
      ],
      shiftMarks: [],
      startDateTime: null,
      endDateTime: null,
      timeoutKey: null,
      storedStartDateTime: null,
      shiftInfoState: {}
    }
  },
  props: [
    'shiftInfo',
    'shift',
    'schedulesById',
    'locations',
    'currentProjectData',
    'nameToExchange',
    'nameFromExchange',
    'weekOvertimeEmployeeToExchange',
    'weekOvertime',
    'closeModal',
    'shiftRequest'
  ],
  created () {
    this.shiftInfoState = this.shiftInfo
    this.startDateTime = this.shift.start
    this.endDateTime = this.shift.end
    this.storedStartDateTime = this.shift.start
    this.shiftMarks = Array.isArray(this.shiftInfoState.marks) ? this.shiftInfoState.marks : []
    this.breakTime = this.shiftInfoState.break_time
    if (this.shiftInfoState.presence_info) {
      // Absence / lateness
      if (this.shiftInfoState.presence_info.absence || this.shiftInfoState.presence_info.lateness > 0) {
        if (this.shiftInfoState.presence_info.absence) {
          this.latenessStatus = 'absence'
        } else {
          this.latenessStatus = 'late'
          this.latenessValue = moment(`0${this.shiftInfoState.presence_info.lateness / 60 ^ 0}`.slice(-2) + ':' + ('0' + this.shiftInfoState.presence_info.lateness % 60).slice(-2), 'HH:mm').toDate()
        }
      }
      // Early leaving
      if (this.shiftInfoState.presence_info.early && this.shiftInfoState.presence_info.early > 0) {
        this.earlyLeavingStatus = 'early'
        this.earlyValue = moment(`0${this.shiftInfoState.presence_info.early / 60 ^ 0}`.slice(-2) + ':' + ('0' + this.shiftInfoState.presence_info.early % 60).slice(-2), 'HH:mm').toDate()
      }
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId',
      projectMarks: 'projectMarks',
      preloadUsers: 'employeesIndexed'
    }),
    projectTimeZone () {
      const project = this.$store.getters.projectById(this.currentProjectId)
      const projectTz = moment(this.startDateTime).tz(project.time_zone).format('Z')
      return this.$i18n?.t('PROJECT_TIMEZONE') + ' ' + projectTz
    },
    overtimeTitle () {
      let message = ''
      if (this.weekOvertime.week_overtime) {
        message += this.$t('WEEK_OVERTIME_WARNING') + ' ' + this.$t('WEEK_WORKED_HRS') + ': ' + Math.round(this.weekOvertime.week_working_hours * 100) / 100 + ' ' + this.$t('WEEK_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertime.week_overtime * 100) / 100
      }
      if (this.weekOvertime.month_overtime) {
        message += ' ' + this.$t('MONTH_OVERTIME_WARNING') + ' ' + this.$t('MONTH_WORKED_HRS') + ': ' + Math.round(this.weekOvertime.month_working_hours * 100) / 100 + ' ' + this.$t('MONTH_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertime.month_overtime * 100) / 100
      }
      return message
    },
    overtimeExchangeTitle () {
      let message = ''
      if (this.weekOvertimeEmployeeToExchange.week_overtime) {
        message += this.$t('WEEK_OVERTIME_WARNING') + ' ' + this.$t('WEEK_WORKED_HRS') + ': ' + Math.round(this.weekOvertimeEmployeeToExchange.week_working_hours * 100) / 100 + ' ' + this.$t('WEEK_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertimeEmployeeToExchange.week_overtime * 100) / 100
      }
      if (this.weekOvertimeEmployeeToExchange.month_overtime) {
        message += ' ' + this.$t('MONTH_OVERTIME_WARNING') + ' ' + this.$t('MONTH_WORKED_HRS') + ': ' + Math.round(this.weekOvertimeEmployeeToExchange.month_working_hours * 100) / 100 + ' ' + this.$t('MONTH_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertimeEmployeeToExchange.month_overtime * 100) / 100
      }
      return message
    },
    blockChangeUser () {
      if (!this.shiftInfoState.bonuses) {
        return true
      }
      return this.shiftInfoState.bonuses.length > 0
    },
    durationMinutes () {
      return moment(this.endDateTime).diff(moment(this.startDateTime), 'minutes', true)
    },
    periodDuration () {
      // The function returns the actual duration of the shift in hours
      // without subtracting breaks, etc.
      let value = moment(this.endDateTime).diff(moment(this.startDateTime), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }
      return Math.round(value * 100) / 100
    },
    unpaidBreakTime () {
      let breakTime = 0

      // если стандартный пееррыв скрыт, то считаем модульные перерывы
      if (this.hideBreak) {
        this.shiftInfoState.breaks.forEach(element => {
          if (!element.billable) {
            // на апи не верно возвращает duration перерыва, потому считаем на лету
            breakTime += moment(element.time_to).diff(moment(element.time_from), 'minutes', true)
          }
        })
      } else if (!this.standardBreakIsPaid) {
        breakTime = this.breakTime
      }

      return breakTime
    },
    duration () {
      // Метод возвращает продолжительность дневного и ночного отрезка времени с вычетом перерывов
      const { isNightShift, nightTimeDuration, dayTimeDuration } = this.determineNightData(this.startDateTime, this.endDateTime)

      let value = 0

      if (isNightShift) {
        const nightCoefficientType = this.companySalary.night_coefficient_type !== undefined ? this.companySalary.night_coefficient_type : 'rate_increase'
        if (nightCoefficientType === 'time_recalculation') {
          // Используем множитель продолжительности ночного времени смены
          const nightCoefficient = this.companySalary.night_coefficient ? this.companySalary.night_coefficient : 0
          if (nightCoefficient > 0) {
            value = dayTimeDuration + (nightTimeDuration * nightCoefficient)
          } else {
            value = dayTimeDuration + nightTimeDuration
          }
        } else {
          value = dayTimeDuration + nightTimeDuration
        }
      } else {
        // смена не ночная, значит все время дневное, и без множителя
        value = dayTimeDuration
      }

      value = Math.round(value / 60 * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }

      return Math.round(value * 100) / 100
    },
    periodDurationHrsHuman () {
      let value = moment(this.endDateTime).diff(moment(this.startDateTime), 'hours', true)
      if (value === 0) {
        value = 24
      }

      if (value <= 0) {
        value = (24 + value)
      }

      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    durationHrsHuman () {
      const value = this.duration
      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    standardBreakIsPaid () {
      return this.companySalary.pay_breaks === undefined ? false : this.companySalary.pay_breaks
    },
    companySalary () {
      return this.$store.state.company.company.salary
    },
    usersBySchedule () {
      const usersBySchedule = {}
      const usersIds = this.schedulesById[this.shiftInfoState.schedule_id].users.map(item => item.employee_id)
      Object.keys(this.preloadUsers).forEach(key => {
        if (usersIds.includes(this.preloadUsers[key].id)) {
          usersBySchedule[key] = this.preloadUsers[key]
        }
      })
      return Object.values(usersBySchedule).sort((a, b) => {
        return a.last_name > b.last_name ? 1 : -1
      })
    },
    hideBreak () {
      return this.shiftInfoState.breaks && this.shiftInfoState.breaks.length > 0
    },
    plannedStartDateTime () {
      return moment(this.shift.extendedProps.plannedStart).format(this.localeDateTimeFormat)
    },
    plannedEndDateTime () {
      return moment(this.shift.extendedProps.plannedEnd).format(this.localeDateTimeFormat)
    },
    salaryForManager () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      return this.$store.state.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.state.company.company.salary.show_salary_to_managers
    },
    shiftEmployeeId () {
      return this.shift.extendedProps.employee_id
    },
    showButtons () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      const project = this.$store.getters.projectById(this.currentProjectId)
      const shift = moment(this.storedStartDateTime).format('YYYY-MM-DD hh:mm')
      const now = moment().tz(this.actualTZ).format('YYYY-MM-DD hh:mm')

      return !(project.block_change_in_past_for_manager === true && moment(shift).isBefore(now))
    },
    actualTZ () {
      return this.$store.getters.actualTZ
    }
  },
  watch: {
    'shiftInfoState.employee_id' (val) {
      if (Number.isInteger(val)) {
        this.$emit('updateOvertimeCheck')
      }
    },
    shiftMarks (items) {
      if (Array.isArray(items) && (this.isEmployeeHasPermission('create-shift-admin') ||
        this.rolesByProjects[this.currentProjectId] === 'manager')) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.currentProjectId]).then((mark) => {
              this.shiftMarks[newIndex] = mark.id
            }).catch(() => {
              this.shiftMarks.splice(newIndex, 1)
              this.toastError(this.$i18n?.t('The name has already been taken.'))
            })
          }
        })
      }
    },
    breakTime () {
      clearTimeout(this.timeoutKey)
      this.timeoutKey = setTimeout(() => {
        this.$emit('updateOvertimeCheck')
      }, 500)
    }
  },
  methods: {
    save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (this.weekOvertime.week_overtime && (this.weekOvertime.week_overtime_control_type === 'confirm_overtime' ||
            this.weekOvertime.week_overtime_control_type === 'block_overtime')) {
            const body = document.getElementsByTagName('body')
            body[0].setAttribute('style', 'padding-right: 0px;')
            this.$confirm(this.$i18n?.t('CONFIRM_WEEK_OVERTIME'), this.$i18n?.t('Are you sure?'), {
              confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
              cancelButtonText: this.$i18n?.t('No, cancel it!!'),
              type: 'warning',
              center: true
            }).then((result) => {
              // если есть реквест и меняется владелец шифта или время шифта
              if (this.shiftInfoState.request_id &&
                (+this.shiftEmployeeId !== +this.shiftInfoState.employee_id ||
                  this.startDateTime !== this.shift.start ||
                  this.endDateTime !== this.shift.end)) {
                const body = document.getElementsByTagName('body')
                body[0].setAttribute('style', 'padding-right: 0px;')
                this.$confirm(this.$i18n?.t('DECLINE_REQUEST_WARNING'), this.$i18n?.t('Warning'), {
                  confirmButtonText: this.$i18n?.t('Confirm'),
                  cancelButtonText: this.$i18n?.t('Cancel'),
                  type: 'warning',
                  center: true
                }).then(() => {
                  this.cancel(this.$i18n?.t('Canceled')).then(() => {
                    this.handleSave()
                  })
                }).catch(() => {})
              } else {
                this.handleSave()
              }
            }).catch(() => {})
          } else {
            if (this.shiftInfoState.request_id &&
              (+this.shiftEmployeeId !== +this.shiftInfoState.employee_id ||
                this.startDateTime !== this.shift.start ||
                this.endDateTime !== this.shift.end)) {
              const body = document.getElementsByTagName('body')
              body[0].setAttribute('style', 'padding-right: 0px;')
              this.$confirm(this.$i18n?.t('DECLINE_REQUEST_WARNING'), this.$i18n?.t('Warning'), {
                confirmButtonText: this.$i18n?.t('Confirm'),
                cancelButtonText: this.$i18n?.t('Cancel'),
                type: 'warning',
                center: true
              }).then(() => {
                this.cancel(this.$i18n?.t('Canceled')).then(() => {
                  this.handleSave()
                })
              }).catch(() => {})
            } else {
              this.handleSave()
            }
          }
        }
      })
    },
    cancel (reason) {
      return new Promise((resolve, reject) => {
        let data
        if (reason) {
          data = {
            subtype: this.shiftRequest.subtype,
            status: 'declined',
            message: reason
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftInfoState.request_id, data]).then(response => {
            this.shiftInfoState.request_id = null
            resolve(response)
          }).catch((e) => {
            reject(e)
          })
        } else {
          this.toastWarning(this.$i18n?.t('Enter reason'))
          resolve(false)
        }
      })
    },
    handleSave () {
      let params = {
        lateness: 0,
        early: 0,
        absence: false
      }
      if (this.latenessStatus === 'late') {
        const time = this.latenessValue.toTimeString().split(':').slice(0, 2).join(':')
        params = {
          lateness: moment.duration(time).asMinutes(),
          absence: false
        }
      }
      if (this.earlyLeavingStatus === 'early') {
        const time = this.earlyValue.toTimeString().split(':').slice(0, 2).join(':')
        params.early = moment.duration(time).asMinutes()
      }

      if (this.latenessStatus === 'absence') {
        params = {
          lateness: 0,
          early: 0,
          absence: true
        }
      }

      params.marks = this.shiftMarks
      params.employee_id = this.shiftInfoState.employee_id
      params.color = this.shiftInfoState.color
      params.location_id = this.shiftInfoState.location_id ? this.shiftInfoState.location_id : null
      if (this.shiftInfoState.rate) {
        params.rate = this.shiftInfoState.rate
      }
      params.break_time = +this.breakTime
      params.time_from = this.fromZoneToZone(moment(this.startDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      params.time_to = this.fromZoneToZone(moment(this.endDateTime).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)

      if (this.breaksAction) {
        params.breaks_action = this.breaksAction
      }
      params.note = this.shiftInfoState.note
      params.hide_note = this.shiftInfoState.hide_note

      this.breaksAction = null
      this.$store.dispatch('patchShift', [this.shift.id, params]).then(shift => {
        this.$eventBus.emit('refetchEventsLazy')
        this.toastSuccess(this.$i18n?.t('Shift have been successfully saved'))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((err) => {
        const errorMessage = err.response.data.message
        if (errorMessage === 'week overtime not allowed') {
          this.toastError(this.$i18n?.t('BLOCKED_BY_WEEK_OVERTIME'))
        } else if (errorMessage === 'Night shift restriction') {
          this.toastError(this.$i18n?.t('night_shift_restriction'))
        } else if (errorMessage === 'Shift is out of schedule range') {
          const link = `/c/${this.$store.getters.companyId}/schedule/${this.shift.extendedProps.schedule_id}#resizeSchedule`
          this.toastError(
            `<div>${this.$i18n?.t(errorMessage)}</div><div><a href="${link}">${this.$i18n?.t('LINK_TO_RESIZE_SCHEDULE')}</a></div>`,
            true
          )
        } else if (errorMessage === 'Breaks outside the shift') {
          this.$confirm(this.$i18n?.t('key_warning_breaks_outside_shift'), this.$i18n?.t('Warning'), {
            confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
            cancelButtonText: this.$i18n?.t('No, cancel it!!'),
            type: 'warning',
            center: true
          }).then(() => {
            this.breaksAction = 'skip'
            this.handleSave()
          }).catch(() => {})
        } else if (err.response.data.errors && Object.keys(err.response.data.errors).length > 0) {
          this.toastError(this.$i18n?.t(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]))
          document.getElementById('modalCloseButton').click()
          this.closeModal()
        } else if (errorMessage === 'Shift is overlapping') {
          this.toastError(this.$i18n?.t('Shift is overlapping'))
        } else {
          this.toastError(this.$i18n?.t(errorMessage))
          document.getElementById('modalCloseButton').click()
        }
      })
    },
    deleteShift () {
      const message = this.shiftInfoState.request_id ? this.$i18n?.t('DELETE_WITH_REQUEST') : this.$i18n?.t('Are you sure?')
      const body = document.getElementsByTagName('body')
      body[0].setAttribute('style', 'padding-right: 0px;')
      this.$confirm(message, this.$i18n?.t('Warning'), {
        confirmButtonText: this.$i18n?.t('Ok'),
        cancelButtonText: this.$i18n?.t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        if (this.shiftInfoState.request_id) {
          this.cancel(this.$i18n?.t('Canceled'))
        }
        this.$store.dispatch('deleteShift', this.shiftInfoState.id).then(() => {
          const ids = [this.shiftInfoState.id]
          if (this.shiftInfoState.breaks) {
            this.shiftInfoState.breaks.forEach(breakItem => {
              ids.push('break__sp__' + breakItem.id)
            })
          }
          this.$eventBus.emit('deleteShift', ids)
          this.toastSuccess(this.$t('Shift successfully deleted'))
          this.closeModal()
          document.getElementById('modalCloseButton').click()
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
          this.closeModal()
          document.getElementById('modalCloseButton').click()
        })
      }).catch(() => {})
    },
    putOnFreeBoard () {
      if (this.isEmployeeHasPermission('create-shift-admin') ||
        this.rolesByProjects[this.currentProjectId] === 'manager') {
        this.assignShift()
      }
    },
    assignShift () {
      // всегда админ или менеджер
      if (this.shiftInfoState.request_id) {
        const body = document.getElementsByTagName('body')
        body[0].setAttribute('style', 'padding-right: 0px;')
        this.$confirm(this.$i18n?.t('DECLINE_REQUEST_WARNING'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Confirm'),
          cancelButtonText: this.$i18n?.t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          return this.handleAssign()
        }).catch(() => {})
      } else {
        return this.handleAssign()
      }
    },
    handleAssign () {
      const data = {
        employee_id: 0
      }
      return this.$store.dispatch('patchShift', [this.shift.id, data]).then((shift) => {
        this.$eventBus.emit('assignShiftToFreeBoard', shift)
        this.toastSuccess(this.$i18n?.t('Shift successfully put on the Free Board'))
        this.closeModal()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
        this.closeModal()
      })
    },
    determineNightData (timeFrom, timeTo) {
      timeFrom = moment(timeFrom)
      timeTo = moment(timeTo)

      const duration = moment(timeTo).diff(moment(timeFrom), 'minutes', true)
      const breakTime = this.unpaidBreakTime

      const nightTimes = this.getNightTimes(timeFrom, timeTo)
      if (!nightTimes.length) {
        return { isNightShift: false, nightTimeDuration: 0, dayTimeDuration: duration - breakTime }
      }

      const nightShiftDefinitionType = this.companySalary.night_shift_definition_type !== undefined ? this.companySalary.night_shift_definition_type : 'full_entry'
      const nightShiftDefinitionTime = parseInt(this.companySalary.night_shift_definition_time !== undefined ? this.companySalary.night_shift_definition_time : 0)
      const nightTimeTrackingType = this.companySalary.night_time_tracking_type !== undefined ? this.companySalary.night_time_tracking_type : 'all_time'

      let isNight = false
      let nightTimeDuration = 0
      let dayTimeDuration = duration - breakTime

      if (nightTimes.length) {
        // смена должна полностью входить в отрезок ночного времени
        if (nightShiftDefinitionType === 'full_entry') {
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeTo <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration - breakTime
              dayTimeDuration = 0
            }
          })
        } else if (nightShiftDefinitionType === 'intersects_beginning_shift') {
          // начало смены должно входить в отрезок ночного времени
          // проходимся по всем дням которые затронул шифт
          nightTimes.forEach(element => {
            if (isNight) return
            if (timeFrom >= element[0] && timeFrom <= element[1]) {
              isNight = true
              // все время смены уходит в ночное
              nightTimeDuration = duration - breakTime
              dayTimeDuration = 0
            }
          })
        } else {
          // общее пересечение смены с ночным временем должно составлять N часов, чтобы
          // смена считалась как ночная
          const overlappingNightShiftTime = this.calculateOverlapping(timeFrom, timeTo, nightTimes)

          // так же в местах пересечения смены с ночным временем необходимо отнять время
          // пересекающихся неоплачиваемых перерывов
          let overlappingBreaksWithNightTime = 0
          let overlappingBreaksWithDayTime = 0

          // модульный перерыв
          if (this.hideBreak) {
            this.shiftInfoState.breaks.forEach(element => {
              if (!element.billable) {
                const breakFrom = moment(element.time_from)
                const breakTo = moment(element.time_to)
                const breakDuration = moment(breakTo).diff(moment(breakFrom), 'minutes', true)
                const nightDuration = this.calculateOverlapping(breakFrom, breakTo, nightTimes)

                // время перерыва пересекается с ночным временем
                if (nightDuration > 0) {
                  // все время перерыва в ночном отрезке
                  if (breakDuration === nightDuration) {
                    overlappingBreaksWithNightTime += nightDuration
                  } else {
                    // часть перерыва в ночном времени
                    overlappingBreaksWithNightTime += nightDuration
                    // и часть перерыва в дневном времени
                    overlappingBreaksWithDayTime += breakDuration - nightDuration
                  }
                } else {
                  // время перерыва не пересекается с ночным временем,
                  // все время перерыва идет в дневное время
                  overlappingBreaksWithDayTime += breakDuration
                }
              }
            })
          }

          // продолжительность пересечения с ночным временем, с вычетом неоплачиваемых перерывов
          const totalNightTime = overlappingNightShiftTime - overlappingBreaksWithNightTime
          if (totalNightTime && totalNightTime / 60 >= nightShiftDefinitionTime) {
            isNight = true
            // смена определена как ночная, теперь нужно учитывать, вся смена идет как ночная,
            // или только пересечение
            if (nightTimeTrackingType === 'all_time') {
              nightTimeDuration = duration - breakTime
              dayTimeDuration = 0
            } else {
              nightTimeDuration = overlappingNightShiftTime - overlappingBreaksWithNightTime
              dayTimeDuration = duration - overlappingNightShiftTime - overlappingBreaksWithDayTime
            }
          } else {
            dayTimeDuration = duration - breakTime
          }
        }
      }

      return { isNightShift: isNight, nightTimeDuration: nightTimeDuration, dayTimeDuration: dayTimeDuration }
    },
    getOverlap (fromX, toX, fromY, toY) {
      let overlapFrom = fromY
      if (fromX > fromY) {
        overlapFrom = fromX
      }

      let overlapTo = toX
      if (toX > toY) {
        overlapTo = toY
      }

      return moment.duration(overlapTo.diff(overlapFrom)).asMinutes()
    },
    calculateOverlapping (start, end, ranges) {
      let overlaps = 0

      // order ranges by start time
      ranges = ranges.sort((a, b) => a[0].unix() - b[0].unix())

      for (let i = 0; i < ranges.length; i++) {
        const rangeStart = ranges[i][0]
        const rangeEnd = ranges[i][1]
        if (rangeEnd.isBefore(start) || rangeStart.isAfter(end)) {
          continue
        }
        overlaps += this.getOverlap(start, end, rangeStart, rangeEnd)
      }
      return overlaps
    },
    getNightTimes (timeFrom, timeTo) {
      // В любом случаи от timeFrom минусуем 1 день так как одна смена
      // может 2-а раза пересекаться с ночным времен и нужно проверить все пересечения.
      // К примеру в настройка компании ночное время с 22:00 до 00:08,
      // смена 25.07.2023 02:00 до 25.07.2023 23:30, в итоге смена 2-а раза пересекается
      // с ночным временем, а именно:
      //  - c 25.07.2023 02:00 до 25.07.2023 08:00,
      //  - c 25.07.2023 22:00 до 25.07.2023 23:30
      const range = moment.range(timeFrom.clone().add(-1, 'days'), timeTo.clone().add(1, 'days'))

      // если не указаны сеттинги возвращаем пустой массив
      if (!this.companySalary.night_start || !this.companySalary.night_end) {
        return []
      }

      const nightFrom = moment(this.companySalary.night_start, 'H:mm')
      const nightTo = moment(this.companySalary.night_end, 'H:mm')

      // форматируем время по каждому дню
      const times = Array.from(range.by('days')).map((m, index) => {
        const start = m.clone().set('hour', nightFrom.hour()).set('minute', nightFrom.minute())
        const end = m.clone().set('hour', nightTo.hour()).set('minute', nightTo.minute())

        // переходящая смена, добавляем +1 день к дате окончания
        if (end < start) {
          end.add(1, 'days')
        }
        return [start, end]
      })

      return times
    }
  }
}
</script>

<style scoped>
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 30px;
    height: 30px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 14px;
    left: 11px;
    top: 4px;
  }
</style>
