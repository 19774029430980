<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="`/c/${companyId}/checklists`">
                <span class="breadcrumb-item">{{ $t('Checklists')}}</span>
              </router-link>
            </li>
            <li v-if="this.$route.params['checklistId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_CHECKLIST")}}
              <ui-hint :content="$t('CHECKLIST_HINTS')" />
            </li>
            <li v-if="!this.$route.params['checklistId']" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_CHECKLIST")}}
              <ui-hint :content="$t('CHECKLIST_HINTS')" />
            </li>
          </ol>
        </nav>
      </div>
      <div class="card card-small">
        <Form ref="observer" as="div" class="card-body">
          <div class="row align-items-end">
            <div class="col-12 col-md-4">
              <ui-text-input
                  id="title"
                  name="title"
                  :label="$t('CHECKLIST_TITLE')"
                  v-model="title"
                  :validation="{required: true}"
              />
            </div>
            <div class="col-12 col-md-4 pt-2 my-auto">
              <ui-switch
                  v-model="is_default"
                  @change="val => is_default = val"
                  :active-text="$t('CHECKLIST_DEFAULT')"
                  :inactive-text="''"
              />
            </div>
          </div>
          <div class="title-text text-primary my-2">
            {{$t('CHECKLIST_ITEMS_TITLE')}}
          </div>
          <div v-for="(item, item_key) in items" :key="item_key" :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}" class="p-1 rounded">
            <item
              :name="item_key.toString()"
              :item="item"
              :showSearchable="false"
              :showItemConfig="false"
              :to-hide="['date_range_picker', 'date_picker']"
              @deleteItem="deleteItem(item_key)"
            />
          </div>
          <div class="row my-2">
            <div class="col-sm d-flex">
              <button class="btn btn-outline-primary rounded-pill shadow-sm m-1" aria-label="Close" @click="addItem">
                <i class="icon-plus"></i>
              </button>
              <button class="btn btn-success rounded-pill shadow-sm my-1 ml-auto" aria-label="Close" @click="save">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import Item from '@/components/Tasks/Checklist/Item'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Checklist',
  mixins: [errorMixin, TasksAccessMixin, mixinDummy, ToastMixin],
  components: { Item, DummyPaymentRequired },
  data () {
    return {
      title: null,
      is_default: false,
      items: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      if (!this.isEmployeeHasPermission('create-checklists')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      if (this.$route.params.checklistId) {
        this.$store.dispatch('getChecklist', [this.companyId, this.$route.params.checklistId]).then((response) => {
          this.title = response.title
          this.is_default = response.is_default
          this.items = response.items
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
        }).catch((error) => {
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      } else {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        this.addItem()
      }
    },
    deleteItem (itemKey) {
      this.items.splice(+itemKey, 1)
    },
    addItem () {
      this.items.push({
        id: null,
        title: null,
        checklist_id: this.$route.params.checklistId,
        field_type: 'text',
        required: true,
        type_options: null
      })
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (this.items.length < 1) {
          this.errors.add({
            field: 'items_length',
            msg: 'items required'
          })
        }
        if (result.valid && this.items.length > 0) {
          const payload = {
            title: this.title,
            items: this.items,
            is_default: this.is_default
          }
          if (this.$route.params.checklistId) {
            this.$store.dispatch('editChecklist', [this.companyId, this.$route.params.checklistId, payload]).then((response) => {
              this.toastSuccess(this.$t('Checklist updated'))
            }).catch(() => {})
          } else {
            this.$store.dispatch('createChecklist', [this.companyId, payload]).then((response) => {
              this.toastSuccess(this.$t('Checklist updated'))
              this.$router.push(`/c/${this.companyId}/checklist/${response.id}`)
            }).catch(() => {})
          }
        }
      })
    }
  }
}
</script>

<style>
</style>
