<template>
  <div class="row align-items-center bg-light py-1 my-1" v-if="isEnable && activities.length > 0">
    <template v-for="activity in activities" :key="activity.id">
      <div class="col-auto text-left card mx-1">
        <div class="row align-items-center">
          <div class="col my-1">
            <div class="mb-1">
              <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
              <span class="mx-1">{{activity.title}}</span>
            </div>
            <div v-if="$store.getters.projectById(activity.project_id)">
              <i role="button" class="icon-calendar mr-1" /> {{ $store.getters.projectById(activity.project_id).name }}
            </div>
            <div v-if="$store.getters.scheduleById(activity.schedule_id)">
              <i role="button" class="icon-calendar mr-1" /> {{ $store.getters.scheduleById(activity.schedule_id).name }}
            </div>
            <div>
              {{getTime(activity.time_from)}} - {{getTime(activity.time_to)}}
            </div>
          </div>
          <div class="col-auto align-content-end">
            <button
                type="submit"
                @click="updateActivity(activity)"
                :class="activity.next_statuses[0].check_in ? 'btn-outline-success' : 'btn-outline-danger'"
                class="btn btn-sm rounded-pill shadow-sm m-1">
              <template v-if="activity.next_statuses[0].check_in">
                {{ $t('START_ACTIVITY') }}
              </template>
              <template v-if="activity.next_statuses[0].check_out">
                {{ $t('FINISH_ACTIVITY') }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
export default {
  name: 'ActivityDashboardComponent',
  mixins: [momentMixin],
  data () {
    return {
      isEnable: false,
      activities: []
    }
  },
  created () {
    this.getActivities()
  },
  mounted () {
    this.$eventBus.on('reloadDummy', (emiter) => {
      if (emiter === 'activities') {
        this.getActivities()
      }
    })
    this.$eventBus.on('updateActivityStatus', _ => {
      this.getActivities()
    })
  },
  beforeUnmount () {
    this.$eventBus.off('reloadDummy')
    this.$eventBus.off('updateActivityStatus')
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    getActivities () {
      this.isEnable = false
      this.$store.dispatch('getPersonalActivity', this.companyId).then(response => {
        this.isEnable = true
        this.activities = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.isEnable = false
        }
      })
    },
    updateActivity (activity) {
      const payload = {
        id: activity.id,
        next_status_id: activity.next_statuses[0].id
      }
      this.$store.dispatch('patchPersonalShiftActivity', [this.companyId, payload]).then(response => {
        this.toastSuccess(this.$i18n?.t('ACTIVITY_UPDATED'))
        this.getActivities()
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    getTime (time) {
      return this.fromZoneToZone(time, 'UTC', this.tz).format(this.localeTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
