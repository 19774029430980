<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <TaskModal
      v-if="taskModalShow"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      @closeTaskModal="closeTaskModal"
      :title="'TASK_EVENT_MODAL'"
    />
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <tab-navigation-component :tabs="tabs"></tab-navigation-component>
          <div class="row mt-1">
            <div class="col-12">
              <div class="text-primary text-uppercase font-weight-bold m-1">
                {{ $t("Tasks") }}
                <ui-hint :content="$t('TASKS_HINTS')" />
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12 col-lg-4">
              <Form ref="observer" as="div" class="form-inline">
                <ui-text-input
                    id="search-task"
                    name="search-task"
                    :placeholder="$t('SEARCH_TASKS_PLACEHOLDER')"
                    v-model='searchQuery'
                    :validation="{required: false, min: searchQuery.length ? 3 : null}"
                    v-on:keyup.enter="searchTasks"
                />
                <el-button
                    class="mx-1 mb-1"
                    type="primary"
                    @click="searchTasks"
                    icon="Search">
                </el-button>
              </Form>
            </div>
            <DateNavigationBar
                :hideDateButton="activeTab === 'backlog'"
                :hideAddTaskButton="false"
                @changeDate="changePeriod"
                @addTask="addTask"
            />
          </div>
          <ul class="nav nav-tabs">
            <li v-for="(title, key) in tabsData" :key="key" class="nav-item">
              <a
                :href="'#' +  key"
                class="nav-link"
                :class="{active: activeTab === key}"
                data-toggle="pill"
                role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="handleClick(key)">
                {{ $t(title) }}
              </a>
            </li>
          </ul>
          <tasks-table-component
            :isBank="false"
            :statusesData="statusesData"
            @reload="init">
          </tasks-table-component>
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
            >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import moment from 'moment'
import TasksTableComponent from '@/components/Tasks/Task/Components/TasksTableComponent'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import mixinDummy from '@/mixins/mixinDummy'
import DateNavigationBar from '@/components/Tasks/CommonComponents/DateNavigationBar.vue'

export default {
  name: 'Tasks',
  components: { DateNavigationBar, TaskModal, TasksTableComponent, TabNavigationComponent: TabNavigationComponent, DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy],
  data () {
    return {
      list: [],
      searchQuery: '',
      tabs: [],
      activeTab: 'active',
      tabsData: {
        active: 'Active',
        completed: 'Completed',
        backlog: 'Backlog'
      },
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      period: [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)],
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      taskModalShow: false,
      taskModalData: null,
      statusesData: []
    }
  },
  created () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'active'
    if (this.companyId) {
      this.getStatuses()
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    firstDayOfWeek () {
      return +this.$store.getters.company.salary.week_start + 1
    }
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.getStatuses()
      this.init()
      this.setTubs()
    }
  },
  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'active'
  },
  methods: {
    getStatuses () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then(result => {
        const statusesData = []
        result.forEach(item => {
          statusesData.push({
            text: item.title,
            value: item.title
          })
        })
        this.statusesData = statusesData
      })
    },
    changePeriod (val) {
      this.period = val
      this.init(true)
    },
    searchTasks () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            search: this.searchQuery,
            status: this.activeTab,
            page: this.pagination.currentPage,
            hide_subtasks: true
          }
          if (this.activeTab !== 'backlog') {
            data.date_from = this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat)
            data.date_to = this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
          }
          const payload = '?' + this.stringifyObject(data)
          this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
            this.list = response.data
            this.pagination.currentPage = response.current_page
            this.pagination.total = response.total
          }).catch(() => {})
        }
      })
    },
    handleClick (tab) {
      this.activeTab = tab
      window.location.hash = tab
      this.init()
    },
    setTubs () {
      this.tabs = [
        {
          link: '#active',
          title: 'Tasks',
          isActive: true
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange () {
      this.init()
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    init (fromFirstPage = false) {
      const data = {
        status: this.activeTab,
        page: fromFirstPage ? 1 : this.pagination.currentPage,
        hide_subtasks: true
      }
      if (this.activeTab !== 'backlog') {
        data.date_from = this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat)
        data.date_to = this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    addTask () {
      this.taskModalData = {
        id: null,
        title: null,
        description: null,
        client_id: null,
        client: null,
        client_name: this.name,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: []
      }
      this.taskModalShow = true
    },
    closeTaskModal () {
      this.taskModalShow = false
      this.taskModalData = null
      // this.init()
      const elements = document.getElementsByClassName('pac-container')
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0])
      }
    }
  }
}
</script>

<style scoped>

</style>
