<template>
  <ui-tooltip
      :show-after="openDelay"
      class="w-100">
    <template #hint>
      <div>{{tooltip}}</div>
      <div v-if="event.extendedProps.item.project" class="mt-1">
        {{$t('PROJECT_NAME')}}: {{event.extendedProps.item.project}}
      </div>
      <div v-if="event.extendedProps.item.schedule" class="mt-1">
        {{$t('SCHEDULE_NAME')}}: {{event.extendedProps.item.schedule}}
      </div>
      <div v-if="event.extendedProps.item.location" class="mt-1">
        {{$t('LOCATION_NAME')}}: {{event.extendedProps.item.location}}
      </div>
      <div v-if="event.extendedProps.item.position" class="mt-1">
        {{$t('POSITION_NAME')}}: {{event.extendedProps.item.position}}
      </div>
      <template v-for="mark in event.extendedProps.item.marks" :key="mark.id">
        <div class="mt-1">
          <span class="project-color" :style="{ backgroundColor: mark.color }"></span> <span>{{mark.name}}</span>
        </div>
      </template>
    </template>
    <template #inner-html>
      <div :style="{
            'border-left-color': statusColor,
            'border-left-width': '8px',
            'border-left-style': 'solid'
         }"
           class="fc-content py-1 text-truncate mb-0"
           data-toggle="modal"
           data-target="#ClickShiftActivityModal"
           style="cursor: pointer">
        <div style="position: relative">
          <div>
            <span class="ml-1 overflow-hidden">{{ title }}</span>
          </div>
        </div>
      </div>
    </template>
  </ui-tooltip>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment/moment'
import mixinStatusColors from '@/mixins/mixinStatusColors'

export default {
  name: 'ShiftActivityItem',
  mixins: [momentMixin, mixinStatusColors],
  data () {
    return {
      openDelay: 1000,
      tags: [],
      markTemplate: '',
      tooltipEnable: true
    }
  },
  props: {
    event: Object,
    lang: String
  },
  computed: {
    statusColor () {
      return this.statusColors[this.event.extendedProps.status]
    },
    start () {
      const start = new Date(this.event.start)
      return moment.parseZone(start)
    },
    end () {
      const end = new Date(this.event.end)
      return moment.parseZone(end)
    },
    tooltip () {
      return this.event.title + ' ' + this.start.format(this.localeTimeFormat) + ' - ' + this.end.format(this.localeTimeFormat)
    },
    title () {
      let title = this.event.title + ' ' + this.start.format(this.localeTimeFormat) + ' - ' + this.end.format(this.localeTimeFormat)
      if (this.event.extendedProps.counter && this.event.extendedProps.counter > 0) {
        title = title + ' ' + `(${this.event.extendedProps.counter})`
      }
      return title
    }
  }
}
</script>
<style scoped>
</style>
