// свойства, которые можно передать в компонент:
// :userId = Id пользователя - если не передано, в зависимости от других параметров, будет подставляться заглушка или будут выводиться инициалы пользователя
// :width = number - ширина изображения в px - обязательный параметр
// :borderRadius = number - скругление углов в % - обязательный параметр
// :cache = true/false - кэшировать или не кэшировть запрос, по умолчанию true
// :placeholder = true/false - использовать или нет изображение-загушку для аватара, если false, то на аватар будут выводиться инициалы пользователя
// :placeholderUrl = url - ссылка на заглушку, обязательный параметр, если placeholder = true
// :mini = true/false - обычный аватар или мини - false по умолчанию
// :fontSize = number - размер шрифта иницалов в px, по умолчанию - 13px
// :userName = string - полное имя пользователя для получения инициалов (делится по пробелу), если не задано - берется из localStorage (текущий пользователь)
<template>
  <div id="avatar_image_container">
    <div style="max-width: 100%; height: auto; display: block; margin: 0 auto; overflow: hidden" :style="{'width': width + 'px', 'border-radius': borderRadius + '%'}">
      <img v-if="image" :src="image.url" style="max-width: 100%" :style="{'width': width  + 'px'}" class="m-0 m-auto d-block h-auto"/>
      <img v-else-if="placeholder" @error="linkBroken" rel="prefetch" :src="placeholderUrl" style="max-width: 100%" :style="{'width': width  + 'px'}" class="m-0 m-auto d-block h-auto"/>
      <b v-else class="rounded-circle d-inline-block font-weight-normal text-uppercase"
         :style="{'width': width + 'px', 'height': width + 'px', 'line-height': width + 'px', 'border-radius': borderRadius + '%', 'font-size': font_size + 'px'}"
         style="background-color: #e7eaef; color: #333; text-align: center;">{{noPlaceholder()}}
      </b>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Avatar',
  data () {
    return {
      avatar_url: null,
      image: null,
      font_size: '',
      caching: true
    }
  },
  props: ['width', 'borderRadius', 'userId', 'appId', 'companyIdForAvatar', 'placeholderUrl', 'mini', 'placeholder', 'fontSize', 'cache', 'userName'],
  created () {
    this.getAvatar()
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    }
  },
  methods: {
    linkBroken () {
      this.avatar_url = this.placeholderUrl
    },
    noPlaceholder () {
      // console.log(1, this.$store.getters.Profile, this.userName)
      // console.log(this.userName)
      if (!this.appId) {
        let name = this.$store.getters.userName
        if (this.userName) {
          name = this.userName
        }
        let out = ''
        if (name) {
          const names = name.split(' ')
          names.forEach(function (item) {
            out = out + item[0]
          })
        }
        return out
      }

      return ''
    },
    getAvatar () {
      if (this.userId) {
        let suffix = ''
        if (this.mini === true) {
          suffix = '/avatar_mini'
        } else {
          suffix = '/avatar'
        }
        this.avatar_url = 'users/' + this.userId + suffix
        if (this.cache === false) {
          this.caching = false
        }
        const data = {
          avatarUrl: this.avatar_url,
          cache: this.caching
        }
        this.$store.dispatch('getAvatarImg', data).then(img => {
          this.image = img
        }).catch(() => {})
        if (!this.fontSize) {
          this.font_size = '13'
        } else {
          this.font_size = this.fontSize
        }
      } else if (this.companyIdForAvatar) {
        let suffix = ''
        if (this.mini === true) {
          suffix = '/avatar_mini'
        } else {
          suffix = '/avatar'
        }
        this.avatar_url = 'companies/' + this.companyId + suffix
        if (this.cache === false) {
          this.caching = false
        }
        const data = {
          avatarUrl: this.avatar_url,
          cache: this.caching
        }
        this.$store.dispatch('getCompanyAvatarImg', data).then(img => {
          this.image = img
        }).catch(_ => {})
        if (!this.fontSize) {
          this.font_size = '13'
        } else {
          this.font_size = this.fontSize
        }
      } else if (this.appId) {
        let dispatchMethod = 'getDeveloperAppIcom'
        if (this.mini === true) {
          dispatchMethod = 'getDeveloperAppIcomMini'
        }
        this.$store.dispatch(dispatchMethod, [this.companyId, this.appId, this.cache]).then(img => {
          if (img.url) {
            this.image = img
          }
        }).catch(() => {})
        if (!this.fontSize) {
          this.font_size = '13'
        } else {
          this.font_size = this.fontSize
        }
      }
    }
  },
  watch: {
    // cache: function () {
    //   if (this.cache === false) {
    //     this.getAvatar()
    //     // this.caching = true
    //   }
    // },
    userId () {
      this.getAvatar()
    },
    companyId () {
      if (this.companyId) {
        this.image = null
        this.caching = false
        this.getAvatar()
      }
    }
  }
}
</script>

<style scoped>

</style>
