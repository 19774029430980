<template>
  <modal-component :id="'ClickTaskModal'" :modalClass="'modal-xl'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <dummy-payment-required
          v-if="dummy"
          @activate="getTableDataActivated()"
          :dummyInfo="dummyInfo">
        </dummy-payment-required>
        <div v-if="task.parent_id" class="mb-2 text-warning text-uppercase font-weight-bold">
          {{ $t('SUBTASK_WARNING_TITLE') }}
        </div>
        <Form ref="observer" as="div" class="row">
          <div class="col-lg-2" v-if="taskId">
            <div class="row">
              <div class="nav nav-tabs col-12 flex-column pb-2" id="tabs-vertical" role="tablist"
                   aria-orientation="vertical">
                <a v-for="(title, key) in tabsData" :key="key"
                   :href="'#' +  key"
                   class="nav-link p-1 bg-modal"
                   :class="{active: activeTab === key}"
                   data-toggle="pill" role="tab"
                   :aria-controls="'v-pills-' + key" aria-selected="true"
                   @click="activeTab = key">
                  {{ $t(title) }}
                </a>
              </div>
            </div>
          </div>
          <div :class="taskId ? 'col-lg-10' : 'col-lg-12'">
            <div class="tab-content">
              <template v-if="activeTab === 'main'">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="pb-2">
                      <ui-text-input
                        id="title"
                        name="title"
                        :label="$t('Title')"
                        v-model="task.title"
                        :disabled="!!task.parent_id"
                        :validation="{required: true, min: 3, max: 128, regex: /[?$<>{}*^=\p{L}\d]/u}"
                      />
                    </div>
                    <div class="pb-2">
                      <ui-textarea-input
                        id="description"
                        name="description"
                        :label="$t('Description')"
                        v-model="task.description"
                        :validation="{required: false}"
                        :rows="5"
                      />
                    </div>
                    <div>
                      <div class="row pb-2">
                        <div class="col mx-1">
                          <ui-radio
                            name="existing"
                            :label="$t('existing client')"
                            v-model="clientType"
                            :set-value="'existing'"
                            :disabled="isDisabled"
                          />
                        </div>
                        <div class="col mx-1">
                          <ui-radio
                            name="new"
                            :label="$t('new client')"
                            v-model="clientType"
                            :set-value="'new'"
                            :disabled="isDisabled"
                          />
                        </div>
                      </div>
                      <template v-if="clientType === 'existing'">
                        <ui-autocomplete
                          :label="$t('Client')"
                          v-model="task.client_name"
                          :disabled="isDisabled"
                          :containerClass="'w-100'"
                          :name="'client'"
                          :validation="{required: false, min: 3}"
                          :querySearchAsync="querySearchClientAsync"
                          :placeholder="$t('Please input')"
                          @select="handleSelectClient"
                        />
                      </template>
                      <template v-if="clientType === 'new' || (clientType === 'existing' && task.client_id)">
                        <div class="row">
                          <div class="col-lg-6 pb-2">
                            <ui-text-input
                              id="first_name"
                              name="first_name"
                              :label="$t('First name')"
                              v-model="first_name"
                              :validation="{required: true, min: 1}"
                            />
                          </div>
                          <div class="col-lg-6 pb-2">
                            <ui-text-input
                              id="last_name"
                              name="last_name"
                              :label="$t('Last name')"
                              v-model="last_name"
                              :validation="{required: true, min: 1}"
                            />
                          </div>
                        </div>
                        <div class="pb-2">
                          <label class="control-label font-weight-bold">{{ $t("Address") }}
                          </label>
                          <gmv-autocomplete
                            id="autocomplete"
                            class="form-control w-100"
                            :placeholder="$t('Enter search address')"
                            @focus="focusAutocompate"
                            @change="focusAutocompate"
                            :value="address"
                            @place_changed="setPlace">
                          </gmv-autocomplete>
                        </div>
                        <div class="pb-2">
                          <ui-text-input
                            id="name"
                            name="name"
                            :label="$t('Name')"
                            :hint="$t('CLIENT_NAME_HINT')"
                            v-model="name"
                            :validation="{required: true, min: 1}"
                          />
                        </div>
                        <div class="row">
                          <div class="col-lg-6 pb-2">
                            <ui-text-input
                              id="phone"
                              name="phone"
                              :label="$t('Phone')"
                              v-model="phone"
                              :validation="{required: false, min: 9}"
                            />
                          </div>
                          <div class="col-lg-6 pb-2">
                            <ui-text-input
                              id="email"
                              name="email"
                              :label="$t('Email')"
                              v-model="email"
                              :validation="{email: true}"
                            />
                          </div>
                          <div class="col-lg-12 pb-2">
                            <ui-textarea-input
                              id="notes"
                              name="notes"
                              :label="$t('Notes')"
                              v-model="notes"
                              :validation="{required: false}"
                              :rows="2"
                            />
                          </div>
                        </div>
                        <additional-fields-component
                          :deleted-items="deletedClientInfo"
                          :disabled="false"
                          @proceedSave="handleWithNewClient"
                          :items="clientInfo"
                        />
                      </template>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="pb-2">
                      <ui-number-input
                        id="lead_time"
                        name="lead_time"
                        :precision="2"
                        :step="0.1"
                        :min="0"
                        :label="$t('Lead time (in hrs)')"
                        v-model="task.lead_time"
                        :validation="{decimal: true}"
                        :disabled="task.children.length > 0"
                      />
                    </div>
                    <div class="pb-2">
                      <ui-datetime-picker
                        :label="$t('Scheduled time')"
                        name="scheduled_time"
                        :placeholder="$t('Pick a date time')"
                        v-model="scheduled_time"
                        @change="disableShifts"
                        :validation="{required: false}"
                        :clearable="true"
                        :picker-options="{firstDayOfWeek: +$store.getters.company.salary.week_start + 1}"
                      />
                    </div>
                    <div class="pb-2">
                      <ui-tags-select
                        name="marks"
                        :label="$t('TASK_MARK')"
                        v-model="task.marks"
                        :options="taskMarksList"
                        :validation="{required: false}"
                        :key-name="'id'"
                        :value-name="'id'"
                        :label-name="'name'"
                        :placeholder="$t('Select')"
                        :filterable="true"
                        :allow-create="true"
                        @update:model-value="updateMarks"
                      >
                        <template v-slot:default="slotProps">
                          <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                          {{ slotProps.option.name }}
                        </template>
                      </ui-tags-select>
                    </div>
                    <div class="pb-2">
                      <ui-select
                        id="checklist_id"
                        name="checklist_id"
                        :label="$t('Checklist')"
                        v-model="task.checklist_id"
                        :clearable="true"
                        :filterable="true"
                        class="w-100"
                        :options="checklists"
                        :key-name="'id'"
                        :label-name="'title'"
                        :value-name="'id'"
                      />
                    </div>
                    <div class="pb-2">
                      <ui-select
                        id="schedule_id"
                        name="schedule_id"
                        :label="$t('Schedule')"
                        :disabled="!!task.parent_id"
                        v-model="task.schedule_id"
                        class="w-100"
                        :options="schedules"
                        :key-name="'id'"
                        :label-name="'name'"
                        :value-name="'id'"
                        :clearable="true"
                        :validation="{ required: true }"
                        @change="disableShifts"
                      />
                    </div>
                    <div v-if="loaded && task.shift_id && task.shift" class="pb-2">
                      <div class="my-1">
                        {{ $t('ATTACHED_SHIFT_INFO') }}
                      </div>
                      <div class="my-1">
                        {{ from(task.shift.time_from) }} - {{ to(task.shift.time_to) }}
                        {{ task.shift.employee ? task.shift.employee.full_name : $t('FREE_BOARD_EMPLOYEE_TITLE') }}
                      </div>
                    </div>
                    <template v-if="loaded && subtask">
                      <SubtasksInfoComponent
                        :isParentInfo="!!this.taskModalDataState.parent_id"
                        :subtasks="subtask"
                        @deleted="deleteSubtask"
                        @taskUpdated="$emit('taskUpdated')"
                        :tz="tz"
                      />
                    </template>
                    <div v-if="!task.parent_id" class="py-2">
                      <button
                        v-if="!showShifts"
                        :disabled="!task.schedule_id"
                        @click="switchShowShifts"
                        class="btn btn-primary btn-circle shadow-sm">
                        {{ $t('EMPLOYEES_TO_ATTACH') }}
                      </button>
                      <button
                        :disabled="!task.schedule_id"
                        @click="detachShift"
                        class="btn btn-secondary btn-circle shadow-sm mx-1">
                        {{ $t('DETACH_SHIFT') }}
                      </button>
                    </div>
                    <shift-attach-component
                      @attachShift="(id) => {task.shift_id = id}"
                      v-if="showShifts && task.schedule_id"
                      :date="task.scheduled_time"
                      :taskId="+taskId"
                      :leadTime="+task.lead_time"
                      :scheduledTime="task.scheduled_time"
                      :clientId="task.client_id"
                      :tz="tz"
                      :userTask="false"
                      :scheduleId="task.schedule_id"
                    />
                  </div>
                  <!-- </form> -->
                </div>
                <div class="row">
                  <div class="col py-2 text-right">
                    <template v-if="taskId">
                      <button
                        @click="deleteTask"
                        class="btn btn-danger btn-circle shadow-sm mx-1">
                        {{ $t('Delete') }}
                      </button>
                      <button
                        v-if="!task.parent_id"
                        @click="validateAndClone"
                        class="btn btn-primary btn-circle shadow-sm mx-1">
                        {{ $t('Clone') }}
                      </button>
                    </template>
                    <button
                      @click="validateAndSave"
                      class="btn btn-success btn-circle shadow-sm mx-1"
                    >
                      {{ $t('SAVE_WEB') }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div v-if="mapUrl" class="col">
                    <el-image :src="mapUrl" lazy></el-image>
                  </div>
                </div>
              </template>
              <template v-if="activeTab === 'log'">
                <LogTaskComponent :task="task"/>
              </template>
              <template v-if="activeTab === 'checklist'">
                <template v-if="task.checklist_id && ((task.checklist && task.checklist['items'].length > 0) ||
                   (task.checklist_result && task.checklist_result.length > 0))">
                  <additional-fields-component
                    :title="null"
                    :disabled="true"
                    :items="checklistForm"
                  />
                </template>
                <template v-else>
                  <div class="py-5 text-center">
                    {{$t('NO_STORED_CHECKLISTS')}}
                  </div>
                </template>
              </template>
            </div>
          </div>
        </Form>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import ShiftAttachComponent from '@/components/Tasks/Task/Components/ShiftAttachComponent'
import AdditionalFieldsComponent from '@/components/Tasks/CommonComponents/AdditionalFieldsComponent.vue'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import SubtasksInfoComponent from '@/components/Tasks/Task/Components/SubtasksInfoComponent'
import { sortArrOfObjectsByPropAsc } from '@/utils/sort'
import LogTaskComponent from '@/components/Tasks/CommonComponents/LogTaskComponent'
import moment from 'moment'
import colors from '@/mixins/mixinColorsMarks'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'TaskModal',
  mixins: [errorMixin, momentMixin, TasksAccessMixin, mixinDummy, colors, ToastMixin],
  components: {
    LogTaskComponent,
    SubtasksInfoComponent,
    AdditionalFieldsComponent,
    ShiftAttachComponent,
    ModalComponent,
    DummyPaymentRequired
  },
  data () {
    return {
      task: {
        title: null,
        description: null,
        client_id: null,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: [],
        marks: []
      },
      name: null,
      address: null,
      address_data: null,
      longitude: 0.0,
      latitude: 0.0,
      first_name: '',
      last_name: '',
      email: null,
      phone: null,
      notes: null,
      items: [],
      clientType: 'existing',
      clients: [],
      schedules: [],
      checklists: [],
      links: [],
      mapUrl: '',
      timeout: null,
      gApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      showShifts: false,
      tz: null,
      clientInfo: [],
      deletedClientInfo: [],
      clientAddress: null,
      showAdditionalFields: true,
      taskIdLocal: null,
      nameChanged: false,
      isClone: false,
      children: [],
      parent: null,
      scheduled_time: null,
      tabsData: {
        main: 'MAIN_FORM_TITLE',
        log: 'LOG_INFO_TASKS'
      },
      activeTab: 'main',
      taskModalDataState: {}
    }
  },
  props: { title: String, taskModalData: Object, taskId: Number, disableClient: Boolean },
  async created () {
    this.taskModalDataState = this.taskModalData
    if (this.taskModalDataState.parent_id) {
      this.$store.dispatch('getTask', [this.companyId, this.taskModalDataState.parent_id]).then(response => {
        this.parent = response
      })
    }
    this.$store.dispatch('getTaskMarks', this.companyId)
    const elements = document.getElementsByClassName('pac-container')
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.children = [...this.taskModalDataState.children]
      if (this.taskModalDataState.checklist_id) {
        this.tabsData.checklist = 'CHECKLIST_INFO'
      }
      await this.init()
      this.task = { ...this.taskModalDataState }
      if (this.task.id) {
        this.$store.dispatch('getTask', [this.companyId, this.task.id]).then(task => {
          this.task = { ...task }
          this.task.children = []
        })
      }
      this.task.children = []
      this.switchShowShifts()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    subtask () {
      if (this.children.length > 0) {
        return this.children
      }
      if (this.parent) {
        return [this.parent]
      }
      return null
    },
    isDisabled () {
      return this.disableClient || !!this.task.parent_id
    },
    taskMarksList () {
      const list = []
      if (this.$store.getters.taskMarks.length > 0) {
        this.$store.getters.taskMarks.forEach(item => {
          list.push({
            name: item.name,
            id: item.id,
            color: item.color
          })
        })
      }
      return list
    },
    temporarySubtasks () {
      return this.$store.getters.temporarySubtasks
    },
    checklistForm () {
      if (this.task.checklist_result) {
        return this.task.checklist_result
      }
      if (this.task.checklist) {
        return this.task.checklist.items
      }
      return []
    }
  },
  watch: {
    temporarySubtasks () {
      this.task.children = this.temporarySubtasks
    },
    first_name: function (newVal, oldVal) {
      if (!this.nameChanged) {
        this.name = `${newVal} ${this.last_name}`
      }
    },
    last_name: function (newVal, oldVal) {
      if (!this.nameChanged) {
        this.name = `${this.first_name} ${newVal}`
      }
    },
    'task.client_id': function (val) {
      this.clientAddress = null
      this.clientInfo = [...this.items]
      this.first_name = ''
      this.last_name = ''
      this.email = null
      this.phone = null
      this.notes = null
      if (val) {
        this.showAdditionalFields = false
        const tmpItems = {}
        const companyClientFieldsIds = []
        this.items.forEach(item => {
          tmpItems[item.id] = item
          companyClientFieldsIds.push(item.id)
        })
        this.$store.dispatch('getClient', [this.companyId, val]).then((response) => {
          // console.log(response)
          this.clientAddress = response.address
          this.first_name = response.first_name
          this.last_name = response.last_name
          this.email = response.email
          this.phone = response.phone
          this.notes = response.notes
          this.address = response.address
          this.latitude = response.latitude
          this.longitude = response.longitude
          this.clientInfo = []
          this.deletedClientInfo = []
          const selectedClientFieldsIds = []
          response.fields.forEach((field) => {
            selectedClientFieldsIds.push(field.id)
            if (field.field_type === 'checkbox') {
              field.value = Boolean(Number(field.pivot.value))
            } else {
              field.value = field.pivot.value
            }

            field.options = field.pivot.options
            if (companyClientFieldsIds.includes(field.id)) {
              this.clientInfo.push(field)
            } else {
              this.deletedClientInfo.push(field)
            }
          })
          companyClientFieldsIds.forEach(id => {
            if (!selectedClientFieldsIds.includes(id)) {
              this.clientInfo.push(tmpItems[id])
            }
          })
          this.showAdditionalFields = true
        })
      }
    },
    tz (newTz, oldTz) {
      if (this.task.scheduled_time) {
        if (!oldTz) oldTz = 'UTC'
        this.task.scheduled_time = this.fromZoneToZone(this.task.scheduled_time, oldTz, newTz).format(this.backendDateTimeFormat)
        this.scheduled_time = this.task.scheduled_time
      }
    },
    'task.schedule_id': function (value) {
      const schedule = this.schedules.filter(item => item.id === value)[0]
      if (schedule) {
        const projectId = schedule.project_id
        this.$store.dispatch('getProjects').then((projects) => {
          projects.forEach(project => {
            if (project.id === projectId) {
              this.tz = project.time_zone
            }
          })
        })
      }
    },
    clientType () {
      // this.$validator.pause()
      // this.$nextTick(() => {
      //   this.$validator.errors.clear()
      //   this.$validator.fields.items.forEach(field => field.reset())
      //   this.$validator.fields.items.forEach(field => this.errors.remove(field))
      //   this.$validator.resume()
      // })
      if (this.clientType === 'new') {
        this.task.client_id = null
        this.task.client_name = null
        this.task = { ...this.task }
      } else {
        this.name = null
        this.address = null
        this.longitude = 0.0
        this.latitude = 0.0
      }
    },
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
    },
    items: {
      handler () {
        // this.errors.clear()
      },
      deep: true
    },
    taskId () {
      this.taskIdLocal = this.taskId
      this.children = [...this.taskModalDataState.children]
      this.task = { ...this.taskModalDataState }
      this.task.children = []
    },
    taskIdLocal () {
      if (this.taskIdLocal) {
        if (this.task.client) {
          this.task.client_name = this.task.client.name
        }
        this.task.lead_time = Math.round(+this.task.lead_time * 100) / 100
        this.showShifts = true
      } else {
        this.task = {
          title: null,
          description: null,
          client_id: null,
          scheduled_time: null,
          schedule_id: null,
          checklist_id: null,
          shift_id: null,
          lead_time: 0.0,
          address: null,
          longitude: 0.0,
          latitude: 0.0
        }
        this.showShifts = true
      }
    },
    scheduled_time (value) {
      if (value) {
        this.scheduled_time = moment(this.scheduled_time).format(this.backendDateTimeFormat)
      }
    }
  },
  methods: {
    updateMarks (marks) {
      // console.log(123, marks)
      const newMark = marks?.filter(mark => typeof mark === 'string' || mark instanceof String)
      if (newMark && newMark.length > 0) {
        this.$store.dispatch('createTaskMarks', [this.companyId, {
          name: newMark[0].trim(),
          color: this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
        }]).then(result => {
          this.task.marks.splice(this.task.marks.indexOf(newMark[0]), 1, +result.id)
        }).catch(() => {
          this.task.marks.splice(this.task.marks.indexOf(newMark[0]), 1)
        })
      }
    },
    changeName () {
      if (this.name) {
        this.nameChanged = true
      } else {
        this.nameChanged = false
      }
    },
    focusAutocompate () {
      const pacContainer = document.querySelector('div.pac-container')
      pacContainer.style.zIndex = 999999
    },
    switchShowShifts () {
      this.showShifts = true
    },
    disableShifts (val) {
      if (this.scheduled_time !== null) {
        this.task.scheduled_time = this.scheduled_time.toString()
      }
      this.showShifts = false
      this.task.shift_id = null
      this.task.children = []
      this.$nextTick(() => {
        this.switchShowShifts()
      })
    },
    detachShift () {
      this.task.shift_id = null
      this.showShifts = false
      this.task.employee_id = null
      this.task.children = []
    },
    querySearchClientAsync (queryString, cb) {
      const values = []
      if (this.isSearch(queryString)) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.task.client_id = null
          this.$store.dispatch('getClients', [this.companyId, '?search=' + queryString]).then((response) => {
            response.forEach((item) => {
              values.push({
                name: item.name,
                value: item.name + ' ' + item.address,
                id: item.id
              })
            })
            cb(values)
          }).catch({})
        }, 1500 * Math.random())
      } else {
        cb(values)
      }
    },
    isSearch (queryString) {
      if (this.taskModalDataState.client) {
        return queryString && queryString !== this.taskModalDataState.client.name && queryString.length > 2
      } else {
        return queryString && queryString.length > 2
      }
    },
    setPlace (place) {
      this.longitude = place.geometry.location.lng()
      this.latitude = place.geometry.location.lat()
      this.address = place.formatted_address
      this.address_data = {
        name: place.name,
        address_components: place.address_components,
        formatted_address: place.formatted_address,
        vicinity: place.vicinity
      }
    },
    querySearchAsync (queryString, cb) {
      if (queryString && queryString.length > 2) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$store.dispatch('searchByAddr', queryString).then((response) => {
            const values = []
            response.forEach((item) => {
              values.push({
                value: item.formatted_address,
                lat: item.lat,
                lng: item.lng
              })
            })
            cb(values)
          }).catch({})
        }, 3000 * Math.random())
      } else {
        const values = []
        cb(values)
      }
    },
    createFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelectClient (item) {
      this.task.client_id = item.id
      this.task.client_name = item.name
      if (!this.taskModalDataState.client) {
        this.taskModalDataState.client = {}
      }
      this.taskModalDataState.client.name = item.name
    },
    handleSelect (item) {
      this.longitude = item.lng
      this.latitude = item.lat
    },
    setDescription (description) {
      this.description = description
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    async init () {
      await this.getClientFields()
      this.showShifts = false
      this.$store.dispatch('getTasksSettings', this.$store.getters.companyId).then((response) => {
        this.address = response.default_city
      })
      this.getSchedules()
      this.getChecklists()
    },
    getClientFields () {
      return this.$store.dispatch('getCompanyClientFields').then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        this.items = response
        this.clientInfo = [...this.items]
        this.taskIdLocal = this.taskId
        this.task.client_id = this.taskModalDataState.client_id
        this.task.client_name = this.taskModalDataState.client_name
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    getSchedules () {
      this.$store.dispatch('getSchedules', this.companyId).then((response) => {
        this.schedules = sortArrOfObjectsByPropAsc(response, 'name')
        if (!this.taskId) {
          this.task.schedule_id = this.schedules[0].id
        } else {
          const projectId = this.schedules.filter(item => item.id === this.task.schedule_id)[0].project_id
          this.$store.dispatch('getProjects').then((projects) => {
            projects.forEach(project => {
              if (project.id === projectId) {
                this.tz = project.time_zone
              }
            })
          })
        }
      }).catch(() => {
      })
    },
    getChecklists () {
      this.$store.dispatch('getChecklists', [this.companyId, '']).then((response) => {
        const defaultChecklist = response.find(item => item.is_default === true)
        if (defaultChecklist) {
          this.task.checklist_id = defaultChecklist.id
        }

        this.checklists = sortArrOfObjectsByPropAsc(response, 'title')
        // this.checklists.unshift({
        //   title: this.$t('NO_CHECKLISTS_ATTACHED'),
        //   id: null
        // })
      })
    },
    deleteTask () {
      this.$confirm(this.$t('This will permanently delete the task. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTask', [this.companyId, this.taskId]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          this.$emit('closeTaskModal')
          this.$emit('reloadTasks')
          document.getElementById('ClickTaskModal').click()
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
          this.$emit('closeTaskModal')
          document.getElementById('ClickTaskModal').click()
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    },
    validateAndSave () {
      this.handleSave()
    },
    validateAndClone () {
      if (this.clientType === 'new') {
        this.isClone = true
      } else {
        this.handleClone()
      }
    },
    handleClone () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.createTask()
        }
      }).catch(() => {
      })
    },
    handleWithNewClient () {
      if (this.isClone) {
        this.isClone = false
        this.handleClone()
      } else {
        this.handleSave()
      }
    },
    cleanItems (items) {
      if (items) {
        return items.map(item => {
          const clearItem = {}
          clearItem.value = item.value
          clearItem.options = item.options
          clearItem.id = item.id
          return clearItem
        })
      }
      return null
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          // console.log(56, this.task)
          this.showShifts = false
          const payload = Object.assign({}, this.task)
          payload.name = this.name
          payload.address = this.address
          payload.address_data = this.address_data
          payload.longitude = this.longitude
          payload.latitude = this.latitude
          payload.items = this.cleanItems(this.clientInfo)

          payload.first_name = this.first_name
          payload.last_name = this.last_name
          payload.email = this.email
          payload.phone = this.phone
          payload.notes = this.notes
          if (!payload.shift_id) {
            payload.employee_id = 0
          }
          if (!this.taskId && payload.scheduled_time) {
            payload.scheduled_time = this.fromZoneToZone(payload.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
          }

          payload.children = payload.children.concat(this.children)

          payload.children = payload.children.filter(child => !child.id).map(child => {
            if (!this.taskId && child.scheduled_time) {
              child.scheduled_time = this.fromZoneToZone(child.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
            }
            return child
          })
          if (this.taskId) {
            // console.log(payload.scheduled_time, this.scheduled_time)
            if (this.scheduled_time) {
              payload.scheduled_time = this.fromZoneToZone(this.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
            } else {
              payload.scheduled_time = null
            }
            this.$store.dispatch('updateTask', [this.companyId, this.taskId, payload])
              .then((response) => {
                this.$store.dispatch('setTemporarySubtasks', [])
                this.clientType = 'existing'
                this.toastSuccess(this.$t('Task updated'))
                this.$emit('reloadTasks')
                document.getElementById('ClickTaskModal').click()
              })
              .catch(e => {
                this.toastError(this.$i18n?.t(e.response.data.message))
                document.getElementById('ClickTaskModal').click()
              })
          } else {
            this.createTask()
          }
        }
      }).catch(() => {
      })
    },
    createTask () {
      this.showShifts = false
      const payload = Object.assign({}, this.task)
      payload.name = this.name
      payload.address = this.address
      payload.address_data = this.address_data
      payload.longitude = this.longitude
      payload.latitude = this.latitude
      payload.items = this.cleanItems(this.clientInfo)
      payload.first_name = this.first_name
      payload.last_name = this.last_name
      payload.email = this.email
      payload.phone = this.phone
      payload.notes = this.notes
      if (payload.scheduled_time) {
        payload.scheduled_time = this.fromZoneToZone(this.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
      }
      this.$store.dispatch('createTask', [this.companyId, payload])
        .then((response) => {
          this.$store.dispatch('setTemporarySubtasks', [])
          this.clientType = 'existing'
          this.toastSuccess(this.$t('Task created'))
          this.$emit('reloadTasks')
          document.getElementById('ClickTaskModal').click()
        })
        .catch(() => {
        })
    },
    from (timeFrom) {
      try {
        return this.fromZoneToZone(timeFrom, 'UTC', this.tz).format(this.localeDateTimeFormat)
      } catch (e) {
        // console.log('timeFrom', e)
      }
    },
    to (timeTo) {
      try {
        return this.fromZoneToZone(timeTo, 'UTC', this.tz).format(this.localeDateTimeFormat)
      } catch (e) {
        // console.log('timeTo', e)
      }
    },
    closeModal () {
      const elements = document.getElementsByClassName('pac-container')
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0])
      }
      this.$store.dispatch('setTemporarySubtasks', [])
      this.$emit('closeTaskModal')
    },
    deleteSubtask (id) {
      this.children = this.children.filter(child => child.id !== id)
      this.task.children = this.children
      this.$emit('deleteSubtask')
    }
  }
}
</script>
<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
