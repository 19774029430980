<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <tab-navigation-component :tabs="tabs"></tab-navigation-component>
          <div class="row py-2">
            <div class="col-lg-12">
              <div class="text-primary text-uppercase font-weight-bold">
                {{ $t("CREATE_CLIENT_FIELDS") }}
                <ui-hint :content="$t('CREATE_CLIENT_FIELDS_HINT')" />
              </div>
            </div>
          </div>
          <Form ref="observer" as="div">
            <div v-for="(item, item_key) in items" :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}" class="p-1 rounded" :key="item_key">
              <item
                :name="item_key.toString()"
                :item="item"
                :showItemConfig="true"
                :showSearchable="true"
                @deleteItem="deleteItem(item_key)">
              </item>
            </div>
          </Form>
          <div class="row my-2">
            <div class="col-sm d-flex">
              <button class="btn btn-outline-primary rounded-pill shadow-sm m-1" aria-label="Close" @click="addItem">
                <i class="icon-plus"></i>
              </button>
              <button class="btn btn-success rounded-pill shadow-sm my-1 ml-auto" aria-label="Close" @click="save">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import Item from '@/components/Tasks/Checklist/Item'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  mixins: [errorMixin, TasksAccessMixin, mixinDummy, ToastMixin],
  components: { Item, DummyPaymentRequired, TabNavigationComponent },
  data () {
    return {
      items: [],
      tabs: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  watch: {
    companyId () {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  methods: {
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        },
        {
          link: '#',
          title: 'CLIENT_FIELDS',
          isActive: true
        }
      ]
      if (this.isEmployeeHasPermission('create-task-statuses')) {
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      if (!this.isEmployeeHasPermission('create-task-statuses')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.$store.dispatch('getCompanyClientFields').then((response) => {
        this.items = response
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    deleteItem (itemKey) {
      this.items.splice(+itemKey, 1)
    },
    addItem () {
      this.items.push({
        id: null,
        title: null,
        checklist_id: this.$route.params.checklistId,
        field_type: 'text',
        required: true,
        show_in_grid: false,
        searchable: false,
        type_options: null
      })
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('patchCompanyClientFields', { items: this.items }).then((response) => {
            this.$store.dispatch('getCompany', this.$store.getters.companyId).then(() => {})
            this.toastSuccess(this.$t('Checklist updated'))
          }).catch(() => {})
        }
      })
    }
  }
}
</script>
