<template>
  <div v-if="pageCount > 1" class="row" :class="customClasses">
    <nav aria-label="Page navigation">
      <ul class="pagination ml-2" style="cursor: pointer">
        <li class="page-item shadow-none" aria-label="Previous">
          <a class="page-link" @click="prevPage">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li
          class="page-item shadow-none"
          v-for="item in pages"
          v-bind:key="item.page"
          :class="item.status">
          <a class="page-link" @click="page(item.page)">
            {{item.page}}
          </a>
        </li>
        <li class="page-item shadow-none">
          <a class="page-link" aria-label="Next" @click="nextPage">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'Pagination',

  data () {
    return {
      pageSizeLocal: 25,
      currentPageLocal: 1,
      pages: [],
      pageCount: '',
      paginatedLocal: []
    }
  },

  props: {
    pageSize: Number,
    currentPage: Number,
    rows: Array,
    customClasses: String
  },

  created () {
    // console.log(this.pageSize)
    if (this.pageSize) {
      this.pageSizeLocal = this.pageSize
    }
    if (this.currentPage) {
      this.currentPageLocal = this.currentPage
    }
    this.init()
  },

  methods: {
    init () {
      this.pagination()
      this.paginatedLocal = this.pageData()
      this.updatePagination()
    },

    updatePagination () {
      // console.log(1, this.paginatedLocal)
      this.$emit('updatePaginated', { paginated: this.paginatedLocal, currentPage: this.currentPageLocal, pageSize: this.pageSizeLocal })
    },

    pagination () {
      // console.log(data)
      const pages = Math.ceil(this.rows.length / this.pageSizeLocal)
      let status = ''
      this.pages = []
      this.pageCount = pages
      if (this.pageCount < this.currentPageLocal) {
        this.currentPageLocal = 1
      }
      // console.log(this.pageCount)
      for (let i = 1; i <= pages; i++) {
        if (i === this.currentPageLocal) {
          status = 'active'
        } else {
          status = ''
        }
        this.pages.push({
          page: i,
          status: status
        })
      }
    },

    pageData () {
      return this.rows.filter((row, index) => {
        const start = (this.currentPageLocal - 1) * this.pageSizeLocal
        const end = this.currentPageLocal * this.pageSizeLocal

        return (index >= start && index < end)
      })
    },

    nextPage: function () {
      // console.log(this.currentPageLocal, this.currentPageLocal * this.pageSizeLocal, this.pageCount)
      if (this.currentPageLocal < this.pageCount) {
        this.pages[this.currentPageLocal - 1].status = ''
        this.currentPageLocal++
        this.pages[this.currentPageLocal - 1].status = 'active'
      }
      this.paginatedLocal = this.pageData()
      this.updatePagination()
    },

    prevPage: function () {
      this.pages[this.currentPageLocal - 1].status = ''
      if (this.currentPageLocal > 1) {
        this.currentPageLocal--
      }
      this.pages[this.currentPageLocal - 1].status = 'active'
      this.paginatedLocal = this.pageData()
      this.updatePagination()
    },

    page: function (i) {
      // console.log(this.pages)
      this.currentPageLocal = i
      this.pages.forEach(function (item) {
        item.status = ''
      })
      this.pages[i - 1].status = 'active'
      this.paginatedLocal = this.pageData()
      this.updatePagination()
    }
  },

  watch: {
    rows: function () {
      this.init()
    },
    pageSize () {
      this.pageSizeLocal = this.pageSize
      this.currentPageLocal = 1
      this.init()
    },
    currentPage () {
      this.currentPageLocal = this.currentPage
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
