<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="activated()"
      :dummyInfo="dummyInfo"
      >
    </dummy-payment-required>
    <template v-if="!dummy && loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("LOCATION_CONTROL_TITLE") }}
          <ui-hint :content="$t('LOCATION_CONTROL_TITLE_HINTS')" />
        </span>
      </div>
      <div class="row">
        <div v-show="drawer" class="col p-1" style="max-width: 25%;">
          <div class="card card-small h-100">
            <div class="card-body p-1">
              <FilterComponent
                :isAutoRefreshEnable="isAutoRefreshEnable"
                :clearInterval="clearInterval"
              />
            </div>
          </div>
        </div>
        <div class="col w-100 p-1">
          <div class="card card-small">
            <div class="card-body table-responsive">
              <div class="row">
                <div class="col-auto">
                  <i class="icon-filter btn-icon text-primary mx-1" @click="drawer = !drawer"/>
                </div>
                <div class="col" />
                <div v-if="this.employeesGeolocation.length > 0 && this.displayFilters.only_last_point" class="col-auto pb-1">
                  <ui-switch
                    name="show_hide"
                    v-model="showTooltips"
                    :active-text="$t('SHOW_ALL_TOOLTIPS')"
                    :inactive-text="$t('HIDE_ALL_TOOLTIPS')"
                  />
                </div>
                <div class="col-auto pb-1">
                  <ui-checkbox
                    name="IS_AUTO_REFRESH_ENABLE"
                    v-model="isAutoRefreshEnable"
                  >
                    {{$t('IS_AUTO_REFRESH_ENABLE')}}
                  </ui-checkbox>
                </div>
              </div>
              <MapComponent
                :showTooltips="showTooltips"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import MapComponent from '@/components/LocationControl/Components/MapComponent'
import FilterComponent from '@/components/LocationControl/Components/FilterComponent'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'LocationControlContainer',
  components: { FilterComponent, MapComponent, DummyPaymentRequired },
  mixins: [mixinDummy, momentMixin],
  beforeRouteLeave (to, from, next) {
    this.clearInterval = true
    this.$nextTick(() => {
      next()
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.clearInterval = false
    })
  },
  data () {
    return {
      dummy: false,
      loaded: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      drawer: false,
      showTooltips: false,
      isAutoRefreshEnable: false,
      clearInterval: false
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      companyId: 'companyId'
    }),
    projects () {
      return this.$store.getters.projects.filter(project => this.isEmployeeHasPermission('create project') ||
        (project.managers && project.managers.includes(this.$store.getters.company.employee_id)))
    },
    geolocationFilters () {
      return this.$store.getters.geolocationFilters
    },
    displayFilters () {
      return this.$store.getters.displayFilters
    },
    employeesGeolocation () {
      return this.$store.getters.employeesGeolocation
    }
  },
  watch: {
    companyId () {
      if (this.companyId) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      this.$store.dispatch('isModuleActive', [this.companyId, 26]).then(settings => {
        this.dummy = false
        const projectId = this.$store.getters.currentProjectId > 0 ? this.$store.getters.currentProjectId : this.projects[0].id
        const period = [
          moment().startOf('day').format(this.backendDateTimeFormat),
          moment().endOf('day').format(this.backendDateTimeFormat)
        ]
        this.loaded = true
        this.$store.dispatch('updateGeolocationFilter', {
          project_id: projectId,
          period: period
        })
        const queryGeolocation = `?project_id=${projectId}&from=${period[0]}&to=${period[1]}`
        this.$store.dispatch('getEmployeesLocation', [
          this.$store.getters.companyId,
          queryGeolocation
        ]).catch(e => {})
      }).catch(err => {
        if (err.response.data.module) {
          this.dummyInfo = this.setupDummyData(err.response.data)
          this.dummy = true
        }
      })
    },
    activated () {
      this.dummy = false
      this.loaded = true
      this.init()
    }
  }
}
</script>

<style scoped>
</style>
