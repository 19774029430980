<template>
  <div class="col-12 col-lg-8 text-right">
    <div class="row float-right">
      <div class="col-auto my-auto">
        <el-button
            v-if="!hideDateButton"
            class="mx-1"
            type="primary"
            @click="changeDate(-1)"
            icon="Arrow-left">
        </el-button>
        <el-button
            v-if="!hideDateButton"
            class="mx-1"
            type="primary"
            @click="changeDate(0)"
        >
          {{$t('TODAY')}}
        </el-button>
        <el-button
            v-if="!hideDateButton"
            class="mx-1"
            type="primary"
            @click="changeDate(1)"
            icon="Arrow-right">
        </el-button>
      </div>
      <div class="col-auto my-auto">
        <ui-date-picker
            :pickerType="'daterange'"
            name="from_to_date"
            v-model="period"
            :validation="{required: true}"
            :clearable="false"
        />
      </div>
      <div class="col-auto my-auto">
        <button
            v-if="!hideAddTaskButton"
            class="btn btn-primary btn-sm rounded-pill"
            data-toggle="modal"
            data-target="#ClickTaskModal"
            type="button"
            @click="addTask">
          {{ $t('Create Task') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment/moment'

export default {
  name: 'DateNavigationBar',
  mixins: [momentMixin],
  data () {
    return {
      period: [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
    }
  },
  props: {
    hideDateButton: { type: Boolean, default: false },
    hideAddTaskButton: { type: Boolean, default: false }
  },
  computed: {
    firstDayOfWeek () {
      return +this.$store.getters.company.salary.week_start + 1
    }
  },
  watch: {
    period () {
      this.pickerChange()
    }
  },
  methods: {
    changeDate (val) {
      if (val === 0) {
        this.period = [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
      } else {
        const date = moment(this.period[0], this.backendDateFormat).add(val, 'days').format(this.backendDateFormat)
        this.period = [date, date]
      }
      this.$emit('changeDate', this.period)
    },
    pickerChange () {
      this.$emit('changeDate', this.period)
    },
    addTask () {
      this.$emit('addTask')
    }
  }
}
</script>

<style scoped>

</style>
