<template>
  <div>
    <template v-if="hasCompany">
      <div class="row my-2">
        <div class="col title-text">
          {{ $t('Dashboard') }}
        </div>
        <template v-if="isEmployeeHasPermission('get-dashboard-project-users')">
          <div class="col-auto text">
            <button
              type="submit"
              @click="toCompanyDashboard"
              data-cy="switch-view"
              class="btn btn-outline-primary rounded-pill shadow-sm">
              {{ $t('Statistic') }}
            </button>
          </div>
        </template>
      </div>
      <div class="row bg-light">
        <div class="col-auto bg-light">
          <AttendanceDashboardComponent v-if="attendanceSettings && attendanceSettings.interfaces.web.enabled" />
        </div>
        <div class="col-auto bg-light">
          <ActivityDashboardComponent />
        </div>
      </div>
      <div v-if="loaded" class="form-body pt-1">
        <dashboard-calendar
          :schedules="schedules"
          :viewType="windowWidth >= 576 ? 'dayGridMonth' : 'listMonth'">
        </dashboard-calendar>
      </div>
    </template>
    <template v-else>
      <div class="row my-2 mx-auto">
        <div class="col d-flex">
          <div class="title-text">{{ $t('Dashboard') }}</div>
        </div>
      </div>
      <div class="card">
        <div class="card-body text-center">
          <img class="img-fluid" style="max-height: 350px" src="../../../public/static/images/App/Splash_1_01.png"/>
          <h5 class="m-3">
            {{$t('NO_COMPANY_DASHBOARD_TOKEN')}}
          </h5>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import dashboardCalendar from './DashboardComponents/DashboardCalendar'
import momentMixin from '@/mixins/mixinMoment'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import AttendanceDashboardComponent from '@/components/Dashboard/DashboardComponents/AttendanceDashboardComponent'
import ActivityDashboardComponent from '@/components/Dashboard/DashboardComponents/ActivityDashboardComponent.vue'

export default {
  name: 'DashboardUser',
  mixins: [momentMixin, mixinWindowWidthWatch],
  components: {
    ActivityDashboardComponent,
    AttendanceDashboardComponent,
    dashboardCalendar
  },
  data () {
    return {
      statMode: false,
      pickdate: 'now',
      activeProjectItem: {},
      activeScheduleItem: {},
      loaded: false,
      usersCompanyTotal: '',
      companyName: '',
      timeZone: 'UTC',
      schedules: [],
      attendanceSettings: null
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    ModulesByCompany () {
      if (this.ModulesByCompany) {
        this.ModulesByCompany.forEach((module) => {
          if (+module.module_id === 24) {
            if (!Array.isArray(module.settings)) {
              this.attendanceSettings = module.settings
            }
          }
        })
      }
    }
  },
  computed: {
    roleDashboard () {
      let role = 'noRole'
      if (this.$store.getters.companyRole !== null) {
        role = this.$store.getters.companyRole
      }
      return role
    },
    ModulesByCompany () {
      return this.$store.getters.ModulesByCompany
    }
  },
  methods: {
    init () {
      if (this.ModulesByCompany) {
        this.ModulesByCompany.forEach((module) => {
          if (+module.module_id === 24) {
            if (!Array.isArray(module.settings)) {
              this.attendanceSettings = module.settings
            }
          }
        })
      }
      if (this.roleDashboard === 'noRole') {
        this.$router.push('/profile')
      }
      this.$store.dispatch('getSchedules', this.companyId).then(schedules => {
        this.schedules = schedules
        this.loaded = true
      })
    },
    toCompanyDashboard () {
      this.$router.push(`/c/${this.companyId}/dashboard`)
    },
    closeModal () {
      // this.dummy = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
