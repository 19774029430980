<template>
  <Form ref="custom">
    <div v-if="itemsState.length > 0">
      <div v-if="title" class="text-primary text-uppercase font-weight-bold mb-2">
        {{ $t(title) }}
      </div>
      <div v-for="(field, key) in itemsState" :key="'existing-' + field['title'] + '-' + key" class="mb-2">
        <template v-if="field['field_type'] === 'text'">
          <ui-text-input
            :name="'input-' + field['title']"
            v-model="itemsState[key]['value']"
            :validation="{required: !!field['required'] && !disabled}"
            :label="field['title']"
            :disabled="disabled"
          />
        </template>
        <template v-if="field['field_type'] === 'date_range_picker'">
          <ui-date-picker
            :pickerType="'daterange'"
            :label="field['title']"
            :name="'range-' + field['title']"
            v-model="itemsState[key]['options']"
            :validation="{required: !!field['required']}"
            :clearable="true"
            :picker-format="localeDateFormatElementUi"
          />
        </template>
        <template v-if="field['field_type'] === 'date_picker'">
          <ui-date-picker
            :label="field['title']"
            :name="'picker-' + field['title']"
            v-model="itemsState[key]['value']"
            :validation="{required: !!field['required']}"
            :clearable="true"
            :picker-format="localeDateFormatElementUi"
            :disabled="disabled"
            :picker-options="pickerOptions"
            :placeholder="$t('Pick a day')"
          />
        </template>
        <template v-else-if="field['field_type'] === 'number'">
          <ui-number-input
            :id="field['title']"
            :disabled="disabled"
            :label="field['title']"
            v-model="itemsState[key]['value']"
            :name="'input-number-' + field['title']"
            :validation="{required: field['required'], decimal: true}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'checkbox'">
          <ui-checkbox
            :id="field['title']"
            :name="'input-checkbox-' + field['title']"
            :disabled="disabled"
            v-model="itemsState[key]['value']"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'select'">
          <ui-select
            :id="field['title']"
            :name="'select-' + field['title']"
            :disabled="disabled"
            :label="field['title']"
            v-model="itemsState[key]['value']"
            class="w-100"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :clearable="true"
          />
        </template>
        <template v-else-if="field['field_type'] === 'multi_select'">
          <ui-multi-select
            :label="field['title']"
            :id="field['title']"
            :name="'multiselect-' + field['title']"
            v-model="itemsState[key]['options']"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :disabled="disabled"
          />
        </template>
      </div>
      <div v-for="(field, key) in deletedItemsState" :key="'deleted-' + field['title']" class="pb-2">
        <template v-if="field['field_type'] === 'text'">
          <ui-text-input
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :validation="{required: !!field['required'] && !disabled}"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'number'">
          <ui-number-input
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            :label="field['title']"
            v-model="deletedItemsState[key]['value']"
            :validation="{required: field['required'], decimal: true}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'checkbox'">
          <ui-checkbox
            :id="field['title']"
            :name="'deleted-checkbox-' + field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :label="field['title']"
          />
        </template>
        <template v-else-if="field['field_type'] === 'select'">
          <ui-select
            :id="field['title']"
            :name="field['title']"
            :disabled="true"
            v-model="deletedItemsState[key]['value']"
            :label="field['title']"
            class="w-100"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
          />
        </template>
        <template v-else-if="field['field_type'] === 'multi_select'">
          <ui-multi-select
            :label="field['title']"
            :id="field['title']"
            :name="field['title']"
            v-model="deletedItemsState[key]['options']"
            :options="field['type_options']"
            :validation="{required: field['required'] && !disabled}"
            :disabled="true"
          />
        </template>
      </div>
    </div>
  </Form>
</template>

<script>
import mixinMoment from '@/mixins/mixinMoment'

export default {
  name: 'AdditionalFieldsComponent',
  mixins: [mixinMoment],
  expose: ['resetValidation'],
  data () {
    return {
      pickerOptions: {
        cellClassName: 'ml-2'
      },
      itemsState: [],
      deletedItemsState: []
    }
  },
  props: {
    items: Array,
    deletedItems: Array,
    disabled: Boolean,
    title: { type: String, default: 'Additional fields' }
  },
  created () {
    this.itemsState = this.items
    this.deletedItemsState = this.deletedItems
    this.$eventBus.on('validateClientFields', this.validate)
  },
  mounted () {
    setTimeout(() => {
      this.$refs.custom.resetForm()
    }, 500)
  },
  beforeUnmount () {
    this.$eventBus.off('validateClientFields')
  },
  watch: {
    items () {
      this.itemsState = []
      this.$nextTick(() => {
        this.itemsState = this.items
      })
    },
    deletedItems () {
      this.deletedItemsState = this.deletedItems
    }
  },
  methods: {
    resetValidation () {
      setTimeout(() => {
        this.$refs.custom.resetForm()
      }, 500)
    },
    validate () {
      if (this.disabled) {
        this.$emit('proceedSave')
      } else {
        this.$refs.custom.validate().then(val => {
          if (val.valid) {
            this.$emit('proceedSave')
          }
        }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>

</style>
