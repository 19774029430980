<template>
  <div>
    <AttendanceChoiceComponent
      v-if="nextAttendance"
      @proceedAttendance="(data) => {
        proceedAttendance(...data)
      }"
      @proceedCloseAttendance="() => this.dialogAttendanceVisible = false"
      :dialogAttendanceVisible="dialogAttendanceVisible"
      :next="nextAttendance"/>
    <nav v-if="company.is_blocked" class="navbar navbar-dark bg-danger justify-content-center text-white p-1">
      <div v-if="isEmployeeHasPermission('delete-company')">
        <div class="text-center">
          {{
            $t('All account actions are disabled because you’ve missed the payment deadline on your latest invoice. Please pay the debt to continue working with the service.')
          }}
        </div>
        <div class="text-center">
          <router-link
            :to="`/c/${companyId}#balance`"
            id="balance_link"
            class="text-white">
            <u>
              {{ $t('Company balance') }}
            </u>
          </router-link>
        </div>
      </div>
      <div v-else>
        {{
          $t('All account actions are disabled because your employer missed the payment deadline on the latest invoice. Please contact them immediately to resolve this issue.')
        }}
      </div>
    </nav>
    <nav v-if="!company.is_blocked && unpaidInvoice && isEmployeeHasPermission('create-schedule')"
         class="navbar navbar-dark bg-danger justify-content-center text-white p-1">
      <div>
        <div class="text-center">
          {{ $t('web_unpaid_invoice_alert_admin') }}
        </div>
        <div class="text-center">
          <router-link
            :to="`/c/${companyId}#balance`"
            id="balance_link"
            class="text-white">
            <u>
              {{ $t('Company balance') }}
            </u>
          </router-link>
        </div>
      </div>
    </nav>
    <el-dialog
      key="showPersonalizationModal"
      :title="$t('PERSONALIZATION_MODAL_TITLE')"
      :close-on-click-modal="true"
      :show-close="true"
      v-model="showPersonalizationModal"
      :before-close="handleClose"
      width="40%">
      <div style="max-height: 300px; overflow:auto; background-color: white;">
        <div class="text-wrap text-break">
          {{ $t('PERSONALIZATION_MODAL_INFORMATION') }}
        </div>
        <div class="my-2">
          <ui-switch
            v-model="impersonalizationAccess"
            @change="val => impersonalizationAccess = val"
            :active-text="$t('Enable personalization')"
          />
        </div>
        <div class="text-wrap text-break">
          {{ $t('PERSONALIZATION_MODAL_SUBINFORMATION') }}
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
        <el-button type="primary" @click="saveImpersonalization">{{ $t('Confirm') }}</el-button>
      </span>
      </template>
    </el-dialog>

    <nav class="navbar navbar-expand-md fixed-top navbar-light bg-white shadow-sm py-0 px-0 pr-1">
      <router-link :to="dashboardLink" class="navbar-brand mx-1 ml-md-3 mr-md-3">
        <img src="@/assets/images/logo.svg" width="180" alt="shifton">
      </router-link>

      <button
        @click="toggleBellMenu"
        class="navbar-toggler ml-auto mt-lg-0 d-md-none mr-2 p-0"
        type="button"
        data-toggle="collapse"
        data-target="#bellModal"
        aria-controls="bellModal"
        aria-expanded="false"
        aria-label="Toggle Notifications">
        <div class="header-icon-mobile text-center">
          <el-badge :value="alertsCount" class="item" :hidden="alertsCount === 0">
            <i class="icon-bell"></i>
          </el-badge>
        </div>
      </button>

      <button
        @click="toggleMainMenu"
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerLoggedBar"
        aria-controls="navbarTogglerLoggedBar"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" :class="{'vh-100' : isMobile}" v-if="isMobile" id="bellModal">
        <alerts/>
      </div>

      <div class="collapse navbar-collapse justify-content-between flex-wrap" :class="{'vh-100' : isMobile}"
           id="navbarTogglerLoggedBar">
        <ul class="navbar-nav d-flex justify-content-center gap-5">

          <li v-if="companyLimitState > 0">
            <router-link
              to="/company"
              id="demo_business_account"
              class="btn btn-outline-secondary rounded-pill mr-lg-3 my-2">
              {{ $t('Create business account') }}
            </router-link>
          </li>
          <!-- Scheduling -->
          <li v-if="hasCompany" class="nav-item  dropdown border-top no-md-border my-auto">
            <div class="btn-group nav-link px-0" v-bind:class="{ 'py-0' : isMobile }">
              <router-link
                :is="$store.getters.isDataLoading ? 'span' : 'router-link'"
                :to="currentProject ? `/c/${companyId}/p/${currentProject}/schedule` : `/c/${companyId}/schedule/company`"
                id="demo_company_schedule">
                  <span class="d-md-none p-0 mx-2">
                      <i class="icon-calendar mobile-menu-icon"></i>
                  </span>
                <button type="button" class="btn btn-light menu-color">
                  {{ $t('SCHEDULING') }}
                </button>
              </router-link>
              <button v-if="isEmployeeHasPermission('get projects by company') ||
                              isEmployeeHasPermission('get related projects full data') ||
                               isEmployeeHasPermission('get-company-schedules-admin') ||
                              isEmployeeHasPermission('get-company-schedules-manager') ||
                               isEmployeeHasPermission('create-template')"
                      type="button"
                      class="btn dropdown-toggle dropdown-toggle-split scheduling-btn" data-toggle="dropdown"
                      aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
                <el-badge :value="companyReminder" class="item d-flex badge-pos" :hidden="companyReminder === 0">
                </el-badge>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link id="demo_projects"
                             v-if="isEmployeeHasPermission('get projects by company') ||
                              isEmployeeHasPermission('get related projects full data')"
                             class="dropdown-item"
                             :to="`/c/${companyId}/projects`">
                  <el-badge is-dot class="item" :hidden="!!hasProject">
                    <i class="icon-package"></i>
                  </el-badge>
                  <span class="menu-color ml-2">{{ $t('Projects') }}</span>
                </router-link>
                <router-link id="demo_schedules"
                             v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
                              isEmployeeHasPermission('get-company-schedules-manager')"
                             class="dropdown-item"
                             :to="`/c/${companyId}/schedules`">
                  <el-badge is-dot class="item" :hidden="!!hasSchedule">
                    <i class="icon-calendar"></i>
                  </el-badge>
                  <span class="menu-color ml-2">{{ $t('Schedules list') }}</span>
                </router-link>
                <router-link v-if="isEmployeeHasPermission('create-template')"
                             class="dropdown-item"
                             :to="`/c/${companyId}/templates`">
                  <i class="icon-eye"></i>
                  <span class="menu-color ml-2">{{ $t('View templates') }}</span>
                </router-link>
              </div>
            </div>
          </li>
          <!-- Attendance -->
          <template v-if="hasCompany">
            <li class="nav-item dropdown border-top no-md-border my-auto">
              <div @click="getAttendance"
                   data-toggle="dropdown"
                   class="head-link nav-link px-0"
                   style="cursor: pointer"
                   id="attendanceDropdown"
                   aria-haspopup="true"
                   aria-expanded="false">
                <button class="btn btn-light dropdown-toggle d-none d-md-block menu-color" type="button"
                        aria-expanded="false">{{ $t('ATTENDANCE') }}
                </button>
                <span class="d-md-none p-0 mx-2">
                    <i class="icon-clock"></i>
                  </span>
                <span class="d-md-none menu-color"
                      v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('ATTENDANCE_DROPDOWN') }}</span>
              </div>
              <div class="dropdown-menu dropdown-menu-right py-0"
                   aria-labelledby="attendanceDropdown" :style="dummy ? 'min-width: 450px;' : null">
                <template
                  v-if="!dummy && attendance && attendance.next_events && attendanceSettings && attendanceSettings.interfaces.web.enabled">
                  <div v-for="(event, index) in attendance.next_events" :key="index" class="p-1">
                    <button
                      type="submit"
                      @click="updateAttendance(event)"
                      data-cy="switch-view"
                      :class="buttonAttendanceClass(event)"
                      class="btn rounded-pill shadow-sm w-100">
                      <template v-if="event.type === 'shift_end'">
                        {{ $t('END_SHIFT_ATTENDANCE') }}
                      </template>
                      <template v-if="event.type === 'break_end'">
                        {{ $t('END_BREAK_ATTENDANCE') }}
                      </template>
                      <template v-if="event.type === 'shift_start'">
                        {{ $t('START_SHIFT_ATTENDANCE') }}
                      </template>
                      <template v-if="event.type === 'break_start'">
                        {{ $t('START_BREAK_ATTENDANCE') }}
                      </template>
                    </button>
                  </div>
                </template>
                <template v-if="dummy">
                  <dummy-payment-required
                    @activate="activate"
                    :dummyInfo="dummyInfo"
                    :compactMode="true">
                  </dummy-payment-required>
                </template>
              </div>
            </li>
          </template>
          <!-- Tasks -->
          <template v-if="hasCompany">
            <li class="nav-item border-top no-md-border my-auto" v-if="showTaskDropdown">
              <div class="btn-group nav-link px-0" v-bind:class="{ 'py-0' : isMobile }">
                <router-link id="task_calendar" :to="`/c/${companyId}/user-tasks/calendar`" style="width:100%">
                    <span class="d-md-none p-0 mx-2">
                      <i class="icon-clipboard mobile-menu-icon"></i>
                    </span>
                  <button type="button" id="field_service" class="btn btn-light menu-color">{{
                      $t('FIELD_SERVICE')
                    }}
                  </button>
                </router-link>
                <button type="button" class="btn dropdown-toggle dropdown-toggle-split" id="tasksDropdown"
                        data-toggle="dropdown" aria-expanded="false">
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <router-link id="demo_tasks" class="dropdown-item" :to="`/c/${companyId}/tasks#active`">
                    <i class="icon-clipboard btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('TASKS_LINK') }}</span>
                  </router-link>

                  <router-link v-if="isEmployeeHasPermission('get-clients')"
                               id="tasks_clients" class="dropdown-item"
                               :to="`/c/${companyId}/clients`">
                    <i class="icon-smile btn-icon text-secondary" width="20"></i>
                    <span class="menu-color ml-2">{{ $t('Clients') }}</span>
                  </router-link>

                  <router-link v-if="isEmployeeHasPermission('create-checklists')"
                               id="" class="dropdown-item"
                               :to="`/c/${companyId}/checklists`">
                    <i class="icon-playlist_add_check btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('Checklists') }}</span>
                  </router-link>

                  <router-link v-if="isEmployeeHasPermission('create-checklists')"
                               id="" class="dropdown-item" :to="`/c/${companyId}/tasks-map`">
                    <i class="icon-map-pin btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('Map') }}</span>
                  </router-link>

                  <router-link id="demo_user_tasks" class="dropdown-item" :to="`/c/${companyId}/user-tasks`">
                    <i class="icon-how_to_reg btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('USER_TASKS_LINK') }}</span>
                  </router-link>

                  <div v-if="isEmployeeHasPermission('create-task-statuses')"
                       class="dropdown-divider" />
                  <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                               :to="`/c/${companyId}/client-fields`">
                    <i class="icon-list btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('CLIENT_FIELDS') }}</span>
                  </router-link>

                  <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item"
                               :to="`/c/${companyId}/task-statuses`">
                    <i class="icon-bar-chart btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('Statuses') }}</span>
                  </router-link>

                  <router-link v-if="isEmployeeHasPermission('create-task-statuses')" id="" class="dropdown-item" :to="`/c/${companyId}/task-marks`">
                    <i class="icon-award btn-icon text-secondary"></i>
                    <span class="menu-color ml-2">{{ $t('TASK_MARKS_LINK') }}</span>
                  </router-link>
                </div>
              </div>
            </li>
            <li v-else id="demo_tasks_button" class="nav-item my-auto">
              <router-link :to="`/c/${companyId}/user-tasks`" class="nav-link d-none d-md-block my-auto px-0">
                <button type="button" class="btn btn-light">{{ $t('USER_TASKS_LINK') }}</button>
              </router-link>
              <router-link class="d-md-none dropdown-item p-2 nav-link" :to="`/c/${companyId}/user-tasks`">
                <i class="icon-clipboard"></i>
                <span class="menu-color ml-2" v-bind:class="{ mobileItemStyle: isMobile }">{{
                    $t('USER_TASKS_LINK')
                  }}</span>
              </router-link>
            </li>
          </template>
          <!-- Map -->
          <template v-if="hasCompany && isEmployeeHasPermission('view-geolocation')">
            <li class="nav-item d-none d-md-block">
              <router-link
                :to="`/c/${companyId}/location-control`"
                class="nav-link d-none d-md-block my-auto px-0">
                <button id="locationControl" type="button" class="btn btn-light menu-color">{{ $t('LOCATION_CONTROL_LINK') }}</button>
              </router-link>
            </li>
            <li class="nav-item d-md-none border-top py-2">
              <router-link
                :to="`/c/${companyId}/location-control`"
                class="d-md-none px-0">
                  <span class="d-md-none mx-2 menu-color">
                    <i class="icon-map-pin" />
                  </span>
                <span class="d-md-none menu-color"
                      v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('LOCATION_CONTROL_LINK') }}
                  </span>
              </router-link>
            </li>
          </template>
          <template v-if="hasCompany && isEmployeeHasPermission('get-shift-activities')">
            <li class="nav-item border-top no-md-border my-auto">
              <div class="btn-group nav-link px-0" v-bind:class="{ 'py-0' : isMobile }">
                <router-link id="task_calendar" :to="`/c/${companyId}/shift-activities`" style="width:100%">
                    <span class="d-md-none p-0 mx-2">
                      <i class="icon-clipboard mobile-menu-icon"></i>
                    </span>
                  <button type="button" id="activity_service" class="btn btn-light menu-color">{{
                      $t('SHIFT_ACTIVITIES_LINK')
                    }}
                  </button>
                </router-link>
                <button id="activity_dropdown" @click="getActivities" type="button" class="btn dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown" aria-expanded="false">
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" :style="{minWidth: dummy ? '400px' : null }">
                  <template v-if="dummy">
                    <dummy-payment-required
                        @activate="activate"
                        :dummyInfo="dummyInfo"
                        :compactMode="true">
                    </dummy-payment-required>
                  </template>
                  <template v-if="!dummy">
                    <div v-if="activities && activities.length > 0" class="mb-1 px-1" style="min-width: 250px">
                      <template v-for="activity in activities" :key="activity.id">
                        <div>
                          <button
                              type="submit"
                              @click="updateActivity(activity)"
                              data-cy="switch-view"
                              :class="activity.next_statuses[0].check_in ? 'btn-outline-success' : 'btn-outline-danger'"
                              class="btn rounded-pill shadow-sm w-100 mt-1">
                            <template v-if="activity.next_statuses[0].check_in">
                              <div class="mb-1">
                                <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
                                <span class="mx-1">{{activity.title}}</span>
                              </div>
                              <div>
                                {{ $t('START_ACTIVITY') }}
                              </div>
                            </template>
                            <template v-if="activity.next_statuses[0].check_out">
                              <div class="mb-1">
                                <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
                                <span class="mx-1">{{activity.title}}</span>
                              </div>
                              <div>
                                {{ $t('FINISH_ACTIVITY') }}
                              </div>
                            </template>
                          </button>
                        </div>
                      </template>
                    </div>
                    <div v-else class="p-3">
                      {{$t('NO_ACTIVITIES_TO_UPDATE')}}
                    </div>
                  </template>
                </div>
              </div>
            </li>
          </template>
        </ul>
        <ul
          class="navbar-nav d-flex gap-10"
          :class="{ navBtn: firstSteps >= 100 }"
        >
          <li v-if="isEmployeeHasPermission('create-schedule') &&
           loaded === true &&
            firstSteps < 100" class="nav-item d-none d-md-block my-auto">
            <router-link class="p-0" :to="link">
              <div class="row p-0" id="demo_steps">
                <div class="col d-flex pr-0">
                  <div class="align-self-center">
                    <div class="text-secondary">
                      {{ $t('Create a first schedule') }}
                    </div>
                    <button
                      class="btn btn-outline-primary btn-sm-header rounded-pill btn-fix-width shadow-sm float-right">
                      {{ $t('Setup') }}
                    </button>
                  </div>
                </div>
                <div class="col-auto d-flex">
                  <el-progress
                    type="circle"
                    :width="50"
                    color="#17c671"
                    :percentage="firstSteps">
                  </el-progress>
                </div>
              </div>
            </router-link>
          </li>
          <li class="nav-item dropdown my-auto border-top no-md-border my-auto">
            <div class="head-link nav-link px-0"
                 style="cursor: pointer"
                 id="helpDropdown"
                 role="button"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false">
              <span class="header-icon text-center d-none d-md-block text-primary" style="font-size: 30px;">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="$t('HELP_TOOLTIP')"
                  placement="top"
                  :show-after="1000">
                   <i class="icon-help-circle"/>
                </el-tooltip>
              </span>
              <span class="d-md-none p-0 mx-2">
                <i class="icon-help-circle"></i>
              </span>
              <span class="d-md-none menu-color" v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Help') }}</span>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="helpDropdown">
              <a class="dropdown-item" :href="helpLink" target="_blank">
                {{ $t('Help articles') }}
              </a>
              <a class="dropdown-item" :href="socialLinks.youtube" target="_blank">
                {{ $t('Video tutorials') }}
              </a>
              <button class="dropdown-item" @click="openImpersonalization">
                {{ $t('Impersonalization') }}
              </button>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" :href="socialLinks.facebook" target="_blank">
                <i class="icon-facebook"></i> {{ $t('Facebook') }}
              </a>
              <a class="dropdown-item" :href="socialLinks.twitter" target="_blank">
                <i class="icon-twitter"></i> {{ $t('Twitter') }}
              </a>
              <a class="dropdown-item" :href="socialLinks.linkedin" target="_blank">
                <i class="icon-linkedin"></i> {{ $t('Linkedin') }}
              </a>
            </div>
          </li>
          <li v-if="companyLimitState > 0">
            <router-link
              to="/company"
              id="demo_business_account"
              class="btn btn-outline-secondary rounded-pill mr-lg-3 my-2">
              {{ $t('Create business account') }}
            </router-link>
          </li>
          <li class="nav-item dropdown d-none d-md-block my-auto" v-if="hasCompany">
            <div class="head-link nav-link px-0"
                 style="cursor: pointer"
                 id="alerts"
                 role="button"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false">
              <span class="header-icon text-center d-none d-md-block">
                <el-badge :value="alertsCount" :hidden="alertsCount === 0">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="$t('NOTIFICATIONS_TOOLTIP')"
                    placement="top"
                    :show-after="1000">
                    <i class="icon-bell"/>
                  </el-tooltip>
                </el-badge>
              </span>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="alertsDropdown" style="max-width: 450px">
              <alerts/>
            </div>
          </li>
          <template v-if="hasCompany &&
           attendance &&
            attendanceSettings &&
             attendanceSettings.interfaces.web.enabled">
          </template>
          <li v-if="hasCompany && isEmployeeHasPermission('get-employee-admin')"
              id="demo_employees"
              data-cy="company-employees"
              class="nav-item my-auto">
            <router-link :to="`/c/${companyId}/users`" class="nav-link d-none d-md-block my-auto p-0">
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('EMPLOYEES_TOOLTIP')"
                placement="top"
                :show-after="1000">
                <i class="icon-users" style="font-size: 24px"/>
              </el-tooltip>
            </router-link>
            <router-link class="d-md-none dropdown-item p-2 nav-link border-top" :to="`/c/${companyId}/users`">
              <i class="icon-users"></i>
              <span class="menu-color ml-2" v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Employees') }}</span>
            </router-link>
          </li>
          <li v-if="hasCompany &&
           isEmployeeHasPermission('create-employee') &&
           !isEmployeeHasPermission('get-employee-admin')"
              id="demo_employees_projects"
              @click="redirectToEmployees"
              data-cy="company-employees"
              class="nav-item my-auto">
            <div class="nav-link d-none d-md-block my-auto" style="cursor: pointer">
              <i class="icon-users" style="font-size: 24px"></i>
            </div>
            <div class="d-md-none dropdown-item p-2 nav-link border-top" style="cursor: pointer">
              <i class="icon-users"></i>
              <span class="menu-color ml-2" v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Employees') }}</span>
            </div>
          </li>
          <li class="nav-item dropdown border-top no-md-border my-auto" v-if="hasCompany">
            <div class="head-link nav-link px-0" style="cursor: pointer" id="requestsDropdown" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                  <span class="header-icon text-center d-none d-md-block">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="$t('REQUESTS_TOOLTIP')"
                      placement="top"
                      :show-after="1000">
                      <i class="icon-request"/>
                    </el-tooltip>
                  </span>
              <span class="d-md-none p-0 mx-2">
                    <i class="icon-request"></i>
                  </span>
              <span class="d-md-none menu-color"
                    v-bind:class="{ mobileItemStyle: isMobile }">{{ $t('Requests') }}</span>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="requestsDropdown">
              <a id="demo_request_time_off" class="dropdown-item" @click="openCreateRequestModal" data-toggle="modal"
                 data-target="#requestsModal" style="cursor: pointer">
                <i class="icon-plus-circle"></i>
                <span class="menu-color ml-2">{{ $t('Request Time-Off') }}</span>
              </a>

              <router-link id="demo_request_time_off_view" class="dropdown-item" :to="`/c/${companyId}/requests`">
                <i class="icon-eye"></i>
                <span class="menu-color ml-2">{{ $t('View requests') }}</span>
              </router-link>
            </div>
          </li>
          <li class="nav-item dropdown border-top no-md-border my-auto" v-if="hasCompany">
            <div class="head-link nav-link pointer-cursor d-flex align-items-center px-0" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false" id="companySettingsDropdown">
                  <span class="p-0 d-flex align-items-center">
                      <avatar
                        ref="logo"
                        class="rounded-circle d-inline-block m-auto pl-1"
                        :width="30"
                        :borderRadius="50"
                        :placeholder="true"
                        :placeholderUrl="'../../../static/images/App/upload.png'"
                        :companyIdForAvatar="companyId"
                        :mini="true"
                        :cache="headerCache">
                      </avatar>
                        <span class="menu-color company-name"
                              v-bind:class="{ mobileItemStyle: isMobile, 'ml-1': !isMobile }"> {{ company.name }}</span>
                  </span>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="companySettingsDropdown">
              <router-link id="demo_dashboard_dropdown_item"
                           v-if="isEmployeeHasPermission('get-dashboard-project-users')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/dashboard`">
                <i class="icon-grid" />
                <span class="menu-color ml-2">{{ $t('Dashboard') }}</span>
              </router-link>

              <router-link id="demo_user_salary"
                           v-if="isEmployeeHasPermission('get-shifts') &&
                            showSalary &&
                             !isEmployeeHasPermission('create-position')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/salary`">
                <i class="icon-users"></i>
                <span class="menu-color ml-2">{{ $t('USER_SALARY') }}</span>
              </router-link>

              <router-link id="demo_company_settings"
                           v-if="isEmployeeHasPermission('delete-company')"
                           class="dropdown-item menu-color"
                           :to="currentCompany">
                <el-badge is-dot class="item" :hidden="!!isWizardPassed">
                  <i class="icon-settings"></i>
                </el-badge>
                <span class="menu-color ml-2">{{ $t('Company settings') }}</span>
              </router-link>

              <router-link id="company_modules"
                           v-if="isEmployeeHasPermission('get-company-admin')"
                           class="dropdown-item menu-color"
                           :to="`/c/${companyId}/modules`">
                <el-badge is-dot class="item" :hidden="!!isWizardPassed">
                  <i class="icon-layers"></i>
                </el-badge>
                <span class="menu-color ml-2">{{ $t('MODULES') }}</span>
              </router-link>

              <router-link id="demo_availability" v-if="hasCompany" class="dropdown-item"
                           :to="{ name: 'ProfileAvailability', params: { id: companyId }}">
                <i class="icon-check-circle"></i>
                <span class="menu-color ml-2">{{ $t('When I am available') }}</span>
              </router-link>

              <router-link id="demo_my_files" class="dropdown-item" to="/files">
                <i class="icon-copy"></i>
                <span class="menu-color ml-2">{{ $t('My files') }}</span>
              </router-link>

              <router-link id="demo_company_files"
                           v-if="isEmployeeHasPermission('get-company-files-admin')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/files`">
                <i class="icon-file-text"></i>
                <span class="menu-color ml-2">{{ $t('_HEADER_ALL_COMPANY_FILES_LINK') }}</span>
              </router-link>

              <router-link id="demo_reports" v-if="isEmployeeHasOneOrMorePermissions([
                'get-budget-report',
                'get-budget-report-admin',
                'get-minimum-hours-per-week-report',
                'get-minimum-hours-per-week-report',
                'get-minimum-hours-per-week-report-admin',
                'get-pay-report',
                'get-pay-report-admin',
                'get-project-employees-report',
                'get-timeoff-report',
                'get-timeoff-report-admin',
                'get-working-hours-report',
                'get-working-hours-report-admin',
                'get-tasks-report',
                'get-tasks-report-admin',
                'get-time-sheet-export'
              ])" class="dropdown-item"
                           :to="`/c/${companyId}/statistic`">
                <i class="icon-bar-chart"></i>
                <span class="menu-color ml-2">{{ $t('Reports') }}</span>
              </router-link>

              <router-link id="demo_positions"
                           v-if="isEmployeeHasPermission('create-position')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/positions`">
                <i class="icon-price-tags text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('POSITIONS_MENU_ITEM') }}</span>
              </router-link>

              <router-link id="demo_skills"
                           v-if="isEmployeeHasPermission('create-skill')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/skills`">
                <i class="icon-library text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('SKILLS_MENU_ITEM') }}</span>
              </router-link>

              <router-link id="demo_locations"
                           v-if="isEmployeeHasPermission('create-location')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/locations`">
                <i class="icon-map-pin text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('LOCATIONS_MENU_ITEM') }}</span>
              </router-link>

              <router-link id="demo_referrals"
                           v-if="isEmployeeHasPermission('get-referrals')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/referrals`">
                <i class="icon-handshake-o text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('Referral system') }}</span>
              </router-link>

              <router-link id="company_logs"
                           v-if="isEmployeeHasPermission('delete-company') || employeeProfile.is_log_allowed"
                           class="dropdown-item"
                           :to="`/c/${companyId}/logs`">
                <i class="icon-align-justify text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('COMPANY_LOGS') }}</span>
              </router-link>

              <router-link id="marketplace"
                           v-if="isEmployeeHasPermission('get-company-apps')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/app-marketplace`">
                <i class="icon-grid text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('Apps Marketplace') }}</span>
              </router-link>

              <router-link id="developer"
                           v-if="isEmployeeHasPermission('get-company-apps')"
                           class="dropdown-item"
                           :to="`/c/${companyId}/developer`">
                <i class="icon-grid text-secondary" style="font-size: 0.9em"></i>
                <span class="menu-color ml-2">{{ $t('FOR_DEVELOPERS') }}</span>
              </router-link>

              <div v-if="isEmployeeHasPermission('delete-company')" class="dropdown-divider"></div>
              <router-link id="demo_payments"
                           v-if="isEmployeeHasPermission('delete-company')"
                           class="dropdown-item"
                           :to="`/c/${companyId}#balance`">
                <i class="icon-credit-card"></i>
                <span class="menu-color ml-2">{{ $t('Company balance') }}</span>
              </router-link>

              <div v-if="hasMoreThanOneCompany" class="dropdown-divider"></div>

              <a id="demo_change_company" v-if="hasMoreThanOneCompany" class="dropdown-item" @click="openModal"
                 data-toggle="modal"
                 data-target="#ChangeCompany">
                <i class="icon-repeat"></i>
                <span class="menu-color ml-2">{{ $t('Change company') }}</span>
              </a>
            </div>
          </li>
          <li class="nav-item dropdown border-top no-md-border my-auto">
            <div class="head-link nav-link pointer-cursor d-flex align-items-center pr-1 pl-0" id="mainMenuDropdown"
                 data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                  <span class="p-0 d-flex align-items-center">
                    <avatar
                      ref="avatar"
                      data-cy="profile-menu"
                      class="rounded-circle d-inline-block m-0 m-auto pl-1"
                      :width="30"
                      :borderRadius="50"
                      :placeholder="false"
                      :userId="userId"
                      :mini="true"
                      :cache="headerCache">
                    </avatar>
                  </span>
              <span class="menu-color d-md-none" v-bind:class="{ mobileItemStyle: isMobile }">{{ fullname }}</span>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="mainMenuDropdown">
              <router-link id="demo_dashboard" class="dropdown-item" :to="`/dashboard`">
                <i class="icon-grid"></i>
                <span class="menu-color ml-2">{{ $t('Calendar') }}</span>
              </router-link>

              <router-link id="demo_profile" class="dropdown-item" to="/profile#general">
                <i class="icon-user"></i>
                <span class="menu-color ml-2">{{ $t('My profile') }}</span>
              </router-link>

              <a v-if="Profile.register_provider === 'form'" id="demo_change_password" class="dropdown-item" @click="openChangePasswordModal" data-toggle="modal"
                 style="cursor: pointer"
                 data-target="#ChangePassword">
                <i class="icon-lock"></i>
                <span class="menu-color ml-2">{{ $t('Change password') }}</span>
              </a>

              <div class="dropdown-divider"></div>

              <a id="demo_logout" data-cy="logout" href="/logout" class="dropdown-item" @click.prevent="logout">
                <i class="icon-log-out"></i>
                <span class="menu-color ml-2">{{ $t('Log Out') }}</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import * as $ from 'jquery'
import { mapGetters, mapState } from 'vuex'
import Echo from 'laravel-echo'
import Avatar from './CommonComponents/Avatar'
import Alerts from '@/components/Alerts'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import momentMixin from '@/mixins/mixinMoment'
import setup from '@/modules/setup'
import AttendanceChoiceComponent from '@/components/CommonComponents/Attendance/AttendanceChoiceComponent'
import mixinDummy from '@/mixins/mixinDummy'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'

export default {
  mixins: [mixinWindowWidthWatch, momentMixin, mixinDummy],
  components: {
    DummyPaymentRequired,
    AttendanceChoiceComponent,
    ...mapGetters.modal,
    Avatar,
    Alerts
  },
  data: function () {
    return {
      nodeEnv: process.env.NODE_ENV,
      diffDays: 0,
      currCompanyId: '',
      modal: false,
      alertsCount: 0,
      avatarHashId: undefined,
      headerCache: true,
      canCreateCompany: false,
      hasProjectsByCompany: false,
      alertTimerId: null,
      polling: null,
      employeesCount: 1,
      schedules: [],
      loaded: false,
      employeeProfile: {}, // профиль сотрудника текущей компании
      employeePositions: {},
      attendance: null,
      modalType: null,
      object_id: null,
      dialogAttendanceVisible: false,
      nextAttendance: null,
      showPersonalizationModal: false,
      impersonalizationAccess: false,
      profile: null,
      attendanceSettings: null,
      timer: '',
      unpaidInvoice: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: {}
      },
      socialLinks: {
        twitter: 'https://twitter.com/shifton_com/',
        linkedin: 'https://www.linkedin.com/company/65830746/',
        facebook: 'https://www.facebook.com/shifton.en/',
        youtube: 'https://www.youtube.com/channel/UCzwHqjHVI-e9xLpWJRUmRHw'
      },
      activities: []
    }
  },
  name: 'HeaderV',
  created () {
    this.$eventBus.on('checkSteps', this.init)
    this.$eventBus.on('updateAvatar', () => {
      this.headerCache = false
      this.$refs.avatar.getAvatar()
    })
    this.$eventBus.on('updateLogo', () => {
      this.headerCache = false
      this.$refs.logo.getAvatar()
    })
    if (this.companyId) {
      this.pollData()
      this.init()
    }
  },
  beforeUnmount () {
    clearInterval(this.polling)
    this.cancelAutoUpdate()
  },
  mounted () {
    // TODO: v3 compat issue
    // this.$root.$on('updateAlertCount', () => {
    //   this.loadAlertsCount()
    // })
    if (localStorage.getItem('api_key') && this.$store.getters.companyId) {
      this.loadSchedule()
    }
    // сокеты через Pusher
    window.Pusher = require('pusher-js')
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_KEY,
      wsHost: process.env.VUE_APP_PUSHER_HOST,
      wsPort: process.env.VUE_APP_PUSHER_PORT,
      wssPort: process.env.VUE_APP_PUSHER_WSS_PORT,
      forceTLS: false,
      encrypted: process.env.VUE_APP_PUSHER_ENCRYPTED === true,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: `${process.env.VUE_APP_API_AUTH}broadcasting/auth`,
      auth:
        {
          headers:
            {
              Authorization: 'Bearer ' + localStorage.getItem('api_key')
            }
        }
    })
    this.makeChannel()
    this.refreshRole()
  },
  computed: {
    ...mapGetters([
      'Profile',
      'company',
      'profileId',
      'locale'
    ]),
    ...mapState({
      companyLimitState: state => (state.user.companyLimit - state.user.companyCount)
    }),
    showSalary () {
      if (this.company) {
        return this.company.salary &&
          this.company.salary.show_salary_to_users &&
          !this.company.is_blocked
      }
      return false
    },
    userId () {
      return this.$store.getters.profileId
    },
    isCompanyCreated: function () {
      return true
    },
    currentCompany () {
      return '/c/' + this.$store.getters.companyId + '#general'
    },
    currentProject () {
      let id = this.$store.getters.currentProjectId
      if (!id) {
        id = localStorage.getItem('currentProject')
      }
      if (!id && this.$store.getters.projectsCount > 0) {
        id = this.$store.getters.projects[0].id
      }
      return id
    },
    avatarHash () {
      return this.$store.getters.avatarHashId
    },
    fullname () {
      return this.$store.getters.userName
    },
    hasSchedule () {
      return this.$store.getters.hasSchedule
    },
    companyReminder () {
      let counter = 0
      if (this.isEmployeeHasPermission('create-schedule')) {
        if (!this.isWizardPassed) {
          counter++
        }
        if (!this.hasSchedule) {
          counter++
        }
        if (!this.hasProject) {
          counter++
        }
      }

      return counter
    },
    hasProject () {
      return this.hasProjectsByCompany
    },
    isWizardPassed () {
      return true
    },
    projectsCount () {
      return this.$store.getters.projectsCount
    },
    schedulesCount () {
      return this.$store.getters.schedulesCount
    },
    linkLang () {
      return this.locale === 'ru' ? 'ru' : 'en'
    },
    helpLink () {
      return `https://help.shifton.com/${this.linkLang}/home`
    },
    showProject () {
      return this.projectsCount < 1
    },
    showUsers () {
      return this.$store.getters.employeesCount <= 1
    },
    showSchedule () {
      if (this.schedules.length < 1) return true
      let manuallyCount = 0
      this.schedules.forEach(schedule => {
        if (schedule.schedule_type === 'manually') manuallyCount++
      })
      return this.schedules.length === 1 && manuallyCount > 0
    },
    firstSteps () {
      let value = 0
      if (!this.showProject) value += 33
      if (!this.showUsers) value += 33
      if (!this.showSchedule) value += 33
      if (!this.showProject && !this.showUsers && !this.showSchedule) value = 100
      return value
    },
    link () {
      if (this.showUsers) return `/c/${this.companyId}/users`
      if (this.showProject) return `/c/${this.companyId}/project`
      if (this.showSchedule) return `/c/${this.companyId}/schedule/master`
      return ''
    },
    dashboardLink () {
      if (this.isEmployeeHasPermission('get-dashboard-project-users')) return `/c/${this.companyId}/dashboard`
      return '/dashboard'
    },
    isLoaded () {
      return this.loaded
    },
    showTaskDropdown () {
      if (this.isEmployeeHasPermission('get-tasks-admin')) {
        return true
      }
      if (this.isEmployeeHasPermission('get-tasks-manager') &&
        this.taskSettings &&
        ['gg_user', 'gg_manager'].includes(this.taskSettings.min_role)) {
        return true
      }
      return !!(this.isEmployeeHasPermission('get-tasks-user') &&
        this.taskSettings &&
        this.taskSettings.min_role === 'gg_user' &&
        (this.taskSettings.positions_list.length === 0 ||
          this.taskSettings.positions_list.filter(item => {
            return this.employeePositions[item]
          }).length > 0))
    },
    isAttendanceShiftModal () {
      if (!this.attendance) {
        return false
      }
      let value = false
      this.attendance.next_events.forEach(next => {
        if ((next.type === 'shift_start') && next.data.length > 1) {
          value = true
        }
      })
      return value
    },
    isAttendanceBreakModal () {
      if (!this.attendance) {
        return false
      }
      let value = false
      this.attendance.next_events.forEach(next => {
        if ((next.type === 'break_start') && next.data.length > 1) {
          value = true
        }
      })
      return value
    }
  },
  watch: {
    companyId () {
      this.init()
      if (this.companyId) {
        this.makeCompanyChannel()
      }
    },
    '$route' () {
      $('#navbarTogglerLoggedBar').collapse('hide')
      $('.head-link').dropdown('hide')
    },
    avatarHash: function () {
      // отслеживаем загрузку нового аватара пользователем и инициализируем обновление аватарки в хедере
      this.headerCache = false
      setTimeout(() => {
        this.headerCache = true
      }, 2000)
    },
    projectsCount () {
      this.hasProjectsByCompany = !(this.projectsCount === 0)
    },
    schedulesCount () {
      this.schedules = this.$store.getters.schedules
    },
    profileId () {
      this.makeChannel()
    },
    locale () {
      this.setSocialLink()
    }
  },
  methods: {
    toggleBellMenu () {
      const el = document.getElementById('navbarTogglerLoggedBar')
      el.classList.remove('show')
    },
    toggleMainMenu () {
      // const el = document.getElementById('bellModal')
      // el.classList.remove('show')
    },
    checkUnpaidInvoices () {
      // TODO: переписать на стор
      this.$store.dispatch('getUnpaidedInvoices', '?status=created').then(response => {
        if (response && response.result === true) {
          this.unpaidInvoice = true
        } else {
          this.unpaidInvoice = false
        }
      })
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
    openImpersonalization () {
      this.$store.dispatch('dropProfile').then(() => {
        this.$store.dispatch('getProfile').then(profile => {
          this.profile = profile
          this.impersonalizationAccess = profile.impersonalization_access
        })
      })
      this.showPersonalizationModal = true
    },
    handleClose () {
      this.showPersonalizationModal = false
    },
    saveImpersonalization () {
      if (this.profile.impersonalization_access !== this.impersonalizationAccess) {
        const data = {
          impersonalization_access: this.impersonalizationAccess
        }
        this.$store.dispatch('setProfile', data).then((response) => {
          this.profile = response
          this.impersonalizationAccess = response.impersonalization_access
          this.toastSuccess(this.$i18n?.t('Profile successfully saved'))
        }).catch(e => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }

      this.showPersonalizationModal = false
    },
    redirectToEmployees () {
      const projectId = localStorage.getItem('currentProject')
      this.$router.push(`/c/${this.companyId}/project/${projectId}/users`)
    },
    buttonAttendanceClass (event) {
      if (event.type === 'shift_start') {
        return 'btn-outline-success'
      }
      if (event.type === 'shift_end') {
        return 'btn-outline-danger'
      }
      if (event.type === 'break_start' || event.type === 'break_end') {
        return 'btn-outline-primary'
      }
      return 'btn-outline-primary'
    },
    getAttendance () {
      this.dummy = false
      this.$store.dispatch('getAttendance').then(response => {
        this.dummy = false
        this.attendance = response
        if (!this.attendanceSettings) {
          this.getModulesByCompany()
        }
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          error.response.data.module_id = error.response.data.id
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    activate () {
      this.$eventBus.emit('reloadDummy', 'attendance')
      this.$eventBus.emit('reloadDummy', 'activities')
      this.$nextTick(() => {
        this.init()
      })
    },
    updateAttendance (event) {
      this.dialogAttendanceVisible = false
      if ((this.isAttendanceShiftModal && event.type === 'shift_start') ||
        (this.isAttendanceBreakModal && event.type === 'break_start')) {
        this.nextAttendance = event
        this.dialogAttendanceVisible = true
      } else {
        const scheduleId = event.data && event.data.length > 0 && !event.data[0].id ? event.data[0].schedule_id : null
        this.proceedAttendance(event.type, null, scheduleId)
      }
    },
    proceedAttendance (type, objectId = null, scheduleId = null) {
      const payload = {
        type
      }
      if (objectId) {
        payload.object_id = objectId
      }

      if (scheduleId) {
        payload.schedule_id = scheduleId
      }

      this.$store.dispatch('updateAttendance', payload).then(response => {
        this.getAttendance()
        this.$eventBus.emit('getAttendance')
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
      })
    },
    getActivities () {
      this.dummy = false
      this.$store.dispatch('getPersonalActivity', this.companyId).then(response => {
        this.dummy = false
        this.activities = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          error.response.data.module_id = error.response.data.id
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    updateActivity (activity) {
      const payload = {
        id: activity.id,
        next_status_id: activity.next_statuses[0].id
      }
      this.$store.dispatch('patchPersonalShiftActivity', [this.companyId, payload]).then(response => {
        this.$eventBus.emit('updateActivityStatus')
        this.toastSuccess(this.$i18n?.t('ACTIVITY_UPDATED'))
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    pollData () {
      this.polling = setInterval(() => {
        this.$store.dispatch('clearCompanyStore')
        this.$store.dispatch('getCompany', this.companyId)
      }, 60 * 60 * 1000)
    },
    makeChannel () {
      if (this.profileId) {
        const privateChannel = window.Echo.private('App.User.' + this.$store.getters.profileId)
        privateChannel.notification((notification) => {
          if (notification.message === 'generation_processed') {
            const generationInfo = {
              data: notification.data,
              status: notification.message
            }
            this.$store.dispatch('setGenerationInfo', generationInfo)
          }

          if (notification.message === 'update_task') {
            this.$store.commit('UPDATE_TASKS_INDEXED', notification.data.task)
            this.$eventBus.emit('taskCalendarRefreshEvents')
          }
          if (notification.message === 'check_alerts') {
            this.loadAlertsCount()
            this.$eventBus.emit('updateAlerts')
          }
          if (notification.message === 'refresh_account') {
            this.$store.dispatch('dropProfile')
            this.$store.dispatch('clearCompanyStore')
            this.refreshRole()
          }
          if (notification.message.type === 'module_updated') {
            const fn = setup[notification.message.module_permission_id]
            if (notification.message.action === 'enable') {
              if (fn) {
                fn.install()
              }
            }
            if (notification.message.action === 'disable') {
              if (fn) {
                fn.uninstall()
              }
            }
          }
        })
      }
    },
    makeCompanyChannel () {
      const privateChannelCompany = window.Echo.private('App.Company.' + this.companyId)
      privateChannelCompany.listen('CheckCompanySettingsEvent', notification => {
        if (notification.message === 'update_modules') {
          this.$store.dispatch('clearCompanyStore').then(async () => {
            await this.$store.dispatch('getCompany', this.$store.getters.companyId)
            await this.$store.dispatch('updateShouldUpdateWeekStatistic', true)
            this.$eventBus.emit('refetchEvents')
            this.$store.dispatch('getModulesByCompany', this.$store.getters.companyId)
          })
        }
      })
    },
    init () {
      this.setSocialLink()
      this.getModulesByCompany()
      this.getAttendance()
      this.checkUnpaidInvoices()
      this.timer = setInterval(this.checkUnpaidInvoices, 1000 * 60 * 5)
      const coworkers = new Promise(resolve => {
        this.$store.dispatch('getEmployeesByCompany', this.companyId).then(result => {
          const employees = this.$store.getters.employees
          this.employeesCount = this.$store.getters.employeesCount
          const profileId = this.$store.getters.profileId

          employees.some(employee => {
            if (+employee.user_id === +profileId) {
              this.employeeProfile = employee
              this.employeePositions = {}
              this.employeeProfile.positions.forEach(position => {
                this.employeePositions[position.id] = true
              })

              return true
            }

            return false
          })
          resolve(result)
        })
      })
      const projects = new Promise(resolve => {
        this.$store.dispatch('getProjectsByCompany', this.$store.getters.companyId).then(projects => {
          resolve(projects)
          this.hasProjectsByCompany = !(this.$store.getters.projectsCount === 0)
          this.$store.dispatch('setProjectRole', this.$store.getters.projects)
        })
      })
      const schedules = new Promise(resolve => {
        this.$store.dispatch('getSchedules', this.$store.getters.companyId).then(schedules => {
          resolve(schedules)
          this.schedules = schedules
        })
      })
      Promise.all([coworkers, projects, schedules]).then(() => {
        this.loaded = true
      })
      this.loadAlertsCount()
    },
    getModulesByCompany () {
      this.$store.dispatch('getModulesByCompany').then(response => {
        this.companyModules = response.forEach((module) => {
          if (+module.module_id === 24) {
            this.module = module
            if (!Array.isArray(this.module.settings)) {
              this.attendanceSettings = this.module.settings
            }
          }
          if (+module.module_id === 8) { // базовый модуль
            if (!Array.isArray(module.settings)) {
              const warningInPast = !!module.settings.should_warning_shift_in_past
              this.$store.commit('warningInPast', warningInPast)
              this.$store.commit('working_hours_display_type', module.settings.working_hours_display_type)
              this.$store.commit('require_user_confirm', module.settings.require_user_confirm)
            }
          }
        })
      })
    },
    loadAlertsCount () {
      this.$store.dispatch('getAlertsCount', this.$store.getters.companyId).then((response) => {
        if (response) {
          this.alertsCount = response.unread_notifications_count
        }
      })
    },
    refreshRole () {
      this.$store.dispatch('getProfile').then(() => {
        let id = this.$store.getters.companyId
        if (!id) {
          id = this.$store.getters.Profile.companies[0]
        }
        if (id) this.$store.dispatch('getCompany', id)
      })
    },
    loadSchedule () {
      this.$store.dispatch('getSchedules', this.$store.getters.companyId).then(() => {
      }).catch(() => {})
    },
    logout: function () {
      this.$store.dispatch('logout', this.$store.getters.locale).then(() => {
      }).catch(() => {
      })
    },
    openModal: function () {
      this.$store.state.modal = true
      this.$store.dispatch('getCompanies')
    },
    openChangePasswordModal: function () {
      this.$emit('openChangePasswordModal')
    },
    openCreateRequestModal: function () {
      this.$emit('openCreateRequestModal')
    },
    setSocialLink () {
      switch (this.locale) {
        case 'ru':
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.ru/'
          this.socialLinks.youtube = 'https://www.youtube.com/channel/UCzwHqjHVI-e9xLpWJRUmRHw'
          break
        case 'uk':
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.ua/'
          this.socialLinks.youtube = 'https://www.youtube.com/@shifton-ua5029'
          break
        default:
          this.socialLinks.facebook = 'https://www.facebook.com/shifton.en/'
          this.socialLinks.youtube = 'https://www.youtube.com/@shifton-eng8848'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all');

.menu-color {
  color: #4a4a4a !important;
}

@media (min-width: 768px) {
  .no-md-border {
    border-top: 0 !important;
  }
  .no-md-border-bottom {
    border-bottom: 0 !important;
  }
}

.header-icon {
  font-size: 24px;
}

.header-icon-mobile {
  width: 39.5px;
  height: 39.5px;
  font-size: 22.5px;
  padding: 7.5px;
}

.bage {
  right: 15px;
  top: 0;
  height: 20px;
  width: 20px;
  color: white;
  font-size: 10px;
  line-height: 18px;
}

.company-name {
  font-family: "Open Sans";
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle,
div#attendanceDropdown .btn {
  border-radius: 0px 7px 7px 0px !important;
}

a#demo_company_schedule .btn,
#field_service,
#activity_service,
#activity_dropdown,
#locationControl, #shift_activities {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
  border: none;
}

a#demo_company_schedule .btn:hover,
div#attendanceDropdown .btn:hover,
div#tasksDropdown .btn:hover,
li#demo_tasks_button .btn:hover,
.scheduling-btn:hover,
#field_service:hover,
#activity_service:hover,
#activity_dropdown:hover,
#tasksDropdown:hover,
#locationControl:hover, #shift_activities:hover {
  background-color: #eceeef;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

div#attendanceDropdown .btn,
div#tasksDropdown .btn,
li#demo_tasks_button .btn,
#locationControl {
  border-radius: 7px !important;
  background-color: transparent;
  border: none;
}

.scheduling-btn {
  background-color: transparent;
  border: none;
}

.mobile-menu-icon {
  color: rgba(0, 0, 0, 0.5);
}

.mobileItemStyle {
  padding-left: 1.25rem;
}

.badge-pos {
  position: absolute;
  top: 0;
  right: -5px;
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.gap-10 {
  gap: 10px
}

.gap-5 {
  gap: 5px
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>

<style lang="scss">
.el-badge__content.is-dot {
  height: 10px !important;
  width: 10px !important;
}

.el-progress__text {
  font-size: 12px !important;
}
</style>
