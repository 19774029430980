<template>
  <div>
    <div id="mainAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            {{ $t('COMPANY_MAIN_SETTINGS') }}
            <ui-hint v-if="moduleInfo" :content="moduleInfo"/>
          </h5>
          <div class="font-weight-bold text-warning mt-2">{{ $t('SCHEDULES_SETTINGS_SUBTITLE') }}</div>
        </div>
        <Form ref="observer" as="div" class="card-body">
          <div v-if="isEmployeeHasPermission('delete-company')" class="form-row">
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="disable_change_username"
                v-model="disable_change_username"
                :label="$t('DISABLE_CHANGE_USERNAME')"
                :hint="$t('DISABLE_CHANGE_USERNAME_HINT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('WARNING_CHANGE_SHIFTS_IN_PAST') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="shouldWarningShiftInPast"
                v-model="shouldWarningShiftInPast"
                :label="$t('SHOULD_WARNING_SHIFT_IN_PAST')"
                :hint="$t('SHOULD_WARNING_SHIFT_IN_PAST_HINT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('WORKING_HOURS_DISPLAY_TYPE_HEADER') }}
                <ui-hint :content="$t('WORKING_HOURS_DISPLAY_TYPE_HINT')" />
              </span>
            </el-divider>
            <div class="col-lg-12 mb-1 font-weight-bold">
              {{ $t('WORKING_HOURS_DISPLAY_TYPE') }}
            </div>
            <div class="col-lg-12 mb-2">
              <ui-radio
                name="working_hours_display_type"
                :label="$t('HIDE_WORKING_TIME')"
                :hint="$t('HIDE_WORKING_TIME_HINT')"
                v-model="working_hours_display_type"
                :set-value="'hide'"
              />
              <ui-radio
                name="working_hours_display_type"
                :label="$t('OWN_WORKING_TIME')"
                :hint="$t('OWN_WORKING_TIME_HINT')"
                v-model="working_hours_display_type"
                :set-value="'own'"
              />
              <ui-radio
                name="working_hours_display_type"
                :label="$t('ALL_WORKING_TIME')"
                :hint="$t('ALL_WORKING_TIME_HINT')"
                v-model="working_hours_display_type"
                :set-value="'all'"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('REQUIRE_USER_CONFIRM_ON_SHIFTS_CHANGES_HEADER') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="require_user_confirm"
                v-model="require_user_confirm"
                :label="$t('REQUIRE_USER_CONFIRM_ON_SHIFTS_CHANGES')"
                :hint="$t('REQUIRE_USER_CONFIRM_HINT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('SHIFT_OVERLAP_LEVEL_HEADER') }}
              </span>
            </el-divider>
            <div class="mb-1 col-lg-12 font-weight-bold">{{ $t('SHIFT_OVERLAP_LEVEL') }}</div>
            <div class="col-lg-12 mb-2">
              <ui-radio
                name="block_overlap_level"
                :label="$t('Company')"
                v-model="block_overlap_level"
                :set-value="'company'"
                :hint="$t('COMPANY_OVERLAP_LEVEL_HINT')"
              />
              <ui-radio
                name="block_overlap_level"
                :label="$t('Project')"
                v-model="block_overlap_level"
                :set-value="'project'"
                :hint="$t('PROJECT_OVERLAP_LEVEL_HINT')"
              />
              <ui-radio
                name="block_overlap_level"
                :label="$t('ALLOW_SHIFTS_OVERLAP')"
                v-model="block_overlap_level"
                :set-value="'no_control'"
                :hint="$t('ALLOW_OVERLAP_HINT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('ALLOW_EMPLOYEE_SET_MARK_ON_SHIFT_TITLE') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="allow_set_mark"
                v-model="allow_set_mark"
                :label="$t('ALLOW_EMPLOYEE_SET_MARK_ON_SHIFT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('ALLOW_EMPLOYEE_SET_LOCATION_ON_SHIFT_TITLE') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="allow_set_location"
                v-model="allow_set_location"
                :label="$t('ALLOW_EMPLOYEE_SET_LOCATION_ON_SHIFT')"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('SHIFTS_EXCHANGE_DAYS_RANGE_ALLOWED_TITLE') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-number-input
                name="shifts_exchange_days_range_allowed"
                class="w-50"
                v-model="shifts_exchange_days_range_allowed"
                :validation="{required: true, integer: true, max_value: 30, min_value: 1}"
                :precision="0"
                :step="1"
                :min="1"
              />
            </div>
          </div>
          <div class="form-row">
            <el-divider content-position="left">
              <span class="text-primary text-uppercase font-weight-bold">
                {{ $t('COMPANY_NOTIFICATIONS_SETTINGS') }}
              </span>
            </el-divider>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="enable_telegram_notification"
                :hint="$t('ENABLE_TELEGRAM_NOTIFICATION_HINT')"
                v-model="enable_telegram_notification"
                :label="$t('ENABLE_TELEGRAM_NOTIFICATION')"
              />
            </div>
            <div class="col-md-12 form-group">
              <ui-checkbox
                name="enable_sms_notification"
                :hint="$t('ENABLE_SMS_NOTIFICATION_HINT')"
                v-model="enable_sms_notification"
                :label="$t('ENABLE_SMS_NOTIFICATION')"
              />
            </div>
          </div>
        </Form>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm">{{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsBasic',
  data () {
    return {
      shouldWarningShiftInPast: true,
      working_hours_display_type: 'hide',
      require_user_confirm: false,
      block_overlap_level: 'company',
      module: null,
      allow_set_mark: false,
      allow_set_location: false,
      disable_change_username: false,
      moduleInfo: null,
      enable_telegram_notification: true,
      enable_sms_notification: false,
      shifts_exchange_days_range_allowed: 7
    }
  },
  created () {
    this.disable_change_username = this.$store.getters.company.disable_change_username
    this.setModules()
  },
  computed: {
    ModulesByCompany () {
      return this.$store.getters.ModulesByCompany
    },
    locale () {
      return this.$store.getters.locale
    }
  },
  methods: {
    setModules () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 8) { // базовый модуль
          this.module = module
          // console.log(module)
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.shouldWarningShiftInPast = !!this.module.settings.should_warning_shift_in_past
            this.working_hours_display_type = this.module.settings.working_hours_display_type
            this.require_user_confirm = !!this.module.settings.require_user_confirm
            this.block_overlap_level = this.module.settings.block_overlap_level
            this.allow_set_mark = !!this.module.settings.allow_set_mark
            this.allow_set_location = !!this.module.settings.allow_set_location
            this.enable_telegram_notification = this.module.settings.enable_telegram_notification === null || this.module.settings.enable_telegram_notification === undefined ? true : this.module.settings.enable_telegram_notification
            this.enable_sms_notification = !!this.module.settings.enable_sms_notification
            this.shifts_exchange_days_range_allowed = this.module.settings.shifts_exchange_days_range_allowed || 7
          }
        }
      })
    },
    async submitForm () {
      try {
        const result = await this.$refs.observer.validate()
        if (result.valid) {
          const requests = []

          if (this.isEmployeeHasPermission('delete-company')) {
            requests.push(this.$store.dispatch('editCompany', [
              this.$store.getters.company.id,
              { disable_change_username: this.disable_change_username }
            ]))
          }

          requests.push(this.$store.dispatch('updateModuleSettings', [
            this.$store.getters.companyId,
            this.module.id,
            {
              settings: {
                should_warning_shift_in_past: this.shouldWarningShiftInPast,
                working_hours_display_type: this.working_hours_display_type,
                require_user_confirm: this.require_user_confirm,
                block_overlap_level: this.block_overlap_level,
                allow_set_mark: this.allow_set_mark,
                allow_set_location: this.allow_set_location,
                enable_telegram_notification: this.enable_telegram_notification,
                enable_sms_notification: this.enable_sms_notification,
                shifts_exchange_days_range_allowed: this.shifts_exchange_days_range_allowed
              }
            }
          ]))

          await Promise.all(requests)

          this.$emit('success')
        }
      } catch (error) {
        this.$emit('error')
      }
    }
  }
}
</script>
