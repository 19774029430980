<template>
  <div>
    <TaskModal
      v-if="showTaskModal"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      :disableClient="false"
      @closeTaskModal="closeTaskModal"
      @reloadTasks="init"
      :title="'TASK_EVENT_MODAL'"
    />
    <div data-toggle="modal" data-target="#ClickTaskModal" id="taskModalID"></div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-else>
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <tab-navigation-component :tabs="tabs" />
          <div class="row pt-2">
            <div class="col">
              <div class="text-primary text-uppercase font-weight-bold m-1">
                {{ $t("Tasks map") }}
                <ui-hint :content="$t('TASKS_MAP_HINTS')" />
              </div>
            </div>
            <div class="col-auto">
              <div class="row align-items-center">
                <div class="col-auto">
                  <button
                    class="btn btn-primary btn-sm rounded-pill mx-3"
                    data-toggle="modal"
                    data-target="#ClickTaskModal"
                    type="button"
                    @click="addTask">
                    {{ $t('Create Task') }}
                  </button>
                </div>
                <div class="col-auto">
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(-1)"
                    icon="Arrow-left">
                  </el-button>
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(0)"
                  >
                    {{$t('TODAY')}}
                  </el-button>
                  <el-button
                    class="mx-1"
                    type="primary"
                    @click="changeDate(1)"
                    icon="Arrow-right">
                  </el-button>
                </div>
                <div class="col-auto">
                  <ui-date-picker
                    :pickerType="'daterange'"
                    name="from_to_date"
                    v-model="period"
                    :validation="{required: true}"
                    :clearable="false"
                    :picker-options="{firstDayOfWeek: +$store.getters.company.salary.week_start + 1}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-lg-6">
              <div class="w-100 d-inline-flex align-items-center">
                <ui-multi-select
                  name="employees"
                  :placeholder="$t('Filter employees')"
                  class="w-75"
                  v-model="selectdEmployees"
                  :options="employees"
                  :key-name="'id'"
                  :label-name="'name'"
                  :value-name="'id'"
                />
                <el-button
                  class="mx-1"
                  type="primary"
                  @click="filterEmployees"
                  icon="Search">
                </el-button>
              </div>
            </div>
          </div>
          <div>
              <GmvMap
                mapId="mapId"
                id="map"
                ref="mapRef"
                :center="center"
                :zoom='11'
                map-type-id="roadmap"
                style="width:100%;  height: 65vh;"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                  }"
              >
<!--                <GmvMarker-->
<!--                  ref="myMarker"-->
<!--                  :key="index"-->
<!--                  v-for="(m, index) in searchMarkers"-->
<!--                  :clickable="true"-->
<!--                  :position="m.client.position"-->
<!--                  :icon="m.icon"-->
<!--                  @click="clickMarker(m)"-->
<!--                  @mouseover="toggleInfoWindow(m, index)"-->
<!--                  @mouseout="closeInfoWindow()"-->
<!--                />-->
            <GmvInfoWindow
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false"
            >
              <div>
                <div class="mb-1"><span class="font-weight-bold">{{$t('Title')}}</span>: {{ infoContent.title }}</div>
                <div class="mb-1"><span class="font-weight-bold">{{$t('Client')}}</span>: {{ infoContent.client.name }}</div>
                <div v-if="infoContent.scheduled_time" class="mb-1"><span class="font-weight-bold">{{$t('Schedule time')}}</span>: {{ toTimeZone(infoContent.scheduled_time).format(localeDateTimeFormat) }}</div>
                <div class="mb-1"><span class="font-weight-bold">{{$t('Address')}}</span>: {{ infoContent.client.address_formated }}</div>
                <div v-if="infoContent.employee" class="mb-1"><span class="font-weight-bold">{{$t('Employee')}}</span>: {{ infoContent.employee.full_name }}</div>
                <div class="mb-1"><span class="font-weight-bold">{{$t('Status')}}</span>: {{ infoContent.status.title }} <span v-if="infoContent.status.time">({{ toTimeZone(infoContent.status.time).format(localeDateTimeFormat) }})</span> </div>
                <!-- <div class="mb-1"><span class="font-weight-bold">{{$t('Current status')}}</span>: {{ 'Test status' }}</div> -->
              </div>
            </GmvInfoWindow>
            <!-- <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(1.38, 103.87)" /> -->
            </GmvMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import moment from 'moment'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import mixinDummy from '@/mixins/mixinDummy'
import { utilities } from '@gmap-vue/v3'
import { toRaw } from 'vue'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'TasksMap',
  components: { TaskModal, TabNavigationComponent: TabNavigationComponent, DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy],
  data () {
    return {
      center: { lat: 0, lng: 0 },
      infoContent: {
        title: '',
        description: '',
        scheduled_time: null,
        client: {
          position: { lat: 0, lng: 0 },
          name: '',
          address: ''
        },
        status: {
          title: this.$i18n?.t('New'),
          time: null
        },
        employee: {
          first_name: '',
          last_name: ''
        }
      },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      showSearchInput: false,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -40
        }
      },
      period: [moment().format(this.backendDateFormat), moment().add(1, 'days').format(this.backendDateFormat)],
      indexLocation: null,
      showByIndex: null,
      list: [],
      searchQuery: '',
      tabs: [],
      activeTab: 'active',
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      taskModalData: {
        id: null,
        children: []
      },
      showTaskModal: false,
      markers: [],
      searchMarkers: [],
      markersToShow: [],
      selectdEmployees: [],
      employees: [],
      map: null,
      google: null
    }
  },
  mounted () {
    this.initAutocomplete()
  },
  created () {
    this.initGoogle()
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
      this.setTubs()
    }
  },
  beforeUnmount () {
    toRaw(this.markersToShow).forEach(marker => {
      marker.setMap(null)
    })
    this.markersToShow = []
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    google: {
      handler () {
        if (this.google.maps && this.map) {
          this.updateMarkers(this.searchMarkers)
        }
      },
      deep: true
    },
    searchMarkers () {
      if (this.google.maps && this.map) {
        this.updateMarkers(this.searchMarkers)
      }
    },
    companyId () {
      if (this.companyId) {
        this.checkAccess(this.companyId)
        this.init()
        this.setTubs()
      }
    },
    period () {
      this.init()
    }
  },
  methods: {
    initGoogle () {
      this.google = getGoogleMapsAPI()
      if (!this.google) {
        setTimeout(() => {
          this.initGoogle()
        }, 1000)
      }
    },
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: true
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    addTask () {
      this.taskModalData = {
        id: null,
        title: null,
        description: null,
        client_id: null,
        client: null,
        client_name: this.name,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: []
      }
      this.showTaskModal = true
    },
    filterEmployees () {
      if (this.selectdEmployees.length > 0) {
        this.searchMarkers = this.markers.filter(item => this.selectdEmployees.includes(item.employee_id))
      } else {
        this.searchMarkers = this.markers
      }
      // searchMarkers
    },
    changeDate (val) {
      if (val === 0) {
        this.period = [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
      } else {
        const date = moment(this.period[0], this.backendDateFormat).add(val, 'days').format(this.backendDateFormat)
        this.period = [date, date]
      }
    },
    initAutocomplete () {
      try {
        this.$refs.mapRef.mapPromise?.then((map) => {
          this.map = map
          // Create the search box and link it to the UI element.

          // find and set current position
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              let crd = pos.coords
              const lat = crd.latitude
              const lng = crd.longitude
              this.$refs.mapRef?.mapPromise?.then((map) => {
                map.panTo({ lat: lat, lng: lng })
              })
            },
            (err) => {
              console.log(err.message)
              // alert('Geocode was not successful for the following reason: ' + err.message)
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0
            })
        })
        this.$nextTick(() => {
          if (this.google.maps && this.map) {
            this.updateMarkers(this.searchMarkers)
          }
        })
      } catch (e) {
        setTimeout(() => {
          this.initAutocomplete()
        }, 1000)
      }
    },
    updateMarkers (tasks) {
      toRaw(this.markersToShow).forEach(marker => {
        marker.setMap(null)
      })
      this.markersToShow = []
      this.infoList = null
      const marks = []
      tasks.forEach((task, index) => {
        if (task.client && task.client.longitude && task.client.latitude) {
          const marker = new this.google.maps.Marker({
            position: {
              lat: +task.client.latitude,
              lng: +task.client.longitude
            },
            map: this.map,
            task: task
          })
          marker.addListener('mouseover', () => {
            this.toggleInfoWindow(marker, index)
          })
          marker.addListener('mouseout', () => {
            this.closeInfoWindow()
          })
          marker.addListener('click', () => {
            this.clickMarker(marker)
          })
          marks.push(marker)
        }
      })
      if (marks[0]) {
        this.$refs.mapRef.mapPromise?.then((map) => {
          map.panTo(marks[0].position)
        })
      }
      this.markersToShow = marks
    },
    clickMarker (marker) {
      // if (marker.scheduled_time) {
      //   marker.scheduled_time = this.toTimeZone(marker.scheduled_time)
      // }
      this.taskModalData = marker
      this.showTaskModal = true
      // console.log('click marker', marker)
      document.getElementById('taskModalID').click()
      this.$nextTick(() => {
        document.getElementById('taskModalID').click()
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.task.client.position
      this.infoContent = marker.task
      this.infoWinOpen = true
      this.currentMidx = idx
    },
    closeTaskModal () {
      this.showTaskModal = false
      this.taskModalData = {
        id: null,
        children: []
      }
      this.init()
    },
    closeInfoWindow () {
      this.infoWinOpen = false
    },
    getInfoWindowContent (marker) {
      return marker.description
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    stringifyObject (objData, joinSymbol = '&') {
      let options = []
      for (let optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    init () {
      this.markers = []
      this.searchMarkers = []
      if (!this.hasCompany) {
        this.accessErrorRedirect('In order to access this page, you must first create a company')
      }
      let data = {
        status: 'all',
        // page: this.pagination.currentPage,
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
        if (response) {
          let tasks = response.filter(item => item.client !== null)
          // console.log(tasks)
          let employees = {}
          tasks.map((task) => {
            task.icon = null

            // дефолтный цвет таски
            let statusColor = '#958d19'

            // если нет статусов по дефолту New
            task.status = {
              title: this.$i18n?.t('New'),
              time: null
            }

            // инфа по последнему выставленному статусу
            if (task.logs && task.logs.length > 0) {
              const log = task.logs[task.logs.length - 1]
              statusColor = log.status.color
              task.status = log.status
              task.status.time = log.created_at
            }

            // урл принимает цвет без символа #
            statusColor = statusColor.replace('#', '')
            task.icon = `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${statusColor}` // 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'

            // координаты пометки на карте
            if (task.client) {
              task.client.position = {
                lat: task.client.latitude,
                lng: task.client.longitude
              }
              task.client.address_formated = task.client.address_data && task.client.address_data.name ? task.client.address_data.name : task.client.address
            }

            if (task.employee_id && task.employee && !(task.employee_id in employees)) {
              employees[task.employee_id] = {
                name: task.employee.full_name,
                id: task.employee_id
              }
            }
            return task
          })
          this.employees = Object.values(employees)
          this.markers = toRaw(tasks)
          this.searchMarkers = toRaw(tasks)
        }
        // console.log(response.filter(item => item.client !== null))
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        // console.log(error)
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>
#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}
</style>
