export default {
  methods: {
    setupDummyData (err) {
      // console.log(3, {
      //   title: err.module.locales[this.locale] ? err.module.locales[this.locale].name : err.module.name,
      //   trial: err.module.trial_period,
      //   price: +err.module.price,
      //   perUser: +err.module.user_price,
      //   isActive: err.module.is_active,
      //   id: +err.module.id,
      //   description: err.module.locales[this.locale] ? err.module.locales[this.locale]['short_description'] : err.module.short_description,
      //   intercomArticle: err.module.intercom_article
      // })
      if (!err) {
        return {}
      }

      return {
        title: err.module.locales[this.locale] ? err.module.locales[this.locale].name : err.module.name,
        trial: err.module.trial_period,
        price: +err.module.price,
        perUser: +err.module.user_price,
        isActive: err.module.is_active,
        id: +err.module.id,
        description: err.module.locales[this.locale] ? err.module.locales[this.locale].description : err.module.description,
        short_description: err.module.locales[this.locale] ? err.module.locales[this.locale].short_description : err.module.short_description,
        helpCenter: err.module.help_center,
        features: err.module.features,
        images: err.module.images
      }
    }
  }
}
