import { createApp } from 'vue'
import { createGmapVuePlugin } from '@gmap-vue/v3'
import '@gmap-vue/v3/dist/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import { useI18n } from 'vue-i18n'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createGtm } from '@gtm-support/vue-gtm'
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'
import { initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'
import ToastMixin from '@/mixins/ToastMixin'
import mitt from 'mitt'
import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate'
import * as AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import dictionary from './validators/dictionaries/veevalidateDictionaries'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueAuthenticate from 'vue-authenticate-shifton'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/style.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueMask from '@devindex/vue-mask'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

// UI library
import UiAutocomplete from './ui/autocomplete'
import UiCheckbox from './ui/checkbox'
import UiCheckboxButton from './ui/checkbox-button'
import UiCheckboxGroup from './ui/checkbox-group'
import UiColorPicker from './ui/color-picker'
import UiDatePicker from './ui/date-picker'
import UiDatetimePicker from './ui/datetime-picker'
import UiDropdown from './ui/dropdown'
import UiFileUpload from './ui/upload-file'
import UiFormRow from './ui/form-row'
import UiGroupSelect from './ui/group-select'
import UiHint from './ui/hint'
import UiInputLabel from './ui/input-label.vue'
import UiIntegerInput from './ui/integer-input'
import UiMaskedInput from './ui/masked-input'
import UiMonthPicker from './ui/month-picker.vue'
import UiMonthRangePicker from './ui/month-range-picker'
import UiMultiSelect from './ui/multi-select'
import UiNumberInput from './ui/number-input'
import UiPeriodPicker from './ui/period-picker'
import UiRadio from './ui/radio'
import UIRadioGroup from './ui/radio-group'
import UiSelect from './ui/select'
import UiSwitch from './ui/switch.vue'
import UiTagsSelect from './ui/tags-select'
import UiTelInput from './ui/tel-input'
import UiTextInput from './ui/text-input'
import UiTextareaInput from './ui/textarea-input'
import UiTimePicker from './ui/time-picker'
import UiTooltip from './ui/tooltip'
import { decimalValidator } from '@/validators/decimal'
import { greaterOrEqualThanZeroValidator } from '@/validators/greaterOrEqualThanZero'
import { ipValidator } from '@/validators/ip'
import { decimalOrNullValidator } from '@/validators/decimalOrNull'
import { passwordValidator } from '@/validators/password'
import { passwordConfirmValidator } from '@/validators/passwordConfirm'

Object.keys(AllRules).forEach(rule => {
  if (rule !== 'all') {
    defineRule(rule, AllRules[rule])
  }
})

configure({
  // Generates an English message locale generator
  generateMessage: localize(dictionary)
})

defineRule('decimal', decimalValidator)
defineRule('greaterOrEqualThanZero', greaterOrEqualThanZeroValidator)
defineRule('ip', ipValidator)
defineRule('decimalOrNull', decimalOrNullValidator)
defineRule('password', passwordValidator)
defineRule('passwordConfirm', passwordConfirmValidator)

export const app = createApp(App, {
  data () {
    return {
      // countriesWithStates: ['ca', 'us', 'au', 'in', 'br', 've', 'mx', 'ng', 'my'],
      // redirectUrl: 'https://shifton.com/',
      // showChangeCompany: false
    }
  },
  setup () {
    const { t } = useI18n() // use as global scope
    return { t }
  }
})
app.use(createGmapVuePlugin({
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    language: store.getters.locale
  }
}))
app.use(router)
app.use(store)
app.use(i18n)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(VueResize)
app.use(setupCalendar, {})
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.use(VueMask)
app.use(VueTelInput)
app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
  loaderOptions: {
    useEnterprise: true,
    autoHideBadge: true
  }
})

function isUndefined (value) {
  return typeof value === 'undefined'
}

function getRedirectUri (uri) {
  try {
    return (!isUndefined(uri)) ? ('' + (window.location.origin) + uri) : window.location.origin
  } catch (e) {
    // console.log('getRedirectUri', e)
  }

  return uri || null
}

app.use(VueAxios, axios)
axios.defaults.baseURL = getRedirectUri()

app.use(VueAuthenticate, {
  baseUrl: getRedirectUri(),
  tokenName: 'access_token',
  storageType: 'localStorage',
  tokenType: 'api-key',
  tokenPrefix: '',
  storageNamespace: 'api_key',
  providers: {
    facebook: {
      name: 'facebook',
      clientId: '2153104444928456',
      url: '/',
      authorizationEndpoint: 'https://www.facebook.com/dialog/oauth',
      redirectUri: getRedirectUri('/registration'),
      requiredUrlParams: ['display', 'scope'],
      scope: ['email,public_profile,user_about_me'],
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 580, height: 400 },
      responseType: 'token'
    },
    google: {
      name: 'google',
      url: '/',
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
      redirectUri: getRedirectUri('/registration'),
      requiredUrlParams: ['scope'],
      optionalUrlParams: ['display'],
      scope: ['https://www.googleapis.com/auth/plus.me https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'],
      display: 'popup',
      responseType: 'token',
      oauthType: '2.0',
      popupOptions: { width: 452, height: 633 }
    },
    live: {
      name: 'live',
      url: process.env.VUE_APP_ROOT_API + 'get/live/token',
      clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
      authorizationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      redirectUri: getRedirectUri('/login'),
      requiredUrlParams: ['display', 'scope'],
      scope: ['User.Read'],
      scopeDelimiter: ' ',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 500, height: 560 }
    }
  }
})

app.component('Form', Form)
app.component('Field', Field)
app.component('ErrorMessage', ErrorMessage)
app.component('ui-autocomplete', UiAutocomplete)
app.component('ui-checkbox', UiCheckbox)
app.component('ui-checkbox-button', UiCheckboxButton)
app.component('ui-checkbox-group', UiCheckboxGroup)
app.component('ui-color-picker', UiColorPicker)
app.component('ui-date-picker', UiDatePicker)
app.component('ui-datetime-picker', UiDatetimePicker)
app.component('ui-dropdown', UiDropdown)
app.component('ui-file-upload', UiFileUpload)
app.component('ui-form-row', UiFormRow)
app.component('ui-group-select', UiGroupSelect)
app.component('ui-hint', UiHint)
app.component('ui-input-label', UiInputLabel)
app.component('ui-integer-input', UiIntegerInput)
app.component('ui-masked-input', UiMaskedInput)
app.component('ui-month-picker', UiMonthPicker)
app.component('ui-month-range-picker', UiMonthRangePicker)
app.component('ui-multi-select', UiMultiSelect)
app.component('ui-number-input', UiNumberInput)
app.component('ui-period-picker', UiPeriodPicker)
app.component('ui-radio', UiRadio)
app.component('ui-select', UiSelect)
app.component('ui-switch', UiSwitch)
app.component('ui-tags-select', UiTagsSelect)
app.component('ui-tel-input', UiTelInput)
app.component('ui-text-input', UiTextInput)
app.component('ui-textarea-input', UiTextareaInput)
app.component('ui-time-picker', UiTimePicker)
app.component('ui-tooltip', UiTooltip)
app.component('ui-radio-group', UIRadioGroup)

if (process.env.VUE_APP_GTM_IS_ENABLE === 'true') {
  app.use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
    })
  )
}

app.mixin(ToastMixin)
app.mixin({
  methods: {
    accessErrorRedirect (errorText) {
      this.$router.push(`/c/${this.companyId}/dashboard`)
      this.toastError(this.$i18n?.t(errorText))
    },
    selectAll (data, choices) {
      choices.forEach((user) => {
        if (!(data.indexOf(user.id) > -1)) {
          data.push(user.id)
        }
      })
    },
    isEmployeeHasPermission (permissionName) {
      if (!this.$store.getters.employeePermissions) {
        return false
      }
      return !!this.$store.getters.employeePermissions.includes(permissionName)
    },
    isEmployeeHasOneOrMorePermissions (permissionNames) {
      if (!this.$store.getters.employeePermissions) {
        return false
      }
      return this.isIncluded(this.$store.getters.employeePermissions, permissionNames)
    },
    isIncluded (array1, array2) {
      const filteredArray = array1.filter(value => array2.includes(value))
      return filteredArray.length > 0
    },
    hrsForHuman (hours) {
      // return hours ? Math.floor(hours) + this.$t('h') + Math.round(hours * 60 % 60) + this.$t('m') : ''
      return hours ? Math.floor(hours) + ':' + Math.round(hours * 60 % 60) : ''
    }
  },
  computed: {
    redirectUrl () {
      return 'https://shifton.com/'
    },
    getOS () {
      let userAgent = window.navigator.userAgent
      let platform = window.navigator.platform
      let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      let iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'MacOS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
      }

      return os
    },
    projectLimit () {
      return parseInt(this.$store.getters.projectsLimit) > 0
    },
    companyLimit () {
      return parseInt(this.$store.getters.companyLimit) > 0
    },
    rolesByProjects () {
      return this.$store.getters.rolesByProjects || {}
    },
    hasCompany () {
      return this.$store.getters.companyId
    },
    companyId () {
      return this.$store.getters.companyId
    },
    hasMoreThanOneCompany () {
      return +this.$store.getters.companyCount > 1
    },
    doubleAccountAllowed () {
      return this.$store.getters.doubleAccountAllowed
    },
    hasProject () {
      return this.$store.getters.hasProject
    },
    taskSettings () {
      return this.$store.getters.company.tasksSettings
    },
    lang () {
      return this.$store.getters.locale
    }
  },
  watch: {
    lang (val) {
      this.$gmapOptions.load.language = val
    }
  }
})

// probably it's bad idea, there is better ways to achive that
const emitter = mitt()
app.config.globalProperties.$eventBus = emitter

const params = {
  companyId: () => {
    let cachedCompanyId = null
    if (store.getters.companyId) { // если переход между компонентами, берем id компании из стора
      cachedCompanyId = store.getters.companyId
    } else { // если перезагрузка страницы, берем id копании из localStorage если он есть
      if (localStorage.getItem('company')) {
        cachedCompanyId = (JSON.parse(localStorage.getItem('company'))).id
      } else if (localStorage.getItem('profile')) {
        cachedCompanyId = (JSON.parse(localStorage.getItem('profile'))).companies[0]
      }
    }
    return cachedCompanyId
  }
}

router.beforeEach((to, from, next) => {
  const list = document.getElementsByTagName('html')
  for (let el of list) {
    el.setAttribute('translate', 'no')
  }
  document.title = to.meta.title
  if (to.matched.some(record => record.meta.requiresAuth === true)) {
    if (!localStorage.getItem('api_key')) {
      next('/login')
      return
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth === false) && to.matched.some(record => record.meta.dontRedirectIfAuth !== true)) {
    if (localStorage.getItem('api_key')) {
      next('/dashboard')
      return
    }
  }
  if (to.matched.some(record => record.meta.companyRoute === true)) { // если рауты компании
    let cachedCompanyId = params.companyId()
    if (!cachedCompanyId) { // если нет companyId дропаем стор и редирект на логин
      // store.dispatch('logout').then(() => {
      //   next('/login')
      // })
    } else {
      if (+cachedCompanyId === +to.params.id) { // если companyId из раута совпадает с companyId в сторе
        next()
        return
      } else { // иначе дропаем стор и получаем компанию по новому id
        store.dispatch('clearCompanyStore').then(() => {
          store.dispatch('getCompany', to.params.id).then(() => {
            next()
          }).catch(e => {
            next(false)
          })
        })
      }
    }
  }
  next()
})

// app.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
//     libraries: 'places',
//     language: store.getters.locale
//   },
//   installComponents: true
// })

router.isReady().then(() => app.mount('#app'))

// Push notifications
const firebaseConfig = {
  apiKey: 'AIzaSyBzJmX4uFzge8uHhAr-ZfPIX4f9DyRtOMw',
  authDomain: 'shifton-app.firebaseapp.com',
  databaseURL: 'https://shifton-app.firebaseio.com',
  projectId: 'shifton-app',
  storageBucket: 'shifton-app.appspot.com',
  messagingSenderId: '381721084029',
  appId: '1:381721084029:web:abba85e75b8f9d15'
}
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

app.config.globalProperties.$messaging = messaging

navigator.serviceWorker.register('/service-worker.js')
  .then((registration) => {
    console.log('notifications SW registered')
  }).catch(err => {
    console.log(err)
  })

// messaging token request handled in alerts component

// just for debugging
onMessage(messaging, (payload) => {
  console.log('Push message received. ', payload)
})
