<template>
  <div>
    <div class="container-fluid p-0" v-if="loaded">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="align-middle text-left table-header" rowspan="2">{{ $t("Project") }}</th>
            <th class="align-middle text-left table-header" rowspan="2">{{ $t("Date") }}</th>
            <th class="align-middle text-left table-header" rowspan="2">{{ $t("Active employees") }}</th>
            <th class="align-middle text-center table-header" :colspan="chunks.length+1">{{ $t("Time worked based on set rate, hours") }}</th>
            <th class="align-middle text-left table-header" rowspan="2">{{ $t("Costs") }}</th>
          </tr>
          <tr>
            <template v-for="chunk in chunks" :key="chunk + 'chunk_header'">
              <th class="align-middle text-left table-header">{{ chunk }}%</th>
            </template>
            <th class="align-middle text-left table-header">{{ $t('Total hours') }}</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filteredData" v-bind:key="'row_' + index">
              <td class="text-nowrap">{{ row.name }}</td>
              <td class="text-nowrap">{{ row.date }}</td>
              <td class="text-nowrap">{{ row.employees }}</td>
              <template v-for="(chunk, key) in row.chunks" :key="key + 'chunk_body'">
                <td class="align-middle text-left">{{ roundValue(chunk.time / 60.0) }} <br> ({{ hrsForHuman(chunk.time / 60.0 + Number.EPSILON) }})</td>
              </template>
              <td class="text-nowrap">{{ roundValue(row.time / 60.0) }} <br> ({{ hrsForHuman(row.time / 60.0 + Number.EPSILON) }})</td>
              <td class="text-nowrap">{{ roundValue(row.cost) }}</td>
            </tr>
          <tr class="bg-raspisanka-light" v-for="(row, index) in totals" v-bind:key="'row_total_' + index">
            <td class="text-nowrap">{{ row.name }} {{ $t("total") }}:</td>
            <td class="text-nowrap"></td>
            <td class="text-left">{{ row.employees }}</td>
            <template v-for="(chunk, key) in row.chunks" :key="key + 'chunk_total'">
              <td class="align-middle text-left">{{ roundValue(chunk.time / 60.0) }} <br> ({{ hrsForHuman(chunk.time / 60.0 + Number.EPSILON) }})</td>
            </template>
            <td class="text-left">{{ roundValue(row.time / 60.0) }} <br> ({{ hrsForHuman(row.time / 60.0 + Number.EPSILON) }})</td>
            <td class="text-left">{{ roundValue(row.cost) }}</td>
          </tr>
          <tr class="bg-raspisanka-dlight border border-primary">
            <td class="text-nowrap">{{ $t("Projects grand total") }}:</td>
            <td class="text-nowrap"></td>
            <td class="text-nowrap">{{ grandTotal.employees }}</td>
            <template v-for="(chunk, key) in grandTotal.chunks" :key="key + 'chunk_grand_total'">
              <td class="align-middle text-left">{{ roundValue(chunk.time / 60.0) }} <br> ({{ hrsForHuman(chunk.time / 60.0 + Number.EPSILON) }})</td>
            </template>
            <td class="text-nowrap">{{ roundValue(grandTotal.time / 60.0) }} <br> ({{ hrsForHuman(grandTotal.time / 60.0 + Number.EPSILON) }})</td>
            <td class="text-nowrap">{{ roundValue(grandTotal.cost) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'

export default {
  name: 'Budget',
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      columns: [
        { name: 'project', title: this.$i18n?.t('Project') },
        { name: 'date', title: this.$i18n?.t('Date') },
        { name: 'employees', title: this.$i18n?.t('Employees') },
        { name: 'hours', title: this.$i18n?.t('Hours') },
        { name: 'costs', title: this.$i18n?.t('Costs') }
      ],
      chunks: [],
      rows: [],
      total: [],
      grandTotal: [],
      loaded: false
    }
  },
  props: ['projects', 'period', 'search', 'selectedMarks', 'marksByUsers'],
  beforeUnmount () {
    this.$eventBus.off('exportBudgetReport')
  },
  mounted () {
    this.$eventBus.on('exportBudgetReport', this.getTableData)
  },
  created () {
    this.getTableData()
  },
  methods: {
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    getTableData (exportType = null) {
      this.loaded = false
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      let data = '?projects[]=' + this.projects.join('&projects[]=') + '&date_from=' + dateFrom + '&date_to=' + dateTo

      let blob = false
      if (exportType) {
        data += '&export_type=' + exportType
        blob = true
      }

      this.$store.dispatch('getBudget', [this.$store.getters.companyId, data, blob]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (blob) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_budget_report')}_${moment(this.toTimeZone(dateFrom)).format(this.backendDateFormat)}_${moment(this.toTimeZone(dateTo)).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response.data.module) {
          this.$eventBus.emit('dummy', true)
        } else {
          this.$router.push('dashboard')
        }
      })
    },
    setTable (response) {
      // this.rows = []
      this.chunks = response.chunks
      this.grandTotal = response.total
      const dtFormat = this.localeDateFormat
      const totals = []
      const rows = []

      Object.keys(response.projects).forEach(function (project) {
        if (Object.keys(response.projects[project].by_date).length > 0) {
          response.projects[project].total.name = response.projects[project].name
          totals.push(response.projects[project].total)
        }
        Object.keys(response.projects[project].by_date).forEach(function (date) {
          const row = response.projects[project].by_date[date]
          row.name = response.projects[project].name
          row.date = moment(date, 'YYYY-MM-DD').format(dtFormat)
          rows.push(row)
        })
      })
      rows.sort(function (a, b) {
        if (a.date < b.date) { return -1 }
        if (a.date > b.date) { return 1 }
        return 0
      })

      totals.sort(function (a, b) {
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0
      })

      this.totals = totals
      this.rows = rows
    }
  },
  computed: {
    filteredData () {
      return this.rows.filter((row, index) => {
        if (this.search === '') {
          return row
        }
        return ((row.name.toLowerCase()).indexOf(this.search.toString().toLowerCase()) > -1)
      })
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    }
  }
}
</script>

<style scoped>

</style>
