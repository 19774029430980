import store from '../store/index'

export const MODULE_WORKING_HOUR = '1'
export const MODULE_TIME_OFF = '2'
export const MODULE_PROJECT_WORKERS = '3'
export const MODULE_PAY_REPORTS = '4'
export const MODULE_BUDGET_REPORTS = '5'
export const MODULE_FREE_BOARD = '6'
export const MODULE_SHIFT_SWAPPING = '7'
export const MODULE_SCHEDULE_WIZARD = '8'
export const MODULE_SCHEDULE_MANUAL = '9'
export const MODULE_API = '10'
export const MODULE_MOBILE_APP = '11'
export const MODULE_EMAIL_NOTIFICATIONS = '12'
export const MODULE_PUSH_NOTIFICATIONS = '13'
export const MODULE_DEPARTMENTS = '14'
export const MODULE_TRACKER = '15'
export const MODULE_TIME_ZONES = '16'
export const MODULE_FILE_UPLOAD = '17'
export const MODULE_TIMEOFF_REQUESTS = '18'
export const MODULE_ANALYTICS = '19'
export const MODULE_SMS = '20'
export const MODULE_TASKS = '21'
export const MODULE_MIN_WORKING_TIME = '22'
export const MODULE_BONUS_PENALTY = '23'
export const MODULE_BREAKS = '24'
export const MODULE_ATTENDANCE = '25'
export const MODULE_GEOLOCATION = '26'

const setup = {
  [MODULE_WORKING_HOUR]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_TIME_OFF]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_PROJECT_WORKERS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_PAY_REPORTS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_BUDGET_REPORTS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_FREE_BOARD]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_SHIFT_SWAPPING]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_SCHEDULE_WIZARD]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_SCHEDULE_MANUAL]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_API]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_MOBILE_APP]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_EMAIL_NOTIFICATIONS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_PUSH_NOTIFICATIONS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_DEPARTMENTS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_TRACKER]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_TIME_ZONES]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_FILE_UPLOAD]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_TIMEOFF_REQUESTS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_ANALYTICS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_SMS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_TASKS]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_MIN_WORKING_TIME]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_BONUS_PENALTY]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_BREAKS]: {
    install: () => {
      // включаем галочку на расписании компании
      let settings = store.getters.displayEventsSettings
      if (!settings) {
        settings = JSON.parse(localStorage.getItem('displayEventsSettings'))
      }
      if (!settings) {
        settings = {
          shifts: true,
          timeOff: true,
          unavailability: true,
          availability: true,
          breaks: true,
          holidays: true,
          deletedUsers: true,
          notes: true
        }
      } else {
        settings.breaks = true
      }
      localStorage.setItem('displayEventsSettings', JSON.stringify(settings))
      store.dispatch('updateDisplaySettings', settings)
      console.log('install', MODULE_BREAKS)
    },
    uninstall: () => {
      // выключаем галочку на расписании компании
      let settings = store.getters.displayEventsSettings
      if (!settings) {
        settings = JSON.parse(localStorage.getItem('displayEventsSettings'))
      }
      if (!settings) {
        settings = {
          shifts: true,
          timeOff: true,
          unavailability: true,
          availability: true,
          breaks: false,
          holidays: true,
          deletedUsers: true,
          notes: true
        }
      } else {
        settings.breaks = false
      }
      localStorage.setItem('displayEventsSettings', JSON.stringify(settings))
      store.dispatch('updateDisplaySettings', settings)
      console.log('uninstall', MODULE_BREAKS)
    }
  },
  [MODULE_ATTENDANCE]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  },
  [MODULE_GEOLOCATION]: {
    install: () => {
      console.log('install')
    },
    uninstall: () => {
      console.log('uninstall')
    }
  }
}

export default setup
