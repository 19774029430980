<template>
  <div>
    <add-user-to-project-modal
      @closeAddUserToProjectModal="() => { this.showModal = false }"
      v-if="showModal"
      :userId="employeeId">
    </add-user-to-project-modal>
    <div v-if="!isModal" class="row mb-2 mx-auto">
      <div class="col-lg my-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li v-if="!isEmployeeHasPermission('get-employee-admin')"
                class="breadcrumb-item title-text">
              <router-link :to="`/c/${companyId}/projects`">
                <span>{{ $t('Projects') }}</span>
              </router-link>
            </li>
            <li class="breadcrumb-item title-text">
              <router-link v-if="isEmployeeHasPermission('get-employee-admin')"
                           :to="`/c/${companyId}/users/`">
                <span>{{ $t('Users list') }}</span>
              </router-link>
              <router-link v-if="!isEmployeeHasPermission('get-employee-admin')"
                           :to="`/c/${companyId}/project/${projectId}/users/`">
                <span>{{ $t('Users list') }}</span>
              </router-link>
            </li>
            <li class="breadcrumb-item title-text active" aria-current="page">
              <span class="title-text">{{ $t('User info') }}</span>
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-auto my-1">
        <div class="row">
          <div class="col-lg my-1"></div>
          <div class="col-auto my-1 d-flex align-items-center">
            <template v-if="activeTab === 'projects'">
              <button
                class="btn btn-sm btn-primary rounded-pill mx-1"
                role="button"
                data-toggle="modal"
                data-target="#AddUserToProjectModal"
                data-backdrop="static"
                @click="() => { showModal = true }"
                :title="$t('Add to project')">
                {{ $t('Add to project') }}
              </button>
            </template>
          </div>
          <div class="col-auto my-1 d-flex align-items-center">
            <template v-if="userInfo.user_id === +companyOwnerId">
              <div class="help my-auto" style="padding-left: 20px"></div>
            </template>
            <template v-else-if="isEmployeeHasPermission('get-employee-admin')">
              <div class="row h-100">
                <div class="col text-right text-nowrap d-flex align-items-center">
                  {{ $t('User rights') }}:
                </div>
                <div class="col my-auto" style="min-width: 240px">
                  <ui-select
                    name="groups"
                    v-model="groups"
                    :clearable="false"
                    :filterable="false"
                    :options="userGroups"
                    :key-name="'id'"
                    :label-name="'name'"
                    :value-name="'id'"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="col-auto my-1 d-flex align-items-center">
            <router-link :to="projectId ? `/c/${$store.getters.companyId}/files/${userInfo.id}?q=${projectId}` : `/c/${$store.getters.companyId}/files/${userInfo.id}`">
              <button class="btn btn-sm btn-outline-secondary rounded-pill mx-1" :title="$t('Files')">
                {{ $t('Files') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Меню для модалки -->
      <div v-if="isModal" class="col-sm-3 text-truncate pr-0">
        <div class="row">
          <div class="nav nav-tabs col-12 pt-1" id="v-pills-tab" :class="{
                'flex-column': !isMobile,
                'text-center': isMobile,
                'pb-2': !isMobile
              }">
            <template v-if="userInfo.is_registered">
              <a v-for="(title, key) in tabsData" :key="key"
                :href="'#' +  key"
                class="nav-link p-1 bg-modal"
                :class="{
                  active: activeTab === key,
                  'w-25': isMobile,
                  'tabs-mobile': isMobile,
                  'tabs-desktop': !isMobile,
                }"
                data-toggle="pill" role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="activateTab(key)">
                  <span>
                    <i class="btn-icon mx-1" :class="{
                      'icon-edit': key === 'general',
                      'icon-bell': key === 'notification',
                      'icon-check-circle': key === 'availability',
                      'icon-package': key === 'projects',
                      'icon-clipboard': key === 'notes',
                      'icon-file-text': key === 'files',
                      'icon-coffee': key === 'vacations'
                    }"></i>
                  </span>
                  <span v-if="!isMobile">
                      {{ $t(title) }}
                  </span>
              </a>
            </template>
            <template v-if="!userInfo.is_registered">
              <a v-for="(title, key) in tabsVirtualData" :key="key"
                :href="'#' +  key"
                class="nav-link p-1 bg-modal"
                :class="{
                    active: activeTab === key,
                    'w-25': isMobile,
                    'tabs-mobile': isMobile,
                    'tabs-desktop': !isMobile,
                }"
                data-toggle="pill"
                role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="activateTab(key)">
                  <span>
                    <i class="btn-icon mx-1" :class="{
                      'icon-edit': key === 'edit',
                      'icon-check-circle': key === 'availability',
                      'icon-package': key === 'projects',
                      'icon-clipboard': key === 'notes',
                      'icon-file-text': key === 'files',
                      'icon-coffee': key === 'vacations'
                    }"></i>
                  </span>
                  <span v-if="!isMobile">
                      {{ $t(title) }}
                  </span>
              </a>
            </template>
          </div>
        </div>
      </div>
      <!-- Меню для обычной вьюхи -->
      <div v-if="!isModal" class="col-lg-2">
        <div class="card card-small">
          <div class="card-body">
            <div id="dropdown-vertical" class="dropdown d-flex d-md-none">
              <button class="btn btn-primary dropdown-toggle w-100" type="button" @click="toggleMenu">
                {{ $t(buttonTitle) }}
              </button>
            </div>
            <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical" :class="{'d-none': !showTabs}">
              <template v-if="userInfo.is_registered">
                <a v-for="(title, key) in tabsData" :key="key"
                  :href="'#' +  key"
                  class="nav-link pl-3 pr-1 rounded"
                  :class="{active: activeTab === key}"
                  data-toggle="pill" role="tab"
                  :aria-controls="'v-pills-' + key" aria-selected="true"
                  @click="activateTab(key)">
                  {{ $t(title) }}
                </a>
              </template>
              <template v-if="!userInfo.is_registered">
                <a v-for="(title, key) in tabsVirtualData" :key="key"
                  :href="'#' +  key"
                  class="nav-link pl-3 pr-1 rounded"
                  :class="{active: activeTab === key}"
                  data-toggle="pill" role="tab"
                  :aria-controls="'v-pills-' + key" aria-selected="true"
                  @click="activateTab(key)">
                  {{ $t(title) }}
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- Tab content -->
      <div :class="{
        'col-lg-10': !isModal,
        'col-sm-9': isModal,
        'px-0': isModal
      }">
        <div v-if="userInfo.id" class="tab-content">
          <div v-if="activeTab === 'general'" class="card card-small">
            <div class="card-body">
              <main-info
                id="general"
                :userInfo="userInfo">
              </main-info>
            </div>
          </div>
          <div v-if="activeTab === 'edit'" class="card card-small">
            <div class="card-body">
              <company-user-info
                id="edit"
                :userInfo="userInfo">
              </company-user-info>
            </div>
          </div>
          <div v-if="activeTab === 'notification' && userInfo.notification_settings" class="card card-small">
            <div class="card-body">
              <notification-settings
                :isEditable="false"
                :Profile="userInfo"
                id="notification">
              </notification-settings>
            </div>
          </div>
          <div v-if="activeTab === 'availability' && employeeId && userInfo.time_zone" class="card card-small">
            <div class="card-body">
              <availability
                :employeeId="employeeId"
                id="availability">
              </availability>
            </div>
          </div>
          <div  v-if="activeTab === 'projects'">
            <user-projects-info
              :userInfo="userInfo"
              :userId="employeeId"
              id="projects">
            </user-projects-info>
          </div>
          <div v-if="activeTab === 'notes'" class="card card-small">
            <div class="card-body">
              <notes
                :isModal="isModal"
                :employeeId="employeeId"
                :projectId="+projectId" />
            </div>
          </div>
          <div v-if="activeTab === 'files'" class="card card-small">
            <div class="card-body">
              <files
                :employeeId="employeeId"
              />
            </div>
          </div>
          <div v-if="activeTab === 'vacations'">
            <vacations
              :userInfo="userInfo"
              :employeeId="employeeId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import MainInfo from '@/components/User/user/UserComponents/MainInfo'
import CompanyUserInfo from '@/components/User/user/UserComponents/CompanyUserInfo'
import NotificationSettings from '@/components/CommonComponents/NotificationSettings'
import Availability from '@/components/CommonComponents/Availability/Availability'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import { mapGetters } from 'vuex'
import UserProjectsInfo from '@/components/User/user/UserComponents/UserProjectsInfo'
import AddUserToProjectModal from '@/components/User/user/UserModals/AddUserToProjectModal'
import Notes from '@/components/User/user/UserComponents/Notes'
import Files from '@/components/User/user/UserComponents/Files'
import Vacations from '@/components/User/user/UserComponents/Vacations.vue'

export default {
  name: 'User',
  mixins: [momentMixin, mixinWindowWidthWatch],
  components: {
    Vacations,
    Files,
    Notes,
    AddUserToProjectModal,
    UserProjectsInfo,
    CompanyUserInfo,
    MainInfo,
    NotificationSettings,
    Availability
  },

  data () {
    return {
      employeeId: null,
      groups: '',
      userInfo: {
        id: null,
        user_id: null,
        add_info: {},
        phones: {},
        required_working_time_minutes: null
      },
      companyOwnerId: '',
      userGroups: [],
      languages: '',
      activeTab: null,
      showTabs: true,
      buttonTitle: this.$i18n?.t('Please Select'),
      tabsData: {
        general: 'General information',
        notification: 'Notifications',
        availability: 'Availability',
        projects: 'Projects',
        notes: 'Notes',
        files: 'Files',
        vacations: 'Vacations'
      },
      tabsVirtualData: {
        edit: 'General information',
        availability: 'Availability',
        projects: 'Projects',
        notes: 'Notes',
        files: 'Files',
        vacations: 'Vacations'
      },
      projectId: null,
      showModal: false,
      isModal: false
    }
  },

  props: {
    userId: Number
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },

  created () {
    this.employeeId = this.userId ? this.userId : +this.$route.params.userId
    this.isModal = !!this.userId
    if (this.companyId) {
      this.onCreated()
    }
  },

  mounted () {
    if (this.tabsData[this.$route.hash.substr(1)]) {
      this.activateTab(this.$route.hash.substr(1))
    }
  },

  watch: {
    groups () {
      this.setGroup()
    },
    '$route' () {
      if (this.tabsData[this.$route.hash.substr(1)]) {
        this.activateTab(this.$route.hash.substr(1))
      }
    },
    companyId () {
      this.onCreated()
    }
  },

  methods: {
    onCreated () {
      if (!this.isEmployeeHasPermission('get-employee')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.companyOwnerId = this.$store.getters.companyOwnerId
      this.timeZone = this.$store.getters.profileTimeZone

      this.$store.dispatch('usersStatusFields').then(response => {
        this.userGroups = response.groups.map(group => {
          group.name = this.$t(group.name)
          return group
        })
        this.projectId = this.$route.params.projectId
        if (this.projectId) {
          this.$store.dispatch('getEmployeeByProject', [this.projectId, this.employeeId]).then(response => {
            this.init(response)
          }).catch(() => {
            this.$router.push(`/c/${this.companyId}/dashboard`)
          })
        } else {
          this.$store.dispatch('getUserInfoById', { companyId: this.$store.getters.companyId, userId: this.employeeId }).then(response => {
            this.init(response)
          }).catch(() => {
            this.$router.push(`/c/${this.companyId}/dashboard`)
          })
        }
      })
    },

    init (response) {
      this.userInfo = JSON.parse(JSON.stringify(response))
      this.groups = response.group
      this.userInfo.id = this.employeeId
      this.userInfo.user_id = response.user_id
      this.userInfo.time_zone = response.time_zone || this.timeZone
      this.userInfo.required_working_time_minutes = response.required_working_time_minutes
      if (!this.tabsData[this.$route.hash.substr(1)]) {
        this.activeTab = this.userInfo.is_registered ? 'general' : 'edit'
      }
    },

    setGroup () {
      const permData = {
        group: this.groups
      }
      this.$store.dispatch('changeUserPriorityAndPermission', [this.$store.getters.companyId, this.userInfo.id, permData]).then(response => {
        this.groups = response.group
      })
    },

    toggleMenu () {
      this.showTabs = !this.showTabs
    },

    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      if (!this.buttonTitle) {
        this.buttonTitle = this.tabsVirtualData[key]
      }
      if (!this.isModal) {
        window.location.hash = key
      }
      this.showTabs = false
    }
  }
}
</script>

<style scoped lang="scss">
  .tabs-mobile {
    margin-bottom: -1px!important;
    border-bottom-width: 0px!important;
  }
  .tabs-desktop {
    border-bottom-left-radius: 7px!important;
    margin-right: -5px;
  }
</style>
