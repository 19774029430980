<template>
  <div
    data-toggle="modal"
    :data-target="isClickable ? '#ClickEventModal' : ''"
  >
    <div class="row" :class="{'px-2': borderColor.length > 0}">
      <div v-if="borderColor.length > 0" class="ml-1" />
      <div
        class="col-auto p-0 m-0 rounded-lg"
        v-for="color in borderColor"
        :key="color"
        :style="{
            'background-color': color,
            'width': '4px'
         }"
      />
      <div class="ml-1" />
      <div class="col overflow-hidden mx-0" :class="{'px-0': borderColor.length > 0}">
        <el-tooltip
          :show-after="openDelay"
          :hide-after="hideDelay"
          effect="light"
          placement="top"
          class="w-100">
          <template #content>
            <div>{{ tooltip }}<br/>{{ tooltipDAC }}</div>
          </template>
          <template v-if="viewType === 'resourceTimeMonth' && monthDisplay === 'compact'">
            <div class="fc-content text-truncate text-center" :class="{'py-1': tags.length === 0}">
              <span class="fc-time text-nowrap text-truncate">{{ durationHrs }} </span>
              <span class="" v-if="eventData.extendedProps.counter > 1">({{ eventData.extendedProps.counter }})</span>
            </div>
          </template>
          <template v-else>
            <div class="fc-content py-1 text-truncate">
              <!--      <i v-if="isArrowLeft" class="icon-arrow-left"></i>-->
              <div style="position: relative">
                <div>
                  <el-tooltip
                    v-if="isOverhead && !isUserStatus"
                    effect="light"
                    :content="warningQuotas"
                    placement="top">
                    <span class="red-circle"></span>
                  </el-tooltip>
                  <button v-if="eventData.extendedProps.is_double_account" class="m-0 p-0 ml-2"
                          :style="{background: eventData.backgroundColor, border: 0}">
                    <img src="../../../../public/static/images/App/men-group-outline.png" width="10" />
                  </button>
                  <div class="confirmed">
                    <el-tooltip
                      v-if="require_user_confirm && eventData.extendedProps.confirmed_by_employee"
                      effect="light"
                      :content="confirmedMessage"
                      placement="top">
                      <i class="icon-check-circle" />
                    </el-tooltip>
                  </div>
                  <span v-if="eventData.extendedProps.showTime" class="fc-time ml-1 text-nowrap text-truncate">
                    {{ start.format(timeFormat) }} - {{ end.format(timeFormat) }}
                  </span>
                  <span class="ml-1" v-if="eventData.extendedProps.counter > 1">({{ eventData.extendedProps.counter }})</span>
                  <span class="ml-1 overflow-hidden">{{ eventData.title }}</span>
                  <el-tooltip
                    v-if="eventData.extendedProps.note && (isEmployeeHasPermission('update-shifts') || !eventData.extendedProps.hide_note)"
                    effect="light"
                    placement="top">
                    <template #content>
                      <div class="popper">
                        {{eventData.extendedProps.note}}
                      </div>
                    </template>
                    <el-icon :size="12" class="mr-1">
                      <Memo/>
                    </el-icon>
                  </el-tooltip>
                  <template v-if="event.extendedProps.location">
                    <el-tooltip
                      effect="light"
                      placement="bottom">
                      <template #content>
                        <div>
                          <div>{{ event.extendedProps.location.title }}</div>
                          <div>{{ event.extendedProps.location.address }}</div>
                        </div>
                      </template>
                      <i class="icon-map-pin" />
                    </el-tooltip>
                    <span class="mx-1">{{event.extendedProps.location.title}}</span>
                  </template>
                </div>
                <div v-if="isTwoDays && lang === 'he'"
                     style="position: absolute; left: 0px; top: 3px;"
                     :style="{'background-color': eventData.backgroundColor}"
                >
                  <i class="icon-arrow-left"></i>
                </div>
                <div v-if="isTwoDays && lang !== 'he'"
                     style="position: absolute; right: 0px; top: 3px;"
                     :style="{'background-color': eventData.backgroundColor}"
                >
                  <i class="icon-arrow-right"></i>
                </div>
              </div>
              <div class="ml-1" v-if="tags.length > 0">
                <el-tooltip
                  v-for="tag in tags"
                  :key="tag.id"
                  class="item"
                  effect="light"
                  placement="bottom-start">
                  <template #content>
                    <div>{{tag.name}}</div>
                  </template>
                  <span class="mr-1" :style="{background: tag.color}" style="width: 15px; height: 15px; display: inline-block; border-radius: 25px; vertical-align: middle;"></span>
                </el-tooltip>
              </div>
              <!--      <i v-if="isArrowRight" class="icon-arrow-left"></i>-->
            </div>
          </template>
        </el-tooltip>
      </div>
      <template v-if="isClickable">
        <div v-if="event.extendedProps.isAdvance"
            class="col-auto mx-0 px-0"
            :style="{'margin-right': event.extendedProps.isBonus || event.extendedProps.isPenalty ? '2px!important' : '12px!important'}"
            style="margin-top: -8px!important;">
          <el-tooltip :content="$t('advance') + ': ' + event.extendedProps.advanceAmount" placement="top" effect="light">
            <i class='icon-coin-dollar bg-success rounded' style="font-size: 20px;" />
          </el-tooltip>
        </div>
        <div v-if="event.extendedProps.isPenalty"
            class="col-auto mx-0 px-0"
            :style="{'margin-right': event.extendedProps.isBonus ? '2px!important' : '12px!important'}"
            style="margin-top: -8px!important;">
          <el-tooltip :content="$t('penalty') + ': ' + event.extendedProps.penaltyAmount" placement="top" effect="light">
            <i class='icon-coin-dollar bg-danger rounded' style="font-size: 20px;" />
          </el-tooltip>
        </div>
        <div v-if="event.extendedProps.isBonus"
             class="col-auto mx-0 px-0"
             style="margin-top: -8px!important; margin-right: 12px!important;">
          <el-tooltip :content="$t('bonus') + ': ' + event.extendedProps.bonusAmount" placement="top" effect="light">
            <i class='icon-coin-dollar bg-warning rounded' style="font-size: 20px;" />
          </el-tooltip>
        </div>
      </template>
    </div>
    <div v-if="viewType === 'resourceTimeMonth' && monthDisplay === 'compact'" class="row">
      <div class="col">
        <div class="ml-1 p-0" v-if="tags.length > 0">
          <el-tooltip
            v-for="tag in tags"
            :key="tag.id"
            class="m-0 p-0"
            effect="light"
            placement="bottom-start">
            <template #content>
              <div>{{tag.name}}</div>
            </template>
            <span
              class="mx-0 px-0"
              :style="{background: tag.color}"
              style="margin-right: 0.15rem !important; width: 7px; height: 7px; display: inline-block; border-radius: 5px; vertical-align: middle;" />
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ShiftComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 1000,
      hideDelay: 4000,
      tags: [],
      markTemplate: '',
      tooltipEnable: true
    }
  },
  props: {
    event: Object,
    marks: Array,
    activeStart: Date,
    activeEnd: Date,
    dayQuotas: String,
    warningQuotas: String,
    isUserStatus: Boolean,
    view: Object,
    require_user_confirm: Boolean,
    confirmedMessage: String,
    unconfirmedMessage: String,
    monthDisplay: String,
    currentEmployeeId: Number,
    isManager: Boolean,
    isAdmin: Boolean
  },
  created () {
    this.updateTags(this.event.extendedProps.marks)
  },
  computed: {
    borderColor () {
      let statuses = []
      let statusColors = {
        // 'new': '#065535',
        'late_early': '#ffa500',
        'absent': '#4b4a49',
        'started': '#ffff00',
        'finished': '#00ff00'
      }
      if (this.event.extendedProps.isShift && !this.event.extendedProps.freeBoardKey) {
        if (this.event.extendedProps.presence_info) {
          if ((this.event.extendedProps.presence_info.early && this.event.extendedProps.presence_info.early > 0) ||
            (this.event.extendedProps.presence_info.lateness && this.event.extendedProps.presence_info.lateness > 0)) {
            statuses.push(statusColors['late_early'])
          }
          if (this.event.extendedProps.presence_info.absence) {
            statuses.push(statusColors['absent'])
          }
        }
        if (moment(this.event.start).isSame(moment(this.event.extendedProps.plannedStart, this.backendDateTimeFormat))) {
          // statuses.push(statusColors['new'])
        } else if (!moment(this.event.end).isSame(moment(this.event.extendedProps.plannedEnd, this.backendDateTimeFormat))) {
          statuses.push(statusColors['finished'])
        } else {
          statuses.push(statusColors['started'])
        }
      }
      return statuses
    },
    isClickable () {
      if (this.isAdmin || this.isManager) {
        return true
      }
      if (this.event.extendedProps.employee_id === 0) {
        return true
      }
      if (+this.currentEmployeeId === +this.event.extendedProps.employee_id) {
        return true
      }
      return false
    },
    timeFormat () {
      return this.localeTimeFormat
    },
    viewType () {
      return this.view.type
    },
    eventData () {
      return this.event
    },
    start () {
      return moment(this.eventData.start)
    },
    end () {
      return moment(this.event.end)
    },
    durationHrs () {
      const duration = this.eventData.extendedProps.shiftDuration / 60
      return Math.round(duration * 100) / 100
    },
    isOverhead () {
      // console.log(this.event)
      if (!+this.dayQuotas) return false
      const diff = moment.duration(this.end.diff(this.start)).asHours() - +this.event.extendedProps.break_time / 60
      return diff > +this.dayQuotas
    },
    tooltip () {
      // console.log(123, this.eventData.extendedProps.template_name)
      return this.eventData.title + ' ' + this.eventData.extendedProps.template_name[0] + ' ' + this.eventData.extendedProps.template_name[1] + ' ' + this.start.format(this.timeFormat) + ' - ' + this.end.format(this.timeFormat)
    },
    tooltipDAC () {
      return this.eventData.extendedProps.is_double_account ? 'double account: ' + this.eventData.title : ''
    },
    /** Проверяем, есть ли у шифта переход между датами (00:00 - 00:00 считается одним днем, если длительность шифта = 1 день) */
    isSameDay () {
      return moment(this.start.format(this.backendDateFormat), this.backendDateFormat).isSame(moment(this.end.format(this.backendDateFormat), this.backendDateFormat), 'day') ||
        (this.start.format(this.backendTimeFormat) === this.end.format(this.backendTimeFormat) &&
          this.start.format(this.backendTimeFormat) === '00:00' &&
          this.start.diff(this.end, 'days') === -1)
    },
    isTwoDays () {
      // console.log(123, this.lang)
      return (this.view.type === 'resourceTimeMonth' || this.view.type === 'resourceTimelineWeek') &&
        !this.isSameDay
    }
  },
  methods: {
    setView (view) {
      // eslint-disable-next-line vue/no-mutating-props
      this.view = view
    },
    mark () {
      let mark = ''
      this.tags.forEach(tag => {
        mark += `<div class="mr-1" style="background: ${tag.color}; width: 15px; height: 15px; display: inline-block; border-radius: 25px; vertical-align: middle;"></div>${tag.name}<br>`
      })
      this.markTemplate = mark
    },
    updateTags (markIds) {
      this.tags = []
      if (Array.isArray(markIds)) {
        for (const value of markIds) {
          for (const mark of this.marks) {
            if (mark.id === value) this.tags.push(mark)
          }
        }
      }
      this.mark()
    }
  }
}
</script>

<style scoped>
  .confirmed {
    z-index: 100;
    position: absolute; /* Абсолютное позиционирование */
    top: -8px; /* Положение от верхнего края */
    right: 6px; /* Положение от правого края */
  }
  .red-circle {
    background: red;
    border: 1px solid white;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  /*.project-color {*/
    /*width: 20px;*/
    /*height: 20px;*/
    /*display: inline-block;*/
    /*border-radius: 25px;*/
    /*vertical-align: middle;*/
  /*}*/
  .popper {
    max-width: 150px;
  }
</style>
