<template>
  <div>
    <TaskModal
      v-if="showTaskModal"
      :taskModalData="taskModalData"
      :taskId="taskModalData.id"
      @reloadTasks="reloadTasks"
      @closeTaskModal="closeTaskModal"
      :title="$t('TASK_EVENT_MODAL')"
    />
    <dummy-payment-required
      v-if="dummy"
      @activate="activatedAction()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="!dummy">
      <div class="row mb-2 mx-auto">
          <span class="title-text">
            {{ $t("TASKS_MAIN_TITLE") }}
            <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
          </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <tab-navigation-component :tabs="tabs" />
          <template v-if="hasCompany">
            <div v-show="!dummy">
              <div class="row my-2">
                <div class="col title-text">
                  {{ $t('TASK_CALENDAR') }}
                </div>
                <div class="col-auto" style="min-width: 240px">
                  <ui-select
                      name="showMode"
                      v-model="showMode"
                      class="cell-width"
                      :options="showModeChoice"
                      :validation="{required: false}"
                      :key-name="'id'"
                      :value-name="'id'"
                      :label-name="'name'"
                  />
                </div>
                <div class="col-auto my-auto">
                  <div id="viewSettingsDropdown" class="dropdown">
                    <i id="viewSettings" class="icon-settings btn-icon text-primary mx-1" type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false"></i>
                    <div id="viewSettingsDropdownMenu"
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="viewSettings"
                        style="z-index: 10000;overflow-y:auto;">
                      <form class="px-2">
                        <div class="form-group">
                          <div class="py-1 text-primary" style="min-width: 300px">{{ $t('DISPLAY_MODE_TASKS') }}</div>
                          <div>
                            <ui-radio
                                name="full"
                                :set-value="'full'"
                                v-model="tasksDisplayType"
                                :label="$t('FULL_MODE_TASKS')"
                            />
                          </div>
                          <div>
                            <ui-radio
                                name="compact"
                                :set-value="'compact'"
                                v-model="tasksDisplayType"
                                :label="$t('COMPACT_MODE_TASKS')"
                            />
                          </div>
                          <div class="py-1 text-primary" style="min-width: 250px">{{ $t('CALENDAR_MAX_MIN_TIME_DAY') }}</div>
                          <div class="block px-3">
                            <el-slider
                              v-model="maxMinTime"
                              range
                              show-stops
                              :format-tooltip="formatTooltip"
                              :marks="marksCalendarTimeRange"
                              :min="0"
                              :max="24">
                            </el-slider>
                          </div>
                          <div class="py-1 mt-3 text-primary" style="min-width: 250px">{{ $t('RESOURCE_SHOW_MODE') }}</div>
                          <div class="py-2">
                            <ui-checkbox
                              name="showPosition"
                              v-model="showPosition"
                            >
                              <span class="text-wrap">{{ $t('SHOW_POSITION_LABEL') }}</span>
                            </ui-checkbox>
                            <ui-checkbox
                              name="showIdentifier"
                              v-model="showIdentifier"
                            >
                              <span class="text-wrap">{{ $t('SHOW_IDENTIFIER_LABEL') }}</span>
                            </ui-checkbox>
                            <ui-select
                                id="primary_sort_order"
                                name="primary_sort_order"
                                v-model="primarySortOrder"
                                class="cell-width"
                                :options="sortChoices"
                                :validation="{required: false}"
                                :key-name="'value'"
                                :value-name="'value'"
                                :label-name="'label'"
                            />
                          </div>
                        </div>
                        <button type="submit" @click="updateDisplayMode" class="btn btn-outline-primary btn-sm">
                          {{ $t('Apply') }}
                        </button>
                      </form>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="drawer" class="col-lg-2">
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col title-text">
                          {{ $t('TASKS_NO_SCHEDULED') }}
                        </div>
                      </div>
                      <full-calendar id="backlogCalendar" v-if="backlogOptions" ref="backlogCalendar" :options="backlogOptions" />
                      <div v-if="backlogTasks.length > 0" class="row my-2">
                        <div class="col">
                          <ui-text-input
                            name="search"
                            v-model="search"
                            :placeholder="$t('Search')"
                            :is-search="true"
                          />
                        </div>
                      </div>
                      <div ref="extendedEvents" class="row" style="max-height: 600px!important; overflow: scroll">
                        <div v-for="task in backlogTasksFiltered" :key="task.id"
                            class="col-12 mb-1"
                            @click="setModal(task)">
                          <BacklogTaskComponent
                            class="font-weight-bold fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event mb-1"
                            style="max-width: 400px"
                            :event="task"
                            :view="view"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col title-text">
                          {{ $t('FILTERS') }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <ui-input-label :label="$t('FILTER_BY_USERS')" />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col">
                          <ui-multi-select
                              :label="$t('Select individual users')"
                              name="usersMultiplySelect"
                              v-model="usersMultiplySelect"
                              :options="usersMultiplySelectChoices"
                              :validation="{required: false}"
                              class="w-100"
                              :key-name="'key'"
                              :value-name="'id'"
                              :label-name="'name'"
                              :disabled="usersMultiplySelectChoices.length < 1"
                          />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col">
                          <button class="btn btn-primary btn-sm rounded-pill shadow-sm" type="button" @click="applyFilter">
                            <i class="icon-search" />
                          </button>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-secondary btn-sm rounded-pill shadow-sm" type="button" @click="clearEmployeeFilter">
                            <span class="small">{{$t('Clear')}}</span>
                          </button>
                        </div>
                      </div>
                      <el-divider />
                      <div>
                        <div class="row">
                          <div class="col">
                            <ui-input-label :label="$t('FILTER_BY_TASKS')" />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col">
                            <ui-multi-select
                                :label="$t('Select marks')"
                                name="marksMultiplySelect"
                                v-model="marksMultiplySelect"
                                :options="marksMultiplySelectChoices"
                                :validation="{required: false}"
                                class="w-100"
                                :key-name="'key'"
                                :value-name="'id'"
                                :label-name="'name'"
                                :disabled="marksMultiplySelectChoices.length < 1"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col">
                            <ui-multi-select
                                :label="$t('Select statuses')"
                                name="statusesMultiplySelect"
                                v-model="statusesMultiplySelect"
                                :options="statusesMultiplySelectChoices"
                                :validation="{required: false}"
                                class="w-100"
                                :key-name="'key'"
                                :value-name="'id'"
                                :label-name="'name'"
                                :disabled="statusesMultiplySelectChoices.length < 1"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col">
                            <button class="btn btn-primary btn-sm rounded-pill shadow-sm" type="button" @click="applyTaskFilter">
                              <i class="icon-search" />
                            </button>
                          </div>
                          <div class="col-auto">
                            <button class="btn btn-secondary btn-sm rounded-pill shadow-sm" type="button" @click="clearTasksFilter">
                              <span class="small">{{$t('Clear')}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <i class="icon-menu btn-icon mx-1" @click="handleDrawer" :class="drawer ? 'text-primary' : 'text-dark'" />
                        <button
                          @click="createTask"
                          class="btn btn-primary btn-sm btn-circle shadow-sm mx-1"
                          data-toggle="modal"
                          data-target="#ClickTaskModal">
                          {{ $t('CREATE_TASK') }}
                        </button>
                      </div>
                      <div ref="sizing">
                        <full-calendar v-if="calendarOptions" ref="taskCalendar" :options="calendarOptions">
                          <template v-slot:resourceLabelContent='arg'>
                            <div>
                          <span v-if="arg.resource.id !== 'info_event'" class="h-auto">
                            <template v-if="+arg.resource.id !== 0">
                              <div v-if="showIdentifier" class="small">
                                {{ arg.resource.extendedProps.identifier }}
                              </div>
                              <div v-if="showPosition" class="small">
                                {{ arg.resource.extendedProps.position }}
                              </div>
                            </template>
                            <span
                              class="font-weight-bold"
                              :style="{ 'color': arg.resource.extendedProps.color ? arg.resource.extendedProps.color : false }">
                              {{ arg.resource.title }}
                            </span>
                          </span>
                            </div>
                          </template>
                          <template v-slot:eventContent='arg'>
                            <TaskComponent
                              class="font-weight-bold"
                              :event="arg.event"
                              :view="view"
                              :tasksDisplayType="tasksDisplayType"
                            />
                          </template>
                        </full-calendar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import heLocale from '@fullcalendar/core/locales/he'
import ruLocale from '@fullcalendar/core/locales/ru'
import deLocale from '@fullcalendar/core/locales/de'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import interaction, { Draggable } from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import ukLocale from '@fullcalendar/core/locales/uk'
import DatePickerComponent from '@/components/CommonComponents/Fullcalendar/DatePickerComponent'
import momentMixin from '@/mixins/mixinMoment'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import moment from 'moment'
import TaskComponent from '@/components/Tasks/Calendar/TaskComponent'
import BacklogTaskComponent from './BacklogTaskComponent'
import TaskModal from '@/components/Tasks/CommonComponents/TaskModal'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import ToastMixin from '@/mixins/ToastMixin'
import { mount } from '@/utils/mount'
import { app } from '@/main'

const FREE_BOARD_RESOURCE_ID = 0

export default {
  name: 'TaskCalendar',
  components: { TabNavigationComponent, TaskModal, BacklogTaskComponent, TaskComponent, FullCalendar, DummyPaymentRequired: DummyPaymentRequired },
  mixins: [
    momentMixin,
    mixinWindowWidthWatch,
    mixinFCWeek,
    mixinDummy,
    ToastMixin
  ],
  data () {
    return {
      tabs: [],
      resources: [],
      backlogTasks: [],
      backlogTasksFiltered: [],
      scheduledTasks: [],
      calendarApi: null,
      calendarOptions: null,
      maxMinTime: [0, 24],
      marksCalendarTimeRange: {
        0: '00:00',
        24: '24:00'
      },
      calendarTimeRange: {
        start: 0,
        end: 24
      },
      showPosition: true,
      showIdentifier: true,
      primarySortOrder: 'name',
      tasksDisplayType: 'full',
      sortChoices: [
        {
          label: this.$t('SORT_BY_NAME'),
          value: 'name'
        },
        {
          label: this.$t('SORT_BY_POSITION'),
          value: 'position'
        },
        {
          label: this.$t('SORT_BY_IDENTIFIER'),
          value: 'identifier'
        }
      ],
      employeEs: [],
      view: {},
      slotWidth3days: 10,
      slotWidth1day: 10,
      resourceColumnWidth: 300,
      showMode: 'all',
      showModeChoice: [
        {
          name: this.$t('ALL_USERS'),
          id: 'all'
        },
        {
          name: this.$t('USERS_WITH_TASK'),
          id: 'tasks'
        }
      ],
      taskModalData: {
        id: null,
        children: []
      },
      showTaskModal: false,
      drawer: true,
      search: '',
      taskCalendarDefaultView: 'resourceTimelineWeek',
      usersWithTasksIds: [],
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      usersMultiplySelectChoices: [],
      usersMultiplySelect: [],
      backlogOptions: null,
      marksMultiplySelectChoices: [],
      marksMultiplySelect: [],
      statusesMultiplySelectChoices: [],
      statusesMultiplySelect: []
    }
  },
  created () {
    if (this.companyId) {
      this.getTasksMarks()
      this.getStatuses()
    }
    this.setTubs()
    const tasksDisplayType = localStorage.getItem('tasksDisplayType')
    const minTimeCalendar = +localStorage.getItem('tasksMinTimeCalendar')
    const maxTimeCalendar = +localStorage.getItem('tasksMaxTimeCalendar')
    const showPosition = localStorage.getItem('tasksShowPosition')
    const showIdentifier = localStorage.getItem('tasksShowIdentifier')
    const primarySortOrder = localStorage.getItem('tasksPrimarySortOrder')
    const taskCalendarDefaultView = localStorage.getItem('taskCalendarDefaultView')
    if (showPosition) {
      if (showPosition === 'true') {
        this.showPosition = true
      } else {
        this.showPosition = false
      }
    } else {
      localStorage.setItem('tasksShowPosition', this.showPosition)
    }
    if (showIdentifier) {
      if (showIdentifier === 'true') {
        this.showIdentifier = true
      } else {
        this.showIdentifier = false
      }
    } else {
      localStorage.setItem('tasksShowIdentifier', this.showIdentifier)
    }
    if (primarySortOrder) {
      this.primarySortOrder = primarySortOrder
    } else {
      localStorage.setItem('tasksPrimarySortOrder', this.primarySortOrder)
    }
    if (tasksDisplayType) {
      this.tasksDisplayType = tasksDisplayType
    } else {
      localStorage.setItem('tasksDisplayType', this.tasksDisplayType)
    }
    if (minTimeCalendar) {
      this.maxMinTime[0] = +minTimeCalendar
    } else {
      localStorage.setItem('tasksMinTimeCalendar', this.maxMinTime[0])
    }
    if (maxTimeCalendar) {
      this.maxMinTime[1] = +maxTimeCalendar
    } else {
      localStorage.setItem('tasksMaxTimeCalendar', this.maxMinTime[1])
    }
    this.calendarTimeRange = {
      start: this.maxMinTime[0],
      end: this.maxMinTime[1]
    }
    if (!taskCalendarDefaultView) {
      localStorage.setItem('taskCalendarDefaultView', 'resourceTimelineWeek')
      this.taskCalendarDefaultView = 'resourceTimelineWeek'
    } else {
      this.taskCalendarDefaultView = taskCalendarDefaultView
    }
  },
  mounted () {
    this.$eventBus.on('goToDate', this.goToDate)
    this.$eventBus.on('taskCalendarRefreshEvents', this.taskCalendarRefreshEvents)
    this.timeSlotWidth()
    if (this.companyId) {
      this.getEmployeesByCompany()
    }
  },
  beforeUnmount () {
    this.$eventBus.off('goToDate')
    this.$eventBus.off('taskCalendarRefreshEvents')
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      companyId: 'companyId'
    }),
    currentEmployeeId  () {
      return this.$store.getters.company.employee_id ? this.$store.getters.company.employee_id : 0
    },
    slotMinTime () {
      if (this.calendarTimeRange.start === 0) {
        return '00:00:00'
      }
      return moment().startOf('day').add(this.calendarTimeRange.start, 'hours').format(this.backendTimeFormatExtended)
    },
    slotMaxTime () {
      if (this.calendarTimeRange.end === 24) {
        return '24:00:00'
      }
      return moment().startOf('day').add(this.calendarTimeRange.end, 'hours').format(this.backendTimeFormatExtended)
    },
    handleResourceColumnWidth () {
      if (this.windowWidth >= 576) {
        return '275px'
      }
      return '60%'
    },
    weekStart () {
      return this.$store.getters.company.salary ? this.$store.getters.company.salary.week_start : 0
    },
    firstDay () {
      return this.fcWeek[this.apiWeek[this.weekStart]]
    },
    lang () {
      return this.$store.getters.locale ? this.$store.getters.locale : 'en'
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    viewType () {
      return this.view.type
    },
    viewStart () {
      return this.view.currentStart
    },
    viewEnd () {
      return this.view.currentEnd
    },
    defaultViewName () {
      if (this.windowWidth >= 576) {
        return 'resourceTimelineWeek'
      }
      return 'resourceTimelineDay'
    },
    header () {
      if (this.windowWidth >= 576) {
        return {
          start: 'title',
          center: 'prev,calendarButton,next',
          end: 'today resourceTimelineDay,resourceTimeline3days,resourceTimelineWeek,resourceTimeMonth'
        }
      }
      return {
        start: 'title',
        center: 'prev,calendarButton,next',
        end: 'today'
      }
    },
    views () {
      if (this.windowWidth >= 576) {
        return {
          resourceTimelineDay: {
            slotLabelFormat: { // формат времени лейбла временной сетки
              hour: 'numeric',
              hour12: this.is12hourFormat,
              omitZeroMinute: false,
              meridiem: 'short'
            },
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            slotDuration: '00:10:00',
            slotMinWidth: 5,
            displayEventTime: true,
            displayEventEnd: true,
            titleFormat: { // will produce something like "Tuesday, September 18, 2018"
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          },
          resourceTimeline3days: {
            type: 'resourceTimelineMonth',
            slotLabelInterval: '24:00',
            buttonText: this.$i18n?.t('3 days'),
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            // dateIncrement: '24:00',
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { days: 3 },
            slotDuration: { hours: 1 },
            slotMinWidth: this.slotWidth3days
          },
          resourceTimelineWeek: {
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { week: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00'
            // slotWidth: this.slotWidth
          },
          resourceTimeMonth: {
            type: 'resourceTimelineMonth',
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              // weekday: 'narrow',
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { month: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00',
            slotMinWidth: this.tasksDisplayType === 'full' ? 105 : 1
          }
        }
      }
      return {
        resourceTimelineDay: {
          slotLabelFormat: [{ // формат времени лейбла временной сетки
            weekday: 'short',
            day: 'numeric'
          }],
          eventTimeFormat: { // формат времени, которое отображается на событии
            hour: 'numeric',
            hour12: this.is12hourFormat,
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          },
          editable: true,
          displayEventTime: true,
          displayEventEnd: true,
          duration: { day: 1 },
          slotDuration: { days: 1 },
          snapDuration: '24:00',
          titleFormat: { // will produce something like "Tuesday, September 18, 2018"
            month: 'numeric',
            year: 'numeric',
            day: 'numeric',
            weekday: 'short'
          }
        }
      }
    }
  },
  watch: {
    tasksDisplayType () {},
    search () {
      this.backlogTasksFiltered = this.backlogTasks.filter((task, index) => {
        const needle = this.search ? this.search.toString().toLowerCase() : ''
        if (needle === '') {
          return true
        }
        if ((task.title.toLowerCase()).indexOf(needle) > -1) {
          return true
        }
        if (task.client && (task.client.name.toLowerCase()).indexOf(needle) > -1) {
          return true
        }
        if (task.client && (task.client.address.toLowerCase()).indexOf(needle) > -1) {
          return true
        }
        return false
      })
    },
    slotMinTime (val) {
      if (this.viewType === 'resourceTimelineDay') {
        this.calendarApi.setOption('slotMinTime', val)
      }
    },
    slotMaxTime (val) {
      if (this.viewType === 'resourceTimelineDay') {
        this.calendarApi.setOption('slotMaxTime', val)
      }
    },
    viewType (val) {
      this.taskCalendarDefaultView = val
      localStorage.setItem('taskCalendarDefaultView', val)
      if (this.calendarApi) {
        if (this.viewType !== 'resourceTimelineDay') {
          this.calendarApi.setOption('nowIndicator', false)
          this.calendarApi.setOption('slotMinTime', '00:00:00')
          this.calendarApi.setOption('slotMaxTime', '24:00:00')
        } else { // day view
          this.calendarApi.setOption('nowIndicator', true)
          this.calendarApi.setOption('slotMinTime', this.slotMinTime)
          this.calendarApi.setOption('slotMaxTime', this.slotMaxTime)
        }
      }
    },
    calendarApi () {
      if (this.calendarApi) {
        if (this.viewType !== 'resourceTimelineDay') {
          this.calendarApi.setOption('nowIndicator', false)
          this.calendarApi.setOption('slotMinTime', '00:00:00')
          this.calendarApi.setOption('slotMaxTime', '24:00:00')
        } else { // day view
          this.calendarApi.setOption('nowIndicator', true)
          this.calendarApi.setOption('slotMinTime', this.slotMinTime)
          this.calendarApi.setOption('slotMaxTime', this.slotMaxTime)
        }
      }
    },
    view () {
      if (this.showMode !== 'all' && this.calendarApi) {
        // todo сделать нормально
        setTimeout(() => {
          this.calendarApi.refetchResources()
        }, 1000)
      }
    },
    companyId (val) {
      this.getTasksMarks()
      this.getStatuses()
      this.getEmployeesByCompany()
    },
    showMode () {
      this.changeShowMode()
    }
  },
  methods: {
    getLastStatusId (task) {
      const logs = task.logs
      if (!logs || logs.length === 0) {
        return null
      }
      return logs[logs.length - 1].status_id
    },
    getTasksMarks () {
      this.$store.dispatch('getTaskMarks', this.companyId).then(marks => {
        this.marksMultiplySelectChoices = []
        marks.forEach(mark => {
          this.marksMultiplySelectChoices.push({
            id: mark.id,
            name: mark.name
          })
        })
      })
    },
    getStatuses () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then(statuses => {
        this.statusesMultiplySelectChoices = []
        statuses.forEach(status => {
          if (!status.is_first) {
            this.statusesMultiplySelectChoices.push({
              id: status.id,
              name: status.title
            })
          }
        })
      })
    },
    getEmployeesByCompany () {
      this.$store.dispatch('getEmployeesByCompany', this.companyId).then((employeEs) => {
        this.employeEs = employeEs
        this.setCalendarOptions()
        this.$nextTick(() => {
          this.init().then(() => {
            this.calendarApi.setOption('height', 'auto')
            this.basicRate = this.$store.getters.basicRate
            // костыль, чтобы отображались тултипы на слайдере в меню настроек
            const el = document.getElementById('viewSettingsDropdownMenu')
            el.style = null
          })
        })
      })
    },
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/tasks#active`,
          title: 'Tasks',
          isActive: false
        },
        {
          link: `/c/${this.companyId}/clients`,
          title: 'Clients',
          isActive: false
        }
      ]
      if (this.isEmployeeHasPermission('create-checklists')) {
        this.tabs.push({
          link: `/c/${this.companyId}/checklists`,
          title: 'Checklists',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/client-fields`,
          title: 'CLIENT_FIELDS',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-statuses`,
          title: 'Statuses',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/tasks-map`,
          title: 'Map',
          isActive: false
        })
        this.tabs.push({
          link: `/c/${this.companyId}/user-tasks/calendar`,
          title: 'USER_TASKS_CALENDAR_LINK',
          isActive: true
        })
        this.tabs.push({
          link: `/c/${this.companyId}/task-marks`,
          title: 'TASK_MARKS_LINK',
          isActive: false
        })
      }
    },
    applyFilter () {
      this.calendarApi.refetchResources()
    },
    clearEmployeeFilter () {
      this.usersMultiplySelect = []
      this.calendarApi.refetchResources()
    },
    applyTaskFilter () {
      this.calendarApi.refetchEvents()
    },
    clearTasksFilter () {
      this.marksMultiplySelect = []
      this.statusesMultiplySelect = []
      this.calendarApi.refetchEvents()
    },
    activatedAction () {
      this.dummy = false
      this.setCalendarOptions()
      this.$nextTick(() => {
        this.init().then(() => {
          this.calendarApi.setOption('height', 'auto')
          this.basicRate = this.$store.getters.basicRate
          // костыль, чтобы отображались тултипы на слайдере в меню настроек
          const el = document.getElementById('viewSettingsDropdownMenu')
          el.style = null
        })
      })
    },
    formatTooltip (val) {
      return val
    },
    createTask () {
      this.taskModalData = {
        id: null,
        title: null,
        description: null,
        client_id: null,
        scheduled_time: null,
        schedule_id: null,
        checklist_id: null,
        shift_id: null,
        employee_id: null,
        lead_time: 0.0,
        address: null,
        longitude: 0.0,
        latitude: 0.0,
        children: []
      }
      this.showTaskModal = true
    },
    handleDrawer () {
      this.drawer = !this.drawer
      this.$nextTick(() => {
        this.calendarApi.updateSize()
      })
    },
    init () {
      return new Promise(async (resolve, reject) => {
        this.calendarApi = await this.$refs.taskCalendar?.getApi()
        this.draggable = new Draggable(this.$refs.extendedEvents, {
          itemSelector: '.fc-event',
          eventData: function (eventEl) {
            return JSON.parse(eventEl.dataset.event)
          }
        })
        this.initFC = true
        const usersMultiplySelectChoices = []
        const sortedUsersResources = JSON.parse(JSON.stringify(this.resources))
        sortedUsersResources.forEach(value => {
          if (value.id > 0) {
            usersMultiplySelectChoices.push({ id: value.id, name: value.title })
          }
        })
        this.usersMultiplySelectChoices = usersMultiplySelectChoices.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
        })
        resolve()
      })
    },
    setCalendarOptions () {
      this.backlogOptions = {
        // schedulerLicenseKey: process.env['VUE_APP_SCHEDULER_LICENSE_KEY'],
        plugins: [dayGridPlugin, interaction, bootstrapPlugin],
        themeSystem: 'bootstrap',
        contentHeight: 60,
        aspectRatio: 1.5,
        headerToolbar: {
          start: '',
          center: '',
          end: ''
        },
        droppable: true,
        editable: true,
        initialView: 'dayGridDay',
        duration: { days: 1 },
        views: {
          dayGridDay: {
            type: 'dayGrid',
            duration: { days: 1 },
            buttonText: ''
          }
        },
        eventReceive: (info) => {
          this.search = ''
          const payload = info.event.extendedProps.item
          if (!info.event.extendedProps.item.scheduled_time && !info.event.extendedProps.item.shift_id) {
            info.revert()
            return
          }
          payload.scheduled_time = null
          payload.scheduled_time_end = null
          payload.shift_id = null
          payload.employee_id = null
          delete payload.employee
          delete payload.shift
          this.$store.dispatch('updateTask', [payload.company_id, payload.id, payload])
            .then(task => {
              info.revert()
              this.backlogTasks.push(task)
              this.backlogTasksFiltered.push(task)
            })
            .catch(e => {
              info.revert()
              this.calendarApi.refetchEvents()
              this.toastError(this.$i18n?.t(e.response.data.message))
            })
        }
      }
      this.calendarOptions = {
        schedulerLicenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
        plugins: [interaction, resourceTimelinePlugin, dayGridPlugin, bootstrapPlugin],
        aspectRatio: 1.5,
        themeSystem: 'bootstrap',
        selectable: true,
        droppable: true,
        editable: true,
        headerToolbar: this.header,
        stickyHeaderDates: true,
        stickyFooterScrollbar: false,
        firstDay: this.firstDay,
        // nowIndicator: true,
        refetchResourcesOnNavigate: false,
        nowIndicatorDidMount: () => {
        },
        customButtons: {
          calendarButton: {
            text: ' '
          }
        },
        buttonText: {
          prev: '<',
          next: '>'
        },
        initialView: this.taskCalendarDefaultView,
        locales: [ruLocale, heLocale, ukLocale, deLocale, plLocale, esLocale, elLocale],
        locale: this.lang,
        direction: this.direction,
        dayMaxEventRows: false,
        lazyFetching: false,
        views: this.views,
        viewClassNames: (arg) => {
          this.view = {}
          this.view = arg.view
          if (arg.view.type === 'resourceTimeMonth' && this.tasksDisplayType === 'compact') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        slotLabelClassNames: () => {
          if (this.view.type === 'resourceTimeMonth' && this.tasksDisplayType === 'compact') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        scrollTime: '00:00:00',
        nextDayThreshold: '24:00:00',
        resourceAreaWidth: this.handleResourceColumnWidth,
        resourceAreaColumns: [
          {
            labelText: '',
            field: 'title'
          }
        ],
        loading: (isLoading) => {
        },
        resourceOrder: 'type,sortOrder,title',
        resourceLaneClassNames: (el) => {
          if (el.resource.id === '0' || el.resource.id === this.currentEmployeeId.toString()) {
            return 'bg-resource-color'
          }
        },
        resourceLabelClassNames: (el) => {
          if (el.resource.id === '0' || el.resource.id === this.currentEmployeeId.toString()) {
            return 'bg-resource-color'
          }
        },
        resources: (fetchInfo, successCallback, failureCallback) => {
          this.createResources().then(resources => {
            successCallback(resources)
          }).catch(e => {
            failureCallback(e.message)
          })
        },
        // use events, till we find any reason to use sources below
        events: (fetchInfo, successCallback, failureCallback) => {
          this.getEvents(fetchInfo).then(events => {
            if (this.marksMultiplySelect && this.marksMultiplySelect.length > 0) {
              events.tasks = events.tasks.filter(task => {
                const setMarks = new Set(this.marksMultiplySelect)
                return task.marks.filter(markId => setMarks.has(markId)).length > 0
              })
            }
            if (this.statusesMultiplySelect && this.statusesMultiplySelect.length > 0) {
              events.tasks = events.tasks.filter(task => {
                const statusId = this.getLastStatusId(task)
                if (!statusId) {
                  return false
                }
                return this.statusesMultiplySelect.includes(statusId)
              })
            }
            this.setEvents(events).then(response => {
              successCallback(response)
            })
          })
        },
        eventClassNames: (arg) => {
          if (arg.event.extendedProps.isTask) {
            return 'rounded'
          }
          return ''
        },
        viewDidMount: (info) => {
          // монтируем датапикер на кнопку в хедере календаря
          const { el } = mount(DatePickerComponent,
            {
              props: {
                locale: this.$store.getters.locale
              },
              app: app
            }
          )
          let btn = document.querySelector('.fc-calendarButton-button')
          if (btn) {
            btn.replaceWith(el)
          }
          if (this.isMobile) {
            btn = document.querySelector('.fc-today-button')
            btn.classList.add('btn-sm')
            btn.classList.add('mx-1')
            btn = document.querySelector('.fc-prev-button')
            btn.classList.add('btn-sm')
            btn = document.querySelector('.fc-next-button')
            btn.classList.add('btn-sm')
          }
        },
        datesSet: (info) => {
          this.view = {} // todo костыль для активанции вотчера при первой загрузке календаря (вотчер не видит изменения the.view пока не переключишься между вьюхами), проверить после обновления версии календаря
          this.view = info.view
          this.$eventBus.emit('updateCurrentDate', this.view.currentStart)
        },
        dateClick: (dateClickInfo) => {
        },
        eventClick: (eventClickInfo) => {
          this.setModal(eventClickInfo.event.extendedProps.item)
        },
        eventDrop: (info) => {
          let date = info.event.extendedProps.item.scheduled_time ? 'date=' + this.toUTC(moment(info.event.start, this.backendDateFormat)).format(this.backendDateTimeFormat) : null
          if (!date) {
            date = info.event.extendedProps.item.shift_id ? 'date=' + this.toUTC(moment(info.event.start, this.backendDateFormat)).format(this.backendDateTimeFormat) : null
          }
          if (!date) {
            info.revert()
            return
          }
          let resourceId = null
          if (info.newResource) {
            resourceId = +info.newResource.id
          } else {
            resourceId = +info.event.extendedProps.item.employee_id
          }
          this.handleDrop(info, date, resourceId)
        },
        eventReceive: (dropInfo) => {
          const dateRangeStart = this.toUTC(moment(dropInfo.event.start, this.backendDateFormat).startOf('day')).format(this.backendDateTimeFormat)
          const dateRangeEnd = this.toUTC(moment(dropInfo.event.start, this.backendDateFormat).startOf('day').add(1, 'day')).format(this.backendDateTimeFormat)
          const date = 'date_range_start=' + dateRangeStart + '&date_range_end=' + dateRangeEnd
          this.handleDrop(dropInfo, date, +dropInfo.event.getResources()[0].id)
        }
      }
      this.$nextTick(() => {
        const node = document.getElementById('backlogCalendar')
        if (node) {
          node.style.width = '100%'
        }
        let collection = this.$refs.backlogCalendar.$el?.getElementsByClassName('fc-daygrid-body')
        for (const item of collection) {
          item.style.width = '100%'
        }
        collection = this.$refs.backlogCalendar.$el?.getElementsByClassName('fc-scrollgrid-sync-table')
        for (const item of collection) {
          item.style.width = '100%'
        }
        collection = this.$refs.backlogCalendar.$el?.getElementsByClassName('fc-col-header-cell-cushion')
        for (const item of collection) {
          item.text = this.$t('DROPZONE_HINT')
        }
        collection = this.$refs.backlogCalendar.$el?.getElementsByClassName('fc-header-toolbar')
        for (const item of collection) {
          item.hidden = true
        }
      })
    },
    handleDrop (info, date, resourceId) {
      const task = info.event.extendedProps.item
      if (resourceId === 0) {
        if (task.employee_id || task.shift_id) {
          const payload = {
            title: task.title,
            schedule_id: task.schedule_id,
            shift_id: null,
            client_id: task.client_id,
            employee_id: 0
          }
          if (info.event.extendedProps.item.scheduled_time) {
            payload.scheduled_time = this.toUTC(moment(info.event.start, this.backendDateFormat)).format(this.backendDateTimeFormat)
          }
          this.updateTusk(info, payload)
        } else {
          info.revert()
        }
      } else {
        this.$store.dispatch('getShiftsToAttachTaskCalendar', [
          this.companyId,
          info.event.extendedProps.item.schedule_id,
          date
        ]).then(response => {
          const shifts = response.filter(item => +item.employee_id === +resourceId)
          if (shifts.length > 0) {
            const payload = {
              title: task.title,
              schedule_id: task.schedule_id,
              shift_id: shifts[0].id,
              client_id: task.client_id,
              employee_id: resourceId
            }
            if (info.event.extendedProps.item.scheduled_time) {
              payload.scheduled_time = this.toUTC(moment(info.event.start, this.backendDateFormat)).format(this.backendDateTimeFormat)
            }
            this.updateTusk(info, payload)
          } else {
            this.toastError(this.$i18n?.t('NO_SHIFTS_FOUND'))
            info.revert()
          }
        })
      }
    },
    taskCalendarRefreshEvents () {
      this.calendarApi.refetchEvents()
    },
    updateTusk (info, payload) {
      this.$store.dispatch('updateTask', [this.companyId, info.event.id, payload])
        .then((response) => {
          this.toastSuccess(this.$t('Task updated'))
          info.event.remove()
          this.calendarApi.refetchEvents()
        })
        .catch((e) => {
          this.toastError(this.$i18n?.t(e.message))
        })
    },
    getEvents ({ start, end }) {
      return new Promise((resolve, reject) => {
        const dateFrom = this.toUTC(moment(start, this.backendDateFormat)).format(this.backendDateTimeFormat)
        const dateTo = this.toUTC(moment(end, this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
        const active = {
          status: 'active',
          date_from: dateFrom,
          date_to: dateTo
        }
        const completed = {
          status: 'completed',
          date_from: dateFrom,
          date_to: dateTo
        }
        const backlog = {
          status: 'backlog'
        }
        const shifts = `start=${dateFrom}&end=${dateTo}`
        const payloadActive = '?' + this.stringifyObject(active)
        const payloadCompleted = '?' + this.stringifyObject(completed)
        const payloadBacklog = '?' + this.stringifyObject(backlog)
        const responseActive = this.$store.dispatch('getTasks', [this.companyId, payloadActive])
        const responseCompleted = this.$store.dispatch('getTasks', [this.companyId, payloadCompleted])
        const responseBacklog = this.$store.dispatch('getTasks', [this.companyId, payloadBacklog])
        const responseShifts = this.$store.dispatch('getShiftsByCompany', [this.companyId, shifts])
        Promise.all([responseActive, responseCompleted, responseBacklog, responseShifts]).then(resp => {
          this.loaded = true
          this.dummy = false
          this.$eventBus.emit('dummy', false)
          let tasks = []
          tasks = tasks.concat(resp[0])
          tasks = tasks.concat(resp[1])
          tasks = tasks.concat(resp[2])
          const shifts = resp[3]
          resolve({
            tasks: tasks,
            shifts: shifts
          })
        }).catch(error => {
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          }
        })
      })
    },
    setEvents (events) {
      return new Promise((resolve, reject) => {
        const backlogTasks = []
        const scheduledTasks = []
        const usersWithTasksIds = []
        events.tasks.forEach(task => {
          if (!task.scheduled_time && !task.shift_id) {
            backlogTasks.push(task)
          } else {
            if (task.shift_id && !task.shift) {
              // do nothing таски привязаные к удаленному шифту
            } else {
              if (task.shift && this.toTimeZone(task.shift.time_from).isBetween(moment(this.view.currentStart), moment(this.view.currentEnd))) {
                usersWithTasksIds.push(task.employee_id)
              }
              scheduledTasks.push(this.setTask(task))
            }
          }
        })
        events.shifts.forEach(shift => {
          if (shift.employee_id) {
            scheduledTasks.push(this.setShift(shift))
          }
        })
        this.backlogTasks = [...backlogTasks]
        this.backlogTasksFiltered = [...backlogTasks]
        this.scheduledTasks = scheduledTasks
        this.usersWithTasksIds = [...new Set(usersWithTasksIds)]
        resolve(scheduledTasks)
      })
    },
    setShift (shift) {
      const startTime = this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(shift.time_to, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const event = {
        id: shift.id,
        title: '',
        displayEventTime: true,
        start: startTime,
        end: endTime,
        resourceId: shift.employee_id,
        backgroundColor: '#FDF5E6',
        item: shift,
        startEditable: false,
        durationEditable: false,
        display: 'background'
      }
      return event
    },
    setTask (task) {
      let startTime, endTime
      if (task.scheduled_time) {
        startTime = this.fromZoneToZone(task.scheduled_time, 'UTC', this.$store.getters.profileTimeZone)
          .format(this.backendDateTimeFormat)
        if (+task.lead_time) {
          endTime = this.fromZoneToZone(task.scheduled_time, 'UTC', this.$store.getters.profileTimeZone)
            .add(+task.lead_time, 'hours')
            .format(this.backendDateTimeFormat)
        } else {
          endTime = this.fromZoneToZone(task.scheduled_time, 'UTC', this.$store.getters.profileTimeZone)
            .add(2, 'hours')
            .format(this.backendDateTimeFormat)
        }
      } else {
        startTime = this.fromZoneToZone(task.shift.time_from, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
        if (+task.lead_time) {
          endTime = this.fromZoneToZone(task.shift.time_from, 'UTC', this.$store.getters.profileTimeZone)
            .add(+task.lead_time, 'hours')
            .format(this.backendDateTimeFormat)
        } else {
          endTime = this.fromZoneToZone(task.shift.time_to, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
        }
      }
      const durationHrs = Math.round(moment.duration(moment(endTime).diff(moment(startTime))).asHours() * 100) / 100
      const title = task.title
      let color = '#00accb'
      if (task.logs && task.logs.length > 0) {
        color = task.logs[task.logs.length - 1].status.color
      }
      if (this.tasksDisplayType === 'compact') {
        endTime = moment(startTime).add(4, 'hours').format(this.backendDateTimeFormat)
      }

      const event = {
        id: task.id,
        title: title,
        displayEventTime: true,
        start: startTime,
        end: endTime,
        resourceId: task.employee_id,
        color: color,
        item: task,
        startEditable: true,
        durationEditable: false,
        durationHrs: durationHrs,
        isTask: true
      }

      return event
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    createResources () {
      return new Promise((resolve, reject) => {
        this.resources = []
        let resourceFreeBoard, userResources
        let resources = []
        /** Фриборд (для всех пользователей) */
        resourceFreeBoard = this.resourceFreeBoard()
        resources = resources.concat(resourceFreeBoard)
        /** Users */
        userResources = this.createResource()
        resources = resources.concat(userResources)
        this.resources = resources
        this.filteredResources = [...resources]
        if (this.usersMultiplySelect.length > 0) {
          this.filteredResources = this.filteredResources.filter(item => this.usersMultiplySelect.includes(item.id))
        }
        const currentEmployeeIndex = this.filteredResources.findIndex(item => +item.id === +this.currentEmployeeId)
        if (currentEmployeeIndex > -1) {
          this.filteredResources[currentEmployeeIndex].sortOrder = -1
        }
        resolve(this.filteredResources)
      })
    },
    createResource () {
      let resource, resources, resourcesArray
      this.userSchedule = {}
      resources = {}
      resourcesArray = []
      Object.keys(this.employeEs).forEach((id, index) => {
        const user = this.employeEs[id]
        if (this.condition(user)) {
          const position = []
          user.positions.sort((a, b) => {
            if (a.priority > b.priority) {
              return 1
            }
            return -1
          })
          user.positions.forEach(item => {
            position.push(item.title)
          })
          resource = {
            id: user.id,
            title: user.full_name,
            control: null,
            deleted: false,
            type: 7,
            identifier: user.identifier ? user.identifier : '',
            position: position.join(', '),
            priority: user.positions.length > 0 ? user.positions[0].priority : 90000000
          }
          resources[user.id] = resource
        }
      })
      Object.keys(resources).forEach(key => {
        resourcesArray.push(resources[key])
      })
      resourcesArray = resourcesArray.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
      })
      resourcesArray.sort(this.sortResources)
      if (this.primarySortOrder === 'identifier') {
        const firstArray = resourcesArray.filter(item => item.identifier !== null &&
          item.identifier !== '' &&
          item.identifier !== 'undefined')
        const secondArray = resourcesArray.filter(item => item.identifier === null ||
          item.identifier === '' ||
          item.identifier === 'undefined')
        resourcesArray = firstArray.concat(secondArray)
      }
      resourcesArray.forEach((res, index) => {
        res.sortOrder = index
      })
      return resourcesArray
    },
    condition (user) {
      if (this.showMode === 'all') {
        return !!user
      }
      return user && this.usersWithTasksIds.includes(user.id)
    },
    sortResources (a, b) {
      if (this.primarySortOrder === 'name') {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'position') {
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'identifier') {
        if (a.identifier > b.identifier) {
          return 1
        }
        if (a.identifier < b.identifier) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
    },
    /** Создания ресурса биржи для всех пользователей */
    resourceFreeBoard () {
      return [{
        id: FREE_BOARD_RESOURCE_ID,
        title: this.$i18n?.t('FREE_TASKS'),
        control: null,
        type: 3
      }]
    },
    updateDisplayMode (e) {
      e.preventDefault()
      const el1 = document.getElementById('viewSettingsDropdown')
      el1.classList.remove('show')
      const el2 = document.getElementById('viewSettings')
      el2.classList.remove('show')
      const el3 = document.getElementById('viewSettingsDropdownMenu')
      el3.style = null
      el3.classList.remove('show')
      localStorage.setItem('tasksDisplayType', this.tasksDisplayType) // на какой вьюхе ?? таски могут отображаться в реальную продолжительность или ровно 2 часа, в зависимости от настроек
      localStorage.setItem('tasksMinTimeCalendar', this.maxMinTime[0])
      localStorage.setItem('tasksMaxTimeCalendar', this.maxMinTime[1])
      localStorage.setItem('tasksShowPosition', this.showPosition)
      localStorage.setItem('tasksShowIdentifier', this.showIdentifier)
      localStorage.setItem('tasksPrimarySortOrder', this.primarySortOrder)
      this.calendarTimeRange = {
        start: this.maxMinTime[0],
        end: this.maxMinTime[1]
      }
      this.calendarApi.destroy()
      this.setCalendarOptions()
      this.$nextTick(() => {
        this.init().then(() => {
          this.calendarApi.setOption('height', 'auto')
          this.calendarApi.render()
        })
      })
    },
    goToDate (value) {
      if (this.calendarApi && !moment(value).isSame(moment(this.view.currentStart))) this.calendarApi.gotoDate(value)
    },
    timeSlotWidth () {
      const calendarEl = this.$refs.sizing
      if (calendarEl) {
        const containerWidth = calendarEl.clientWidth
        this.slotWidth3days = Math.round((containerWidth - this.resourceColumnWidth) / 72)
        this.slotWidth1day = Math.round((containerWidth - this.resourceColumnWidth) / 96)

        // calculate more precise resource column width
        this.resourceColumnWidth = Math.floor(containerWidth - this.slotWidth1day * 96 - 22)
      }
    },
    changeShowMode (value) {
      this.calendarApi.refetchResources()
    },
    closeTaskModal () {
      this.showTaskModal = false
      this.taskModalData = {
        id: null,
        children: []
      }
    },
    reloadTasks () {
      this.calendarApi.refetchEvents()
    },
    setModal (task) {
      this.taskModalData = task
      this.showTaskModal = true
    }
  }
}
</script>

<style lang="scss">
.user-mark {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}

.fc-event-main > span {
  width: 100%;
}

.fc-calendarButton-button {
  padding: 0 !important;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center !important;
  width: 0px !important;
  height: 0px !important;
  border: none !important;
  background: none !important;
  /*background:url('../../../../public/static/images/App/calendar-dark.png') center no-repeat!important;*/
}
/*.fc-timeline-bg-harness:hover {*/
/*  background:url('../../../../public/static/images/App/calendar-dark.png') center no-repeat!important;*/
/*}*/
.bg-resource-color {
  background-color: rgb(140, 140, 140, 0.4)!important;
}
.el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(255,255,255,.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  max-height: 100vh;
  overflow: auto;
}
.el-select-dropdown.el-popper {
  z-index: 20000!important;
}
</style>
