<template>
  <div>
    <div class="row my-2 mx-auto">
      <div class="col d-flex">
        <div class="title-text">{{ $t('Dashboard') }}</div>
        <div class="align-self-end text-right w-100">
          <button
            type="submit"
            @click="toUserDashboard"
            data-cy="switch-view"
            class="btn btn-outline-primary rounded-pill shadow-sm">
            {{ $t('Calendar') }}
          </button>
        </div>
      </div>
    </div>
    <template v-if="loaded">
      <dashboard-statistic :schedules="schedules" />
    </template>
  </div>
</template>

<script>
import DashboardStatistic from './DashboardComponents/DashboardStatistic'
import momentMixin from '@/mixins/mixinMoment'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'

export default {
  name: 'DashboardCompany',
  mixins: [momentMixin, mixinWindowWidthWatch],
  components: {
    DashboardStatistic
  },
  data () {
    return {
      statMode: true,
      pickdate: 'now',
      activeProjectItem: {},
      activeScheduleItem: {},
      loaded: false,
      usersCompanyTotal: '',
      companyName: '',
      timeZone: 'UTC',
      schedules: []
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  computed: {
    roleDashboard () {
      let role = 'noRole'
      if (this.$store.getters.companyRole !== null) {
        role = 'gg_user'
        if (this.$store.getters.companyRole === 'gg_admin') role = 'gg_admin'
        if (this.$store.getters.projectRole === 'manager') role = 'gg_manager'
        if (this.$store.getters.projectRole === 'viewer') role = 'gg_manager'
      }
      return role
    },
    lang () {
      return this.$store.getters.locale
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getProjectsByCompany', this.companyId).then(() => {
        this.$store.dispatch('setProjectRole', this.$store.getters.projects)
        if (this.roleDashboard === 'noRole') {
          this.$router.push('/profile')
        }
        if (this.roleDashboard === 'gg_user') {
          this.$router.push('/dashboard')
        }
        if (this.$route.params.id && ['gg_manager', 'gg_admin'].includes(this.roleDashboard)) {
          this.$store.dispatch('getSchedules', this.$route.params.id).then(schedules => {
            this.schedules = schedules
            this.loaded = true
          })
        }
      })
    },
    toUserDashboard () {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
