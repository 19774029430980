<template>
  <Form ref="observer" as="tr">
    <td>{{userState.name}}</td>
    <td>
      <ui-number-input
        :placeholder="$t('Pay rate')"
        v-model="userState.rate"
        :name="index + '__pay_rate'"
        :validation="{required: false, decimal: 2}"
        :append="true"
      />
    </td>
    <td style="max-width: 300px; width: 300px">
      <ui-tags-select
        :name="index + '__marks'"
        v-model="userState.marks"
        :filterable="true"
        :placeholder="$t('Select')"
        :no-data-text="$t('No data')"
        :options="userMarksChoices"
        :key-name="'key'"
        :label-name="'name'"
        :value-name="'id'"
        :allow-create="true"
      >
        <template v-slot:default="slotProps">
          <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
          {{ slotProps.option.name }}
        </template>
      </ui-tags-select>
    </td>
    <td>
      <ui-date-picker
        :name="index + '__period'"
        class="w-auto"
        v-model="dateFrom"
        :validation="{required: true}"
        :clearable="false"
      />
    </td>
    <td>
      <button class="btn btn-success btn-circle" type="button" @click="saveUserRate(userState.id)">
        {{ $t('SAVE_WEB') }}
      </button>
    </td>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'

export default {
  name: 'UserRateComponent',
  mixins: [momentMixin, errorMixin, mixinColorsMarks],
  data () {
    return {
      dateFrom: null,
      userState: {}
    }
  },
  props: { user: Object, userMarksChoices: Array, usersData: Array, project_id: Number, index: Number },
  created () {
    this.dateFrom = this.user.date_from
    this.userState = this.user
  },
  computed: {
    currency () {
      return this.$store.getters.currency
    }
  },
  watch: {
    'userState.marks': {
      handler (items) {
        // console.log(items)
        if (Array.isArray(items)) {
          items.forEach((item, index) => {
            if (typeof item === 'string') {
              const tag = {
                name: item,
                enabled: true
              }
              const usedColors = []
              this.userMarksChoices.forEach(mark => {
                usedColors.push(mark.color)
              })
              const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
              if (colorDiff.length > 0) {
                tag.color = colorDiff[0]
              } else {
                tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
              }
              const newIndex = index
              this.$store.dispatch('createMark', [tag, this.project_id]).then((mark) => {
                this.newMark(mark, newIndex)
              }).catch((err) => {
                this.toastError(this.$i18n?.t(err.response.data.errors.name[0]))
                this.userState.marks = []
              })
            }
          })
          // const marks = [...new Set(this.user.marks)]
          // console.log(marks)
          // this.user.marks[indexToClear] = null
          // this.user.marks = this.user.marks.filter(mark => mark !== null)
        }
      }
    }
  },
  methods: {
    newMark (mark, newIndex) {
      this.$emit('newMark', [mark, newIndex])
    },
    saveUserRate (userId) {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          let userRate = {}
          this.usersData.forEach((item) => {
            if (item.id === userId) {
              userRate = Object.assign({}, item)
            }
          })
          this.prepareScheduleRate(userRate)
          userRate.date_from = this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)

          this.$store.dispatch('addPayRateToUser', [this.$route.params.scheduleId, userRate.id, userRate]).then((schedule) => {
            if (schedule) {
              this.toastSuccess(this.$t('Schedule successfully edited'))
            }
          })
        }
      })
    },
    prepareScheduleRate (scheduleRate) {
      scheduleRate.rate = parseFloat(scheduleRate.rate)
      scheduleRate.date_from = this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
    }
  }
}
</script>

<style scoped lang="scss">
  .text-error > input {
    border-color: red;
  }
  .circlearancione {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 20px;
    width: 20px;
  }

  .el-select__tags-text {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
</style>
