<template>
  <div class="card card-small">
    <div class="card-body">
      <div class="row pb-2">
        <div class="col-lg-12">
          <div class="errors" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
          <div class="text-primary text-uppercase font-weight-bold">{{ $t('Payment methods') }}</div>
          <div class="font-weight-bold">{{ $t('Linking payment card or PayPal account to pay for modules use.') }}</div>
        </div>
      </div>
      <el-alert
        v-if="unpaidInvoice"
        :title="$t('subscription_unpaid_invoice_alert')"
        type="warning"
        effect="dark"
        :closable="false"
        show-icon
        class="mb-2"
      >
      </el-alert>
      <div class="form-row pt-3">
        <div class="col-lg-2 form-group float-left">
          <button class="btn btn-primary rounded-pill shadow-sm" @click="showForm">{{ $t('Add card') }}</button>
        </div>
      </div>
      <div v-if="formOn" class="form-row">
        <div class="col-12 col-lg-4 form-group">
          <credit-card />
        </div>
      </div>
      <div class="form-row" v-if="cards.length > 0">
        <div v-bind:key="card.id" class="col-lg-4" v-for="card in cards">
          <card-template :cardData="card" :month="month" :years="years" />
        </div>
      </div>
      <div class="form-row" v-if="newbie">
        <div class="col-lg-12 text-center">
          <button type="submit" name="submit" class="btn btn-primary rounded-pill shadow-sm" @click="next">{{ $t('Next') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '../../../mixins/mixinApiErrors'
import CardTemplate from './CardTemplate'
import CreditCard from './FormComponents/CreditCard'
import moment from 'moment'

export default {
  name: 'PaymentMethods',
  components: { CardTemplate: CardTemplate, CreditCard: CreditCard },
  mixins: [errorMixin],
  data () {
    return {
      cards: [],
      formOn: false,
      month: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      subscriptionId: '',
      unpaidInvoice: false

    }
  },
  props: ['newbie'],
  created () {
    this.$store.dispatch('getSubscription').then(response => {
      if (response && response.length) {
        this.subscriptionId = response[0].id
      }
      this.getCards()
    })
    for (let i = 0; i <= 20; i++) {
      this.years.push(moment().add(i, 'years').format('YYYY'))
    }

    // check unpaid invoices
    this.checkUnpaidInvoice()
    this.$eventBus.on('removeCard', this.getCards)
    this.$eventBus.on('addCard', this.addCard)
  },
  beforeUnmount () {
    this.$eventBus.off('removeCard')
    this.$eventBus.off('addCard')
  },
  methods: {
    next () {
      if (this.newbie) this.$eventBus.emit('setStep', 6)
    },
    getCards () {
      this.formOn = false
      this.$store.dispatch('getCards').then(response => {
        this.cards = []
        if (response) this.cards = response
        this.cards.forEach(card => {
          card.subscription_id = this.subscriptionId
        })
      })
    },
    checkUnpaidInvoice () {
      this.$store.dispatch('getInvoices', { payload: '?status=created' }).then(response => {
        if (response && response.length > 0) {
          this.unpaidInvoice = true
        } else {
          this.unpaidInvoice = false
        }
      })
    },
    addCard (card) {
      this.checkUnpaidInvoice()
      this.formOn = false
      this.cards.push(card)
    },
    showForm () {
      this.formOn = !this.formOn
    }
  }
}
</script>

<style scoped>
</style>
