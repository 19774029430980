<template>
  <modal-component
    :id="'requestsModal'"
    @closeModal="closeModal"
    :modalClass="dummy ? 'modal-dummy' : null"
    :modalBodyClass="dummy ? 'dummy-popup' : null"
    :hideFooter="!!dummy"
    :hideHeader="!!dummy"
  >
    <template #header>
      <h5 class="modal-title">{{ $t('Create request')}}</h5>
    </template>
    <template #body>
      <div v-if="dummy">
        <dummy-payment-required
          @activate="activate()"
          :dummyInfo="dummyInfo"
          :isModal="true"
        >
        </dummy-payment-required>
      </div>
      <div v-else>
        <Form ref="observe" as="div">
          <div id="request_create_form">
            <div class="font-weight-bold mb-2">
              {{ $t('Are you taking time-off for part of a day or full day?') }}
            </div>
            <ui-radio
              name="all_day"
              :label="$t('All day')"
              v-model="duration"
              :set-value="'all_day'"
            />
            <ui-radio
              name="part_day"
              :label="$t('Part of a day')"
              v-model="duration"
              :set-value="'part_day'"
            />
            <div class="font-weight-bold my-2">
              {{ $t('Time-off type') }}
            </div>
            <div class="row">
              <div class="col-6">
                <ui-select
                  name="paid_type"
                  v-model="paidType"
                  class="w-100"
                  :clearable="false"
                  :options="typeChoices.paid"
                  :validation="{required: true}"
                  :key-name="'code'"
                  :value-name="'code'"
                  :label-name="'name'"
                />
              </div>
              <div class="col-6">
                <ui-select
                  name="type"
                  v-model="type"
                  class="w-100"
                  :clearable="false"
                  :options="typeChoices.type"
                  :validation="{required: true}"
                  :key-name="'code'"
                  :value-name="'code'"
                  :label-name="'name'"
                />
              </div>
            </div>
            <template v-if="use_reasons">
              <div class="font-weight-bold my-2">
                {{ $t('Time-off reason') }}
              </div>
              <div class="row">
                <div class="col-6">
                  <ui-select
                    name="reason_id"
                    v-model="reason_id"
                    class="w-100"
                    :clearable="false"
                    :options="filteredReasonsByType"
                    :validation="{required: false}"
                    :key-name="'id'"
                    :value-name="'id'"
                    :label-name="'title'"
                  />
                </div>
              </div>
            </template>
            <div class="font-weight-bold my-2">
              {{ $t('Time-off duration, dates') }}
            </div>
            <div class="row">
              <div class="col-6">
                <div class="font-weight-bold" v-if="duration === 'all_day'">{{ $t('From') }}</div>
                <ui-date-picker
                  id="dateFrom"
                  name="date_from"
                  v-model="dateFrom"
                  :validation="{required: true}"
                  :clearable="false"
                />
              </div>
              <div class="col-6" v-if="duration === 'all_day'">
                <div class="font-weight-bold">{{ $t('To') }}</div>
                <ui-date-picker
                  id="dateTo"
                  name="date_to"
                  v-model="dateTo"
                  :validation="{required: true}"
                  :clearable="false"
                />
              </div>
            </div>
            <div v-if="isVacationModuleEnable && type === 'vacation' && monthStatistic && monthStatistic.balance" class="row">
              <div class="col-12">
              <span
                class="small"
                :class="{
                  'text-primary': monthStatistic.balance >= period,
                  'text-warning': monthStatistic.balance < period
                }"
              >
                {{$t('AVAILABLE_VACATIONS_FOR_CURRENT_MONTH')}} {{monthStatistic.balance}} {{$t('days')}}
                <ui-hint :content="$t('AVAILABLE_VACATIONS_FOR_CURRENT_MONTH_HINT')" />
              </span>
              </div>
            </div>
            <div v-if="isVacationModuleEnable && type === 'vacation' && (!monthStatistic || !monthStatistic.balance)" class="row">
              <div class="col-12">
              <span
                class="small text-primary"
              >
                {{$t('NO_VACATION_STATISTIC_PRECALCULATED')}}
                <ui-hint :content="$t('NO_VACATION_STATISTIC_PRECALCULATED_HINT')" />
              </span>
              </div>
            </div>
            <template v-if="duration === 'part_day'">
              <div class="font-weight-bold my-2">{{ $t('Time-off duration, hours') }}</div>
              <div class="row">
                <div class="col-6">
                  <ui-time-picker
                    :label="$t('From')"
                    :name="'time_from'"
                    :placeholder="$t('From')"
                    :validation="{required: true}"
                    :clearable="false"
                    :value-format="localeTimeFormatElementUi"
                    v-model="timeFrom"
                  />
                </div>
                <div class="col-6">
                  <ui-time-picker
                    :label="$t('To')"
                    :name="'time_to'"
                    :placeholder="$t('To')"
                    :validation="{required: true}"
                    :clearable="false"
                    :value-format="localeTimeFormatElementUi"
                    v-model="timeTo"
                  />
                </div>
              </div>
            </template>
            <template v-if="isEmployeeHasPermission('create-request-admin') ||
           isEmployeeHasPermission('create-request-manager')">
              <ui-select
                name="name"
                v-model="users"
                class="w-100"
                :label="$t('Users')"
                :clearable="false"
                :options="usersChoices"
                :validation="{required: true}"
                :key-name="'code'"
                :value-name="'code'"
                :label-name="'name'"
              />
            </template>
            <template v-if="isEmployeeHasPermission('delete-request')">
              <div class="font-weight-bold my-2">
                {{ $t('TIME_OFF_MOVE_SHIFTS_ACTION_TYPE') }}
              </div>
              <div>
                <ui-radio
                  name="skip"
                  class="mt-2"
                  :label="$t('MOVE_SHIFTS_ACTION_SKIP')"
                  v-model="move_shifts_action"
                  :set-value="'skip'"
                />
              </div>
              <div>
                <ui-radio
                  name="move"
                  :label="$t('MOVE_SHIFTS_ACTION_MOVE')"
                  v-model="move_shifts_action"
                  :set-value="'move'"
                />
              </div>
              <div>
                <ui-radio
                  name="delete"
                  :label="$t('MOVE_SHIFTS_ACTION_DELETE')"
                  v-model="move_shifts_action"
                  :set-value="'delete'"
                />
              </div>
            </template>
            <template v-if="type !== 'sick'">
              <ui-textarea-input
                name="message"
                :label="$t('Provide a reason')"
                v-model="message"
                :validation="{required: false}"
                :rows="2"
              />
            </template>
            <template v-if="isFileUploadActive && (!selectedUser || selectedUser.userId > 0)">
              <ui-file-upload
                ref="upload"
                :name="'file'"
                :upload="upload"
                :label="$t('Attach file')"
                @file="(data) => {
                this.file = data.file
                this.size = data.size
              }"
              />
            </template>
          </div>
        </Form>
      </div>
    </template>
    <template v-if="!dummy" #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm" @click="submitForm">
          {{ $t('Send request') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment-timezone'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'CreateRequest',
  components: {
    ModalComponent,
    DummyPaymentRequired
  },
  mixins: [errorMixin, momentMixin, mixinDummy],

  data () {
    return {
      typeChoices: {
        paid: [{ code: 'unpaid', name: this.$i18n?.t('Unpaid') }, { code: 'paid', name: this.$i18n?.t('Paid') }],
        type: [{ code: 'sick', name: this.$i18n?.t('Sick day') }, { code: 'vacation', name: this.$i18n?.t('Vacation') }, { code: 'holiday', name: this.$i18n?.t('Holiday') }]
      },
      use_reasons: false,
      reasons: [],
      filteredReasonsByType: [],
      reason_id: '',
      paidType: 'unpaid',
      type: 'sick',
      subtype: 'time_off',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:mm',
      duration: 'all_day',
      dateFrom: moment().startOf('day').format(this.backendDateTimeFormat),
      timeFrom: '00:00',
      dateTo: moment().startOf('day').format(this.backendDateTimeFormat),
      timeTo: '00:00',
      users: null,
      message: '',
      file: '',
      fileList: [],
      upload: {
        url: process.env.VUE_APP_ROOT_API + 'requests/',
        headers: {
          Authorization: 'api-key ' + localStorage.getItem('api_key'),
          'Content-Type': 'multipart/form-data'
        }
      },
      base: '',
      move_shifts_action: 'skip',
      dummyInfo: null,
      dummy: false,
      isFileUploadActive: false,
      size: 0,
      fileLimit: 102400,
      isVacationModuleEnable: false
    }
  },

  created () {
    if (this.companyId) {
      this.users = +this.currentCompanyUserId
      this.init()
    }
  },

  beforeMount () {
    this.$store.dispatch('getProfile')
  },

  computed: {
    ...mapGetters([
      'locale',
      'employees',
      'employeesIndexed',
      'Profile',
      'companyId',
      'currentCompanyUserId',
      'ModulesByCompany',
      'projects'
    ]),
    usersChoices () {
      const usersChoices = []

      if (this.isEmployeeHasPermission('create-request-admin')) {
        this.employees.forEach((item) => {
          usersChoices.push({
            code: item.id,
            name: item.identifier ? `${item.last_name} ${item.first_name} (${item.identifier})` : `${item.last_name} ${item.first_name}`,
            userId: item.user_id
          })
        })
      } else if (this.isEmployeeHasPermission('create-request-manager') && this.projects) {
        let projectUsers = []
        this.projects.forEach(project => {
          if (project.managers && project.managers.includes(this.currentCompanyUserId)) {
            projectUsers = projectUsers.concat(project.users)
          }
        })
        projectUsers = [...new Set(projectUsers)]
        projectUsers.forEach(projectUserId => {
          if (this.employeesIndexed[projectUserId]) {
            usersChoices.push({
              code: this.employeesIndexed[projectUserId].id,
              name: this.employeesIndexed[projectUserId].last_name + ' ' + this.employeesIndexed[projectUserId].first_name,
              userId: this.employeesIndexed[projectUserId].user_id
            })
          }
        })
      }

      usersChoices.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })

      return usersChoices
    },
    selectedUser () {
      return this.usersChoices.find(user => user.code === this.users)
    },
    monthStatistic () {
      return this.$store.getters.vacationsMonthStatistic[this.users]
    },
    period () {
      return Math.abs(moment.duration(moment(this.dateTo).diff(moment(this.dateFrom))).asMinutes() / (60 * 24))
    }
  },

  watch: {
    companyId () {
      this.init()
    },
    currentCompanyUserId () {
      this.users = this.currentCompanyUserId.toString()
    },
    use_reasons (value) {
      if (value) {
        this.$store.dispatch('getCompanyUserRequestReasons').then(requestsReasons => {
          this.reasons = requestsReasons
          this.filteredReasonsByType = requestsReasons.filter(item => item.type_time_off === this.type)
        })
      }
    },
    type (value) {
      this.filteredReasonsByType = this.reasons.filter(item => item.type_time_off === value)
      this.reason_id = ''
    },
    dateFrom (val, oldVal) {
      this.correctDateFrom()
      this.onFromChange(val, oldVal)
    },
    dateTo () {
      this.correctDateTo()
    },
    timeFrom () {
      this.correctDateFrom()
    },
    timeTo () {
      this.correctDateTo()
    },
    users (employeeId) {
      if (Number.isInteger(employeeId)) {
        this.$store.dispatch('getVacationsMonthReport', [
          this.companyId,
          moment(this.dateFrom).format(this.backendMonthYearFormat),
          employeeId
        ]).catch(() => {
          this.isVacationModuleEnable = false
        })
      }
    }
  },
  methods: {
    init () {
      const date = moment().startOf('day').format(this.backendMonthYearFormat)
      this.isVacationModuleEnable = true
      this.$store.dispatch('getVacationsMonthReport', [this.companyId, date, this.users]).catch(() => {
        this.isVacationModuleEnable = false
      })
      this.ModulesByCompany.forEach((module) => {
        if (module.module_id === 5) {
          if (!Array.isArray(module.settings)) {
            this.use_reasons = module.settings.use_reasons ? module.settings.use_reasons : false
          }
        }
      })
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, process.env.VUE_APP_COMPANY_FILES_ID]).then(resolve => {
        this.isFileUploadActive = true
      })
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, process.env.VUE_APP_TIME_OFF_REQUESTS_ID]).then(resolve => {
        this.dummy = false
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.$router.push('dashboard')
        }
      })
      this.$store.dispatch('getEmployeesByCompany', this.companyId)
    },

    activate () {
      this.dummy = false
    },
    closeModal () {
      this.$emit('closeCreateRequestModal')
    },

    correctDateFrom () {
      if (moment(this.dateFrom).isAfter(moment(this.dateTo))) {
        this.dateTo = this.dateFrom
      }
      if (moment(this.timeFrom, this.localeTimeFormatElementUi).isAfter(moment(this.timeTo, this.localeTimeFormatElementUi))) {
        this.timeTo = this.timeFrom
      }
    },
    correctDateTo () {
      if (moment(this.dateTo).isBefore(moment(this.dateFrom))) {
        this.dateFrom = this.dateTo
      }
      if (moment(this.timeTo, this.localeTimeFormatElementUi).isBefore(moment(this.timeFrom, this.localeTimeFormatElementUi))) {
        this.timeFrom = this.timeTo
      }
    },
    onFromChange (val, oldVal) {
      const oldDate = moment(oldVal)
      const newDate = moment(val)
      if (this.isVacationModuleEnable && !oldDate.isSame(newDate, 'month')) {
        this.$store.dispatch('getVacationsMonthReport', [
          this.companyId,
          newDate.format(this.backendMonthYearFormat),
          this.users
        ])
      }
    },
    submitForm (e) {
      e.preventDefault()
      if (this.size / 1000 > this.fileLimit) {
        return
      }
      const formData = new FormData()
      let dateTo = null
      let dateFrom = null
      if (this.duration === 'all_day') {
        // console.log(this.dateFrom, this.dateTo)
        dateFrom = this.toUTC(moment(this.dateFrom, this.backendDateTimeFormat).format(this.localeDateTimeFormat))
        dateTo = this.toUTC(moment(this.dateTo, this.backendDateTimeFormat).format(this.localeDateTimeFormat))
        dateTo = dateTo.add(1, 'days')
      }
      if (this.duration === 'part_day') {
        dateFrom = this.toUTC(moment(this.dateFrom, this.backendDateFormat).format(this.localeDateFormat) + ' ' + this.timeFrom)
        dateTo = this.toUTC(moment(this.dateFrom, this.backendDateFormat).format(this.localeDateFormat) + ' ' + this.timeTo)
        if (dateFrom.isSame(dateTo)) {
          dateTo = dateTo.add(1, 'days')
        }
      }
      formData.append('duration', this.duration)
      formData.append('move_shifts_action', this.move_shifts_action)
      formData.append('type_time_off', this.type)
      formData.append('subtype', this.subtype)
      formData.append('reason_id', this.reason_id)
      formData.append('date_from', dateFrom.format(this.backendDateTimeFormat))
      formData.append('date_to', dateTo.format(this.backendDateTimeFormat))
      formData.append('message', this.message)
      if (!this.isEmployeeHasPermission('delete-request')) {
        formData.append('employees', this.currentCompanyUserId)
      } else {
        formData.append('employees', this.users)
      }
      formData.append('company_id', this.$store.getters.companyId)
      if (this.paidType === 'unpaid') {
        formData.append('is_paid', false)
      } else if (this.paidType === 'paid') {
        formData.append('is_paid', true)
      }
      if (this.file) {
        formData.append('file', this.file)
      }
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('setRequest', [this.$store.getters.companyId, formData]).then(resolve => {
            if (resolve) {
              if (this.$refs.upload) {
                this.$refs.upload.cleanFile()
              }
              // console.log(resolve)
              if (this.subtype === 'time_off' && this.type === 'vacation' && resolve.is_overuse === true) {
                this.toastWarning(this.$i18n?.t('VACATION_OVERUSE_WARNING'))
              } else {
                this.toastSuccess(this.$i18n?.t('Request successfully send'))
              }
              if (this.move_shifts_action === 'move' || this.move_shifts_action === 'delete' || this.subtype === 'time_off') {
                this.$eventBus.emit('refetchEvents')
              }
              document.getElementById('modalCloseButton').click()
              this.closeModal()
            }
            // requestsModal
          }).catch((err) => {
            this.$notify({
              title: this.$i18n?.t('Error'),
              message: this.$i18n?.t(err.response.data.message),
              position: this.notificationPosition,
              type: 'error'
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
