<template>
  <div>
    <div>
      <span class="text-primary text-uppercase font-weight-bold">{{ $t('Notifications') }}</span>
    </div>
    <form @submit.prevent="submitForm">
      <Form ref="observer" as="div" class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col" class="text-left" style="width: 50%">
              {{ $t('Notification type') }}
            </th>
            <th scope="col" class="text-capitalize text-center" style="width: 16.66%">
              {{ $t('to email') }}
            </th>
            <th scope="col" class="text-capitalize text-center" style="width: 16.66%">
              {{ $t('to application (coming soon)') }}
            </th>
            <th scope="col" class="text-capitalize text-center" style="width: 16.66%">
              {{ $t('to phone number') }}*
            </th>
            <th scope="col" class="text-capitalize text-center" style="width: 16.66%">
              {{ $t('Telegram') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" class="text-left">{{ $t('Newsletter') }}<ui-hint :content="$t('NEWSLETTER_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="newsletter_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['newsletter.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="newsletter_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['newsletter.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="newsletter_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['newsletter.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="newsletter_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['newsletter.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('Swap / Drop Requests') }}<ui-hint :content="$t('SWAP_DROP_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="requests_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['requests.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="requests_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['requests.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="requests_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['requests.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="requests_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['requests.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('Schedule Updates') }}<ui-hint :content="$t('SCHEDULE_UPDATES_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="schedule_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['schedule.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="schedule_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['schedule.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="schedule_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['schedule.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="schedule_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['schedule.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('Password change') }}<ui-hint :content="$t('PASSWORD_CHANGE_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="password_change_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['password_change.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="password_change_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['password_change.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="password_change_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['password_change.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="password_change_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['password_change.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('LATE_NOTIFICATIONS') }}<ui-hint :content="$t('LATE_NOTIFICATIONS_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="late_notify_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['late_notify.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="late_notify_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['late_notify.app']"
                :centered="true"
              />
            </td>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="late_notify_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['late_notify.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('EARLY_LEAVING_NOTIFICATIONS') }}<ui-hint :content="$t('EARLY_LEAVING_NOTIFICATIONS_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="early_leaving_notify_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['early_leaving_notify.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="early_leaving_notify_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['early_leaving_notify.app']"
                :centered="true"
              />
            </td>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="early_leaving_notify_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['early_leaving_notify.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('LEFT_LOCATION_NOTIFICATION_SETTINGS') }}<ui-hint :content="$t('LEFT_LOCATION_NOTIFICATION_SETTINGS_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="left_location_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['left_location.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="left_location_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['left_location.app']"
                :centered="true"
              />
            </td>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="left_location_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['left_location.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">{{ $t('TASK_ATTACH') }}<ui-hint :content="$t('TASK_ATTACH_HINTS')"/></th>
            <td align="center">
              <ui-checkbox
                name="task_attach_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['task_attach.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="task_attach_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['task_attach.app']"
                :centered="true"
              />
            </td>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="task_attach_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['task_attach.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">
              {{ $t('ATTENDANCE_START_STOP_REMINDER') }}
              <ui-hint :content="$t('ATTENDANCE_START_STOP_REMINDER_HINTS')"/>
            </th>
            <td align="center">
              <ui-checkbox
                name="attendance_reminder_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['attendance_reminder.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="attendance_reminder_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['attendance_reminder.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="attendance_reminder_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['attendance_reminder.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="attendance_reminder_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['attendance_reminder.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">
              <ui-number-input
                :disabled="!editable"
                name="shift_reminder"
                v-model="profileState.notification_settings['reminder.hours']"
                :precision="2"
                :step="0.1"
                :min="0"
                :validation="{required: false, decimal: 2, greaterOrEqualThanZero: true}"
                :label="$t('Shift Reminders')"
                :hint="$t('SHIFT_REMINDERS_HINTS')"
              />
              <div class="font-weight-bold">{{ $t('hours before my shift') }}</div>
            </th>
            <td align="center">
              <ui-checkbox
                name="reminder_email"
                :disabled="!editable"
                v-model="profileState.notification_settings['reminder.email']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="reminder_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['reminder.app']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="reminder_phone"
                :disabled="!editable"
                v-model="profileState.notification_settings['reminder.phone']"
                :centered="true"
              />
            </td>
            <td align="center">
              <ui-checkbox
                name="reminder_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['reminder.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-left">
              <ui-number-input
                :disabled="!editable"
                name="break_reminder"
                v-model="profileState.notification_settings['break_reminder.minutes']"
                :step="1"
                :precision="0"
                :min="0"
                :validation="{required: false, integer: true, greaterOrEqualThanZero: true}"
                :label="$t('BREAKS_REMINDER')"
                :hint="$t('BREAKS_REMINDER_HINTS')"
              />
              <div class="font-weight-bold">{{ $t('MINUTES_BEFORE_BREAK') }}</div>
            </th>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="break_reminder_app"
                :disabled="!editable"
                v-model="profileState.notification_settings['break_reminder.app']"
                :centered="true"
              />
            </td>
            <td align="center">
            </td>
            <td align="center">
              <ui-checkbox
                name="break_reminder_telegram"
                :disabled="!editable"
                v-model="profileState.notification_settings['break_reminder.telegram']"
                :centered="true"
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div>
          <a
            id="demo_schedules_add_schedule"
            tag="button"
            target="_blank"
            :href="`https://t.me/shifton_inc_bot?start=` + profileState.telegram_token"
            class="btn btn-primary btn-sm rounded-pill shadow-sm">
            {{ $t('JOIN_TO_TELEGRAM') }}
          </a> {{ $t('OR_USE_TOKEN_IN_BOT') }}: <b>{{ profileState.telegram_token }}</b>
        </div>
        <small class="ml-2">* {{ $t("We cannot guarantee stable SMS delivery since delay on mobile carriers side is possible.") }}</small>
      </Form>
      <div v-if="editable">
        <div class="text-center py-2">
          <button type="submit" name="submit" class="btn btn-success rounded-pill shadow-sm" :disabled="disabled">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'NotificationSettings',
  mixins: [errorMixin],
  data () {
    return {
      disabled: false,
      editable: true,
      profileState: {}
    }
  },
  created () {
    if (this.isEditable === false) {
      this.editable = this.isEditable
    }
    this.profileState = this.Profile
  },
  props: ['Profile', 'isEditable'],
  methods: {
    submitForm () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            notification_settings: this.profileState.notification_settings
          }
          this.disabled = true
          this.$store.dispatch('setProfile', data).then((response) => {
            this.$store.dispatch('dropProfile').then(() => {
              this.$store.dispatch('getProfile')
            })
            this.toastSuccess(this.$i18n?.t('Profile successfully saved'))
            this.disabled = false
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  label {
    margin-bottom: 0;
  }

  table tr td {
    vertical-align: middle;
  }
</style>
